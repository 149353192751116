<template lang="pug">
b-container(fluid style="height: 100vh; background-color: #F4F4F4;")
    b-popover.col-1(class="popover" target="menu-popover" triggers="focus" placement="bottomleft")    
        div(style="text-align: right;")
            div(style="font-size: 14px") Date: {{ formattedDate }}
            div(style="font-size: 14px") Time: {{ formattedTime }}
        div.mt-4(style="text-align: right;")
            b-button(variant="danger" @click="moveToHomePage") Homepage

    //-- header            
    b-row.align-items-center(style="height: 10%;")
        //-- phone screen
        b-col.d-block.d-xl-none.col-12.px-4
            b-row.align-items-center
                b-col.col-2
                    Icon(icon="dashicons:menu-alt" width="40" style="cursor: pointer;" v-b-toggle.sidebar-1)
                //-- phone screen
                b-col.d-block.d-lg-none.col-4.text-left
                    b-img(class="" src="../assets/Images/DSG_logo.png", width="90" alt="DSG Logo" style="cursor: pointer;")
                //-- phone screen
                //-- ipad screen
                b-col.d-none.d-lg-block.col-4.text-left
                    b-img(class="" src="../assets/Images/DSG_logo.png", width="128" alt="DSG Logo" style="cursor: pointer;")
                //-- ipad screen
                b-col.col-6.text-right
                    b-button(class="" :style="`background-color: ${btnClickedColor}`" size="sm" @click="moveToHomePage") Back to Homepage
        //-- phone screen

        //-- other screen
        b-col.d-none.d-xl-block.col-12.px-4
            b-row.align-items-center
                b-col.col-6.text-left
                    b-img(class="" src="../assets/Images/DSG_logo.png", width="128px" alt="DSG Logo" style="cursor: pointer;")
                b-col.col-6.text-right
                    b-button(class="" :style="`background-color: ${btnClickedColor}`" @click="moveToHomePage") Back to Homepage
        //-- other screen

    //-- navigation
    the-navigation(class="" style="height: 90%;" cols="12")
</template>

  
<script lang="ts">
import {Icon} from '@iconify/vue2';
import TheNavigation from './admin-homepage/TheNavigation.vue';
// import TheNavigation from './admin-homepage/thenavigation.vue';
import dayjs from 'dayjs';

export default {
    data(){
        return{
            currentComponentName: null,  
            isPopoverVisible: true,
            currentDateTime: dayjs(),
            btnClickedColor: "#7C359F"
        }
    },
    name: "AdminHomepage",
    components: {
        Icon,
        TheNavigation,
    },
    async mounted() {},
    methods: {
        togglePopover() {
            this.isPopoverVisible = !this.isPopoverVisible;
        },
        moveToHomePage() {
            this.$router.push({ path: "/" });
        },
    },
    computed:{
        formattedDate() {
            return this.currentDateTime.format('DD-MM-YYYY');
        },
        formattedTime() {
            return this.currentDateTime.format('HH:mm');
        },
    },
    
}
</script>

<style>
.popover .popover-body {
    max-width: 140px !important;
    border: none;
    border-radius: 10px;
}
</style>
