<template lang="pug">
b-container(style="height: 85%")

    b-modal(id="new-appointment" title="Add New Appointment" body-class="position-static" size="lg" no-close-on-backdrop scrollable)
        b-overlay(no-wrap :show="isSubmitAddNewAppointment")
            template(#overlay)
        template
            //- b-form-group(label="Select client" label-for="event-group")
            //-     v-select(
            //-         id="select-client"
            //-         :options="listClient"
            //-         :reduce="item => ({ id: item.crb5c_fow_customerid, name: item.crb5c_no })" 
            //-         v-model="inputNewAppointment.inputSelectListAppointmentClient"
            //-         label="crb5c_no"
            //-         placeholder="Select Client"
            //-     )
            b-row
                b-col.col-6
                    b-form-group(id="input-group-1" label-for="input-1") Client Name
                        span(style="color:red")  *
                        b-form-input(id="input-1" v-model="inputNewAppointment.clientName" type="text" placeholder="Enter Client Name")
                b-col.col-6
                    b-form-group(id="input-group-3" label-for="input-3") Caregiver Name:
                        span(style="color:red")  *
                        b-form-input(id="input-3" v-model="inputNewAppointment.caregiverName" type="text" placeholder="Enter Caregiver Name")
            b-row
                b-col.col-6
                    b-form-group(id="input-group-4" label-for="input-4") Caregiver Email:
                        span(style="color:red")  *
                        b-form-input(id="input-4" v-model="inputNewAppointment.email" type="email" placeholder="Enter Caregiver Email")
                b-col.col-6
                    b-form-group(id="input-group-5" label="Phone Number:" label-for="input-5")
                        b-form-input(id="input-5" v-model="inputNewAppointment.phoneNumber" type="tel" placeholder="Enter Phone Number")
            //- b-form-group(id="input-group-6" label="Appointment Start Date:" label-for="input-6")
            //-     b-form-input(id="input-6" v-model="inputNewAppointment.appointmentStartDate" type="datetime-local")
            
            b-row
                b-col.col-6
                    b-form-group(id="input-group-startdate" label-for="input-startdate") Appointment Start Date
                        span(style="color:red")  *
                        b-form-datepicker(id="input-startdate" :min="todayDate" v-model="inputNewAppointment.appointmentStartDate" )
                b-col.col-6
                    b-form-group(id="input-group-starttime" label-for="input-starttime") Appointment Start Time
                        span(style="color:red")  *
                        p(v-if="notValidTime" style="color:red") Minimum 30 minutes from now.
                        b-time(id="input-starttime" locale="en" :min="minTime" v-model="inputNewAppointment.appointmentStartTime")
            b-row
                b-col.col-6
                    b-form-group(id="input-group-houroptions" label="Appointment Hours" label-for="input-houroptions")
                        b-form-select(id="input-houroptions" v-model="inputNewAppointment.appointmentHour" :options="listOptionAppointmentHour" class="mb-3")
                b-col.col-6
                    b-form-group(label="Appointment End Date and Time")
                        b-form-input(type="text" :value="appointmentEndDateIsoString" disabled)
            //- b-form-group(id="input-group-typeClient" label="Type of Client:" label-for="input-typeClient")
            //-     b-form-select(v-model="inputNewAppointment.typeClient" :options="listOptionTypeClient")


            //- b-row.align-items-center
            //-     b-col.col-9                        
            //-         b-form-group(id="input-group-8" label="Registration Link" label-for="input-8")
            //-             b-form-input(id="input-8" v-model="inputAppointmentEmail.registrationLink" type="text" :disabled="!inputAppointmentEmail.isEditingRegistrationLink")
            //-     b-col.col-3.text-center
            //-         //- b-form-group(id="input-group-0" label="Edit Registration Link" label-for="input-9")
            //-         b-form-checkbox(id="input-9" v-b-tooltip.hover :title="`${inputAppointmentEmail.isEditingRegistrationLink ? 'Disable' : 'Enable'} edit registration link`" v-model="inputAppointmentEmail.isEditingRegistrationLink" size="lg" switch)
        
        template(#modal-footer)
            p(v-if="disableSubmit") Fill up all the fields that are marked with 
                span(style="color:red")  *
            b-button(variant="success" @click="onSubmit" :disabled='disableSubmit') Add
            b-button(variant="secondary" @click="$bvModal.hide('new-appointment')") Cancel

    
    b-modal(:id="nameModal.modalClientId" title="More Info")
        template
        b-col(v-for="client in nameModal.data" :key="client.id" style="text-align: center;")
            b-row.p-2(style="display: flex; align-items: center; justify-content: center; background-color: #50276D; color: white; font-weight: 400; font-size: medium;")
                b-col Phone Number
                b-col Email
            b-row.p-2(style="border: 1px solid black; display: flex; align-items: center;")
                b-col {{ client.clientContact }} 
                b-col {{ client.clientEmail }}
        template(#modal-footer)
            b-button(@click="hideNameModal") Close

    //- b-modal(id="open-calendar" hide-footer modal-class="wider-modal" title="Filter Client Date")
    //-     template
    //-     b-collapse(id="filter")
    //-         b-card.mb-3(style="border-radius: 20px")
    //-             div.d-flex.align-items-start.mb-3(style="font-weight: 600") Sort by Selected Date : 
    //-             div.d-flex.align-items-center
    //-                 b-form-datepicker(type="date" v-model="datefilter1")
    //-                 span.mx-3 to
    //-                 b-form-datepicker(type="date" v-model="datefilter2")
    //-                 b-button.ml-2(@click="filterDate") Filter
    //-                 b-button.ml-2.btn-danger(@click="clearDate") Clear
    //-     template
    //-         div.d-flex.justify-content-end.align-items-center
    //-             b-button(@click="$bvModal.hide('open-calendar')") OK


    //---- modal delete confirmation
    b-modal(:id="modalDeleteAppointment.modalId" title="Delete Confirmation" body-class="position-static" no-close-on-backdrop)
        b-overlay(no-wrap :show="modalDeleteAppointment.isDeleteAppointmentItem")
            template(#overlay)
        template
            b-row
                b-col.col-12
                    span Are you sure you want to delete this appointment?
        template(#modal-footer)
            b-button(variant="danger" @click="deleteAppointment(staffEmail)") Delete
            b-button(variant="secondary" @click="closeModalDeleteAppointment") Cancel
    //---- modal delete confirmation

        
    b-col.mt-5
        b-row.mb-3.align-items-center
            b-col.col-12.col-sm-6(class="d-flex justify-content-start")
                h4 List of Appointment
            b-col.col-12.col-sm-6.mt-3.mt-sm-0(class="d-flex justify-content-end")
                b-button.font-weight-bold(style="background-color: #7C359F;" size="sm" @click="$bvModal.show('new-appointment')") Add New Appointment

        b-row
            //---- phone screen ----//
            b-col.col-12.d-block.d-sm-none(style="max-height: 35rem; overflow-y: auto;")
                b-table(
                    :fields="tableFields" 
                    :items="mappedListUpcomingAppointment"
                    class="table-header-color"
                    thead-tr-class="text-white"
                    tbody-tr-class="bg-white"
                    show-empty
                )
                    template(#empty="scope")
                        b-row(v-if="isFetchingAdminDashboardData")
                            b-col.col-12
                                b-spinner(variant="info")
                        b-row(v-else)
                            b-col.col-12
                                span No data
                        //- b-row
                        //-     b-col.col-12
                        //-         span No data
                    template(#cell(No)="data")
                        span {{ data.item.no }}
                    template(#cell(clientName)="data")
                        span {{ data.item.clientName }}
                    template(#cell(caregiverName)="data")
                        span {{ data.item.caregiverName }}
                    //- template(#cell(phoneNo)="data")
                    //-     span {{ data.item.phoneNo }}
                    template(#cell(email)="data")
                        span {{ data.item.email }}
                    //- template(#cell(clientType)="data")
                    //-     span {{ data.item.email }}
                    template(#cell(appointmentStartDate)="data")
                        span {{ formatDate(data.item.dateOfAppointmentStartDate) }}
                    template(#cell(appointmentEndDate)="data")
                        span {{ formatDate(data.item.dateOfAppointmentEndDate) }}
                    template(#cell(Action)="data")
                        b-button.font-weight-bold(variant="danger" size="sm" @click="openModalDeleteAppointment(data.item.crb5c_fowappointmentid, data.item.crb5c_meetingteamsid)") Delete
            //---- phone screen ----//
            //---- other screen ----//
            b-col.col-12.d-none.d-sm-block(style="max-height: 20rem; overflow-y: auto;")
                b-table(
                    :fields="tableFields" 
                    :items="mappedListUpcomingAppointment"
                    class="table-header-color"
                    thead-tr-class="text-white"
                    tbody-tr-class="bg-white"
                    show-empty
                )
                    template(#empty="scope")
                        b-row(v-if="isFetchingAdminDashboardData")
                            b-col.col-12
                                b-spinner(variant="info")
                        b-row(v-else)
                            b-col.col-12
                                span No data
                        //- b-row
                        //-     b-col.col-12
                        //-         span No data
                    template(#cell(No)="data")
                        span {{ data.item.no }}
                    template(#cell(clientName)="data")
                        span {{ data.item.clientName }}
                    template(#cell(caregiverName)="data")
                        span {{ data.item.caregiverName }}
                    //- template(#cell(phoneNo)="data")
                    //-     span {{ data.item.phoneNo }}
                    template(#cell(email)="data")
                        span {{ data.item.email }}
                    template(#cell(appointmentStartDate)="data")
                        span {{ formatDate(data.item.dateOfAppointmentStartDate) }}
                    template(#cell(appointmentEndDate)="data")
                        span {{ formatDate(data.item.dateOfAppointmentEndDate) }}
                    template(#cell(Action)="data")
                        b-button.font-weight-bold(variant="danger" size="sm" @click="openModalDeleteAppointment(data.item.crb5c_fowappointmentid, data.item.crb5c_meetingteamsid)") Delete
            //---- other screen ----//

        b-row
            b-col.col-12.py-2.text-left
                span Total appointment: {{ mappedListUpcomingAppointment.length }}


</template>

<script>
import dayjs from 'dayjs';
import { mapState, mapMutations, mapActions } from 'vuex';
import { z } from 'zod';
import { ADMIN_DASHBOARD_NAMESPACE, MUTATIONS, ACTIONS } from "../../store/Modules/AdminDashboard/types-admin-dashboard.js";
// eslint-disable-next-line no-unused-vars
import { TABLE } from '../../store/ModuleName';


export default {
    data() {
        return {
            notValidTime: false,
            minTime: '00:00' ,
            todayDate: dayjs().format('YYYY-MM-DD'),
            staffEmail: null,
            listRegistrationLink: ["https://fow-registration.npo.sg/?formType=FOW", "https://fow-registration.npo.sg/?formType=CIP"],
            tableFields: [
                "No",
                { 
                    key: "clientName", 
                    label: "Client Name",
                },
                { 
                    key: "caregiverName", 
                    label: "Caregiver Name",
                },
                { 
                    key: "email", 
                    label: "Email",
                },
                // { 
                //     key: "phoneNo", 
                //     label: "Phone No",
                // },
                // {
                //     key: "clientType",
                //     label: "Client Type",
                // },
                { 
                    key: "appointmentStartDate", 
                    label: "Appointment Start Date",
                },
                { 
                    key: "appointmentEndDate", 
                    label: "Appointment End Date",
                },
                "Action",
            ],
            inputNewAppointment: {
                clientName: null,
                caregiverName: null,
                email: null,
                phoneNumber: null,
                appointmentStartDate: null,
                appointmentStartTime: null,
                appointmentHour: 0.5,
                // appointmentEndDate: null,
                isEditingRegistrationLink: false,
                typeClient: "FOW",
            },
            listOptionTypeClient: [
                { value: "FOW", text: "FOW" },
                { value: "CIP", text: "CIP" }
            ],
            inputAppointmentEmail: {
                registrationLink: null,
                // caregiverEmail: null,
                // emailSubject: null,
                // emailMessage: null,
            },
            listOptionAppointmentHour: [
                { value: 0.5, text: "30 minutes" },
                { value: 1, text: "1 hour" },
                { value: 1.5, text: "1 hour 30 minutes" }
            ],
            clients: [],
            filteredClients:[],
            form: {
                name: '',
                caregivername: '',
                // typeAppointment: '',
                date: null,
                phone: '',
                email: '',
                message: ''
            },
            show: true,
            value: '',
            context: null,
            nameModal: {
                modalClientId: null,
                data: null
            },
            datefilter1:null,
            datefilter2:null,
            isSubmitAddNewAppointment: false,
            modalDeleteAppointment: {
                modalId: null,
                isDeleteAppointmentItem: false,
            }
        }
    },
    async mounted(){
        this.staffEmail = this.currentAdmin?.username;
        // this.inputAppointmentEmail.registrationLink = this.listRegistrationLink[0];
        // this.deleteTeamsCalendar(this.staffEmail, )

    },
    computed: {
        disableSubmit(){
            if ((this.inputNewAppointment.clientName &&  this.inputNewAppointment.caregiverName &&  this.inputNewAppointment.appointmentStartDate 
            &&  this.inputNewAppointment.email &&  this.inputNewAppointment.appointmentStartTime) && !this.notValidTime) {
            return false;     
            }
            return true;
        },
        ...mapState({
            currentAdmin: state => state.currentAdmin,
            listUpcomingAppointment: state => (state[ADMIN_DASHBOARD_NAMESPACE].adminDashboard.listUpcomingAppointment),
            // listClient: state => (state[ADMIN_DASHBOARD_NAMESPACE].adminDashboard.listClient),
            isFetchingAdminDashboardData: state => (state[ADMIN_DASHBOARD_NAMESPACE].adminDashboard.isFetchingAdminDashboardData),
        }),
        appointmentEndDateIsoString(){
            const { appointmentStartDate, appointmentStartTime, appointmentHour } = this.inputNewAppointment;
            if(!appointmentStartDate || !appointmentStartTime || !appointmentHour){
                return "No date";
            }

            let dateIsoString;

            if(appointmentHour === 0.5){
                dateIsoString = dayjs(`${appointmentStartDate} ${appointmentStartTime}`).add(30, "m").toISOString();
            }
            if(appointmentHour === 1){
                dateIsoString = dayjs(`${appointmentStartDate} ${appointmentStartTime}`).add(1, "h").toISOString();
            }
            if(appointmentHour === 1.5){
                dateIsoString = dayjs(`${appointmentStartDate} ${appointmentStartTime}`).add(1, "h").add(30, "m").toISOString();
            }

            return dayjs(dateIsoString).format('DD MMM YY, hh:mm A');

        },
        mappedListUpcomingAppointment(){
            if(this.listUpcomingAppointment.length === 0){
                return [];
            }

            return this.listUpcomingAppointment.map((item, index) => ({
                no: index + 1,
                clientName: item?.crb5c_clientname,
                caregiverName: item?.crb5c_caregivername,
                phoneNo: item?.crb5c_phonenumber,
                email: item?.crb5c_email,
                dateOfAppointmentStartDate : item?.crb5c_appointmentdate,
                dateOfAppointmentEndDate : item?.crb5c_appointmentenddate,
                crb5c_fowappointmentid: item?.crb5c_fowappointmentid,
                crb5c_meetingteamsid: item?.crb5c_meetingteamsid,
            }))
        }
    },
    methods: {
        checkDateTimeValidity(date, time) {
        const selectedDateTime = dayjs(`${date} ${time}`);
        const currentDateTime = dayjs();
            if (selectedDateTime.isBefore(currentDateTime)) {
                this.notValidTime = true;
            }
            else{
                this.notValidTime = false;
            }
        },
        ...mapMutations(ADMIN_DASHBOARD_NAMESPACE, [
            MUTATIONS.REMOVE_ITEM_FROM_LIST_APPOINTMENT,
        ]),
        ...mapActions(ADMIN_DASHBOARD_NAMESPACE, [
           ACTIONS.DB_ADD_APPOINTMENT,
           ACTIONS.SEND_EMAIL_REMINDER_ASSESSMENT,
           ACTIONS.DB_GET_APPOINTMENT,
        ]),
        async getTeamsCalendar(staffEmail){
            try {
                if(!staffEmail){
                    return;
                }
                
                const queryParams = new URLSearchParams({
                    staffEmail,
                });
                const { data } = await this.$store.getters[`${TABLE}/api`].get(`/admin_dashboard/meetingCalendar?${queryParams.toString()}`);
                console.log("Teams calendar data: ", data);
                
            } catch (error) {
                console.error(error);
            }

        },
        async postTeamsCalendar(staffEmail, eventTitle, startDate, endDate){
            try {
                return await this.$store.getters[`${TABLE}/api`].post(`/admin_dashboard/meetingCalendar`, {
                    staffEmail,
                    eventTitle,
                    startDate, 
                    endDate
                });
                
            } catch (error) {
                console.error(error);
                alert("Fail to add appointment");
                return null;
            }

        },
        async deleteTeamsCalendar(staffEmail, teamsCalendarId){
            try {
                await this.$store.getters[`${TABLE}/api`].delete(`/admin_dashboard/meetingCalendar`, {
                    data: {
                        staffEmail, 
                        teamsCalendarId,
                    }
                });

            } catch (error) {
                console.error(error);
            }
        },
        formatDate(rawDate){
            if(!rawDate){
                return "No Date";
            }

            return dayjs(rawDate).format("DD MMM YY, hh:mm A");
        },
        filterDate(){
            if(this.datefilter1 !== null && this.datefilter2 !== null){
                /*for(let i = 0 ; i < this.clients.length; i++){
                    if((dayjs(this.clients[i].clientDate).isAfter(dayjs(this.datefilter1)) && dayjs(this.clients[i].clientDate).isBefore(this.datefilter2))){
                        filtered.push(this.clients[i])
                    } 
                }*/
                this.filteredClients = this.clients
                .filter(client => dayjs(client.clientDate).isAfter(dayjs(this.datefilter1)) && dayjs(client.clientDate).isBefore(this.datefilter2))
            }else{
                this.filteredClients = this.clients
                alert("Select both filter dates.")
            }
        },
        clearDate(){
            this.datefilter1 = null;
            this.datefilter2 = null;
            this.filteredClients = this.clients
        },
        clearInputAddNewAppointement(){
            this.inputNewAppointment.clientName = null;
            this.inputNewAppointment.caregiverName = null,
            this.inputNewAppointment.email = null,
            this.inputNewAppointment.phoneNumber = null,
            this.inputNewAppointment.appointmentStartDate = null;
            this.inputNewAppointment.appointmentStartTime = null;
            this.inputNewAppointment.typeClient = "FOW";
            // this.inputNewAppointment.appointmentEndDate = null;
            
        },
        // async sendEmailToCaregiver(staffEmail, caregiverEmail, clientName, startDateIsoString, typeClient){
        async sendEmailToCaregiver(staffEmail, caregiverEmail, clientName, startDateIsoString){
            try {
                // const { registrationLink } = this.inputAppointmentEmail;
                const dateAppointmentFormatted = dayjs(startDateIsoString).format("DD-MMM");
                const timeAppointmentFormatted = dayjs(startDateIsoString).format("h.mm a");
                const emailSubject = `DSG FOW Appointment for ${clientName} on ${dateAppointmentFormatted} at ${timeAppointmentFormatted}`;

                // const listFowEmailMessage = [
                //     "Dear Caregiver,",
                //     `Please assist us to complete this registration form before you bring your loved ones Mr ${clientName} to our Centre for assessment on ${dateAppointmentFormatted} at ${timeAppointmentFormatted} : <a href="${registrationLink}" target="_blank">Registration Link</a>.`,
                //     `On the day of assessment on ${dateAppointmentFormatted}, we will collect a one-time assessment fee of $50 + GST and a one-month refundable deposit of $320. The preferred mode of payment is via scanned QR code for PayLah or PayNow. Alternatively, cash is also acceptable.`,
                //     `Our address is : 20 Bendemeer Rd #01-02, BS Bendemeer Centre, Singapore 339914 (Please press the bell at the glass door that is opposite a café).`,
                //     `Attached is an information write-up about our Family of Wisdom programme.`,
                //     `For any further queries, you may contact us either at 63895385 or 80205006.`,
                // ];

                // const listCipEmailMessage = [
                //     "Dear Caregiver,",
                //     `Please assist us to complete this registration form before you bring your loved ones Mr ${clientName} to our Centre for assessment on ${dateAppointmentFormatted} at ${timeAppointmentFormatted} : <a href="${registrationLink}" target="_blank">Registration Link</a>.`,
                //     `On the day of assessment on ${dateAppointmentFormatted}, we will collect a one-time registration fee of $50 + GST and an advance fee for the entire month. The one-time assessment fee also includes a one-session free trial. The preferred mode of payment is via scanned QR code for PayLah or PayNow. Alternatively, cash is also acceptable.`,
                //     `Our address is : 20 Bendemeer Rd #01-02, BS Bendemeer Centre, Singapore 339914 (Please press the bell at the glass door that is opposite a café).`,
                //     `Attached is an information write-up about our Family of Wisdom programme.`,
                //     `For any further queries, you may contact us either at 63895385 or 80205006.`,
                // ];


                const listEmailMessage = [
                    "Dear Caregiver,",
                    // `Please assist us to complete this registration form before you bring your loved ones Mr ${clientName} to our Centre for assessment on ${dateAppointmentFormatted} at ${timeAppointmentFormatted} : <a href="${registrationLink}" target="_blank">Registration Link</a>.`,
                    `Please assist us to complete the attached registration form below before you bring your loved ones Mr ${clientName} to our Centre for assessment on ${dateAppointmentFormatted} at ${timeAppointmentFormatted}`,
                    `On the day of assessment on ${dateAppointmentFormatted}, we will collect a one-time assessment fee of $50 + GST and a one-month refundable deposit of $320. The preferred mode of payment is via scanned QR code for PayLah or PayNow. Alternatively, cash is also acceptable.`,
                    `Our address is : 20 Bendemeer Rd #01-02, BS Bendemeer Centre, Singapore 339914 (Please press the bell at the glass door that is opposite a café).`,
                    // `Attached is an information write-up about our Family of Wisdom programme.`,
                    `For any further queries, you may contact us either at 63895385 or 80205006.`,
                ];


                
                // const data = await this[ACTIONS.SEND_EMAIL_REMINDER_ASSESSMENT]({
                //     staffEmail,
                //     caregiverEmail,
                //     emailSubject,
                //     // emailMessage,
                //     listEmailMessage: typeClient === "FOW" ? listFowEmailMessage : listCipEmailMessage,
                //     typeClient,
                // });

                const data = await this[ACTIONS.SEND_EMAIL_REMINDER_ASSESSMENT]({
                    staffEmail,
                    caregiverEmail,
                    emailSubject,
                    // emailMessage,
                    listEmailMessage,
                    // typeClient,
                });
        
                if(!data){
                    throw new Error("No data");
                }

            } catch (error) {
                console.error(error);
            }



        },
        validateAppointmentForm(payloadInputAppointment){
            try {
                // const phoneRegex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);

                const SchemaAppointmentForm = z.object({
                    clientName: z.string(),
                    caregiverName: z.string(),
                    email: z.string().email(),
                    // phoneNumber: z.string().regex(phoneRegex, 'Invalid Number!'),
                    appointmentStartDate: z.string().datetime(),
                    appointmentEndDate: z.string().datetime(),
                    registrationLink: z.string().url(),
                })
                .strict();

                SchemaAppointmentForm.parse(payloadInputAppointment);
                
                return true;

            } catch (error) {
                console.error(error);
                alert("Please complete appointment form with correct format");
                
                return false;
            }
        },
        async onSubmit() {
            try {
                const { 
                    clientName,
                    caregiverName,
                    email,
                    // phoneNumber, 
                    appointmentStartDate,
                    appointmentStartTime,
                    typeClient
                } = this.inputNewAppointment;

                // if(typeClient === "FOW"){
                //     this.inputAppointmentEmail.registrationLink = this.listRegistrationLink[0];
                // }

                // if(typeClient === "CIP"){
                //     this.inputAppointmentEmail.registrationLink = this.listRegistrationLink[1];
                // }

                this.inputAppointmentEmail.registrationLink = this.listRegistrationLink[0];

                const { registrationLink } = this.inputAppointmentEmail;
                
                const appointmentStartDateISOstring = dayjs(`${appointmentStartDate} ${appointmentStartTime}`).toISOString();
                const appointmentEndDateISOstring = dayjs(this.appointmentEndDateIsoString).toISOString();

                const payloadForValidation = {
                    clientName,
                    caregiverName,
                    email,
                    // phoneNumber, 
                    appointmentStartDate: appointmentStartDateISOstring,
                    appointmentEndDate: appointmentEndDateISOstring,
                    registrationLink,
                }

                const isValid = this.validateAppointmentForm(payloadForValidation);
                
                if(!isValid){
                    return;
                }


                this.isSubmitAddNewAppointment = true;

                const appointmentName = `Appointment ${clientName} ${typeClient}`;
                const { data: teamsData } = await this.postTeamsCalendar(
                    this.staffEmail, 
                    appointmentName, 
                    appointmentStartDateISOstring,
                    appointmentEndDateISOstring,
                );

                if(!teamsData){
                    this.isSubmitAddNewAppointment = false;
                    return;
                }

                // do post request to post appointment table
                const data = await this[ACTIONS.DB_ADD_APPOINTMENT]({
                    clientName,
                    caregiverName,
                    email,
                    // phoneNumber, 
                    appointmentStartDate: appointmentStartDateISOstring,
                    appointmentEndDate: appointmentEndDateISOstring,
                    meetingTeamsId: teamsData.id,
                });

                if(!data){
                    throw new Error("Response is empty");
                }

                await this.sendEmailToCaregiver(this.staffEmail, email, clientName, appointmentStartDateISOstring, typeClient);

                console.log("response after submit: ", data);

                alert("Add appointment successful!");

                await this[ACTIONS.DB_GET_APPOINTMENT]();
                
                this.$nextTick(() =>{
                    this.$bvModal.hide('new-appointment');
                });

                this.clearInputAddNewAppointement();
                this.isSubmitAddNewAppointment = false;
                // location.reload();
                
            } catch (error) {
                console.error(error);
                this.isSubmitAddNewAppointment = false;
                this.clearInputAddNewAppointement();
                alert("Fail to add appointment!");
                this.$nextTick(() =>{
                    this.$bvModal.hide('new-appointment')
                });
            }
            
        },
        openModalDeleteAppointment(crb5c_fowappointmentid, crb5c_meetingteamsid){
            const modalId = `modalDelete/${crb5c_fowappointmentid}/${crb5c_meetingteamsid}`;
            this.modalDeleteAppointment.modalId = modalId;
            this.$nextTick(() => {
                this.$bvModal.show(this.modalDeleteAppointment.modalId);
            });

        },
        closeModalDeleteAppointment(){
            this.$bvModal.hide(this.modalDeleteAppointment.modalId);
            this.modalDeleteAppointment.modalId = null;

        },
        async deleteAppointment(staffEmail){
            try {
                if(!this.modalDeleteAppointment.modalId){
                    return;
                }

                this.modalDeleteAppointment.isDeleteAppointmentItem = true;
    
                // eslint-disable-next-line no-unused-vars
                const [_, crb5c_fowappointmentid, teamsCalendarId] = this.modalDeleteAppointment.modalId.split("/");
                await this.dbDeleteAppointment(crb5c_fowappointmentid);
                await this.deleteTeamsCalendar(staffEmail, teamsCalendarId);

                this.modalDeleteAppointment.isDeleteAppointmentItem = false;
                this.closeModalDeleteAppointment();
                alert("Delete appoinment sucessful");
                
            } catch (error) {
                console.error(error);
                this.closeModalDeleteAppointment();
                alert("Delete appoinment failed");
            }


        },
        async dbDeleteAppointment(crb5c_fowappointmentid){
            await this.$store.getters[[`${TABLE}/api`]].delete("/admin_dashboard/addAssessmentAppointment", {
                data: {
                    crb5c_fowappointmentid,
                }
            });
            this[MUTATIONS.REMOVE_ITEM_FROM_LIST_APPOINTMENT](crb5c_fowappointmentid);

        },
        backAdminHomepage() {
            this.$router.push({ path: '/adminhomepage' })
        },
        moveToHomePage(){
            this.$router.push({ path: "/" })
        },
        openNameModal(clientId){
            this.nameModal.modalClientId = `sendModal-${clientId}`
            this.nameModal.data = this.listModalData(this.nameModal.modalClientId)
            this.$nextTick(() =>{
                this.$bvModal.show(this.nameModal.modalClientId)
            })

            console.log("data: ", this.nameModal.data)
        },
        listModalData(modalClientId){
            const clientId = modalClientId.split("-")[1]

            return this.clients
            .filter(client => client.id === +clientId)
        },
        hideNameModal(){
            this.$bvModal.hide(this.nameModal.modalClientId)

            this.nameModal.modalClientId = null
            this.nameModal.data = null
        }
    },
    watch: {
        'inputNewAppointment.appointmentStartDate'(newDate) {
            this.checkDateTimeValidity(newDate, this.inputNewAppointment.appointmentStartTime);
        },
        'inputNewAppointment.appointmentStartTime'(newTime) {
            this.checkDateTimeValidity(this.inputNewAppointment.appointmentStartDate, newTime);
        },
        async currentAdmin(value){
            if(value.username){
                this.staffEmail = value.username;
                await this.getTeamsCalendar(this.staffEmail);
                // this.deleteTeamsCalendar(value.username, "");

            }
        }

    }
  
}
</script>

<style>
.collapsed>.when-closed,
.not-collapsed>.when-open {
    display: none;
    }
.wider-modal .modal-dialog{
    max-width: 1000px;
}

.table-header-color{
  background-color: #50276D;
}

.table__body{
    text-align: center; 
    max-height: 250px; 
    overflow-y: scroll; 
    border-collapse: separate; 
    border-spacing: 0px;
}

.table__body::-webkit-scrollbar{
    width: 0.5rem;
    height: 0.5rem;
}

.table__body::-webkit-scrollbar-thumb{
    border-radius: 0.5rem;
    background-color: #0004;
}
</style>

