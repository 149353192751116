import moment from 'moment';

const DATE_PATTERN_FORMAT = 'DD/MM/YYYY';
const INPUT_DATE_FORMAT = 'yyyy-MM-DD';
const TIME_WITHOUT_AM_OR_PM_PATTERN_FORMAT = 'HH:mm:ss';
const TIME_PATTERN_FORMAT = 'h:mm A';
const DATE_AND_TIME = 'DD/MM/YYYY h:mm A';

// TODO: refactor using dayjs instead of moment

function formatDate(date){
    if(!date){
        return 'No Date'
    }

    return moment(date).format(DATE_PATTERN_FORMAT);
}


function formatDateToInputDate(date){
    if(!date){
        return null
    }

    return moment(date, DATE_PATTERN_FORMAT).format(INPUT_DATE_FORMAT);
}



function formatTimeFromDate(dateTime){

    // console.log("DATETIME", dateTime)
    if(!dateTime){
        return 'No Date and Time'
    }

    const dateAndTime = moment(dateTime, `${DATE_PATTERN_FORMAT} ${TIME_PATTERN_FORMAT}`).toDate();

    return moment(dateAndTime).format(DATE_AND_TIME);
}


export { DATE_PATTERN_FORMAT, TIME_PATTERN_FORMAT, TIME_WITHOUT_AM_OR_PM_PATTERN_FORMAT, formatDate, formatTimeFromDate, formatDateToInputDate };