<template lang="pug">
b-sidebar(class="d-block d-xl-none" id="sidebar-1" body-class="sidebarColor" header-class="sidebarColor" shadow backdrop)
    b-container
        b-row.pl-4(style="color: white; margin-top: 3rem; font-weight: 550;") 
            button.col-4.ml-2.d-flex.justify-content-center.align-items-center(class="home-button" style="font-weight: 500; padding: 0px") 
                router-link(to="/admindashboard" style="color: white; font-weight: bold; text-decoration: none") Home
        b-row(style="color: white; margin-top: 3rem; font-weight: 550")
            b-col.col-12
                b-button(style="height: fit-content; border: none; background-color: inherit; font-weight: 500; cursor: default;") Management
        
        b-row.pl-4.justify-content-start.align-items-start    
            div.col-10.mt-4.d-flex.justify-content-start.align-items-center(style="font-weight: 400; margin-top: 0rem")   
                button.d-flex.justify-content-start.align-items-center(class="appointment-button")
                    Icon.mr-2(icon="carbon:report" height="10%" color="white")
                    router-link(to="/report" style="color: white; background-color: inherit; text-decoration: none") Report
            div.col-10.mt-2.d-flex.justify-content-start.align-items-center(style="font-weight: 400; margin-top: 0rem")   
                button.d-flex.justify-content-start.align-items-center(class="appointment-button")
                    Icon.mr-2(icon="guidance:calendar" height="10%" color="white")
                    router-link(to="/appointment" style="color: white; background-color: inherit; text-decoration: none") Appointment
            div.col-10.mt-2.d-flex.justify-content-start.align-items-center(style="font-weight: 400; margin-top: 1.25rem;") 
                button.d-flex.justify-content-start.align-items-center(class="assessment-button")
                    Icon.mr-2(icon="fe:list-task" height="10%" color="white")
                    router-link(to="/upcoming-assessment" style="color: white; background-color: inherit; text-decoration: none") Assessment
            div.col-10.mt-2.d-flex.justify-content-start.align-items-center(style="font-weight: 400; margin-top: 1.25rem") 
                button.d-flex.justify-content-start.align-items-center(class="client-button")
                    Icon.mr-2(icon="fluent-mdl2:people" height="10%" color="white")
                    router-link(to="/client" style="color: white; background-color: inherit; text-decoration: none") Clients
            div.col-10.mt-2.d-flex.justify-content-start.align-items-center(style="font-weight: 400; margin-top: 1.25rem") 
                button.d-flex.justify-content-start.align-items-center(class="client-button")
                    Icon.mr-2(icon="ri:survey-line" height="10%" color="white")
                    router-link(to="/survey" style="color: white; background-color: inherit; text-decoration: none") Survey
        
        b-row(style="color: white; margin-top: 3rem; font-weight: 550")
            b-col.col-12
                b-button(style="height: fit-content; border: none; background-color: inherit; font-weight: 500; cursor: default;") Scheduler
        
        
        b-row.pl-4.justify-content-start.align-items-start    
            div.col-10.mt-4.d-flex.justify-content-start.align-items-center(style="font-weight: 400; margin-top: 0rem") 
                button.d-flex.justify-content-start.align-items-center(class="weekly-button")
                    Icon.mr-2(icon="bi:calendar2-week" height="10%" color="white")
                    router-link(to="/weekly" style="color: white; background-color: inherit; text-decoration: none") Weekly
            div.col-10.mt-2.d-flex.justify-content-start.align-items-center(style="font-weight: 400; margin-top: 0rem")
                button.d-flex.justify-content-start.align-items-center(class="master-button")
                    Icon.mr-2(icon="ph:calendar-check" height="10%" color="white")
                    router-link(to="/master" style="color: white; font-weight: 500; background-color: inherit; text-decoration: none") Master
</template>

<script>
import { Icon } from '@iconify/vue2';
export default{
    components: {
        Icon,
    },
    data(){
        return{}
    },
    methods: {},
}
</script>

<style>
.sidebarColor{
    background-color: #50276B;
}
</style>