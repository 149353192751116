<template lang="pug">
b-container(style="height: 100vh;")
    //--------------- modal add event
    b-modal(:id="modal.modalAddEventId" size="lg" title="Create Event" scrollable no-close-on-backdrop)
        template
            b-form-group(label="Event Name (required)" label-for="event-name")
                b-form-input(id="event-name" placeholder="Event name" v-model="modal.addEventinput.title")
            b-form-group(label="Group (required)" label-for="event-group")
                v-select(
                    id="event-group" 
                    :options="listGroupSession"
                    :reduce="item => ({ id: item.id, name: item.name })" 
                    v-model="modal.addEventinput.listGroup"
                    multiple 
                    label="name"
                    placeholder="Select Group"
)
            b-form-group(label="Date (required)" label-for="event-date")
                b-form-input(id="event-date" type="date" v-model="modal.addEventinput.date")
            b-form-group(label="Time (required)" label-for="event-time")
                b-form-input(id="event-time" type="time" v-model="modal.addEventinput.time")
            b-form-group(label="Description (required)" label-for="event-description")
                vue-editor(v-model="modal.addEventinput.description" :editorToolbar="customToolbar")
            b-form-checkbox.my-2(v-model="modal.itineraryState.isIncludeItinerary" :value="true" :unchecked-value="false") Include Itinerary             
            b-collapse.mb-4(v-model="modal.itineraryState.isIncludeItinerary")
                b-row.align-items-center
                    b-col.col-12.col-sm-5
                        b-form-group(id="event-form-itinerary-time" label="Time" label-for="event-itinerary-time")
                            b-form-input(id="event-itinerary-time" type="time" v-model="modal.itineraryState.time")
                    b-col.col-12.col-sm-5
                        b-form-group(id="event-form-itinerary-description" label="Description" label-for="event-itinerary-description")
                            b-form-input(id="event-itinerary-description" placeholder="Enter description" type="text" v-model="modal.itineraryState.description" @keyup.enter="eventAddItineraryItem")
                    b-col.col-12.col-sm-2
                        //-- phone screen --//
                        b-button(class="d-block d-sm-none w-100" variant="success" @click="eventAddItineraryItem") Add
                        //-- phone screen --//
                        //-- other screen --//
                        b-button(class="d-none d-sm-block" variant="success" @click="eventAddItineraryItem") Add
                        //-- other screen --//
                b-row.mt-2(v-if="modal.addEventinput.itinerary?.length !== 0")
                    b-col.col-12
                        b-row(v-if="")
                            b-col.col-12.mb-2
                                span List Itinerary
                            b-col.col-12
                                b-row.mx-1
                                    b-col.col-12.border
                                        b-row.align-items-center(style="min-height: 2.5rem;")
                                            b-col.col-5(style="font-weight: 600;")
                                                span Time
                                            b-col.col-5(style="font-weight: 600;")
                                                span Description
                                            b-col.col-2(style="font-weight: 600;")
                                        b-row.py-2.align-items-center.border.border-right-0.border-bottom-0.border-left-0(
                                            v-for="(item, index) in modal.addEventinput.itinerary" :key="index"
                                            style="min-height: 2.5rem;"
                                            )
                                            b-col.col-5
                                                span {{ item.time }}
                                            b-col.col-5
                                                span {{ item.description }}
                                            b-col.col-2.d-flex.justify-content-center
                                                b-button(variant="danger" @click="eventRemoveItineraryItem(index)") Remove

            b-row
                b-col.col-12.my-2
                    span Lunch
                b-col.col-12.col-sm-6
                    //-- Main lunch
                    b-form-group(id="event-form-main-lunch" label="Main (required)" label-for="event-main-lunch")
                        b-input-group.mb-3(id="event-main-lunch" label="Main Lunch" label-for="event-lunch")
                            b-form-input(id="event-lunch" type="text" placeholder="Enter main lunch" v-model="modal.lunchState.mealName" @keyup.enter="eventAddMainLunch")
                            b-input-group-append
                                b-button(variant="success" @click="eventAddMainLunch") Add
                b-col.col-12.col-sm-6
                    //-- beverage lunch
                    b-form-group(id="event-form-beverage-lunch" label="Beverage (required)" label-for="event-beverage-lunch")
                        b-input-group(id="event-beverage-lunch" label="Beverage name" label-for="event-beverage")
                            b-form-input(id="event-beverage" type="text" placeholder="Enter beverage" v-model="modal.lunchState.beverageName" @keyup.enter="eventAddBeverageLunch")
                            b-input-group-append
                                b-button(variant="success" @click="eventAddBeverageLunch") Add

            b-row.my-4(v-if="modal.addEventinput.lunch?.listMain.length !== 0 || modal.addEventinput.lunch?.listBeverage.length !== 0")
                b-col.col-12.col-sm-6.mb-4.mb-sm-0
                    b-row(v-if="modal.addEventinput.lunch?.listMain.length !== 0")
                        b-col.col-12.mb-2(style="font-weight: 600;")
                            span List of main lunch
                        b-col.col-12
                            b-row.mx-1
                                b-col.col-12.border
                                    b-row.align-items-center(style="min-height: 2.5rem;")
                                        b-col.col-12(style="font-weight: 600;")
                                            span Meal Name
                                    b-row.py-2.align-items-center.border.border-right-0.border-bottom-0.border-left-0(
                                        v-for="(item, index) in modal.addEventinput.lunch?.listMain" :key="index"
                                        style="min-height: 2.5rem;"
                                        )
                                        b-col.col-8
                                            span {{ item }}
                                        b-col.col-4.d-flex.justify-content-center
                                            b-button(variant="danger" @click="eventRemoveLunchMainItem(index)") Remove
                b-col.col-12.col-sm-6
                    b-row(v-if="modal.addEventinput.lunch?.listBeverage.length !== 0")
                        b-col.col-12.mb-2(style="font-weight: 600;")
                            span List of lunch beverage
                        b-col.col-12
                            b-row.mx-1
                                b-col.col-12.border
                                    b-row.align-items-center(style="min-height: 2.5rem;")
                                        b-col.col-12(style="font-weight: 600;")
                                            span Meal Name
                                    b-row.py-2.align-items-center.border.border-right-0.border-bottom-0.border-left-0(
                                        v-for="(item, index) in modal.addEventinput.lunch?.listBeverage" :key="index"
                                        style="min-height: 2.5rem;"
                                        )
                                        b-col.col-8
                                            span {{ item }}
                                        b-col.col-4.d-flex.justify-content-center
                                            b-button(variant="danger" @click="eventRemoveLunchBeverageItem(index)") Remove

            b-form-group(label="Price Per Caregiver (required)" label-for="event-price")
                b-input-group(prepend="$")
                    b-form-input(id="event-price" type="number" prepend="$" placeholder="Enter price per person" v-model="modal.addEventinput.pricePerPerson")
            b-row
                b-col.col-8
                    b-form-group(label="GST" label-for="event-gst")
                        b-input-group(append="%")
                            b-form-input(v-if="modal.addEventinput.isEditGst" id="event-gst" type="number" v-model="modal.addEventinput.gst")
                            b-form-input(v-else id="event-gst" type="number" disabled v-model="modal.addEventinput.gst")
                b-col.col-4
                    b-form-group(:label="`${modal.addEventinput.isEditGst ? 'Disable' : 'Enable'} Edit GST`" label-for="event-isEditGst")
                        b-form-checkbox(v-model="modal.addEventinput.isEditGst" name="event-isEditGst" switch size="lg")

            b-row.py-4.align-items-center
                b-col.col-6
                    span Price after GST {{ modal.addEventinput.gst }}%
                b-col.col-6.text-right.font-weight-bold
                    span(style="font-size: 1.2rem;") ${{ modal.addEventinput.pricePerPerson ? (modal.addEventinput.pricePerPerson * modal.addEventinput.gst).toFixed(2) : 0 }}
                //- b-form-invalid-feedback(id="event-price")
                //-     | Invalid Price
        template(#modal-footer)
            b-button(variant="success" @click="openModalConfirmationAddEvent") Confirm
            b-button(variant="danger" @click="closeModalAddEvent") Cancel
    //--------------- modal add event

    //--------------- modal add event confirmation
    b-modal(:id="modal.modalConfirmationAddEventId" size="sm" title="Create Event Confirmation" no-close-on-backdrop)
        template
            p Are you sure you want to create this event?
        template(#modal-footer)
            b-button(v-if="isCreateEventSubmit" class="d-flex align-items-center" variant="success" disabled style="gap: 0.5rem;") 
                b-spinner(small style="color: rgb(124, 53, 159);")
                span Confirm
            b-button(v-else variant="success" @click="createEvent") Confirm
            b-button(v-if="isCreateEventSubmit" variant="danger" disabled) Cancel
            b-button(v-else variant="danger" @click="closeModalConfitmationAddEvent") Cancel
    //--------------- modal add event confirmation


    //--------------- modal delete event confirmation
    b-modal(:id="modal.deleteEventItem.modalDeleteConfirmationId" size="sm" title="Delete Event Confirmation")
        template
            p Are you sure you want to delete this event?
        template(#modal-footer)
            b-button.d-flex.justify-content-center.align-items-center(
                v-if="modal.deleteEventItem.isDeleting" 
                variant="danger" 
                disabled
                style="gap: 1rem;"
                @click="modalClickDeleteEventItem(modal.modalClickItemId)"
                ) 
                b-spinner(variant="secondary" small)
                span Delete
            b-button(v-else variant="danger" @click="modalClickDeleteEventItem(modal.modalClickItemId)") Delete
            b-button(variant="secondary" @click="closeModalDeleteEvent") Cancel
    //--------------- modal delete event confirmation


    //--------------- modal remove attendant confirmation
    b-modal(:id="modal.removeEventAttendant.id" size="sm" :title="modal.removeEventAttendant.clientName" body-class="position-static" no-close-on-backdrop)
        b-overlay(no-wrap :show="modal.removeEventAttendant.isRemoving")
            template(#overlay)
        template
            p Are you sure you want to remove this attendants?
        template(#modal-footer)
            b-button(variant="danger" @click="removeAttendant(modal.removeEventAttendant.id, modal.removeEventAttendant.caregiverId)") Delete
            b-button(variant="secondary" @click="closeModalRemoveAttendantEvent") Cancel
    //--------------- modal remove attendant confirmation



    //--------------- modal manually add attendant
    b-modal(
        :id="modal.modalManuallyAddEvent.modalId" 
        size="md" 
        title="Add attendant" 
        no-close-on-backdrop
        scrollable
    )
        template(v-if="!modal.modalClickItemData?.multipleDays")
            b-container
                b-row.mb-3
                    b-col.col-12.p-0
                        b-row
                            b-col.col-12.font-weight-bold
                                span Client info 
                            b-col.col-12
                                b-form-group(label="Client Name" label-for="manually-add-client-name")
                                    v-select(
                                        id="manually-add-client-name" 
                                        :options="listAllClientAndCaregiverData.map(item => ({id: item.crb5c_Client.crb5c_fow_customerid, name: item.crb5c_Client.crb5c_no}))"
                                        v-model="modal.modalManuallyAddEvent.inputAddEvent.client.data"
                                        label="name"
                                        placeholder="Select Client"
                                        )
                        b-row
                            b-col.col-6
                                b-form-group(label="Main Lunch" label-for="manually-add-client-mainlunch")
                                    v-select(
                                        id="manually-add-client-mainlunch" 
                                        :options="modal.modalClickItemData?.lunch?.listMain"
                                        v-model="modal.modalManuallyAddEvent.inputAddEvent.client.lunch.main"
                                        label="name"
                                        placeholder="Select Main Lunch"
                                        )
                            b-col.col-6
                                b-form-group(label="Lunch Beverage" label-for="manually-add-client-lunchbeverage")
                                    v-select(
                                        id="manually-add-client-lunchbeverage" 
                                        :options="modal.modalClickItemData?.lunch?.listBeverage"
                                        v-model="modal.modalManuallyAddEvent.inputAddEvent.client.lunch.beverage"
                                        label="name"
                                        placeholder="Select Lunch Beverage"
                                        )

                b-row.mb-3
                    b-col.col-12.p-0
                        b-row
                            b-col.col-12.font-weight-bold
                                span Caregiver info
                            b-col.col-12
                                b-form-group(
                                    label="Caregiver Name" label-for="manually-add-caregiver-name"
                                    )
                                    //- span {{ modal.modalManuallyAddEvent.inputAddEvent.caregiver }}
                                    //- b-form-input(v-model="" disabled)
                                    v-select(
                                        v-if="getCaregiverFromClient"
                                        id="manually-add-caregiver-name" 
                                        :options="getCaregiverFromClient.map(item => ({id: item.crb5c_fow_caregiverid, name: item.crb5c_name}))"
                                        v-model="modal.modalManuallyAddEvent.inputAddEvent.caregiver.data"
                                        label="name"
                                        placeholder="Select Caregiver"
                                    )
                        b-row
                            b-col.col-6
                                b-form-group(label="Main Lunch" label-for="manually-add-client-mainlunch")
                                    v-select(
                                        id="manually-add-client-mainlunch" 
                                        :options="modal.modalClickItemData?.lunch?.listMain"
                                        v-model="modal.modalManuallyAddEvent.inputAddEvent.caregiver.lunch.main"
                                        label="name"
                                        placeholder="Select Main Lunch"
                                    )
                            b-col.col-6
                                b-form-group(label="Lunch Beverage" label-for="manually-add-client-lunchbeverage")
                                    v-select(
                                        id="manually-add-client-lunchbeverage" 
                                        :options="modal.modalClickItemData?.lunch?.listBeverage" 
                                        v-model="modal.modalManuallyAddEvent.inputAddEvent.caregiver.lunch.beverage"
                                        label="name"
                                        placeholder="Select Lunch Beverage"
                                    )
                b-row.mb-4
                    b-col.col-12.p-0
                        b-row
                            b-col.col-12.font-weight-bold
                                span Other person join info
                            b-col.col-12
                                b-form-group(label="Other Person Name" label-for="manually-add-otherperson-name")
                                    b-form-input(
                                        id="manually-add-otherperson-name" 
                                        placeholder="Enter other person name"
                                        v-model="modal.modalManuallyAddEvent.inputAddEvent.otherPersonJoin.tempData.name"
                                    )
                        b-row
                            b-col.col-6
                                b-form-group(label="Main Lunch" label-for="manually-add-otherperson-mainlunch")
                                    v-select(
                                        id="manually-add-otherperson-mainlunch" 
                                        :options="modal.modalClickItemData?.lunch?.listMain" 
                                        v-model="modal.modalManuallyAddEvent.inputAddEvent.otherPersonJoin.tempData.lunch.main"
                                        label="name"
                                        placeholder="Select Main Lunch"
                                    )
                            b-col.col-6
                                b-form-group(label="Lunch Beverage" label-for="manually-add-otherperson-lunchbeverage")
                                    v-select(
                                        id="manually-add-otherperson-lunchbeverage" 
                                        :options="modal.modalClickItemData?.lunch?.listBeverage"
                                        v-model="modal.modalManuallyAddEvent.inputAddEvent.otherPersonJoin.tempData.lunch.beverage"
                                        label="name"
                                        placeholder="Select Lunch Beverage"
                                    )
                        b-row
                            b-col.col-12
                                b-button.w-100(variant="success" @click="manuallyAddAttendantAddOtherPersonJoin") Add Other Person Join

                b-row(v-if="modal.modalManuallyAddEvent.inputAddEvent.otherPersonJoin.listOtherPersonJoin.length !== 0")
                    b-col.col-12.mb-3.p-0
                        span.font-weight-bold List other person join
                    b-col.col-12.mb-2.p-0
                        b-table-simple(class="centerTd")
                            b-thead(style="background-color: #ccc;")
                                b-th Name
                                b-th Main Lunch
                                b-th Lunch Beverage
                                b-th
                            b-tbody
                                b-tr(
                                    v-for="(otherPerson, index) in modal.modalManuallyAddEvent.inputAddEvent.otherPersonJoin.listOtherPersonJoin"
                                    :key="index"
                                    )
                                    b-td {{ otherPerson.name }}
                                    b-td {{ otherPerson.main }}
                                    b-td {{ otherPerson.beverage }}
                                    b-td
                                        b-icon(icon="trash" variant="danger" style="cursor: pointer;" @click="manuallyAddAttendantRemoveOtherPersonJoinItem(index)")
        template(v-else)
            div(class="multiple-day-event-add-people d-flex flex-column")
                div(class="d-flex flex-column")
                    h5(class="mb-1") List Clients
                    v-select(
                                        id="manually-add-client-name" 
                                        :options="listAllClientAndCaregiverData.map(item => ({id: item.crb5c_Client.crb5c_fow_customerid, name: item.crb5c_Client.crb5c_no}))"
                                        v-model="modal.modalManuallyAddEvent.inputAddEvent.client.data"
                                        label="name"
                                        placeholder="Select Client"
                                        )
                div(class="d-flex align-items-center mt-2 ")
                    div(class="d-flex flex-column w-50").mr-2
                        p(class="mb-1") Lunch
                        div(v-for="(item, index) in modal.modalClickItemData?.multipleDays" :key="index").my-2
                            p.m-0 {{ item.title }}
                            v-select(
                                id="manually-add-client-mainlunch" 
                                :options="item.lunch.listMain.map(item => (item.name))"
                                v-model="modal.modalManuallyAddEvent.inputAddEvent.client.lunch.main"
                                label="name"
                                placeholder="Select Main Lunch"
                            )
                            
                    div(class="d-flex flex-column w-50")
                        p(class="mb-1") Beverage
                        div(v-for="(item, index) in modal.modalClickItemData?.multipleDays" :key="index").my-2
                            p.m-0 {{ item.title }}
                            v-select(
                                id="manually-add-client-lunchbeverage" 
                                :options="item.lunch.listBeverage.map(item => (item.name))"
                                v-model="modal.modalManuallyAddEvent.inputAddEvent.client.lunch.beverage"
                                label="name"
                                placeholder="Select Lunch Beverage"
                            )
                div(class="d-flex flex-column mt-4")
                    h5(class="mb-1") List Caregivers
                    v-select(
                                        v-if="getCaregiverFromClient"
                                        id="manually-add-caregiver-name" 
                                        :options="getCaregiverFromClient.map(item => ({id: item.crb5c_fow_caregiverid, name: item.crb5c_name}))"
                                        v-model="modal.modalManuallyAddEvent.inputAddEvent.caregiver.data"
                                        label="name"
                                        placeholder="Select Caregiver"
                                    )
                div(class="d-flex align-items-center mt-2 ")
                    div(class="d-flex flex-column w-50").mr-2
                        p(class="mb-1") Lunch
                        div(v-for="(item, index) in modal.modalClickItemData?.multipleDays" :key="index").my-2
                            p.m-0 {{ item.title }}
                            v-select(
                                id="manually-add-client-mainlunch" 
                                :options="item.lunch.listMain.map(item => (item.name))"
                                v-model="modal.modalManuallyAddEvent.inputAddEvent.caregiver.lunch.main"
                                label="name"
                                placeholder="Select Main Lunch"
                            )
                            
                    div(class="d-flex flex-column w-50")
                        p(class="mb-1") Beverage
                        div(v-for="(item, index) in modal.modalClickItemData?.multipleDays" :key="index").my-2
                            p.m-0 {{ item.title }}
                            v-select(
                                id="manually-add-client-lunchbeverage" 
                                :options="item.lunch.listBeverage.map(item => (item.name))"
                                v-model="modal.modalManuallyAddEvent.inputAddEvent.caregiver.lunch.beverage"
                                label="name"
                                placeholder="Select Lunch Beverage"
                            )
                div(class="d-flex flex-column mt-4")
                    h5(class="mb-1") List Other Person Join
                    b-form-input(
                                id="manually-add-otherperson-name" 
                                placeholder="Enter other person name"
                                v-model="modal.modalManuallyAddEvent.inputAddEvent.otherPersonJoin.tempData.name"
                                ).mb-2
                    div(class="d-flex align-items-center")
                        div(class="d-flex flex-column w-50").mr-2
                            p(class="mb-1") Lunch
                            div(v-for="(item, index) in modal.modalClickItemData?.multipleDays" :key="index").my-2
                                p.m-0 {{ item.title }}
                                v-select(
                                    id="manually-add-otherperson-mainlunch" 
                                    :options="item.lunch.listMain.map(item => (item.name))"
                                    v-model="modal.modalManuallyAddEvent.inputAddEvent.otherPersonJoin.tempData.lunch.main"
                                    label="name"
                                    placeholder="Select Main Lunch"
                                )    
                        div(class="d-flex flex-column w-50")
                            p(class="mb-1") Beverage
                            div(v-for="(item, index) in modal.modalClickItemData?.multipleDays" :key="index").my-2
                                p.m-0 {{ item.title }}
                                v-select(
                                    id="manually-add-otherperson-lunchbeverage" 
                                    :options="item.lunch.listBeverage.map(item => (item.name))"
                                    v-model="modal.modalManuallyAddEvent.inputAddEvent.otherPersonJoin.tempData.lunch.beverage"
                                    label="name"
                                    placeholder="Select Lunch Beverage"
                                ) 
                        

        template(#modal-footer)
            b-button(variant="success" @click="openModalConfirmationManuallyAddAttendantListConfirmation") Add
            b-button(variant="secondary" @click="closeModalManuallyAddAttendant") Cancel
    //--------------- modal manually add attendant


    //--------------- modal list confirmation manually add attendant
    b-modal(
        :id="modal.modalManuallyAddEvent.modalListConfirmationId" 
        size="md" 
        title="List Event Confirmation" 
        centered
        scrollable
        body-class="position-static" 
        no-close-on-backdrop
    )
        b-overlay(no-wrap :show="modal.modalManuallyAddEvent.isSubmit")
            template(#overlay)
        template
            b-container
                b-row.mb-4
                    b-col.col-12
                        span Select your confirmation: 
                b-row(style="gap: 1.5rem;")
                    b-col.col-12
                        b-button.w-100(variant="success" @click="submitManuallyAddAttendantCaregiverClientOtherPersonJoin") Caregiver, client and other person join
                    b-col.col-12
                        b-button.w-100(variant="success" @click="submitManuallyAddAttendantClientAndOtherPersonJoin") Client and other person join
                    b-col.col-12
                        b-button.w-100(variant="success" @click="submitManuallyAddAttendantClientOnly") Client only
        template(#modal-footer)
            b-button(variant="secondary" @click="closeModalConfirmationManuallyAddAttendantListConfirmation") Cancel
    //--------------- modal list confirmation manually add attendant


    //--------------- modal add other person join event item click client item
    b-modal(
        :id="modal.modalAddOtherPersonJoinModalEventItemClickClientItem.id"
        size="lg"
        title="Add Other Person Join"
        scrollable
        body-class="position-static"
        no-close-on-backdrop
    )
        b-overlay(
            no-wrap 
            :show="modal.modalAddOtherPersonJoinModalEventItemClickClientItem.isSubmitting"
            )
            template(#overlay)
        template
            b-container
                b-row.mb-4
                    b-col.col-12.p-0
                        b-row
                            b-col.col-12.font-weight-bold
                                span Other person join info
                            b-col.col-12
                                b-form-group(label="Other Person Name" label-for="manually-add-otherperson-name")
                                    b-form-input(
                                        id="manually-add-otherperson-name" 
                                        placeholder="Enter other person name"
                                        v-model="modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.tempData.name"
                                        )
                        b-row
                            b-col.col-12.col-sm-6
                                b-form-group(label="Main Lunch" label-for="manually-add-otherperson-mainlunch")
                                    v-select(
                                        id="manually-add-otherperson-mainlunch" 
                                        :options="modal.modalClickItemData?.lunch?.listMain" 
                                        v-model="modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.tempData.lunch.main"
                                        label="name"
                                        placeholder="Select Main Lunch"
                                        )
                            b-col.col-12.col-sm-6
                                b-form-group(label="Lunch Beverage" label-for="manually-add-otherperson-lunchbeverage")
                                    v-select(
                                        id="manually-add-otherperson-lunchbeverage" 
                                        :options="modal.modalClickItemData?.lunch?.listBeverage"
                                        v-model="modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.tempData.lunch.beverage"
                                        label="name"
                                        placeholder="Select Lunch Beverage"
                                        )
                        b-row
                            b-col.col-12
                                b-button.w-100(variant="success" @click="addOtherPersonJoinToList") Add Other Person Join

                b-row(v-if="modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.listOtherPersonJoin.length !== 0")
                    b-col.col-12.mb-3.p-0
                        span.font-weight-bold List other person join
                    b-col.col-12.mb-2.p-0
                        b-table-simple.border(class="centerTd")
                            b-thead.text-center(style="background-color: #ccc;")
                                b-th Name
                                b-th Main Lunch
                                b-th Lunch Beverage
                                b-th
                            b-tbody.text-center
                                b-tr(
                                    v-for="(otherPerson, index) in modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.listOtherPersonJoin"
                                    :key="index"
                                    )
                                    b-td {{ otherPerson.name }}
                                    b-td {{ otherPerson.main }}
                                    b-td {{ otherPerson.beverage }}
                                    b-td
                                        b-icon(
                                            icon="trash" 
                                            variant="danger"
                                            style="cursor: pointer;" @click="removeOtherPersonJoinItemFromList(index)"
                                        )
        template(#modal-footer)
            b-button(variant="success" @click="submitOtherPersonJoinModalEventItemClickClientItem(modal.modalAddOtherPersonJoinModalEventItemClickClientItem.id?.split('/')[1])") Confirm
            b-button(variant="secondary" @click="closeModalAddOtherPersonJoinModalEventItemClickClientItem") Close

    //--------------- modal add other person join event item click client item



    //--------------- modal event item click client item
    b-modal(
        v-if="modal.modalEventItemClickClientItem.data"
        :id="modal.modalEventItemClickClientItem.id"
        size="lg"
        :title="modal.modalEventItemClickClientItem.data.client.name"
        scrollable
        body-class="position-static"
        no-close-on-backdrop
    )
        b-overlay(no-wrap :show="modal.acknowledgePaymentInput.isAcknowledgingPayment")
            template(#overlay)
        template
            b-container
                b-row.mb-2
                    b-col.col-6
                        span.font-weight-bold Client Name
                    b-col.col-6.text-right
                        span {{ modal.modalEventItemClickClientItem.data.client.name }}
                b-row.mb-2
                    b-col.col-6
                        span.font-weight-bold Caregiver Name
                    b-col.col-6.text-right
                        span {{ modal.modalEventItemClickClientItem.data.isCaregiverCome ? modal.modalEventItemClickClientItem.data.name : "Not Joining" }}
                b-row.mb-2.align-items-center
                    b-col.col-6
                        span.font-weight-bold Other Person
                    b-col.col-6.text-right
                        span.mr-2 {{ modal.modalEventItemClickClientItem.data.otherPersonJoining.length !== 0 ? modal.modalEventItemClickClientItem.data.otherPersonJoining.map(item => item.name).join(", ") : "No" }}
                        b-button(
                            size="sm" 
                            variant="success"
                            v-b-tooltip.hover title="Add other person join"
                            @click="openModalAddOtherPersonJoinModalEventItemClickClientItem(modal.modalEventItemClickClientItem.data)"
                            )
                            b-icon(icon="plus")
                //- b-row
                //-     b-col.col-6
                //-         span.font-weight-bold Client Lunch Selected
                //-     b-col.col-6.text-right
                //-         span John
                //- b-row
                //-     b-col.col-6
                //-         span.font-weight-bold Caregiver Lunch Selected
                //-     b-col.col-6.text-right
                //-         span John
                //- b-row
                //-     b-col.col-6
                //-         span.font-weight-bold Other Person Joining Lunch Selected
                //-     b-col.col-6.text-right
                //-         span John

                b-row.mb-2
                    b-col.col-6
                        span.font-weight-bold Payment Status
                    b-col.col-6.text-right
                        span {{ modal.modalEventItemClickClientItem.data.paymentInfo.paymentStatus ? "Paid" : "Not Paid" }}
                b-row.mb-2
                    b-col.col-6
                        span.font-weight-bold Payment Method
                    b-col.col-6.text-right
                        span {{ modal.modalEventItemClickClientItem.data.paymentInfo.typeOfPayment ?? "No" }}
                b-row.mb-2
                    b-col.col-6
                        span.font-weight-bold Attend Status
                    b-col.col-6.text-right
                        span {{ modal.modalEventItemClickClientItem.data.isAttending ? "Attend" : "Not Attend" }}
                //-- TODO: add total person joining
                //- b-row
                //-     b-col.col-6
                //-         span.font-weight-bold Total Person Joining
                //-     b-col.col-6.text-right
                //-         span John

                //-- TODO: add total price after gst
                b-row.mb-2
                    b-col.col-6
                        span.font-weight-bold Total Price After GST
                    b-col.col-6.text-right
                        span ${{ modal.modalEventItemClickClientItem.data.paymentInfo.totalPrice }}

                b-row.align-items-center(v-if="modal.modalEventItemClickClientItem.data.paymentInfo.typeOfPayment === 'cash' && !modal.modalEventItemClickClientItem.data.paymentInfo.proofOfPayment.cashMemo")
                    b-col.col-6
                        span.font-weight-bold Acknowledge Payment
                    b-col.col-6.text-right
                        b-button(variant="info" @click="acknowledgePaymentCash(modal.modalEventItemClickClientItem.msgId, modal.modalEventItemClickClientItem.convId, modal.modalEventItemClickClientItem.data.id)") Acknowledge Cash
                b-row.align-items-center(v-if="modal.modalEventItemClickClientItem.data.paymentInfo.typeOfPayment === 'cheque' && !modal.modalEventItemClickClientItem.data.paymentInfo.proofOfPayment.chequeNo")
                    b-col.col-6
                        span.font-weight-bold Acknowledge Payment
                    b-col.col-6.text-right
                        b-input-group
                            b-form-input(type="number" placeholder="Enter Cheque No" v-model="modal.acknowledgePaymentInput.chequeNo")
                            b-input-group-append
                                b-button(variant="info" size="sm" @click="acknowledgePaymentCheque(modal.modalEventItemClickClientItem.msgId, modal.modalEventItemClickClientItem.convId, modal.modalEventItemClickClientItem.data.id)") Acknowledge

                b-row.mt-4
                    b-col.col-12.mb-2
                        span.font-weight-bold List Lunch Selected
                    b-col.col-12
                        b-table-simple.border(class="centerTd")
                            b-thead.text-center(style="background-color: #ccc;")
                                b-th Name
                                b-th Main Lunch
                                b-th Lunch Beverage
                            b-tbody.text-center
                                b-tr
                                    b-td {{ modal.modalEventItemClickClientItem.data.client.name }}
                                    b-td {{ modal.modalEventItemClickClientItem.data.listLunchSelected.client.main[0] }}
                                    b-td {{ modal.modalEventItemClickClientItem.data.listLunchSelected.client.beverage[0] }}
                                b-tr(v-if="modal.modalEventItemClickClientItem.data.listLunchSelected.caregiver.main[0]")
                                    b-td {{ modal.modalEventItemClickClientItem.data.name }}
                                    b-td {{ modal.modalEventItemClickClientItem.data.listLunchSelected.caregiver.main[0] }}
                                    b-td {{ modal.modalEventItemClickClientItem.data.listLunchSelected.caregiver.beverage[0] }}
                                b-tr(
                                    v-if="modal.modalEventItemClickClientItem.data.otherPersonJoining.length !== 0"
                                    v-for="item in modal.modalEventItemClickClientItem.data.otherPersonJoining"
                                )
                                    b-td {{ item.name }}
                                    b-td {{ item.lunch.main }}
                                    b-td {{ item.lunch.beverage }}

        template(#modal-footer)
            b-button(variant="danger" @click="openModalRemoveAttendantEvent(modal.modalClickItemId, modal.modalEventItemClickClientItem.data.id, modal.modalEventItemClickClientItem.data.client.name)") Delete
            b-button(variant="secondary" @click="closeModalEventItemClickClientItem") Close

    //--------------- modal event item click client item

    //- New Modal for view event item
    b-modal(
        v-if="this.listData.length !== 0"
        :id="modal.modalClickItemId" 
        size="lg" 
        rounded="lg"
        body-class="position-static p-0" 
        scrollable 
        no-close-on-backdrop     



    )
        b-overlay(no-wrap :show="modal.stateEditEventItem.isSaveEdit")
            template(#overlay)
        template(#modal-header="{ close }" class="custom-header d-flex justify-content-between align-items-center" v-if="!modal.stateEditEventItem.isEditModalEvent")
            h5.m-0.text-center Event Detail
            b-button(size="sm" variant="outline-light" @click="closeModalItem") 
                b-icon(icon="x" variant="danger")
        template(#modal-header="{ close }" class="d-flex justify-content-between align-items-center" v-else)
            h5.m-0.text-center Edit Event
            b-button(size="sm" variant="outline-light" @click="closeModalItem") 
                b-icon(icon="x" variant="danger")
        template(v-if="!modal.stateEditEventItem.isEditModalEvent"  style="background-color: #f8f9fa !important;")

            div(class="header-body-modal m-0")
                hr(class="m-0 divider")
                div(class="event-info")
                    div(class="event-title")
                        div(class="d-flex align-items-center")
                            h2 {{ modal.modalClickItemData?.title }}
                            b-badge(class="badge ml-2" pill :variant="variantBadge(modal.modalClickItemData?.status)") {{ modal.modalClickItemData?.status }}
                        div(class="buttons-edit-delete d-flex align-items-center")
                            b-button(class="btn-edit-event" variant="light" @click="editEventItem(modal.modalClickItemId)" v-if="isEditModalEventAllowed(modal.modalClickItemData?.msgId)")
                                b-icon(icon="pencil" style="color: #109387;")
                            b-button(v-else variant="dark" disabled) 
                                b-icon(icon="pencil" style="color: #109387;")
                            
                            b-button(class="btn-delete-event ml-2" variant="danger" @click="openModalDeleteEvent(modal.modalClickItemId)")
                                b-icon(icon="trash")

                    div(class="event-description font-weight-light")
                        p.mt-4.mb-0(v-html="modal.modalClickItemData?.description")
                        hr(style="border-top: 1px solid #ccc;")
                    div(class="event-date d-flex align-items-center my-2")
                        b-icon(icon="calendar")
                        span.ml-2 {{ formatDate(modal.modalClickItemData?.date) }}
                    div(class="event-costs mt-2")
                        div(class="d-flex align-items-center")
                            span Price per Caregiver
                            span.ml-2  ${{ modal.modalClickItemData?.pricePerPerson }}
                        div(class="d-flex align-items-center")
                            span GST
                            span.ml-2 {{ modal.modalClickItemData?.gst }}%
                        div(class="d-flex align-items-center")
                            span Price after GST
                            span.ml-2 ${{ (modal.modalClickItemData?.pricePerPerson * modal.modalClickItemData?.gst).toFixed(2) }}
                        div(class="d-flex align-items-center" v-if="this.listData.length !== 0 && modal.modalClickItemData?.msgId")
                            span Total Person Joining
                            span.ml-2 {{ calculateTotalAttendance(searchEvent.find(item => item.msgId === modal.modalClickItemData.msgId)) }} Person
                        div(class="d-flex align-items-center" v-if="modal.modalClickItemData?.multipleDays")
                            span No. of Days
                            span.ml-2 {{ modal.modalClickItemData?.multipleDays?.length }} Day
            div(class="main-body-modal")
                div(class="list-attendants modal-body-item-main d-flex flex-column")
                    div(class="d-flex justify-content-between align-items-center my-2")
                        h5(class="m-0") List Clients 
                        b-button(v-if="!modal.modalClickItemData?.multipleDays" variant="success" @click="openModalManuallyAddAttendant(modal.modalClickItemId)") Add Attendant
                    b-form-group(label="Search client" label-for="search-client-event-item-input")
                        b-form-input(id="search-client-event-item-input" placeholder="Enter client name" v-model="modal.modalClickItemDataClientSearchInput")
                    div(class="list-attendants-item d-flex align-items-center")
                        div(class="list-attendants-item-name font-weight-bold")
                            span Client Name
                        div(class="list-attendants-item-lunch font-weight-bold")
                            span Main Lunch
                        div(class="list-attendants-item-lunch font-weight-bold")
                            span Lunch Beverage
                        div(class="list-attendants-item-lunch font-weight-bold")
                            span Payment Status
                        div(class="list-attendants-item-lunch font-weight-bold")
                            span Attend Status
                    div(class="list-attendants-item d-flex align-items-center" @click="openModalEventItemClickClientItem(modal.modalClickItemData?.msgId, modal.modalClickItemData?.convId, item.client.id, item)"  v-if="modal.modalClickItemData?.caregivers.length !== 0" v-for="(item, index) in searchClientInEventItem" :key="index")
                        div(class="list-attendants-item-name")
                            span {{ item.client.name }}
                        div(class="list-attendants-item-lunch")
                            span {{ item.listLunchSelected.client.main[0].name }}
                        div(class="list-attendants-item-lunch")
                            span {{ item.listLunchSelected.client.beverage[0].name }}
                        div(class="list-attendants-item-lunch")
                            span {{ item.paymentInfo.paymentStatus ? "Paid" : "Not Paid" }}
                        div(class="list-attendants-item-lunch")
                            span {{ item.isAttending ? "Attend" : "Not Attend" }}

                div(class="group-name modal-body-item-main")
                    h5 Group
                    p  {{ modal.modalClickItemData?.listGroup.length !== 0 ? modal.modalClickItemData?.listGroup.map(item => item.name).join(", ") : "No group" }}
                div(class="itinerary modal-body-item-main mt-2" v-if="!modal.modalClickItemData?.multipleDays && modal.modalClickItemData?.itinerary?.length !== 0")
                    h5 Itinerary
                    div(class="itinerary-item d-flex align-items-center" )
                        div(class="itinerary-item-time")
                            span.font-weight-bold Time 
                        div(class="itinerary-item-description")
                            span.font-weight-bold    Description
                    div(class="itinerary-item d-flex align-items-center" v-for="(item, index) in modal.modalClickItemData?.itinerary" :key="index")
                        div(class="itinerary-item-time")
                            span {{ item.time }}
                        div(class="itinerary-item-description")
                            span {{ item.description }}
                div(class="lunch mt-2 modal-body-item-main" v-if="!modal.modalClickItemData?.multipleDays && ( modal.modalClickItemData?.lunch?.listMain.length !== 0 || modal.modalClickItemData?.lunch?.listBeverage.length !== 0)")
                    h5 Lunch 
                    div(class="lunch-item-main d-flex align-items-center" v-if="modal.modalClickItemData?.lunch?.listMain.length !== 0")
                        div(class="lunch-item-main-meal font-weight-bold")
                            span Main Lunch
                        div(class="lunch-item-main-beverage font-weight-bold")
                            span Lunch Beverage
                    div(class="lunch-item-main d-flex align-items-center" v-for="(item, index) in modal.modalClickItemData?.lunch?.listMain" :key="index")
                        div(class="lunch-item-main-meal ")
                            span {{ typeof item === 'object' ? item.name : item }}
                        div(class="lunch-item-main-beverage")
                            span {{  typeof modal.modalClickItemData?.lunch?.listBeverage[index] === 'object' ? modal.modalClickItemData?.lunch?.listBeverage[index].name : modal.modalClickItemData?.lunch?.listBeverage[index] }}
                div(class="attendants-lunch-menu modal-body-item-main mt-2" v-if="modal.modalClickItemData?.caregivers.length !== 0")
                    h5 List Attendees Lunch Menu
                    div(class="attendants-lunch-menu-item d-flex align-items-center")
                        div(class="attendants-lunch-menu-item-name font-weight-bold")
                            span Name
                        div(class="attendants-lunch-menu-item-lunch font-weight-bold")
                            span Main Lunch
                        div(class="attendants-lunch-menu-item-lunch font-weight-bold")
                            span Lunch Beverage
                    div(class="attendants-lunch-menu-item d-flex align-items-center" 
                    v-if="modal.modalClickItemData?.caregivers.length !== 0"
                    v-for="(item, index) in mapEventItemListLunchData(modal.modalClickItemData?.caregivers)" :key="index")
                        div(class="attendants-lunch-menu-item-name")
                            span {{ item.name }}
                        div(class="attendants-lunch-menu-item-lunch")
                            span {{ item.main}}
                        div(class="attendants-lunch-menu-item-lunch")
                            span {{ item.beverage }}
                div(class="attendants-lunch-menu modal-body-item-main mt-2" v-if="listSummaryAttendeesLunchMenu !== null")
                    h5 Summary Attendees Lunch Menu
                    div(class="attendants-lunch-menu-item d-flex align-items-center")
                        div(class="attendants-lunch-menu-item-name font-weight-bold")
                            span Main
                        div(class="attendants-lunch-menu-item-lunch font-weight-bold")
                            span Total
                    div(class="attendants-lunch-menu-item d-flex align-items-center" v-if="listSummaryAttendeesLunchMenu?.resultListMainLunch.length !== 0" v-for="(item, index) in listSummaryAttendeesLunchMenu?.resultListMainLunch" :key="Math.floor(Math.random() * Date.now()).toString(36)")
                        div(class="attendants-lunch-menu-item-name")
                            span {{ Object.keys(item)[0] }}
                        div(class="attendants-lunch-menu-item-lunch")
                            span {{ Object.values(item)[0].count }}
                    div(class="attendants-lunch-menu-item d-flex align-items-center font-weight-bold")
                        span Beverage

                    div(
                        class="attendants-lunch-menu-item d-flex align-items-center"
                        v-if="listSummaryAttendeesLunchMenu?.resultListLunchBeverage.length !== 0"
                        v-for="(item, index) in listSummaryAttendeesLunchMenu?.resultListLunchBeverage"
                        :key="Math.floor(Math.random() * Date.now()).toString(36)")
                        div(class="attendants-lunch-menu-item-name")
                            span {{ Object.keys(item)[0] }}
                        div(class="attendants-lunch-menu-item-lunch")
                            span {{ Object.values(item)[0].count }}
                    div(v-if="listSummaryAttendeesLunchMenu?.resultListMainLunch?.length === 0 && listSummaryAttendeesLunchMenu?.resultListLunchBeverage.length === 0")
                        span No Data
                div(class="multiple-days modal-body-item-main mt-2" v-if="modal.modalClickItemData?.multipleDays")
                    h5.mb-2 Multiple Days Schedule and Lunch Menu
                    div(class="multiple-days-item d-flex align-items-center")
                        div(class="multiple-days-item-date font-weight-bold")
                            span Title
                        div(class="multiple-days-item-description font-weight-bold")
                            span Date 
                    div(class="border-multi-day d-flex flex-column p-2" v-for="(item, index) in modal.modalClickItemData?.multipleDays" :key="index")
                        div(class="d-flex w-100 multiple-day-event-info justify-content-between align-items-center")
                            div(class="date")
                                span.h5 {{ item.title }}
                            div(class="description")
                                span {{ formatDate(item.date) }}
                        h5.mt-3 Itinerary
                        div(id="d-flex flex-column" class="mt-2")
                            div(class="itinerary-items  d-flex flex-column my-2")
                                div(class="itinerary-items multiple-day-event-info font-weight-bold d-flex align-items-center justify-content-between")
                                    span Time
                                    span Description
                                
                                div(v-for="(schedule, index) in item?.itinerary" :key="index" class="")
                                    div.pb-0(class="itinerary-items multiple-day-event-info  d-flex align-items-center justify-content-between")
                                        p {{ schedule.time }}
                                        p {{ schedule.description }}
                            div(class=" d-flex flex-column")  
                                h5.my-2 Food items
                                div(class="itinerary-items multiple-day-event-info font-weight-bold d-flex align-items-center justify-content-between")
                                    span Lunch
                                    
                                div(v-for="(food, index) in item?.lunch.listMain" :key="index")
                                    div(class=" food-item multiple-day-event-info d-flex align-items-center justify-content-between")
                                        div(class="lunch")
                                            span {{ food.name }}
                                div(class="itinerary-items multiple-day-event-info font-weight-bold d-flex align-items-center justify-content-between")
                                    span Beverage
                                div(v-for="(beverage, index) in item?.lunch.listBeverage" :key="index")
                                    div(class=" food-item multiple-day-event-info d-flex align-items-center justify-content-between")
                                        div(class="lunch")
                                            span {{ beverage.name }}
                                            
                

                          
                        
                
                        


            //-- EDIT STATE
        template(v-else)
            b-container(class="mt-4")
                b-form-group.font-weight-bold(label="Title (required)" label-for="edit-event-title")
                    b-form-input(id="edit-event-title" type="text" v-model="modal.editModalClickItemData.title")
                b-form-group.font-weight-bold(label="Date (required)" label-for="edit-event-date")
                    b-form-input(id="edit-event-date" type="date" v-model="modal.stateEditEventItem.tempDataEditDate.date")
                b-form-group.font-weight-bold(label="Time (required)" label-for="edit-event-time")
                    b-form-input(id="edit-event-time" type="time" v-model="modal.stateEditEventItem.tempDataEditDate.time")
                b-row
                    b-col
                        span.font-weight-bold Price per Caregiver (required)
                b-row(class="mb-2")
                    b-col
                        b-input-group(prepend="$")
                            b-form-input(type="number" v-model="modal.editModalClickItemData.pricePerPerson")
                b-form-group.font-weight-bold(label="Description (required)" label-for="edit-event-description")
                    //- b-form-textarea(id="edit-event-description" type="text" v-model="modal.editModalClickItemData.description")
                    vue-editor(v-model="modal.editModalClickItemData.description" :editorToolbar="customToolbar")
                //-- Edit Itinerary
                b-row.mb-4(v-if="!modal.editModalClickItemData.multipleDays")
                    b-col
                        b-row
                            b-col.col-12.font-weight-bold
                                span Itinerary
                        b-row
                            b-col.px-4
                                b-row.align-items-center
                                    b-col.col-5
                                        b-form-group(id="event-form-itinerary-time" label="Time" label-for="event-itinerary-time")
                                            b-form-input(id="event-itinerary-time" type="time" v-model="modal.itineraryState.time")
                                    b-col.col-5
                                        b-form-group(id="event-form-itinerary-description" label="Description" label-for="event-itinerary-description")
                                            b-form-input(id="event-itinerary-description" type="text" v-model="modal.itineraryState.description")
                                    b-col.col-2
                                        b-button(variant="success" @click="editAddEventItinerary(modal.editModalClickItemData)") Add
                                b-row.mt-2(v-if="modal.editModalClickItemData.itinerary?.length !== 0")
                                    b-col.col-12
                                        b-row
                                            b-col.col-12.mb-2
                                                span List Itinerary
                                            b-col.col-12
                                                b-row.mx-1
                                                    b-col.col-12.border
                                                        b-row.align-items-center(style="min-height: 2.5rem;")
                                                            b-col.col-5(style="font-weight: 600;")
                                                                span Time
                                                            b-col.col-5(style="font-weight: 600;")
                                                                span Description
                                                            b-col.col-2(style="font-weight: 600;")
                                                        b-row.py-2.align-items-center.border.border-right-0.border-bottom-0.border-left-0(
                                                            v-for="(item, index) in modal.editModalClickItemData.itinerary" :key="index"
                                                            style="min-height: 2.5rem;"
                                                            )
                                                            b-col.col-5
                                                                //- span {{ item.time }}
                                                                b-form-input(v-model="item.time" type="time")
                                                            b-col.col-5
                                                                //- span {{ item.description }}
                                                                b-form-input(v-model="item.description" type="text")
                                                            b-col.col-2.d-flex.justify-content-center
                                                                b-button(variant="danger" @click="editRemoveEventItinerary(index)") Remove
                //-- Edit Itinerary

                //-- Edit Lunch
                b-row(v-if="!modal.editModalClickItemData.multipleDays")
                    b-col
                        b-row
                            b-col.col-12.font-weight-bold
                                span Lunch
                        //-- Edit Lunch Main
                        b-row.my-2.px-4
                            b-col.col-6
                                b-form-group(label="Main Lunch (required)")
                                    b-input-group(label="Main Lunch" label-for="event-lunch")
                                        b-form-input(id="event-lunch" type="text" placeholder="Enter main lunch" v-model="modal.lunchState.mealName" @keyup.enter="editAddListMainLunch(modal.editModalClickItemData)")
                                        b-input-group-append
                                            b-button(variant="success" @click="editAddListMainLunch(modal.editModalClickItemData)") Add
                                b-row.my-2(v-if="modal.editModalClickItemData.lunch?.listMain.length !== 0")
                                    b-col.col-12
                                        b-row
                                            b-col.col-12.mb-2
                                                span List of lunch meal
                                            b-col.col-12
                                                b-row.mx-1
                                                    b-col.col-12.border
                                                        b-row.align-items-center(style="min-height: 2.5rem;")
                                                            b-col.col-12(style="font-weight: 600;")
                                                                span Meal Name
                                                        b-row.py-2.align-items-center.border.border-right-0.border-bottom-0.border-left-0(
                                                            v-for="(item, index) in modal.editModalClickItemData.lunch?.listMain" :key="index"
                                                            style="min-height: 2.5rem;"
                                                            )
                                                            b-col.col-9
                                                                b-form-input(v-model="item.name")
                                                            b-col.col-3.d-flex.justify-content-center
                                                                b-button(variant="danger" @click="editRemoveItemInMainListLunch(item)") Remove
                            b-col.col-6
                                //-- Edit Lunch Beverage
                                b-form-group(label="Beverage Name (required)")
                                    b-input-group(label="Beverage Name" label-for="event-beverage")
                                        b-form-input(id="event-beverage" type="text" placeholder="Enter beverage" v-model="modal.lunchState.beverageName" @keyup.enter="editAddListBeverageLunch(modal.editModalClickItemData)")
                                        b-input-group-append
                                            b-button(variant="success" @click="editAddListBeverageLunch(modal.editModalClickItemData)") Add
                                b-row.my-2(v-if="modal.editModalClickItemData.lunch?.listBeverage.length !== 0")
                                    b-col.col-12
                                        b-row
                                            b-col.col-12.mb-2
                                                span List of lunch beverage
                                            b-col.col-12
                                                b-row.mx-1
                                                    b-col.col-12.border
                                                        b-row.align-items-center(style="min-height: 2.5rem;")
                                                            b-col.col-12(style="font-weight: 600;")
                                                                span Meal Name
                                                        b-row.py-2.align-items-center.border.border-right-0.border-bottom-0.border-left-0(
                                                            v-for="(item, index) in modal.editModalClickItemData.lunch?.listBeverage" :key="index"
                                                            style="min-height: 2.5rem;"
                                                            )
                                                            b-col.col-9
                                                                b-form-input(v-model="item.name")
                                                            b-col.col-3.d-flex.justify-content-center
                                                                b-button(variant="danger" @click="editRemoveItemInBeverageListLunch(item)") Remove

                //-- edit iternary multiple days
                b-row(v-if="modal.editModalClickItemData.multipleDays").mx-1
                    
                    b-col.col-12.font-weight-bold
                        span Multiple Days Schedule 
                    b-col.col-12(v-for="(item, index) in modal.editModalClickItemData.multipleDays" :key="index")
                        b-row
                            b-col.col-12.font-weight-bold
                                span {{ item.title }}

                            b-col.col-12(v-if="item?.itinerary?.length !== 0")
                                b-row
                                    b-col.col-12.mb-2
                                        span List Itinerary
                                    b-col.col-12
                                                
                                                
                                        b-row.align-items-center(style="min-height: 2.5rem;")
                                            b-col.col-5(style="font-weight: 600;")
                                                                span Time
                                            b-col.col-5(style="font-weight: 600;")
                                                                span Description
                                            b-col.col-2(style="font-weight: 600;")
                                        b-row.py-2.align-items-center.border.border-right-0.border-bottom-0.border-left-0(
                                                            v-for="(schedule, index) in item?.itinerary" :key="index"
                                                            style="min-height: 2.5rem;"
                                                            )
                                            b-col.col-5
                                                            
                                                b-form-input(v-model="schedule.time" type="time")
                                            b-col.col-5
                                                                
                                                b-form-input(v-model="schedule.description" type="text")
                                            b-col.col-2.d-flex.justify-content-center
                                                b-button(variant="danger" @click="editRemoveEventItineraryMultipleDays(index,item)") Remove
                                    b-col.col-12(class="add-items-multiple-days")
                                        b-row
                                            b-col.col-12.font-weight-bold
                                                span Add Itinerary
                                            b-col.px-4
                                                b-row.align-items-center
                                                    b-col.col-5
                                                        b-form-group(id="event-form-itinerary-time" label="Time" label-for="event-itinerary-time")
                                                            b-form-input(id="event-itinerary-time" type="time" v-model="modal.itineraryState.time")
                                                    b-col.col-5
                                                        b-form-group(id="event-form-itinerary-description" label="Description" label-for="event-itinerary-description")
                                                            b-form-input(id="event-itinerary-description" type="text" v-model="modal.itineraryState.description")
                                                    b-col.col-2
                                                        b-button(variant="success" @click="editAddEventItineraryMultipleDays(item)" class="w-100 align-self-baseline") Add
                //-- edit lunch multiple days
                b-row(v-if="modal.editModalClickItemData.multipleDays").mx-1
                    b-col.col-12.font-weight-bold
                        span Multiple Days Lunch Menu
                    b-col.col-12(v-for="(item, index) in modal.editModalClickItemData.multipleDays" :key="index").m-2
                        b-row.my-2
                            b-col.col-12.my-2
                                span.h5 {{ item.title }}
                            b-col.col-12(v-if="item?.lunch?.listMain.length !== 0")
                                b-row
                                    b-col.col-12.my-1
                                        span List of lunch meal
                                    b-col.col-12
                                        b-row
                                            b-col.col-12.border
                                                b-row.align-items-center(style="min-height: 2.5rem;")
                                                    b-col.col-12(style="font-weight: 600;")
                                                        span Meal Name
                                                b-row.py-2.align-items-center.border.border-right-0.border-bottom-0.border-left-0(
                                                            v-for="(food, index) in item?.lunch?.listMain" :key="index"
                                                            style="min-height: 2.5rem;"
                                                            )
                                                    b-col.col-9
                                                        b-form-input(v-model="food.name")
                                                    b-col.col-3.d-flex.justify-content-center
                                                        b-button(variant="danger" @click="editRemoveItemInMainListLunchMultipleDays(index,item)") Remove
                                    b-col.col-12(class="add-lunch-item").my-3.py-1.border
                                        b-row
                                            b-col.col-12.font-weight-bold
                                                span Add Lunch
                                            b-col.col-12
                                               
                                                        b-form-group(label="Main Lunch (required)")
                                                            b-input-group(label="Main Lunch" label-for="event-lunch")
                                                                b-form-input(id="event-lunch" type="text" placeholder="Enter main lunch" v-model="modal.lunchState.mealName" @keyup.enter="editAddListMainLunchMultipleDays(item.title)")
                                                                b-input-group-append
                                                                    b-button(variant="success" @click="editAddListMainLunchMultipleDays(item.title)") Add
                                                    
                            b-col.col-12(v-if="item?.lunch?.listBeverage.length !== 0").mt-2
                                b-row
                                    b-col.col-12.my-1
                                        span List of lunch beverage
                                    b-col.col-12
                                        b-row
                                            b-col.col-12.border
                                                b-row.align-items-center(style="min-height: 2.5rem;")
                                                    b-col.col-12(style="font-weight: 600;")
                                                        span Meal Name
                                                b-row.py-2.align-items-center.border.border-right-0.border-bottom-0.border-left-0(
                                                            v-for="(drinks, index) in item?.lunch?.listBeverage" :key="index"
                                                            style="min-height: 2.5rem;"
                                                            )
                                                    b-col.col-9
                                                        b-form-input(v-model="drinks.name")
                                                    b-col.col-3.d-flex.justify-content-center
                                                        b-button(variant="danger" @click="editRemoveItemInBeverageListLunchMultipleDays(index,item)") Remove
                                    b-col.col-12(class="add-lunch-item").my-3.py-1.border
                                        b-row
                                            b-col.col-12.font-weight-bold
                                                span Add Beverage
                                            b-col.col-12
                                               
                                                        b-form-group(label="Beverage Name (required)")
                                                            b-input-group(label="Beverage Name" label-for="event-beverage")
                                                                b-form-input(id="event-beverage" type="text" placeholder="Enter beverage" v-model="modal.lunchState.beverageName" @keyup.enter="editAddListBeverageLunchMultipleDays(item.title)")
                                                                b-input-group-append
                                                                    b-button(variant="success" @click="editAddListBeverageMultipleDays(item.title)") Add

                b-form-group(class="font-weight-bold" label="Group (required)" label-for="edit-event-group")
                    v-select(
                        class="font-weight-normal"
                        id="edit-event-group"
                        :options="listGroupSession" 
                        :reduce="item => ({ id: item.id, name: item.name })" 
                        multiple 
                        label="name" 
                        v-model="modal.editModalClickItemData.listGroup"
                        )  

        template(v-if="!modal.stateEditEventItem.isEditModalEvent" #modal-footer)
            //- b-button(variant="danger" @click="openModalDeleteEvent(modal.modalClickItemId)") Delete Event
            //- b-button(variant="danger" @click="modalClickDeleteEventItem(modal.modalClickItemId)") Delete Event
            //- b-button(v-if="isEditModalEventAllowed(modal.modalClickItemData?.msgId)" variant="info" @click="editEventItem(modal.modalClickItemId)") Edit Event
            //- b-button(v-else variant="info" disabled) Edit Event
            b-button(variant="secondary" @click="closeModalItem") Close
        template(v-else #modal-footer)
            b-button(variant="success" @click="saveEditEventItem(modal.modalClickItemId)") Save Edit
            b-button(variant="info" @click="backToOpenModalItem()") Back
            b-button(variant="secondary" @click="closeModalItem()") Close


    //--------------- modal click item


    Header(title="Events" :isAdmin="isAdmin")
    b-row(class="align-items-center" style="height: 10%")
        //-- phone screen --//
        b-col(class="col-7 col-sm-10 d-flex d-sm-none justify-content-start")
            h5(class="font-weight-bold") DSG Events
        //-- phone screen --//
        //-- other screen --//
        b-col(class="col-7 col-sm-10 d-none d-sm-flex justify-content-start")
            h2(class="font-weight-bold") DSG Events
        //-- other screen --//
        b-col(class="col-5 col-sm-2 d-flex justify-content-end")
            //- b-button(:style="`background-color: ${btnClickedColor}`" @click="openModalAddEvent") Create Event
            b-button(variant="success" @click="showHideEventCreateModal") Create Event
            

    b-row(class="px-3 px-sm-0 my-3 my-sm-0" style="height: 5%")
        b-col(class="col-8 py-3 py-sm-0 order-2 order-sm-1")
            b-row(class="h-100")
                b-col(class="col-4 col-sm-4 col-lg-2 d-flex justify-content-center align-items-center font-weight-bold" :style="changeBtnFilterStyle(btnFilter.listBtn[2])" @click="upcomingActive")
                    span Upcoming
                b-col(class="col-4 col-sm-3 col-lg-2 d-flex justify-content-center align-items-center font-weight-bold" :style="changeBtnFilterStyle(btnFilter.listBtn[1])" @click="pastActive")
                    span Past
                b-col(class="col-4 col-sm-2 col-lg-2 d-flex justify-content-center align-items-center font-weight-bold" :style="changeBtnFilterStyle(btnFilter.listBtn[0])" @click="allActive")
                    span All
        b-col(class="col-12 col-sm-4 d-flex order-1 order-sm-2 align-items-center")
            //- BIconSearch(style="position: absolute; left: 30px")
            b-input-group
                b-form-input(
                    type="search" 
                    :placeholder="stateSearchEvent.isSeachByEventName ? 'Search by event name' : 'Search by client name'" 
                    v-model="searchEventItem"
                    )
                b-input-group-append
                    b-button.font-weight-bold(
                        :variant="stateSearchEvent.isSeachByEventName ? 'success' : 'secondary'" 
                        size="sm"
                        v-b-tooltip.hover title="Search by event name"
                        @click="handleClickIsSeachByEventName"
                    ) Event
                    b-button.font-weight-bold(
                        :variant="stateSearchEvent.isSearchByClientName ? 'success' : 'secondary'" 
                        size="sm" 
                        v-b-tooltip.hover title="Search by client name"
                        @click="handleClickIsSearchByClientName"
                    ) Client
    //- spacer
    b-row(class="d-none d-sm-block" style="height: 5%;")
    //- spacer
    b-row(class="px-3 px-sm-0 pt-3 pt-sm-none mt-5 mt-sm-0" style="height: 55%; overflow: auto;")
        b-col(class="p-0 rounded border border-dark" style="overflow: auto;")
            b-table(
                :fields="tableFields"
                :items="searchEvent"
                sort-by="date"
                :sort-desc="false"
                class="table-header-color"
                thead-tr-class="text-white"
                tbody-tr-class="bg-white"
                show-empty
                @row-clicked="openModalClickHanlder"
            )
                template(#empty="scope")
                    b-row(
                        v-if="!isLoadingTwilio && searchEvent.length === 0"
                    )
                        b-col.col-12
                            span No data
                    b-row(
                        v-else
                    )
                        b-col.col-12
                            b-spinner(variant="secondary")
                template(#cell(event)="data")
                    span {{ data.item.title }}
                template(#cell(status)="data")
                    span {{ data.item.status }}
                template(#cell(pricePerPerson)="data")
                    span ${{ (data.item.pricePerPerson * data.item.gst).toFixed(2) }}
                template(#cell(totalAttendance)="data")
                    span {{ calculateTotalAttendance(data.item) }}
                template(#cell(date)="data")
                    span {{ formatDate(data.item.date) }}
        
    add-event-modal(:show.sync="showAddEventModal" @close="showHideEventCreateModal" :groups="listGroupSession" @data="createEventNew" :createEventSubmit.sync="isCreateEventSubmit")
        

</template>

<script>
import Header from '../components/Header.vue';
import AddEventModal from '../components/eventModals/AddEventModal.vue';
import { BIconSearch } from "bootstrap-vue";
import { faker } from "@faker-js/faker";
import dayjs from "dayjs";
import { z } from "zod";
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { TABLE } from "../store/ModuleName";
import { EVENTS_NAMESPACE, GETTERS, MUTATIONS, ACTIONS } from "../store/Modules/Events/types-events";
import { VueEditor } from "vue2-editor";
export default {
    name: "Events",
    components: {
        Header,
        BIconSearch,
        VueEditor,
        AddEventModal
    },
    data() {
        return {
            showAddEventModal: false,
            multipleDay: false,
            customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        ["link"],
        ["clean"],
      ],
            tableFields: [
                {
                    key: "event",
                    label: "Event",
                },
                {
                    key: "status",
                    label: "Status",
                },
                {
                    key: "pricePerPerson",
                    label: "Price Per Caregiver (After GST)",
                },
                {
                    key: "totalAttendance",
                    label: "Total Attendance",
                },
                {
                    key: "date",
                    label: "Date",
                    sortable: true,
                },
            ],
            btnClickedColor: "#7C359F",
            searchEventItem: "",
            stateSearchEvent: {
                isSeachByEventName: true,
                isSearchByClientName: false,
            },
            modal: {
                listId: ['modal-add-event', 'modal-item'],
                modalAddEventId: 'modal-add-event',
                modalConfirmationAddEventId: 'modal-confirmation-add-event',
                deleteEventItem: {
                    modalDeleteConfirmationId: null,
                    isDeleting: false,
                },
                removeEventAttendant: {
                    id: null,
                    clientName: null,
                    caregiverId: null,
                    isRemoving: false,
                },
                modalManuallyAddEvent: {
                    modalId: null,
                    modalListConfirmationId: "modal-manually-add-attendant-list-confirmation",
                    modalConfirmationId: "modal-manually-add-attendant-confirmation",
                    isSubmit: false,
                    inputAddEvent: {
                        client: {
                            data: null,
                            lunch: {
                                main: null,
                                beverage: null,
                            }
                        },
                        caregiver: {
                            data: null,
                            lunch: {
                                main: null,
                                beverage: null,
                            },
                        },
                        otherPersonJoin: {
                            tempData: {
                                name: null,
                                lunch: {
                                    main: null,
                                    beverage: null,
                                },
                            },
                            listOtherPersonJoin: [],
                        },
                    }

                },
                modalClickItemId: null,
                modalClickItemData: null,
                modalClickItemDataClientSearchInput: null,
                modalEventItemClickClientItem: {
                    id: null,
                    msgId: null,
                    convId: null,
                    data: null,
                },
                modalAddOtherPersonJoinModalEventItemClickClientItem: {
                    id: null,
                    isSubmitting: false,
                    otherPersonJoinInput: {
                        tempData: {
                            name: null,
                            lunch: {
                                main: null,
                                beverage: null,
                            },
                        },
                        listOtherPersonJoin: [],
                    },

                },
                editModalClickItemData: null,
                stateEditEventItem: {
                    isSaveEdit: false,
                    isEditModalEvent: false,
                    tempDataEditDate: {
                        date: null,
                        time: null,
                    },
                },
                acknowledgePaymentInput: {
                    cashMemo: null,
                    chequeNo: null,
                    isAcknowledgingPayment: false,
                },
                isEditGst: false,
                itineraryState: {
                    isIncludeItinerary: false,
                    time: null,
                    description: null,
                },
                lunchState: {
                    isIncludeLunch: false,
                    mealName: null,
                    beverageName: null,
                },
                addEventinput: {
                    title: null,
                    listGroup: null,
                    date: null,
                    time: null,
                    description: null,
                    itinerary: [],
                    // itinerary: [
                    //     {
                    //         time: "12:00 am",
                    //         description: "Go to Bird Park",
                    //     }
                    // ],
                    lunch: {
                        listMain: [],
                        listBeverage: [],
                    },
                    pricePerPerson: null,
                    gst: 1.08,
                }
            },
            btnFilter: {
                listBtn: ['all', 'past', 'upcoming'],
                isActive: 'upcoming',
            },
            listTypeOfPayment: ["online", "cash", "cheque"],
            isCreateEventSubmit: false,
        }
    },
    async mounted() {
        // TODO: make loading until every data from event is loaded
        // this.initTest();
        // FIX: if getEveryGroup doesn't have any data within 15 secs, throw error. because it's UNAUTHORIZE or Network problem 
        const { sessions } = await this.getEveryGroup();
        // console.log('sessions',sessions)

        if (!sessions && sessions.length === 0) {
            return;
        }

        const sessionMap = sessions.map((item) => ({
            id: item.crb5c_fow_session_scheduleid,
            name: item.crb5c_session_id,
        }));
        const sortListGroupSession = this.$root.sortSessionGroupNameByDay(sessionMap);

        sortListGroupSession.forEach(item => {
            this[MUTATIONS.ADD_LIST_GROUP_SESSION](item);
        });

        await this[ACTIONS.DB_GET_EVENT]();

    },
    computed: {
        ...mapState(["isLoadingTwilio", "isAdmin"]),
        ...mapState({
            listGroupSession: state => (state[EVENTS_NAMESPACE].listGroupSession),
            listData: state => (state[EVENTS_NAMESPACE].listData),
            listLunchDataFromListData: state => (state[EVENTS_NAMESPACE].listLunchDataFromListData),
            listAllClientAndCaregiverData: state => (state[EVENTS_NAMESPACE].listAllClientAndCaregiverData),
        }),
        ...mapGetters({
            [GETTERS.INDICATOR_COUNT_CAREGIVER_CASH_OR_CHEQUE_NOT_ACKNOWLEDGE]: `${EVENTS_NAMESPACE}/${GETTERS.INDICATOR_COUNT_CAREGIVER_CASH_OR_CHEQUE_NOT_ACKNOWLEDGE}`,
        }),
        getCaregiverFromClient() {
            const clientInput = this.modal.modalManuallyAddEvent.inputAddEvent.client.data;
            if (!clientInput) {
                return null;
            }

            const clientId = this.modal.modalManuallyAddEvent.inputAddEvent.client.data.id;
            const clientAndCaregiverItem = this.listAllClientAndCaregiverData.find(item => item.crb5c_Client.crb5c_fow_customerid === clientId);

            if (!clientAndCaregiverItem) {
                return null;
            }

            return [clientAndCaregiverItem.crb5c_Caregiver];

        },
        searchEvent() {
            if (this.listData.length === 0) {
                return [];
            }

            // search by event name
            if (this.stateSearchEvent.isSeachByEventName) {
                if (this.btnFilter.isActive === this.btnFilter.listBtn[1]) {
                    if (!this.searchEventItem || this.searchEventItem === "") {
                        return this.listData.filter(item => item.status === 'Past');
                    }

                    return this.listData.filter(item => item.status === 'Past').filter(item => (
                        item.title.toLowerCase().includes(this.searchEventItem.toLowerCase())
                    ));
                }
                if (this.btnFilter.isActive === this.btnFilter.listBtn[2]) {
                    if (!this.searchEventItem || this.searchEventItem === "") {
                        return this.listData.filter(item => item.status === 'Upcoming');
                    }

                    return this.listData.filter(item => item.status === 'Upcoming').filter(item => (
                        item.title.toLowerCase().includes(this.searchEventItem.toLowerCase())
                    ));
                }
            }

            // search by client name
            if (this.stateSearchEvent.isSearchByClientName) {
                const result = this.listData
                    .filter(item => item.caregivers.find(item2 => item2.client.name.toLowerCase().includes(this.searchEventItem.toLowerCase())));

                return result;
            }


            if (!this.searchEventItem || this.searchEventItem === "") {
                return this.listData;
            }

            return this.listData.filter(item => (
                item.title.toLowerCase().includes(this.searchEventItem.toLowerCase())
            ));

        },
        searchClientInEventItem() {
            if (!this.modal.modalClickItemData) {
                return null;
            }

            if (this.modal.modalClickItemData?.caregivers.length === 0) {
                return null;
            }

            if (this.modal.modalClickItemDataClientSearchInput === null || this.modal.modalClickItemDataClientSearchInput === "") {
                return this.modal.modalClickItemData?.caregivers;
            }

            return this.modal.modalClickItemData?.caregivers
                .filter(item => item.client.name.toLowerCase().includes(this.modal.modalClickItemDataClientSearchInput.toLowerCase()));

        },
        listSummaryAttendeesLunchMenu() {
            if (!this.modal.modalClickItemData) {
                return null;
            }

            if (this.modal.modalClickItemData.caregivers.length === 0) {
                return null;
            }

            const mapLunch = this.modal.modalClickItemData.caregivers.map(item => ({
                client: {
                    main: item.listLunchSelected.client.main[0],
                    beverage: item.listLunchSelected.client.beverage[0],
                },
                caregiver: {
                    main: item.listLunchSelected.caregiver.main[0],
                    beverage: item.listLunchSelected.caregiver.beverage[0],
                },
                otherPersonJoining: item.otherPersonJoining.map(itemInside => itemInside?.lunch),
            }));



            const listMainLunch = [
                ...mapLunch.map(item => item.client.main),
                ...mapLunch.map(item => item.caregiver.main),
                ...mapLunch.map(item => item.otherPersonJoining).flat().map(item => item.main),
            ];

            const listLunchBeverage = [
                ...mapLunch.map(item => item.client.beverage),
                ...mapLunch.map(item => item.caregiver.beverage),
                ...mapLunch.map(item => item.otherPersonJoining).flat().map(item => item.beverage),
            ];


            const resultListMainLunch = accumulateAndTransformListLunch(listMainLunch);
            const resultListLunchBeverage = accumulateAndTransformListLunch(listLunchBeverage);


            return {
                resultListMainLunch: removeUndefinedFromArray(resultListMainLunch),
                resultListLunchBeverage: removeUndefinedFromArray(resultListLunchBeverage),
            };


            function accumulateAndTransformListLunch(listLunch) {
                return listLunch.reduce((accumulator, item) => {
                    const existingItem = accumulator.find(obj => obj[item]);
                    if (existingItem) {
                        existingItem[item].count++;
                    } else {
                        const newItem = { [item]: { count: 1 } };
                        accumulator.push(newItem);
                    }
                    return accumulator;

                }, []);
            }

            function removeUndefinedFromArray(listLunch) {
                return listLunch.filter(obj => Object.keys(obj)[0] !== "undefined");
            }

        },

    },
    methods: {
        editAddListBeverageMultipleDays(title){
            if (!this.modal.lunchState.beverageName) {
                this.makeToast("Add Beverage", "Please fill in the beverage", "danger");
                return;
            }

            const item = this.modal.editModalClickItemData.multipleDays.find(item => item.title === title);
            if(!item){
                return;
            }
            item.lunch.listBeverage.push({
                name: this.modal.lunchState.beverageName,
            });

            this.modal.lunchState.beverageName = null;
        },
        editRemoveItemInMainListLunchMultipleDays(index,item){
            const itemIndex = this.modal.editModalClickItemData.multipleDays.findIndex(itemInside => itemInside.title === item.title);
            if(index !== -1){
            this.modal.editModalClickItemData.multipleDays[itemIndex].lunch.listMain = this.modal.editModalClickItemData.multipleDays[itemIndex].lunch.listMain.filter((itemInside, indexInside) => indexInside !== index);
            }
        },
        editRemoveItemInBeverageListLunchMultipleDays(index,item){

            const itemIndex = this.modal.editModalClickItemData.multipleDays.findIndex(itemInside => itemInside.title === item.title);
            if(index !== -1){
            this.modal.editModalClickItemData.multipleDays[itemIndex].lunch.listBeverage = this.modal.editModalClickItemData.multipleDays[itemIndex].lunch.listBeverage.filter((itemInside, indexInside) => indexInside !== index);
            }
        },
        editAddListMainLunchMultipleDays(title){
            if (!this.modal.lunchState.mealName) {
                this.makeToast("Add Main Lunch", "Please fill in the main lunch", "danger");
                return;
            }

            const item = this.modal.editModalClickItemData.multipleDays.find(item => item.title === title);
            if(!item){
                return;
            }
            item.lunch.listMain.push({
                name: this.modal.lunchState.mealName,
            });

            this.modal.lunchState.mealName = null;

        },
        editRemoveEventItineraryMultipleDays(index,item) {
            const itemIndex = this.modal.editModalClickItemData.multipleDays.findIndex(itemInside => itemInside.title === item.title);
            console.log('itemIndex', itemIndex);
            console.log('item', this.modal.editModalClickItemData.multipleDays);
            if(index !== -1){
            this.modal.editModalClickItemData.multipleDays[itemIndex].itinerary = this.modal.editModalClickItemData.multipleDays[itemIndex].itinerary.filter((itemInside, indexInside) => indexInside !== index);
            }


        },
        editAddEventItineraryMultipleDays(item) {
            if (!this.modal.itineraryState.time || !this.modal.itineraryState.description) {
                this.makeToast("Add Itinerary", "Please fill in the time and description", "danger");
                return;
            }

            item.itinerary.push({
                time: this.modal.itineraryState.time,
                description: this.modal.itineraryState.description,
            });

            this.modal.itineraryState.time = null;
            this.modal.itineraryState.description = null;
        },
        // this is the create event new one - aaddeventmodal file
      async  createEventNew(data) {
            console.log('data', data);
            try {
                this.isCreateEventSubmit = true;
                //es lint disable next line
                // eslint-disable-next-line
                const { eventName,description,group,gst,isSingleDay,singleDay,pricePerCaregiver,total,multipleDays,remarks } = data;
                
                if(isSingleDay){

                const combineDateAndTime = new Date(singleDay.date + "T" + singleDay.time);
                const modifyPricePerPerson = +pricePerCaregiver;
                this.makeToast('Creating Event', 'Creating event...', 'info');
                await this[ACTIONS.CREATE_EVENT]({
                    title : eventName,
                    listGroup: group,
                    date: combineDateAndTime,
                    description,
                    itinerary : singleDay.itineraries ? singleDay.itineraries : [],
                    lunch: {
                        listMain: singleDay.food.lunch ? singleDay.food.lunch : [],
                        listBeverage: singleDay.food.beverages ? singleDay.food.beverages : [],
                    },
                    remarks: remarks,
                    pricePerPerson: modifyPricePerPerson,
                    gst,
                    isSingleDay
                });

                this.makeToast('Event Form', 'Event created successful!', 'success');

                this.closeModalConfitmationAddEvent();
                this.closeModalAddEvent();

                this.isCreateEventSubmit = false;
            }
            else{
                this.makeToast('Creating Event', 'Creating event...', 'info');
                // eslint-disable-next-line no-unused-vars
                const modifyPricePerPerson = +pricePerCaregiver;
                // eslint-disable-next-line no-unused-vars
                const modifyTotal = +total;
                // eslint-disable-next-line no-unused-vars
                const modifyGst = +gst;
                const modifyMultipleDays = multipleDays.events.map(item => {
                    const combineDateAndTime = new Date(item.date + "T" + item.time);
                    return {
                        date: combineDateAndTime,
                        title: item.title,
                        itinerary: item.itineraries ? item.itineraries : [],
                        lunch: {
                            listMain: item.food.lunch ? item.food.lunch : [],
                            listBeverage: item.food.beverages ? item.food.beverages : [],
                        },
                    }
                    
                });
                await this[ACTIONS.CREATE_EVENT]({
                    title : eventName,
                    listGroup: group,
                    description,
                    pricePerPerson: modifyPricePerPerson,
                    gst,
                    remarks: remarks,
                    isSingleDay,
                    multipleDays: modifyMultipleDays,
                    date: modifyMultipleDays[0].date,

                });

                this.makeToast('Event Form', 'Event created successful!', 'success');


                console.log('modifyMultipleDays', modifyMultipleDays);
            }

            } catch (error) {
                this.handleAxiosError(error, () => {
                    this.closeModalConfitmationAddEvent();
                    this.closeModalAddEvent();
                    this.isCreateEventSubmit = false;
                    this.makeToast("Create Event Failed", "Something went wrong...", "danger");
                });
            }
        },
        showHideEventCreateModal(){
            this.showAddEventModal = !this.showAddEventModal;
        },  
        ...mapMutations(EVENTS_NAMESPACE, [
            MUTATIONS.ADD_LIST_DATA,
            MUTATIONS.ADD_LIST_GROUP_SESSION,
            MUTATIONS.ADD_CAREGIVER,
        ]),
        ...mapActions({
            "removeAnnouncement": `${TABLE}/removeAnnouncement`,
            [ACTIONS.DETERMINE_EVENT_STATUS]: `${EVENTS_NAMESPACE}/${ACTIONS.DETERMINE_EVENT_STATUS}`,
            [ACTIONS.CREATE_EVENT]: `${EVENTS_NAMESPACE}/${ACTIONS.CREATE_EVENT}`,
            [ACTIONS.VALIDATE_LIST_DATA]: `${EVENTS_NAMESPACE}/${ACTIONS.VALIDATE_LIST_DATA}`,
            [ACTIONS.UPDATE_CAREGIVER_PAYMENT_ACKNOWLEDGEMENT]: `${EVENTS_NAMESPACE}/${ACTIONS.UPDATE_CAREGIVER_PAYMENT_ACKNOWLEDGEMENT}`,
            [ACTIONS.UPDATE_EVENT_ATTRIBUTES]: `${EVENTS_NAMESPACE}/${ACTIONS.UPDATE_EVENT_ATTRIBUTES}`,
            [ACTIONS.REMOVE_CAREGIVER_FROM_EVENT]: `${EVENTS_NAMESPACE}/${ACTIONS.REMOVE_CAREGIVER_FROM_EVENT}`,
            [ACTIONS.DB_GET_EVENT]: `${EVENTS_NAMESPACE}/${ACTIONS.DB_GET_EVENT}`,
            [ACTIONS.DB_POST_EVENT]: `${EVENTS_NAMESPACE}/${ACTIONS.DB_POST_EVENT}`,
            [ACTIONS.DB_DELETE_EVENT]: `${EVENTS_NAMESPACE}/${ACTIONS.DB_DELETE_EVENT}`,
            [ACTIONS.DB_EVENT_CAREGIVER_DELETE]: `${EVENTS_NAMESPACE}/${ACTIONS.DB_EVENT_CAREGIVER_DELETE}`,
            [ACTIONS.DB_PATCH_EVENT_CAREGIVER_PAYMENT_ACKNOWLEDGE]: `${EVENTS_NAMESPACE}/${ACTIONS.DB_PATCH_EVENT_CAREGIVER_PAYMENT_ACKNOWLEDGE}`,
        }),
        ...mapActions(["getEveryGroup"]),
        // seedTheEventsData(){},
        testAction() {
            this[MUTATIONS.ADD_CAREGIVER]({
                itemId: '123456',
                caregiverPayload: {
                    id: faker.database.mongodbObjectId(),
                    name: faker.person.fullName(),
                    client: {
                        id: faker.database.mongodbObjectId(),
                        name: faker.person.fullName(),
                    },
                    otherPersonJoining: ["mike"],
                }
            });

            console.log('listData after submit: ', this.listData);

        },
        // eslint-disable-next-line no-unused-vars
        openModalClickHanlder(record, index) {
            // console.log(record, index);
            const { msgId, convId } = record;
            this.openModalItem(msgId, convId);
        },
        async initTest() {

            // const listLunchSelected = {
            //     msgId: faker.database.mongodbObjectId(),
            //     main: [
            //         {
            //             name: "Pizza",
            //             listPersonChoose: [
            //                 faker.person.fullName(),
            //                 faker.person.fullName(),
            //                 faker.person.fullName(),
            //             ],
            //         },
            //         {
            //             name: "Fried Chicken",
            //             listPersonChoose: [
            //                 faker.person.fullName(),
            //                 faker.person.fullName(),
            //                 faker.person.fullName(),
            //             ],
            //         }
            //     ],
            //     beverage: [
            //         {
            //             name: "Milk Tea",
            //             listPersonChoose: [
            //                 faker.person.fullName(),
            //                 faker.person.fullName(),
            //                 faker.person.fullName(),
            //             ],
            //         },
            //         {
            //             name: "Barley Drinks",
            //             listPersonChoose: [
            //                 faker.person.fullName(),
            //                 faker.person.fullName(),
            //                 faker.person.fullName(),
            //             ],
            //         }
            //     ],
            // }

            for (let i = 0; i < 3; i++) {
                const eventDate = faker.date.anytime().toISOString();

                const listPaymentType = ["online", "cash", "cheque"];
                const randomPaymenType = listPaymentType[Math.floor(Math.random() * listPaymentType.length)];

                const state = {
                    msgId: faker.database.mongodbObjectId(),
                    convId: faker.database.mongodbObjectId(),
                    description: faker.word.words({ count: 100 }),
                    itinerary: [
                        {
                            time: faker.date.anytime().toISOString(),
                            description: faker.word.sample({ length: 100 }),
                        }
                    ],
                    media: null,
                }

                const attributes = {
                    title: faker.word.words(2),
                    listGroup: [
                        {
                            id: faker.database.mongodbObjectId(),
                            name: "Wednesday Test (TQ only)",
                        }
                    ],
                    lunch: {
                        listMain: ["Pizza", "Fried Rice"],
                        listBeverage: ["Tea", "Barli"],
                    },
                    pricePerPerson: faker.number.int({ max: 100, }),
                    gst: 1.08,
                    date: eventDate,
                    status: await this[ACTIONS.DETERMINE_EVENT_STATUS](eventDate),
                    admin: "ThunderQuote IT4",
                    isEventConversation: true,
                    caregivers: [
                        {
                            id: faker.database.mongodbObjectId(),
                            name: faker.person.fullName(),
                            isAttending: true,
                            isCaregiverCome: true,
                            listLunchSelected: {
                                caregiver: {
                                    main: ["Pizza"],
                                    beverage: ["Tea"],
                                },
                                client: {
                                    main: ["Fried Rice"],
                                    beverage: ["Barli"],
                                },
                            },
                            client: {
                                id: faker.database.mongodbObjectId(),
                                name: faker.person.fullName(),
                            },
                            otherPersonJoining: [
                                {
                                    name: faker.person.fullName(),
                                    lunch: {
                                        main: "Pizza",
                                        beverage: "Barli",
                                    }
                                },
                                {
                                    name: faker.person.fullName(),
                                    lunch: {
                                        main: "Pizza",
                                        beverage: "Barli",
                                    }
                                },
                            ],
                            paymentInfo: {
                                totalPrice: faker.number.float({ max: 100, }),
                                typeOfPayment: "online",
                                proofOfPayment: {
                                    cashMemo: null,
                                    onlineReceiptId: faker.database.mongodbObjectId(),
                                    chequeNo: null,
                                },
                                paymentStatus: true,
                                paymentDate: faker.date.anytime().toISOString(),
                            }
                        },
                        {
                            id: faker.database.mongodbObjectId(),
                            name: faker.person.fullName(),
                            isAttending: true,
                            isCaregiverCome: true,
                            listLunchSelected: {
                                caregiver: {
                                    main: ["Pizza"],
                                    beverage: ["Tea"],
                                },
                                client: {
                                    main: ["Fried Rice"],
                                    beverage: ["Barli"],
                                },
                            },
                            client: {
                                id: faker.database.mongodbObjectId(),
                                name: faker.person.fullName(),
                            },
                            otherPersonJoining: [
                                {
                                    name: faker.person.fullName(),
                                    lunch: {
                                        main: "Pizza",
                                        beverage: "Barli",
                                    }
                                },
                                {
                                    name: faker.person.fullName(),
                                    lunch: {
                                        main: "Pizza",
                                        beverage: "Barli",
                                    }
                                },
                            ],
                            paymentInfo: {
                                totalPrice: faker.number.float({ max: 100, }),
                                typeOfPayment: randomPaymenType,
                                proofOfPayment: {
                                    cashMemo: null,
                                    onlineReceiptId: faker.database.mongodbObjectId(),
                                    chequeNo: null,
                                },
                                paymentStatus: true,
                                paymentDate: faker.date.anytime().toISOString(),
                            }
                        }
                    ],
                }

                const payload = {
                    ...state,
                    ...attributes,
                }

                await this[ACTIONS.VALIDATE_LIST_DATA](payload);
                this[MUTATIONS.ADD_LIST_DATA](payload);

            }

            // this.listLunchDataFromListData = this.transformLunchData(this.listData);

        },
        testUrlReceiptImage() {
            const url = faker.image.url();
            return url;

        },
        handleClickIsSeachByEventName() {
            this.stateSearchEvent.isSeachByEventName = true;
            this.stateSearchEvent.isSearchByClientName = false;
        },
        handleClickIsSearchByClientName() {
            this.stateSearchEvent.isSeachByEventName = false;
            this.stateSearchEvent.isSearchByClientName = true;
        },
        isEditModalEventAllowed(msgId) {
            const event = this.getEventInfo(msgId);

            if (!event) {
                return false;
            }

            if (event.caregivers.length === 0) {
                return true;
            }

            return false;

        },
        calculateEventPriceWithGST(pricePerPerson, gstPercent) {
            if (!pricePerPerson || !gstPercent) {
                return;
            }

            return (pricePerPerson * gstPercent).toFixed(2);
        },
        calculateTotalAttendance(item) {
            if (item.caregivers.length === 0) {
                return 0;
            }

            const countTotalAttendance = item.caregivers
                .filter(item => item.isAttending)
                .map(item => (
                    this.calculateATotalPersonComingInModalEvent(item.isCaregiverCome, item.otherPersonJoining)
                ))
                .reduce((acc, curr) => {
                    return acc + curr;
                }, 0);

            return countTotalAttendance;


        },
        moveToHomePage() {
            this.$router.push({ path: "/" });
        },
        formatDate(rawDate) {
            return dayjs(rawDate).format('DD MMM YY, hh:mm A');
        },
        allActive() {
            this.btnFilter.isActive = this.btnFilter.listBtn[0];
        },
        pastActive() {
            this.btnFilter.isActive = this.btnFilter.listBtn[1];
        },
        upcomingActive() {
            this.btnFilter.isActive = this.btnFilter.listBtn[2];
        },
        changeBtnFilterStyle(filterName) {
            if (filterName === this.btnFilter.isActive) {
                return `cursor: pointer; border-bottom: 3px solid ${this.btnClickedColor}; color: ${this.btnClickedColor}; transition: 0.2s ease-in-out;`;
            }

            return `cursor: pointer; color: #aaa; transition: 0.2s ease-in-out;`;
        },
        variantBadge(status) {
            return status === 'Upcoming' ? 'success' : 'secondary';

        },
        openModalAddEvent() {
            this.$bvModal.show(this.modal.modalAddEventId);
        },
        eventAddItineraryItem() {
            const { time, description } = this.modal.itineraryState;

            if ((!time || time === "") && (!description || description === "")) {
                return;
            }

            this.modal.addEventinput.itinerary.push({
                time,
                description,
            });

            this.modal.itineraryState.time = null;
            this.modal.itineraryState.description = null;

        },
        eventRemoveItineraryItem(itineraryIndex) {
            this.modal.addEventinput.itinerary.splice(itineraryIndex);

        },
        eventAddMainLunch() {
            const mainLunchMealItem = this.modal.lunchState.mealName;

            if (!mainLunchMealItem || mainLunchMealItem === "") {
                return;
            }

            this.modal.addEventinput.lunch?.listMain.push(mainLunchMealItem);
            this.modal.lunchState.mealName = null;

        },
        eventAddBeverageLunch() {
            const beverageLunchItem = this.modal.lunchState.beverageName;

            if (!beverageLunchItem || beverageLunchItem === "") {
                return;
            }

            this.modal.addEventinput.lunch.listBeverage.push(beverageLunchItem);
            this.modal.lunchState.beverageName = null;

        },
        eventRemoveLunchMainItem(listLunchIndex) {
            this.modal.addEventinput.lunch?.listMain.splice(listLunchIndex, 1);

        },
        eventRemoveLunchBeverageItem(listLunchIndex) {
            this.modal.addEventinput.lunch.listBeverage.splice(listLunchIndex, 1);

        },
        closeModalAddEvent() {
            this.clearModalAddEventInputData();
            this.clearIsIncludeState();

            this.$nextTick(() => {
                this.$bvModal.hide(this.modal.modalAddEventId);
            });
        },
        modifySubmitAddEventInput(addEventinputObj) {
            return {
                ...addEventinputObj,
                gst: +addEventinputObj.gst,
            }

        },
        openModalConfirmationAddEvent() {
            try {
                const schemaConfirmCreateEventFormValidation = z.object({
                    title: z.string().min(1),
                    listGroup: z.array(
                        z.object({
                            id: z.string(),
                            name: z.string(),
                        })
                    ),
                    date: z.string().min(1),
                    time: z.string().min(1),
                    description: z.string().min(1),
                    lunch: z.object({
                        listMain: z.array(
                            z.string()
                        ).min(1),
                        listBeverage: z.array(
                            z.string()
                        ).min(1),
                    }),
                    pricePerPerson: z.string().min(1),
                    gst: z.number(),
                });

                const modifyAddEventInput = this.modifySubmitAddEventInput(this.modal.addEventinput);

                schemaConfirmCreateEventFormValidation.parse(modifyAddEventInput);

                console.log("payload to submit: ", modifyAddEventInput);

                this.$bvModal.show(this.modal.modalConfirmationAddEventId);

            } catch (error) {
                console.error(error);
                this.makeToast('Event Form', 'Form is not complete!', 'danger');
            }

        },
        closeModalConfitmationAddEvent() {
            this.$bvModal.hide(this.modal.modalConfirmationAddEventId);

        },
        openModalRemoveAttendantEvent(modalClickItemId, caregiverId, clientName) {
            // eslint-disable-next-line no-unused-vars
            const [msgId, convId] = modalClickItemId.split("/");
            this.modal.removeEventAttendant.id = `removeAttendant/${msgId}`;
            this.modal.removeEventAttendant.clientName = clientName;
            this.modal.removeEventAttendant.caregiverId = caregiverId;

            this.$nextTick(() => {
                this.$bvModal.show(this.modal.removeEventAttendant.id);
            });

        },
        async removeAttendant(modalId, caregiverId) {
            try {
                this.modal.removeEventAttendant.isRemoving = true;

                console.log("modalId: ", modalId);
                console.log("caregiverId: ", caregiverId);

                const msgId = modalId.split("/")[1];

                // eslint-disable-next-line no-unused-vars
                const { caregivers, description, msgId: optionalMsgId, convId, media, status, ...rest } = this.listData.find(item => item.msgId === msgId);

                const checkCaregiver = caregivers.filter(item => item.id === caregiverId);
                if (!checkCaregiver || checkCaregiver.length === 0) {
                    throw new Error("checkCaregiver is empty!");
                }

                if (checkCaregiver[0].paymentInfo.typeOfPayment === this.listTypeOfPayment[0]) {
                    throw new Error("Cannot cancel event if paid using online payment!");
                }

                const modifyCaregiverList = caregivers.filter(item => item.id !== caregiverId);
                const payload = {
                    msgId,
                    convId,
                    attributePayload: {
                        ...rest,
                        caregivers: modifyCaregiverList,
                    }
                }

                console.log("Payload to delete: ", payload);
                await this[ACTIONS.REMOVE_CAREGIVER_FROM_EVENT](payload);

                await this[ACTIONS.DB_EVENT_CAREGIVER_DELETE]({
                    caregiverId,
                });

                this.modal.removeEventAttendant.isRemoving = false;

                this.modal.modalClickItemData.caregivers = [...this.modal.modalClickItemData.caregivers.filter(item => item.id !== caregiverId)];

                this.closeModalRemoveAttendantEvent();
                this.makeToast("Event", "Remove attendant successful", "success");

            } catch (error) {
                console.error(error);
                this.makeToast("Event", error.message, "danger");
                this.modal.removeEventAttendant.isRemoving = false;
                this.closeModalRemoveAttendantEvent();
            }

        },
        closeModalRemoveAttendantEvent() {
            this.$bvModal.hide(this.modal.removeEventAttendant.id);
            this.modal.removeEventAttendant.id = null;
            this.modal.removeEventAttendant.clientName = null;
            this.modal.removeEventAttendant.caregiverId = null;
            this.modal.removeEventAttendant.isRemoving = false;

            this.closeModalEventItemClickClientItem();

        },
        openModalDeleteEvent(modalClickItemId) {
            const modalId = `modalDeleteEvent/${modalClickItemId}`;
            this.modal.deleteEventItem.modalDeleteConfirmationId = modalId;

            this.$nextTick(() => {
                this.$bvModal.show(modalId);
            });

        },
        closeModalDeleteEvent() {
            this.$bvModal.hide(this.modal.deleteEventItem.modalDeleteConfirmationId);
            this.modal.deleteEventItem.modalDeleteConfirmationId = null;

        },
        openModalItem(msgId, convId) {
            const DIVIDER = "/";
            const modalId = `${msgId}${DIVIDER}${convId}`;
            this.modal.modalClickItemId = modalId;
            this.modal.modalClickItemData = {
                ...this.getEventInfo(msgId)
            };
            console.log("modalClickItemData: ", this.modal.modalClickItemData);

            this.$nextTick(() => {
                this.$bvModal.show(this.modal.modalClickItemId);
            });

        },
        openModalEventItemClickClientItem(msgId, convId, clientId, caregiverItem) {
            this.modal.modalEventItemClickClientItem.id = `modalEventItemClickClientItemId-${clientId}`;
            this.modal.modalEventItemClickClientItem.msgId = msgId;
            this.modal.modalEventItemClickClientItem.convId = convId;
            this.modal.modalEventItemClickClientItem.data = caregiverItem;

            this.$nextTick(() => {
                this.$bvModal.show(this.modal.modalEventItemClickClientItem.id);
            });

        },
        closeModalEventItemClickClientItem() {
            this.$bvModal.hide(this.modal.modalEventItemClickClientItem.id);

            this.$nextTick(() => {
                this.modal.modalEventItemClickClientItem.id = null;
                this.modal.modalEventItemClickClientItem.msgId = null;
                this.modal.modalEventItemClickClientItem.convId = null;
                this.modal.modalEventItemClickClientItem.data = null;
            });

        },
        openModalAddOtherPersonJoinModalEventItemClickClientItem(clientItem) {
            const { client: { id } } = clientItem;
            this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.id = `openModalAddOtherPersonJoinModalEventItemClickClientItem/${id}`;

            console.log(clientItem);

            this.$nextTick(() => {
                this.$bvModal.show(this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.id);
            });

        },
        addOtherPersonJoinToList() {
            try {
                const SchemaOtherPersonJoinData = z.object({
                    name: z.string(),
                    main: z.string(),
                    beverage: z.string(),
                });

                const name = this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.tempData.name;
                const main = this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.tempData.lunch.main;
                const beverage = this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.tempData.lunch.beverage;

                const otherPersonJoinData = {
                    name,
                    main,
                    beverage,
                }

                SchemaOtherPersonJoinData.parse(otherPersonJoinData);

                this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.listOtherPersonJoin.push(otherPersonJoinData);

                // clear the other person join temp data
                this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.tempData.name = null;
                this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.tempData.lunch.main = null;
                this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.tempData.lunch.beverage = null;

            } catch (error) {
                this.makeToast("Form not complete", "Other person join information is not complete", "danger");

            }
        },
        removeOtherPersonJoinItemFromList(otherPersonJoinIndex) {
            this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.listOtherPersonJoin.splice(otherPersonJoinIndex, 1);

        },
        async submitOtherPersonJoinModalEventItemClickClientItem(clientId) {
            try {
                this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.isSubmitting = true;

                await new Promise((r) => setTimeout(r, 2000));

                console.log('addOtherPersonJoinModalEventItemClickClientItem');
                console.log("listOtherPersonJoin: ", this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.listOtherPersonJoin);

                const [msgId, convId] = this.modal.modalClickItemId.split("/");
                console.log(msgId, convId);
                console.log("clientId: ", clientId);


                // eslint-disable-next-line no-unused-vars
                const { caregivers, description, msgId: optionalMsgId, convId: conversationId, media, ...rest } = this.listData.find(item => item.msgId === msgId);
                const { isCaregiverCome, client, id, name, listLunchSelected, otherPersonJoining } = caregivers.find(item => item.client.id === clientId);

                if (this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.listOtherPersonJoin.length === 0) {
                    this.closeModalAddOtherPersonJoinModalEventItemClickClientItem();
                    this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.isSubmitting = false;

                    return;
                }

                const modifyListOtherPersonJoining = [];

                // push old other person join
                otherPersonJoining.forEach((item) => {
                    modifyListOtherPersonJoining.push(item);
                });

                // push new other person join
                this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.listOtherPersonJoin
                    .map(item => ({
                        name: item.name,
                        lunch: {
                            main: item.main,
                            beverage: item.beverage,
                        }
                    }))
                    .forEach((item) => {
                        console.log("item: ", item);
                        modifyListOtherPersonJoining.push(item);
                    });


                const newCaregiverItem = {
                    id: id,
                    name: name,
                    isAttending: true,
                    isCaregiverCome: true,
                    listLunchSelected: {
                        caregiver: listLunchSelected.caregiver,
                        client: listLunchSelected.client,
                    },
                    client,
                    otherPersonJoining: modifyListOtherPersonJoining,
                    paymentInfo: {
                        totalPrice: 0,
                        typeOfPayment: null,
                        proofOfPayment: {
                            cashMemo: null,
                            onlineReceiptId: null,
                            chequeNo: null,
                        },
                        paymentStatus: false,
                        paymentDate: null,
                    }
                }


                //------- calculate total price
                const gst = rest.gst;
                const pricePerPerson = rest.pricePerPerson;
                const totalListOtherPersonJoining = newCaregiverItem.otherPersonJoining.length;

                let totalPrice;
                if (isCaregiverCome) {
                    totalPrice = +(((totalListOtherPersonJoining === 0 ? 1 : totalListOtherPersonJoining + 1) * pricePerPerson) * gst).toFixed(2);

                } else {
                    totalPrice = +(((totalListOtherPersonJoining === 0 ? 0 : totalListOtherPersonJoining) * pricePerPerson) * gst).toFixed(2);
                }
                const modifyNewCaregiverItem = {
                    ...newCaregiverItem,
                    paymentInfo: {
                        ...newCaregiverItem.paymentInfo,
                        totalPrice,
                    }
                }
                //------- calculate total price


                const modifyCaregivers = caregivers.filter(item => item.client.id !== clientId)
                modifyCaregivers.push(modifyNewCaregiverItem);

                const payload = {
                    msgId,
                    convId,
                    attributePayload: {
                        ...rest,
                        caregivers: modifyCaregivers,
                    },
                }


                await this[ACTIONS.UPDATE_EVENT_ATTRIBUTES](payload);

                this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.isSubmitting = false;
                this.closeModalAddOtherPersonJoinModalEventItemClickClientItem();
                this.closeModalItem();

            } catch (error) {
                console.error(error);

                this.closeModalAddOtherPersonJoinModalEventItemClickClientItem();
                this.closeModalItem();

                this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.isSubmitting = false;

            }

        },
        clearOtherPersonJoinClientItemInput() {
            this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.tempData.name = null;
            this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.tempData.lunch.main = null;
            this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.tempData.lunch.beverage = null;
            this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.otherPersonJoinInput.listOtherPersonJoin = [];

        },
        closeModalAddOtherPersonJoinModalEventItemClickClientItem() {
            this.$bvModal.hide(this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.id);
            this.modal.modalAddOtherPersonJoinModalEventItemClickClientItem.id = null;

            this.clearOtherPersonJoinClientItemInput();
            this.closeModalEventItemClickClientItem();

        },
        change24HourTo12Hour(time24Hour) {
            if (!time24Hour || time24Hour === "") {
                return;
            }

            let hours = time24Hour.split(":")[0];
            const AmOrPm = hours >= 12 ? 'pm' : 'am';
            hours = (hours % 12) || 12;
            const minutes = time24Hour.split(":")[1];

            return `${hours}:${minutes} ${AmOrPm}`;

        },
        getModalItemId(modalClickItemId) {
            return modalClickItemId?.split("/")[0];

        },
        checkModalClickItemCaregiversItemPaymentNotAcknowledge(modalClickItemData, itemCaregiver) {
            return this.indicatorCountCaregiverCashOrChequeNotAcknowledge
                &&
                (
                    itemCaregiver.isAttending === true
                )
                &&
                (
                    this.indicatorCountCaregiverCashOrChequeNotAcknowledge
                        .find(itemFind => itemFind.msgId === modalClickItemData.msgId)?.caregivers
                        .find(itemFind2 => itemFind2.id === itemCaregiver.id)?.paymentInfo.proofOfPayment.cashMemo === null
                    &&
                    this.indicatorCountCaregiverCashOrChequeNotAcknowledge
                        .find(itemFind => itemFind.msgId === modalClickItemData.msgId)?.caregivers
                        .find(itemFind2 => itemFind2.id === itemCaregiver.id)?.paymentInfo.proofOfPayment.chequeNo === null
                );

        },
        async acknowledgePaymentCash(msgId, convId, caregiverId) {
            try {
                console.log("acknowledgePaymentCash");

                this.modal.acknowledgePaymentInput.isAcknowledgingPayment = true;

                console.log(msgId, convId, caregiverId);
                const cashMemoMessage = "Acknowledge by admin";

                await this.updateTheAttributeDataEventAcknowledgment(this.listData, msgId, caregiverId, {
                    paymentType: "cash",
                    paymentAcknowledgement: cashMemoMessage,
                });

                await this[ACTIONS.DB_PATCH_EVENT_CAREGIVER_PAYMENT_ACKNOWLEDGE]({
                    msgId,
                    typeOfPayment: "cash",
                    // cashMemo: this.modal.acknowledgePaymentInput.cashMemo,
                    cashMemo: cashMemoMessage,
                    chequeNo: null,
                });

                this.modal.acknowledgePaymentInput.isAcknowledgingPayment = false;

                this.makeToast("Acknowledge Payment", "Acknowledge cash payment success", "success");

                // this.clearAcknowledgePaymentInput();
                // this.closeModalEventPaymentDetails();
                this.closeModalEventItemClickClientItem();
                this.closeModalItem();

            } catch (error) {
                this.handleAxiosError(error, () => {
                    this.modal.acknowledgePaymentInput.isAcknowledgingPayment = false;
                    // this.clearAcknowledgePaymentInput();
                    // this.closeModalEventPaymentDetails();
                    this.closeModalEventItemClickClientItem();
                    this.closeModalItem();
                    this.makeToast("Acknowledge Payment Failed", "Something went wrong...", "danger");
                });
            }

        },
        async acknowledgePaymentCheque(msgId, convId, caregiverId) {
            try {
                if (!this.modal.acknowledgePaymentInput.chequeNo) {
                    this.makeToast("Form not complete", "Please enter cheque number", "danger");
                    return;
                }

                console.log("acknowledgePaymentCheque...");

                this.modal.acknowledgePaymentInput.isAcknowledgingPayment = true;

                console.log(msgId, convId, caregiverId);

                await this.updateTheAttributeDataEventAcknowledgment(this.listData, msgId, caregiverId, {
                    paymentType: "cheque",
                    paymentAcknowledgement: this.modal.acknowledgePaymentInput.chequeNo,
                });

                await this[ACTIONS.DB_PATCH_EVENT_CAREGIVER_PAYMENT_ACKNOWLEDGE]({
                    msgId,
                    typeOfPayment: "cheque",
                    cashMemo: null,
                    chequeNo: this.modal.acknowledgePaymentInput.chequeNo,
                });

                this.modal.acknowledgePaymentInput.isAcknowledgingPayment = false;

                this.makeToast("Acknowledge Payment", "Acknowledge cheque payment success", "success");

                // this.clearAcknowledgePaymentInput();
                // this.closeModalEventPaymentDetails();
                this.modal.acknowledgePaymentInput.chequeNo = null;
                this.closeModalEventItemClickClientItem();
                this.closeModalItem();

            } catch (error) {
                this.handleAxiosError(error, () => {
                    this.modal.acknowledgePaymentInput.isAcknowledgingPayment = false;
                    // this.clearAcknowledgePaymentInput();
                    // this.closeModalEventPaymentDetails();
                    this.modal.acknowledgePaymentInput.chequeNo = null;
                    this.closeModalEventItemClickClientItem();
                    this.closeModalItem();
                    this.makeToast("Acknowledge Payment Failed", "Something went wrong...", "danger");
                });
            }

        },
        async updateTheAttributeDataEventAcknowledgment(listData, msgId, caregiverId, payloadAcknowledgement) {
            // eslint-disable-next-line no-unused-vars
            const { caregivers, description, msgId: optionalMsgId, convId, media, ...rest } = listData.find(item => item.msgId === msgId);

            const selectCaregiverItem = listData.find(item => item.msgId === msgId).caregivers.find(item => item.id === caregiverId);
            if (!selectCaregiverItem) {
                throw new Error("Cannot find the selectCaregiverItem");
                // return;
            }

            const modifyCaregiverItem = {
                ...selectCaregiverItem,
                paymentInfo: {
                    totalPrice: selectCaregiverItem.paymentInfo.totalPrice,
                    typeOfPayment: selectCaregiverItem.paymentInfo.typeOfPayment,
                    proofOfPayment: {
                        cashMemo: payloadAcknowledgement.paymentType === "cash" ? payloadAcknowledgement.paymentAcknowledgement : null,
                        onlineReceiptId: null,
                        chequeNo: payloadAcknowledgement.paymentType === "cheque" ? payloadAcknowledgement.paymentAcknowledgement : null,
                    },
                    paymentStatus: selectCaregiverItem.paymentInfo.paymentStatus,
                    paymentDate: selectCaregiverItem.paymentInfo.paymentDate,
                }
            }
            // Remove the caregiver item to be mod first
            const modifyCaregivers = listData.find(item => item.msgId === msgId).caregivers.filter(item => item.id !== caregiverId);
            // Add the mod caregiver Item
            modifyCaregivers.push(modifyCaregiverItem);

            const payload = {
                msgId,
                convId,
                attributePayload: {
                    ...rest,
                    caregivers: modifyCaregivers,
                }
            }

            console.log("Payload to patch: ", payload);
            await this[ACTIONS.UPDATE_CAREGIVER_PAYMENT_ACKNOWLEDGEMENT](payload);

            // const { data } = await this.$root.axios.patch("/events", payload);

        },
        closeModalItem() {
            this.$bvModal.hide(this.modal.modalClickItemId);

            this.modal.modalClickItemId = null;
            this.modal.modalClickItemData = null;
            this.modal.editModalClickItemData = null;
            this.modal.stateEditEventItem.isEditModalEvent = false;

        },
        async openModalManuallyAddAttendant(modalItemId) {
            const [msgId, convId] = modalItemId.split("/");
            const DIVIDER = "/";

            this.modal.modalManuallyAddEvent.modalId = `idModalManuallyAddAttendant${DIVIDER}${msgId}${DIVIDER}${convId}`;

            this.$nextTick(() => {
                this.$bvModal.show(this.modal.modalManuallyAddEvent.modalId);
            });

        },
        manuallyAddAttendantAddOtherPersonJoin() {
            try {
                const SchemaOtherPersonJoinData = z.object({
                    name: z.string(),
                    main: z.string(),
                    beverage: z.string(),
                });

                const name = this.modal.modalManuallyAddEvent.inputAddEvent.otherPersonJoin.tempData.name;
                const main = this.modal.modalManuallyAddEvent.inputAddEvent.otherPersonJoin.tempData.lunch.main;
                const beverage = this.modal.modalManuallyAddEvent.inputAddEvent.otherPersonJoin.tempData.lunch.beverage;

                const otherPersonJoinData = {
                    name,
                    main,
                    beverage,
                }

                SchemaOtherPersonJoinData.parse(otherPersonJoinData);

                this.modal.modalManuallyAddEvent.inputAddEvent.otherPersonJoin.listOtherPersonJoin.push(otherPersonJoinData);

                // clear the other person join temp data
                this.modal.modalManuallyAddEvent.inputAddEvent.otherPersonJoin.tempData.name = null
                this.modal.modalManuallyAddEvent.inputAddEvent.otherPersonJoin.tempData.lunch.main = null
                this.modal.modalManuallyAddEvent.inputAddEvent.otherPersonJoin.tempData.lunch.beverage = null

            } catch (error) {
                this.makeToast("Form not complete", "Other person join information is not complete", "danger");

            }

        },
        manuallyAddAttendantRemoveOtherPersonJoinItem(otherPersonJoinIndex) {
            this.modal.modalManuallyAddEvent.inputAddEvent.otherPersonJoin.listOtherPersonJoin.splice(otherPersonJoinIndex, 1);

        },
        clearModalManuallyAddAttendantInputData() {
            this.modal.modalManuallyAddEvent.inputAddEvent = {
                client: {
                    data: null,
                    lunch: {
                        main: null,
                        beverage: null,
                    }
                },
                caregiver: {
                    data: null,
                    lunch: {
                        main: null,
                        beverage: null,
                    },
                },
                otherPersonJoin: {
                    tempData: {
                        name: null,
                        lunch: {
                            main: null,
                            beverage: null,
                        },
                    },
                    listOtherPersonJoin: [],
                },
            }

        },
        closeModalManuallyAddAttendant() {
            this.$bvModal.hide(this.modal.modalManuallyAddEvent.modalId);
            this.modal.modalManuallyAddEvent.modalId = null;
            this.clearModalManuallyAddAttendantInputData();

        },
        validateManuallyAddAttendantData() {
            const SchemaManuallyAddAttendant = z.object({
                client: z.object({
                    data: z.object({
                        id: z.string(),
                        name: z.string(),
                    }),
                    lunch: z.object({
                        main: z.object({
                            name: z.string(),
                        }),
                        beverage: z.object({
                            name: z.string(),
                        }),
                    })
                }),
                caregiver: z.object({
                    data: z.object({
                        id: z.string(),
                        name: z.string(),
                    }),
                    lunch: z.object({
                        main: z.object({
                            name: z.string(),
                        }),
                        beverage: z.object({
                            name: z.string(),
                        }),
                    })
                }),
                otherPersonJoining: z.array(
                    z.object({
                        name: z.string(),
                        main: z.object({
                            name: z.string(),
                        }),
                        beverage: z.object({
                            name: z.string(),
                        }),
                    })
                ),
            });

            const { client, caregiver, otherPersonJoin: { listOtherPersonJoin } } = this.modal.modalManuallyAddEvent.inputAddEvent;
            const modifyPayload = {
                client,
                caregiver,
                otherPersonJoining: listOtherPersonJoin,
            }
            console.log("modifyPayload: ", modifyPayload);

            SchemaManuallyAddAttendant.parse(modifyPayload);

        },
        modifyCaregiverItemDataBeforeSubmitManuallyAddAttendant(caregiver, client, listOtherPersonJoin, typeOfSubmit) {
            const listTypeOfSubmit = ["Caregiver, client and other person join", "Client and other person join", "Client only"];

            const modifyIsCaregiverCome = typeOfSubmit === listTypeOfSubmit[0] ? true : false;

            const conditionCaregiverLunch = typeOfSubmit === listTypeOfSubmit[0] ? {
                main: [caregiver.lunch.main],
                beverage: [caregiver.lunch.beverage],
            } : {
                main: [],
                beverage: [],
            };

            const conditionOtherPersonJoinOne = listOtherPersonJoin.length !== 0 ? listOtherPersonJoin.map(item => ({
                name: item.name,
                lunch: {
                    main: item.main,
                    beverage: item.beverage,
                }
            })) : [];

            const conditionOtherPersonJoinTwo = typeOfSubmit === listTypeOfSubmit[2] ? [] : conditionOtherPersonJoinOne;



            return {
                id: caregiver.data.id,
                name: caregiver.data.name,
                isAttending: true,
                isCaregiverCome: modifyIsCaregiverCome,
                listLunchSelected: {
                    caregiver: conditionCaregiverLunch,
                    client: {
                        main: [client.lunch.main],
                        beverage: [client.lunch.beverage],
                    }
                },
                client: {
                    id: client.data.id,
                    name: client.data.name,
                },
                otherPersonJoining: conditionOtherPersonJoinTwo,
                paymentInfo: {
                    // TODO: calculate total price by person join and their type
                    totalPrice: 0,
                    typeOfPayment: null,
                    proofOfPayment: {
                        cashMemo: null,
                        onlineReceiptId: null,
                        chequeNo: null,
                    },
                    paymentStatus: false,
                    paymentDate: null,
                },
            }

        },
        async submitManuallyAddAttendantCaregiverClientOtherPersonJoin() {
            try {
                this.modal.modalManuallyAddEvent.isSubmit = true;

                const [msgId, convId] = this.modal.modalClickItemId.split("/");
                console.log(msgId, convId);

                // eslint-disable-next-line no-unused-vars
                const { caregivers, description, msgId: optionalMsgId, convId: conversationId, media, ...rest } = this.listData.find(item => item.msgId === msgId);


                const { client, caregiver, otherPersonJoin: { listOtherPersonJoin } } = this.modal.modalManuallyAddEvent.inputAddEvent;
                const newCaregiverItem = this.modifyCaregiverItemDataBeforeSubmitManuallyAddAttendant(caregiver, client, listOtherPersonJoin, "Caregiver, client and other person join");

                //----- calculate total price
                const { otherPersonJoining } = newCaregiverItem;
                const totalListOtherPersonJoining = otherPersonJoining.length;
                const totalPrice = +(((totalListOtherPersonJoining === 0 ? 1 : totalListOtherPersonJoining + 1) * rest.pricePerPerson) * rest.gst).toFixed(2);

                const modifyNewCaregiverItem = {
                    ...newCaregiverItem,
                    paymentInfo: {
                        ...newCaregiverItem.paymentInfo,
                        totalPrice,
                    }
                }
                //----- calculate total price

                caregivers.push(modifyNewCaregiverItem);

                const payload = {
                    msgId,
                    convId,
                    attributePayload: {
                        ...rest,
                        caregivers,
                    },
                }

                await this[ACTIONS.UPDATE_EVENT_ATTRIBUTES](payload);

                this.makeToast("Success", "Add attendant successful", "success");
                this.modal.modalManuallyAddEvent.isSubmit = false;

                this.closeAllModalManuallyAddAttendant();

            } catch (error) {
                this.modal.modalManuallyAddEvent.isSubmit = false;
                console.error(error);
                this.makeToast("Add attendant fail", "Something went wrong...", "danger");

                this.closeAllModalManuallyAddAttendant();

            }

        },
        async submitManuallyAddAttendantClientAndOtherPersonJoin() {
            try {
                this.modal.modalManuallyAddEvent.isSubmit = true;

                const [msgId, convId] = this.modal.modalClickItemId.split("/");
                console.log(msgId, convId);

                // eslint-disable-next-line no-unused-vars
                const { caregivers, description, msgId: optionalMsgId, convId: conversationId, media, ...rest } = this.listData.find(item => item.msgId === msgId);

                const { client, caregiver, otherPersonJoin: { listOtherPersonJoin } } = this.modal.modalManuallyAddEvent.inputAddEvent;

                const newCaregiverItem = this.modifyCaregiverItemDataBeforeSubmitManuallyAddAttendant(caregiver, client, listOtherPersonJoin, "Client and other person join");

                //----- calculate total price
                const { otherPersonJoining } = newCaregiverItem;
                const totalListOtherPersonJoining = otherPersonJoining.length;
                const totalPrice = +(((totalListOtherPersonJoining === 0 ? 0 : totalListOtherPersonJoining) * rest.pricePerPerson) * rest.gst).toFixed(2);

                const modifyNewCaregiverItem = {
                    ...newCaregiverItem,
                    paymentInfo: {
                        ...newCaregiverItem.paymentInfo,
                        totalPrice,
                    }
                }
                //----- calculate total price


                caregivers.push(modifyNewCaregiverItem);

                const payload = {
                    msgId,
                    convId,
                    attributePayload: {
                        ...rest,
                        caregivers,
                    },
                }

                await this[ACTIONS.UPDATE_EVENT_ATTRIBUTES](payload);

                this.makeToast("Success", "Add attendant successful", "success");
                this.modal.modalManuallyAddEvent.isSubmit = false;

                this.closeAllModalManuallyAddAttendant();

            } catch (error) {
                this.modal.modalManuallyAddEvent.isSubmit = false;
                console.error(error);
                this.makeToast("Add attendant fail", "Something went wrong...", "danger");

                this.closeAllModalManuallyAddAttendant();

            }

        },
        async submitManuallyAddAttendantClientOnly() {
            try {
                this.modal.modalManuallyAddEvent.isSubmit = true;

                const [msgId, convId] = this.modal.modalClickItemId.split("/");
                console.log(msgId, convId);

                // eslint-disable-next-line no-unused-vars
                const { caregivers, description, msgId: optionalMsgId, convId: conversationId, media, ...rest } = this.listData.find(item => item.msgId === msgId);

                const { client, caregiver, otherPersonJoin: { listOtherPersonJoin } } = this.modal.modalManuallyAddEvent.inputAddEvent;

                const newCaregiverItem = this.modifyCaregiverItemDataBeforeSubmitManuallyAddAttendant(caregiver, client, listOtherPersonJoin, "Client only");

                caregivers.push(newCaregiverItem);

                const payload = {
                    msgId,
                    convId,
                    attributePayload: {
                        ...rest,
                        caregivers,
                    },
                }

                await this[ACTIONS.UPDATE_EVENT_ATTRIBUTES](payload);

                this.makeToast("Success", "Add attendant successful", "success");
                this.modal.modalManuallyAddEvent.isSubmit = false;

                this.closeAllModalManuallyAddAttendant();

            } catch (error) {
                this.modal.modalManuallyAddEvent.isSubmit = false;
                console.error(error);
                this.makeToast("Add attendant fail", "Something went wrong...", "danger");

                this.closeAllModalManuallyAddAttendant();

            }

        },
        openModalConfirmationManuallyAddAttendantListConfirmation() {
            try {
                this.validateManuallyAddAttendantData();
                this.$bvModal.show(this.modal.modalManuallyAddEvent.modalListConfirmationId);

            } catch (error) {
                console.error(error);
                this.makeToast("Form not complete", "Please complete all the information", "danger");
            }

        },
        closeModalConfirmationManuallyAddAttendantListConfirmation() {
            this.$bvModal.hide(this.modal.modalManuallyAddEvent.modalListConfirmationId);

        },
        closeAllModalManuallyAddAttendant() {
            this.closeModalConfirmationManuallyAddAttendantListConfirmation();
            this.closeModalManuallyAddAttendant();
            this.closeModalItem();

        },
        getEventInfo(msgId) {
            const item = this.listData.find(item => item.msgId === msgId);

            if (!item) {
                return null;
            }

            return item;

        },
        async createEvent() {
            try {
                this.isCreateEventSubmit = true;

                const { title, listGroup, date, time, description, itinerary, lunch, pricePerPerson, gst } = this.modifySubmitAddEventInput(this.modal.addEventinput);
                // eslint-disable-next-line no-unused-vars
                const combineDateAndTime = new Date(date + "T" + time);
                const modifyPricePerPerson = +pricePerPerson;

                await this[ACTIONS.CREATE_EVENT]({
                    title,
                    listGroup,
                    date: combineDateAndTime,
                    description,
                    itinerary,
                    lunch,
                    pricePerPerson: modifyPricePerPerson,
                    gst,
                });

                this.makeToast('Event Form', 'Event created successful!', 'success');

                this.closeModalConfitmationAddEvent();
                this.closeModalAddEvent();

                this.isCreateEventSubmit = false;

            } catch (error) {
                this.handleAxiosError(error, () => {
                    this.closeModalConfitmationAddEvent();
                    this.closeModalAddEvent();
                    this.isCreateEventSubmit = false;
                    this.makeToast("Create Event Failed", "Something went wrong...", "danger");
                });
            }

        },
        clearIsIncludeState() {
            this.modal.itineraryState = {
                isIncludeItinerary: false,
                time: null,
                description: null,
            };
            this.modal.lunchState = {
                isIncludeLunch: false,
                mealName: null,
                beverageName: null,
            };

        },
        clearModalAddEventInputData() {
            this.modal.addEventinput = {
                title: null,
                listGroup: null,
                date: null,
                time: null,
                description: null,
                itinerary: [],
                lunch: {
                    listMain: [],
                    listBeverage: [],
                },
                pricePerPerson: null,
                gst: 1.08,
            }

        },
        makeToast(title, message, variant) {
            this.$bvToast.toast(message, {
                title,
                variant,
                solid: true,
            })
        },
        calculateATotalPersonComingInModalEvent(isCaregiverCome, otherPersonJoining) {
            const countCaregiverComing = isCaregiverCome ? 1 : 0;
            const countOtherPersonJoining = otherPersonJoining.length;
            const countClient = 1;
            const totalPersonComing = countClient + countCaregiverComing + countOtherPersonJoining;

            return totalPersonComing;

        },
        editEventItem(modalItemId) {
            console.log("Edit event: ", modalItemId);
            this.modal.stateEditEventItem.isEditModalEvent = true;

            const date = dayjs(this.modal.modalClickItemData.date).format("YYYY-MM-DD");
            const time = dayjs(this.modal.modalClickItemData.date).format("hh:mm");

            this.modal.stateEditEventItem.tempDataEditDate.date = date;
            this.modal.stateEditEventItem.tempDataEditDate.time = time;

            // make a temp vmodel for editing
            // make a shallow copy
            this.modal.editModalClickItemData = {
                ...this.modal.modalClickItemData,
                // itinerary: [...this.modal.modalClickItemData.itinerary],
                itinerary: this.modal.modalClickItemData?.multipleDays ? null : [...this.modal.modalClickItemData.itinerary],
                // lunch: {
                //     ...this.modal.modalClickItemData.lunch
                // },
                lunch: this.modal.modalClickItemData?.multipleDays ? null : {
                    ...this.modal.modalClickItemData.lunch
                },
            };

        },
        editAddEventItinerary(editModalClickItemData) {
            const { time, description } = this.modal.itineraryState;

            if ((!time || time === "") && (!description || description === "")) {
                return;
            }

            editModalClickItemData.itinerary.push({
                time,
                description
            });

            this.modal.itineraryState.time = null;
            this.modal.itineraryState.description = null;

        },
        editRemoveEventItinerary(itineraryIndex) {
            console.log("itineraryIndex: ", itineraryIndex);
            this.modal.editModalClickItemData.itinerary.splice(itineraryIndex, 1);

        },
        editAddListMainLunch(editModalClickItemData) {
            const mainLunch = this.modal.lunchState.mealName;

            if (!mainLunch || mainLunch === "") {
                return;
            }

            editModalClickItemData.lunch?.listMain.push({
                name: mainLunch,
            });
            this.modal.lunchState.mealName = null;

        },
        editRemoveItemInMainListLunch(mainLunchName) {
            this.modal.editModalClickItemData.lunch.listMain = this.modal.editModalClickItemData.lunch.listMain.filter(item => item !== mainLunchName);

        },
        editAddListBeverageLunch(editModalClickItemData) {
            const beverage = this.modal.lunchState.beverageName;

            if (!beverage || beverage === "") {
                return;
            }

            editModalClickItemData.lunch.listBeverage.push({
                name: beverage,
            });
            this.modal.lunchState.beverageName = null;

        },
        editRemoveItemInBeverageListLunch(beverageName) {
            this.modal.editModalClickItemData.lunch.listBeverage = this.modal.editModalClickItemData.lunch.listBeverage.filter(item => item !== beverageName);

        },
        backToOpenModalItem() {
            this.modal.editModalClickItemData = null;
            this.modal.stateEditEventItem.isEditModalEvent = false;

        },
        validateBeforeSaveEditEventItem(editModalClickItemData) {
            const { title, listGroup, lunch, date, description, itinerary, pricePerPerson, gst } = editModalClickItemData;

            const editSchema = z.object({
                title: z.string(),
                date: z.string().datetime(),
                pricePerPerson: z.number(),
                description: z.string(),
                itinerary: z.array(
                    z.object({
                        time: z.string(),
                        description: z.string(),
                    })
                ).min(0).nullable(),
                lunch: z.object({
                    listMain: z.array(
                        z.object({
                            name: z.string(),
                        })
                    ).min(0),
                    listBeverage: z.array(
                        z.object({
                            name: z.string(),
                        })
                    ).min(0),
                }).nullable(),
                gst: z.number().step(0.01),
                listGroup: z.array(z.object({
                    id: z.string(),
                    name: z.string(),
                })).min(1),

            })

            editSchema.parse({
                title,
                listGroup,
                lunch,
                date,
                description,
                itinerary,
                pricePerPerson: +pricePerPerson,
                gst
            });

        },
        async saveEditEventItem(modalItemId) {
            const resetModalEditEvent = () => {
                this.modal.stateEditEventItem.isSaveEdit = false;
                this.modal.stateEditEventItem.tempDataEditDate = {
                    date: null,
                    time: null,
                }
            }

            try {

                const [msgId, convId] = modalItemId.split("/");
                console.log("saveEditEventItem: ", msgId, convId);

                this.modal.stateEditEventItem.isSaveEdit = true;

                this.validateBeforeSaveEditEventItem(this.modal.editModalClickItemData);
                // eslint-disable-next-line no-unused-vars
                const { title, listGroup, lunch, date, description, itinerary, pricePerPerson, gst,multipleDays ,remarks, ...rest } = this.modal.editModalClickItemData;
                console.log("rest: ", rest);
                await this.deleteEventItem(msgId, convId);
                await this[ACTIONS.CREATE_EVENT]({
                    title,
                    listGroup,
                    lunch,
                    date,
                    description,
                    itinerary,
                    pricePerPerson: +pricePerPerson,
                    gst,
                    multipleDays,
                    remarks: remarks ? remarks : null,

                });

                // RESET
                resetModalEditEvent();
                this.closeModalItem();

                this.makeToast(title, "Edit form success", "success");

            } catch (error) {
                // console.error(Object.keys(error), Object.values(error));
                if (error?.name === "ZodError") {
                    console.error(error);
                    this.makeToast("Edit not complete", "Please complete the edit form", "danger");
                    resetModalEditEvent();
                    return;
                }

                this.handleAxiosError(error, () => {
                    resetModalEditEvent();
                    this.closeModalItem();

                    this.makeToast("Edit failed", "Something went wrong...", "danger");
                });
            }

        },
        async modalClickDeleteEventItem(modalItemId) {
            const [msgId, convId] = modalItemId.split("/");

            this.modal.deleteEventItem.isDeleting = true;

            await this.deleteEventItem(msgId, convId);

            this.modal.deleteEventItem.isDeleting = false;


            this.closeModalDeleteEvent();
            this.closeModalItem();

            this.makeToast("Delete Event", "Delete event successful", "success");

        },
        async deleteEventItem(msgId, convId) {
            await this.removeAnnouncement({
                action: "remove",
                msgid: msgId,
                convId,
            });

            await this[ACTIONS.DB_DELETE_EVENT]({
                msgId,
                isDelete: true,
            });

        },
        mapEventItemListLunchData(caregiversData) {
            if (!caregiversData) {
                return;
            }

            const listData = [];

            caregiversData.forEach(item => {
                listData.push({
                    name: item.name,
                    main: item.listLunchSelected.caregiver.main[0],
                    beverage: item.listLunchSelected.caregiver.beverage[0],
                });

                listData.push({
                    name: item.client.name,
                    main: item.listLunchSelected.client.main[0],
                    beverage: item.listLunchSelected.client.beverage[0],
                });

                item.otherPersonJoining.forEach(itemInside => {
                    listData.push({
                        name: itemInside.name,
                        main: itemInside.lunch.main,
                        beverage: itemInside.lunch.beverage,
                    });

                });


            });

            const filterListData = listData.filter(item => item.main);

            return filterListData;



        },
        handleAxiosError(error, callback) {
            // const BAD_REQUEST = 400;
            // const UNAUTHORIZED = 401;

            if (error.response) {
                // const { data } = error.response;
                // console.error(data);

                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);

                console.error(error.response);
                callback();

                return;
            }

            if (error.request) {
                console.error(error.request);
                callback();

                return;
            }

            console.error(error);

            callback();

        }

    },
}
</script>

<style lang="scss" scoped>
//------------------- remove input number arrow
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.modal-header {
    background-color: #109387 !important;
    color: white !important;
    border-bottom: 0 !important;

    button {
        color: white !important;
    }

}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

//------------------- remove input number arrow
.modal-content {
    border-radius: 10px !important;
}

.centerTd td {
    vertical-align: middle !important;
}

.centerTd th {
    vertical-align: middle !important;
}

.table-header-color {
    background-color: #50276D;
}

.header-body-modal {
    background-color: #109387;
    display: flex;
    width: 100%;
    flex-direction: column;

    align-items: center;

    .event-info {
        width: 90%;
        margin: 30px auto;
        display: flex;
        color: white;
        flex-direction: column;

        .event-title {
            font-size: 1.5rem;
            font-weight: 500;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h2 {
                font-size: 1.5rem;
                font-weight: 400;
                margin-bottom: 0;
            }

            .badge {
                height: fit-content;

            }
        }

    }

    .divider {
        background-color: white;
        width: 98%;
        height: 0.2px;
        margin: 0 auto;

    }
}

.main-body-modal {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;

    .modal-body-item-main {
        width: 90%;
        margin: 10px auto;
    }

    .list-attendants {
        .list-attendants-item {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            padding: 10px;
            background-color: white;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            .list-attendants-item-name {
                width: 20%;
            }

            .list-attendants-item-lunch {
                width: 20%;



            }


        }


    }

    .itinerary {
        .itinerary-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            padding: 10px;
            background-color: white;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        }
    }

    .lunch {
        .lunch-item-main {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            padding: 10px;
            background-color: white;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            .lunch-item-main-name {
                width: 20%;
            }
        }

        .lunch-item-beverage {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            padding: 10px;
            background-color: white;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            .lunch-item-beverage-name {
                width: 20%;
            }

        }
    }

    .attendants-lunch-menu-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        padding: 10px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        .attendants-lunch-menu-item-name {
            width: 20%;
        }

        .attendants-lunch-menu-item-lunch {
            width: 20%;
        }

    }
    .multiple-days-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        padding: 10px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        .multiple-days-item-date {
            width: 20%;
        }

        .multiple-days-item-time {
            width: 20%;
        }

    }
    .border-multi-day{
        border: 1px solid #109387;
        border-radius: 10px;
        margin:  10px 0;
    }
    .multiple-day-event-info{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        padding: 10px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
    



}

</style>