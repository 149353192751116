<template lang="pug">
div(class="w-100 mx-auto  d-flex" style="gap: 1rem;height: 100vh")
    NavbarV2
    
    //- v-date-picker
    //- div(class="d-flex justify-content-end align-items-end mt-4")
    //-     b-button(v-b-modal.modal-1 variant="primary" @click="showHideEventCreateModal") Add Event
    //- b-tabs(class="mt-2" )
    //-     b-tab(:title="tab" v-for="tab in tabs" :key="tab")
    //-         EventsTab(:events=" tab === 'Upcoming' ? upcomingEvents : tab === 'Past' ? pastEvents : allEvents" :loading="loading" :groups="groups" @fetchEvents="fetchEvents")
    div(class="px-5 pt-5" style="height: 94vh;overflow-y: auto;width:80%" v-if="!loadingInitial" )
        div(class="d-flex justify-content-between align-items-center")
            h3 Events
            b-button(v-b-modal.modal-1 @click="goToCreateEvent") Add Event
        div(class="main-container-event d-flex w-100  mx-auto" style="margin-top: 3rem;height: 80vh;gap: 1rem" )
            div(class="event-cal d-flex flex-column p-2 h-100" style="width: 35%")
                v-calendar(is-expanded title-position="left" :attributes="attributes" @dayclick="dateClicked")
                div(class="d-flex justify-content-between align-items-center mt-3")
                    p Show All
                    b-form-checkbox(switch v-model="showAll")
                div(class="d-flex justify-content-between align-items-center mt-2")
                        h5 Events - {{dayClicked && !showAll ? dayClicked.toDateString() : 'All'}}
                        //- b-button(v-b-modal.modal-1 @click="goToCreateEvent" class="rounded-circle d-flex align-items-center justify-content-center" style="width: 40px;height: 40px;")
                        //-     b-icon(icon="plus")
                div(class="events-container d-flex flex-column w-100" style="height:80%;overflow-y: auto")
            
                    div(v-if="!eventByDay.length" class="d-flex justify-content-center align-items-center w-100 mt-3 px-3 event-card-horizontal" style="height: 60px")
                        p No events found
                    div(v-if="!showAll")
                        div(class="d-flex  align-items-center w-100 mt-3 px-3 py-2 event-card-horizontal" style="height: fit-content" v-for="event in eventByDay" :key="event.crb5c_foweventmanagementid"
                        @click="selectedEventSetter(event.crb5c_foweventmanagementid)"
                        )
                            div(class="d-flex justify-content-center align-items-center " style="width: 40px;height: 40px;")
                                b-icon(icon="calendar")
                            div(class="d-flex flex-column justify-content-center align-items-start ml-3 w-75")
                                p(class="m-0 text-left") {{ event.crb5c_eventname.length > 20 ? event.crb5c_eventname.substring(0, 40) + '... Click to view' : event.crb5c_eventname }}
                                small(class="m-0" style="font-size:smaller;") {{ new Date(event.crb5c_date).toLocaleDateString() }}
                            //- div(class="d-flex justify-content-center align-items-center  " style="width: fit-content;white-space: nowrap")
                            //-     p.m-0 {{event.submissions ? event.submissions.length : 0}} Submissions
                    div(v-else)
                        div(class="d-flex  align-items-center w-100 mt-3 px-3 py-2 event-card-horizontal" style="height:fit-content" v-for="event in allEvents" :key="event.crb5c_foweventmanagementid"
                        @click="selectedEventSetter(event.crb5c_foweventmanagementid)"
                        )
                            div(class="d-flex justify-content-center align-items-center " style="width: 40px;height: 40px;")
                                b-icon(icon="calendar")
                            div(class="d-flex flex-column justify-content-center align-items-start ml-3 w-75")
                                p(class="m-0 text-left") {{ event.crb5c_eventname.length > 20 ? event.crb5c_eventname.substring(0, 20) + '...' : event.crb5c_eventname }}
                                small(class="m-0" style="font-size:smaller;") {{ new Date(event.crb5c_date).toLocaleDateString() }}
                            //- div(class="d-flex justify-content-center align-items-center  " style="width: fit-content;white-space: nowrap")
                            //-     p.m-0 {{event.submissions ? event.submissions.length : 0}} Submissions
            div(style="width: 1px;height: 100%;background-color: #f5f5f5")
            div(class="event-details d-flex flex-column p-3 align-items-start" style="width:65%;height: 100%;overflow-y: auto")
                div(v-if="selectedEvent.crb5c_foweventmanagementid" class="w-100")
                    EventDetails(:eventData="selectedEvent"  :groups="groups" @updatedEvent="eventUpdated" @deletedEvent="deletedEvents")
                div(v-else class="d-flex justify-content-center align-items-center w-100 h-100")
                    p No event selected
        
    div(v-if="loadingInitial" class="d-flex justify-content-center align-items-center w-75 h-100" style="margin-top: 3rem;height: 100vh;gap: 1rem")
        b-spinner(type="grow" variant="primary")
                
                
                        
                        
                    

                
                



    add-event(:show.sync="addEventModal" :groups="groups" :loading="loading" @close="emittedClose" :update:show.sync="addEventModal")
    
</template>

<script>
import Header from "../components/Header.vue";
import EventsTab from "../components/EventsTab.vue";
import AddEvent from "../components/eventModals/AddEvent.vue";
import EditEvent from "../components/eventModals/EditEvent.vue";
import EventDetails from "../components/EventDetails.vue";
import NavbarV2 from "../components/NavbarV2.vue";
import axios from "axios";
import { mapActions } from "vuex";
export default {
  components: {
    Header,
    EventsTab,
    AddEvent,
    EditEvent,
    EventDetails,
    NavbarV2,
  },
  data() {
    return {
      tabs: ["Upcoming", "Past", "All"],
      allEvents: [],
      upcomingEvents: [],
      pastEvents: [],
      groups: [],
      addEventModal: false,
      loading: false,
      attributes: [],
      dayClicked: null,
      eventByDay: [],
      showAll: false,
      selectedEvent: {},
      loadingInitial: false,
    };
  },
  methods: {
    goToCreateEvent() {
      this.$router.push({ name: "AddEventPage" });
    },
    async deletedEvents() {
      await this.fetchEvents();
      this.sortEventByDay();
      this.selectedEvent = {};
    },
    async eventUpdated() {
      await this.fetchEvents();
      this.sortEventByDay();
      let selectedEventId = this.selectedEvent.crb5c_foweventmanagementid;
      this.selectedEventSetter(selectedEventId);
    },

    sortEventByDay() {
      this.eventByDay = this.allEvents.filter((item) => {
        return (
          new Date(item.crb5c_date).toDateString() ===
          this.dayClicked.toDateString()
        );
      });
      console.log("eventByDay", this.eventByDay);
    },

    selectedEventSetter(id) {
      let index = this.allEvents.findIndex(
        (item) => item.crb5c_foweventmanagementid === id
      );
      if (index !== -1) {
        this.selectedEvent = this.allEvents[index];
        console.log("selectedEvent", this.selectedEvent);
      }
    },
    dateClicked(day) {
      console.log("Clicked date:", day.date);
      this.dayClicked = day.date;
      if (this.dayClicked) {
        this.eventByDay = this.allEvents.filter((item) => {
          return (
            new Date(item.crb5c_date).toDateString() ===
            this.dayClicked.toDateString()
          );
        });
        console.log("eventByDay", this.eventByDay);
      }
      this.showAll = false;
    },
    async emittedClose(data) {
      console.log("emittedClose", data);
      this.addEventModal = false;
      await this.fetchEvents();
    },
    async fetchEvents() {
      this.loading = true;
      const res = await axios.get("/api/events/v2");
      // console.log('data', res);
      if (res.status === 200) {
        if (res.data) {
          this.allEvents = res.data.all;
          this.upcomingEvents = res.data.upcoming;
          this.pastEvents = res.data.past;
        }
        this.attributes = this.allEvents.map((item) => ({
          dates: item.crb5c_date,
          name: item.crb5c_eventname,
          id: item.crb5c_foweventmanagementid,
          submissions: item.submissions,
          key: item.crb5c_foweventmanagementid,
          dot: {
            color:
              new Date(item.crb5c_date).toDateString() ===
              new Date().toDateString()
                ? "blue"
                : new Date(item.crb5c_date) < new Date()
                ? "red"
                : "green",
            fillMode: "light",
          },
        }));
      }
      this.loading = false;
    },
    async showHideEventCreateModal() {
      this.addEventModal = !this.addEventModal;
    },
    ...mapActions(["getEveryGroup"]),
  },
  async mounted() {
    this.loadingInitial = true;

    try {
      await this.fetchEvents();
      const { sessions } = await this.getEveryGroup();

      if (!sessions && sessions.length === 0) {
        return;
      }

      const sessionMap = sessions.map((item) => ({
        id: item.crb5c_fow_session_scheduleid,
        name: item.crb5c_session_id,
      }));
      const sortListGroupSession =
        this.$root.sortSessionGroupNameByDay(sessionMap);
      sortListGroupSession.unshift({ id: "All", name: "All" });
      this.groups = sortListGroupSession;
      let query = this.$route.query;

      if (query && query.date) {
        let decodedDate = decodeURIComponent(query.date);

        this.dayClicked = new Date(decodedDate);

        if (isNaN(this.dayClicked)) {
          console.error("Invalid date format:", decodedDate);
          this.dayClicked = new Date();
        }
      } else {
        this.dayClicked = new Date();
      }

      this.sortEventByDay();
    } catch (e) {
      console.log("error", e);
    } finally {
      this.loadingInitial = false;
    }
  },
  watch: {
    /* eslint-disable */
    $route(to, from) {
      let query = to.query;

      if (query && query.date) {
        let decodedDate = decodeURIComponent(query.date);

        this.dayClicked = new Date(decodedDate);

        if (isNaN(this.dayClicked)) {
          console.error("Invalid date format:", decodedDate);
          this.dayClicked = new Date();
        }
      } else {
        this.dayClicked = new Date();
      }
      this.sortEventByDay();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container-event {
  border: 1px solid #f5f5f5;
  border-radius: 10px;
}

.event-card-horizontal {
  transition: all 0.3s;
}

.event-card-horizontal:hover {
  background-color: #f5f5f5;
  cursor: pointer;
  border-radius: 10px;
}
</style>
