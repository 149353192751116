<template lang="pug">
b-modal#Edit(title="Edit Event" v-model="showModalEdit" class="rounded-lg overflow-auto" size="lg" scrollable no-close-on-backdrop)
    b-overlay(:show="loading" spinner-variant="primary" spinner-type="grow" spinner-small)
        div(class="d-flex flex-column")
            b-form-group(label="Event Name (Required)" label-for="eventName")
                b-form-input(v-model="event.name" id="eventName" type="text" placeholder="Enter Event Name" required)
            b-form-group(label="Description" label-for="eventDescription")
                VueEditor(v-model="event.description" :editorToolbar="customToolbar" :disabled="loading")
            b-form-group(label="Group (Required)" label-for="group")
                v-select(
                    id="event-group" 
                    :options="groups"
                    :reduce="item => ({ id: item.id, name: item.name })" 
                    v-model="event.selectedGroup"
                    multiple 
                    label="name"
                    placeholder="Select Group")
            //- b-form-group(label="GST" label-for="gst")
            //-     b-form-input(v-model="event.gst" id="gst" type="number" placeholder="Enter GST" required :disabled="!event.editGST")
            //-     b-form-checkbox(v-model="event.editGST") Edit GST
            b-form-group(label="Total" label-for="total")
                b-form-input(v-model="total" id="total" type="number" placeholder="Total" required :disabled="true")
            b-form-group(v-if="event.isSingleDay" label="Single Day Event" class="my-2")
                b-form-group(label="Date (Required)" label-for="date")
                    b-form-input(v-model="event.singleDay.date" id="date" type="date" required :class="{'is-invalid': !event.singleDay.date}"
                    :min="new Date().toLocaleDateString('en-CA')"
                    )
                b-form-group(label="Time (Required)" label-for="time")
                    //- b-form-input(v-model="event.singleDay.time" id="time" type="time" required :class="{'is-invalid': !event.singleDay.time}")
                    vue-timepicker(v-model="event.singleDay.time" id="time" type="time" format="hh:mm A" :minute-interval="5" input-width="100%" required)
                b-form-group(label="Itineraries For Single Day (Optional)" label-for="itineraries").w-100
                    div(class="d-flex" style="gap: 10px")
                        b-form-input(v-model="tempData.itineraries.time" id="itineraries" placeholder="Enter Time" required type="time")
                        b-form-input(v-model="tempData.itineraries.description" placeholder="Enter Description" required)
                        b-button(@click="addItinerary") Add
                    h5.mt-4(v-if="event?.singleDay?.itineraries.length !==0") Itineraries
                    div(v-for="itinerary in event.singleDay?.itineraries" :key="itinerary.id" class="d-flex justify-content-between align-items-center my-2" style="gap: 10px")
                        //- b-form-input(v-model="itinerary.time" type="time" required)
                        vue-timepicker(v-model="itinerary.time" type="time"  format="hh:mm A" :minute-interval="5" input-width="100%" required)
                        b-form-input(v-model="itinerary.description" required)
                        b-button(@click="removeItinerarySingle(itinerary.id)" variant="danger") Remove
                b-form-group(label="Lunch For Single Day (Optional)" label-for="lunch").w-100
                    div(class="d-flex" style="gap: 10px")
                        b-form-input(v-model="tempData.food.lunch" id="lunch" placeholder="Enter Lunch" required)
                        b-button(@click="addLunch") Add
                    h5.mt-4(v-if="event?.singleDay?.food?.lunch.length !==0") Lunch
                    div(v-for="lunch in event.singleDay?.food?.lunch" :key="lunch.id" class="d-flex justify-content-between align-items-center my-2" style="gap: 10px")
                        b-form-input(v-model="lunch.name" required)
                        b-button(@click="removeLunch(lunch.id)" variant="danger") Remove
                b-form-group(label="Beverages For Single Day (Optional)" label-for="beverages").w-100
                    div(class="d-flex" style="gap: 10px")
                        b-form-input(v-model="tempData.food.beverage" id="beverages" placeholder="Enter Beverages" required)
                        b-button(@click="addBeverage") Add
                    h5.mt-4(v-if="event?.singleDay?.food?.beverages.length !==0") Beverages
                    div(v-for="beverage in event.singleDay?.food?.beverages" :key="beverage.id" class="d-flex justify-content-between align-items-center my-2" style="gap: 10px")
                        b-form-input(v-model="beverage.name" required)
                        b-button(@click="removeBeverage(beverage.id)" variant="danger") Remove
                b-form-group(label="Price Per Person" label-for="pricePerPerson")
                    b-form-input(v-model="event.singleDay.pricePerPerson" id="pricePerPerson" type="number" placeholder="Enter Price Per Person")

            b-form-group(v-else label="Multiple Day Events" class="my-2")
                b-form-group(v-for="day in event.multipleEvents" :key="day.id" class="my-2")
                    p Day {{event.multipleEvents.indexOf(day) + 1}}
                    b-form-group(label="Event Title (Required)" label-for="eventTitle")
                        b-form-input(v-model="day.title" id="eventTitle" placeholder="Enter Event Title" required :class="{'is-invalid': day.title.length === 0}")
                    b-form-group(label="Date (Required)" label-for="date")
                        b-form-input(v-model="day.date" id="date" type="date" required :class="{'is-invalid': !day.date}")
                    b-form-group(label="Time (Required)" label-for="time")
                        //- b-form-input(v-model="day.time" id="time" type="time" required :class="{'is-invalid': !day.time}")
                        vue-timepicker(v-model="day.time" id="time"  format="hh:mm A" :minute-interval="5" input-width="100%" required)
                    b-form-group(:label="`Itineraries For Day ${event.multipleEvents.indexOf(day) + 1} (Optional)`" label-for="itineraries").w-100
                        div(class="d-flex" style="gap: 10px")
                            //- b-form-input(v-model="tempData.itineraries.time" id="itineraries" placeholder="Enter Time" required type="time")
                            vue-timepicker(v-model="tempData.itineraries.time" id="itineraries"  format="hh:mm A" :minute-interval="5" input-width="100%" required)
                            b-form-input(v-model="tempData.itineraries.description" placeholder="Enter Description" required)
                            b-button(@click="addItineraryMultipleDay(day.id)") Add
                        h5.mt-4(v-if="day?.itineraries.length !==0") Itineraries
                        div(v-for="itinerary in day?.itineraries" :key="itinerary.id" class="d-flex justify-content-between align-items-center my-2" style="gap: 10px")
                            //- b-form-input(v-model="itinerary.time" type="time" required)
                            vue-timepicker(v-model="itinerary.time" format="hh:mm A" :minute-interval="5" input-width="100%" required)
                            b-form-input(v-model="itinerary.description" required)
                            b-button(@click="removeItineraryMulti(itinerary.id,day.id)" variant="danger") Remove
                    b-form-group(:label="`Lunch For Day ${event.multipleEvents.indexOf(day) + 1} (Optional)`" label-for="lunch").w-100
                        div(class="d-flex" style="gap: 10px")
                            b-form-input(v-model="tempData.food.lunch" id="lunch" placeholder="Enter Lunch" required)
                            b-button(@click="addLunchMulti(day.id)") Add
                        h5.mt-4(v-if="day?.food?.lunch.length !==0") Lunch
                        div(v-for="lunch in day?.food?.lunch" :key="lunch.id" class="d-flex justify-content-between align-items-center my-2" style="gap: 10px")
                            b-form-input(v-model="lunch.name" required)
                            b-button(@click="removeLunchMulti(lunch.id,day.id)" variant="danger") Remove
                    b-form-group(:label="`Beverages For Day ${event.multipleEvents.indexOf(day) + 1} (Optional)`" label-for="beverages").w-100
                        div(class="d-flex" style="gap: 10px")
                            b-form-input(v-model="tempData.food.beverage" id="beverages" placeholder="Enter Beverages" required)
                            b-button(@click="addBeverageMulti(day.id)") Add
                        h5.mt-4(v-if="day?.food?.beverages.length !==0") Beverages
                        div(v-for="beverage in day?.food?.beverages" :key="beverage.id" class="d-flex justify-content-between align-items-center my-2" style="gap: 10px")
                            b-form-input(v-model="beverage.name" required)
                            b-button(@click="removeBeverageMulti(beverage.id,day.id)" variant="danger") Remove
                    b-form-group(label="Price Per Person" label-for="pricePerPerson" )
                        b-form-input(v-model="day.pricePerPerson" id="pricePerPerson" type="number" placeholder="Enter Price Per Person")
                    b-button(@click="event.multipleEvents.splice(event.multipleEvents.indexOf(day), 1)" variant="danger" class="mt-2 w-100") Remove Day
                b-button(@click="addDayToMulti" variant="primary").w-100.my-2 Add New Day


    template(#modal-footer)
        b-button(variant="secondary" @click="showModalEdit = false" :disabled="loading") Back
        b-button(variant="success" @click="submitEdit" :disabled="loading") Save
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import { VueEditor } from "vue2-editor";
import axios from 'axios';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
export default {
    props: ['eventData', 'showEdit','groups'],
    components: {
        VueEditor,
        VueTimepicker
    },
    data() {
        return {
            customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
                ["link"],
                ["clean"],
            ],
            event:{},
            loading: false,
            tempData: {
                itineraries: {
                    time: '',
                    description: ''
                },
                food: {
                    lunch: '',
                    beverage: ''
                }
            }
        }
    },
    computed: {
        showModalEdit: {
            get() {
                return this.showEdit
            },
            set(val) {
                this.$emit('update:showEdit', val)
            }
        },
        total() {
            return this.event.pricePerPerson * this.event.gst
        }
    },
    methods: {
        async submitEdit() {
            console.log('event', this.event);
            console.log('eventData', this.eventData);

            if (this.event.isSingleDay) {
                console.log('single day event');
                if (!this.event.singleDay.date || !this.event.singleDay.time  || !this.event.name || !this.event.selectedGroup) {
                    this.$bvToast.toast('Please fill all the fields', {
                        title: 'Error',
                        variant: 'danger',
                        solid: true,
                    });
                    return;
                }
                this.loading = true;
                const payload = {
                    name: this.event.name,
                    recipient: this.event.selectedGroup,
                    date: this.event.singleDay.date,
                    json: JSON.stringify(this.event),
                    id: this.eventData.crb5c_foweventmanagementid
                }
                console.log('payload', payload);
                const res = await axios.put('/api/events/v2', payload)
                if (res.status === 200) {
                    this.$bvToast.toast('Event Updated Successfully', {
                        title: 'Success',
                        variant: 'success',
                        solid: true,
                    });
                    this.showModalEdit = false;
                    this.$emit('updatedEvent', true)
                }
                this.loading = false;

            } else {
                console.log('multiple day event');
                this.event.multipleEvents.forEach((day) => {
                    console.log('day',day);
                    if(!day.date || !day.time || !day.title){
                        this.$bvToast.toast('Please fill all the fields', {
                            title: 'Error',
                            variant: 'danger',
                            solid: true,
                        });
                        return;
                    }
                })
                this.loading = true;
                const payload= {
                    name: this.event.name,
                    recipient: this.event.selectedGroup,
                    date: this.event.multipleEvents[0].date,
                    json: JSON.stringify(this.event),
                    id:this.eventData.crb5c_foweventmanagementid
                }
                console.log('payload',payload);
                const res = await axios.put('/api/events/v2',payload)
                if(res.status === 200){
                    this.$bvToast.toast('Event Updated Successfully', {
                        title: 'Success',
                        variant: 'success',
                        solid: true,
                    });
                    this.showModalEdit = false;
                    this.$emit('updatedEvent', true)
                }
                this.loading = false;

            }
        },
        removeBeverageMulti(id, dayId) {
            let index = this.event.multipleEvents.findIndex((day) => day.id === dayId);
            if (index !== -1) {
                let beverageIndex = this.event.multipleEvents[index].food.beverages.findIndex((beverage) => beverage.id === id);
                if (beverageIndex !== -1) {
                    this.event.multipleEvents[index].food.beverages.splice(beverageIndex, 1);
                }
            }
        },
        addBeverageMulti(id) {
            let index = this.event.multipleEvents.findIndex((day) => day.id === id);
            if (index !== -1) {
                if (!this.tempData.food.beverage) {
                    this.$bvToast.toast('Please fill all the fields', {
                        title: 'Error',
                        variant: 'danger',
                        solid: true,
                    });
                    return;
                }
                this.event.multipleEvents[index].food.beverages.push({
                    id: uuidv4(),
                    name: this.tempData.food.beverage,
                });
                this.tempData.food.beverage = '';
            }
        },
        removeLunchMulti(id, dayId) {
            let index = this.event.multipleEvents.findIndex((day) => day.id === dayId);
            if (index !== -1) {
                let lunchIndex = this.event.multipleEvents[index].food.lunch.findIndex((lunch) => lunch.id === id);
                if (lunchIndex !== -1) {
                    this.event.multipleEvents[index].food.lunch.splice(lunchIndex, 1);
                }
            }
        },
        addLunchMulti(id) {
            let index = this.event.multipleEvents.findIndex((day) => day.id === id);
            if (index !== -1) {
                if (!this.tempData.food.lunch) {
                    this.$bvToast.toast('Please fill all the fields', {
                        title: 'Error',
                        variant: 'danger',
                        solid: true,
                    });
                    return;
                }
                this.event.multipleEvents[index].food.lunch.push({
                    id: uuidv4(),
                    name: this.tempData.food.lunch,
                });
                this.tempData.food.lunch = '';
            }
        },
        removeItineraryMulti(id, dayId) {
            let index = this.event.multipleEvents.findIndex((day) => day.id === dayId);
            if (index !== -1) {
                let itineraryIndex = this.event.multipleEvents[index].itineraries.findIndex((itinerary) => itinerary.id === id);
                if (itineraryIndex !== -1) {
                    this.event.multipleEvents[index].itineraries.splice(itineraryIndex, 1);
                }
            }
        },
        addItineraryMultipleDay(id) {
            let index = this.event.multipleEvents.findIndex((day) => day.id === id);
            if (index !== -1) {
                if (!this.tempData.itineraries.time || !this.tempData.itineraries.description) {
                    this.$bvToast.toast('Please fill all the fields', {
                        title: 'Error',
                        variant: 'danger',
                        solid: true,
                    });
                    return;
                }
                this.event.multipleEvents[index].itineraries.push({
                    id: uuidv4(),
                    time: this.tempData.itineraries.time,
                    description: this.tempData.itineraries.description,
                });
                this.tempData.itineraries.time = '';
                this.tempData.itineraries.description = '';
            }
        },
        addDayToMulti() {
            console.log('add day');
            this.event.multipleEvents.push({
                id: uuidv4(),
                title: '',
                date: null,
                time: null,
                itineraries: [],
                food: {
                    lunch: [],
                    beverages: [],
                },
            });
        },
        removeBeverage(id) {
            let index = this.event.singleDay.food.beverages.findIndex((beverage) => beverage.id === id);
            if (index !== -1) {
                this.event.singleDay.food.beverages.splice(index, 1);
            }
        },
        addBeverage() {
            if (!this.tempData.food.beverage) {
                this.$bvToast.toast('Please fill all the fields', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                return;
            }
            this.event.singleDay.food.beverages.push({
                id: uuidv4(),
                name: this.tempData.food.beverage,
            });
            this.tempData.food.beverage = '';
        },
        removeLunch(id) {
            let index = this.event.singleDay.food.lunch.findIndex((lunch) => lunch.id === id);
            if (index !== -1) {
                this.event.singleDay.food.lunch.splice(index, 1);
            }
        },
        addLunch() {
            if (!this.tempData.food.lunch) {
                this.$bvToast.toast('Please fill all the fields', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                return;
            }
            this.event.singleDay.food.lunch.push({
                id: uuidv4(),
                name: this.tempData.food.lunch,
            });
            this.tempData.food.lunch = '';
        },
        removeItinerarySingle(id) {
            let index = this.event.singleDay.itineraries.findIndex((itinerary) => itinerary.id === id);
            if (index !== -1) {
                this.event.singleDay.itineraries.splice(index, 1);
            }
        },
        addItinerary() {
            if (!this.tempData.itineraries.time || !this.tempData.itineraries.description) {
                this.$bvToast.toast('Please fill all the fields', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                return;
            }
            this.event.singleDay.itineraries.push({
                id: uuidv4(),
                time: this.tempData.itineraries.time,
                description: this.tempData.itineraries.description,
            });
            this.tempData.itineraries.time = '';
            this.tempData.itineraries.description = '';
        }
    },
    
    watch: {
        showEdit(newVal) {
            if (newVal && this.eventData) {
                this.event = JSON.parse(this.eventData.crb5c_eventjson);
            }
            console.log(this.groups)
        }
    }
}
</script>

<style lang="scss" scoped>
</style>