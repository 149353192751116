<template lang="pug">
TablePage(typePage="Reminders", :itemList="getRemindersData" :isShowSpinner="isShowSpinner")
</template>

<script>
// import TablePage from "../components/TablePage.vue";
import TablePage from "../components/TablePage.vue";
import { mapGetters } from "vuex";
import { TABLE } from "../store/ModuleName";

export default {
  name: "Reminders",
  data(){
    return {
      isShowSpinner: false,
    }
  },
  components: {
    // TablePage,
    TablePage,
  },
  computed: {
    ...mapGetters(TABLE, ["getRemindersData"]),
  },
};
</script>

<style lang="scss"></style>
