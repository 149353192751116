<template lang="pug">
b-container(style="height: 85%")
    b-modal(id="select-day" title="Sorting Day")
        template
            b-form-group(label="Can select more that one : " v-slot="{ ariaDescribedby }")
                b-form-checkbox-group.mt-3(style="display: flex; flex-direction: column; gap: 0.75rem" v-model="daySelectedAll" :options="selectDay" :aria-describedby="ariaDescribedby" name="days")
        template(#modal-footer)
            div(style="width: 100%; display: flex; justify-content: space-between")
                b-button.px-5(variant="success" style="font-weight: 500" @click="$bvModal.hide('select-day')") OK
                b-button.px-4(variant="danger" style="font-weight: 500" @click="$bvModal.hide('select-day')") Close
    
    b-col.mt-5
        b-row.mb-3
          b-col.col-12.col-sm-8(style="")
            h4.d-flex.justify-content-start.align-items-center Master Schedule
          b-col.col-12.col-sm-4(style="d-flex justify-content-center align-items-center")
            b-row(class="" style="height: 1%; margin-right: 0.1rem;")
              b-col.col-12(class="d-flex align-items-center" style="position: relative;")
                BIconSearch(style="position: absolute; left: 30px")
                b-input(class="pr-1 pl-5 border border-dark d-flex justify-content-center align-items-center" style="border-radius: 20px;" placeholder="Search Name" type="text" v-model="inputSearchItemList")
        //- b-col(style="text-align: center; position: sticky; top: 0px")
        //-     b-row.p-3(style="display: flex; align-items: center; justify-content: center; background-color: #50276D; color: white; font-weight: 400; width: 102.2%")
        //-         b-col(cols="3" style="font-size: 0.85rem") Schedule Name
        //-         b-col(cols="2" style="font-size: 0.85rem" class="d-flex justify-content-center align-items-center")
        //-             b-button(style="background-color: inherit; border: none; font-size: 0.85rem" @click="$bvModal.show('select-day')") Day
        //-                 //- b-icon.mx-1(font-scale="0.9" icon="filter")
        //-         b-col(cols="2" style="font-size: 0.85rem") Time
        //-         b-col(cols="1" style="font-size: 0.85rem") Duration
        //-         b-col(cols="2" style="font-size: 0.85rem") Created On
        //-         b-col(cols="2" style="font-size: 0.85rem") Enrollment

        b-col(class="" style="")
            //- b-row.p-2(
            //-         v-if="scheduleList.length !== 0" 
            //-         v-for="schedule in scheduleList" 
            //-         :key="schedule.crb5c_fow_session_scheduleid" 
            //-         style="border: 1px solid black; display: flex; align-items: center; background-color: white"
            //-     )
            //-     b-col.d-flex.align-items-center(cols="3" style="font-size: 0.85rem; min-height: 50px") {{ schedule.crb5c_session_id }}
            //-     b-col(cols="2" style="font-size: 0.85rem") {{ schedule.day }}
            //-     b-col(cols="2" style="font-size: 0.85rem") {{ schedule.crb5c_time_hrs }}:{{ schedule.crb5c_time_mins }}
            //-     b-col(cols="1" style="font-size: 0.85rem") {{ schedule.formattedDuration }}
            //-     b-col(cols="2" style="font-size: 0.85rem") {{ schedule.formattedCreateOn }}
            //-     //- b-col(v-for="enrolledDetails in schedule.scheduleEnrolled" :key="enrolledDetails.enrolled" col="2")
            //-         a(style="text-decoration: none; border: 0px; background-color: inherit; color: blue; font-size: 0.85rem;" :href="enrolledDetails.redirectTo" target="_blank")                 
        
            //- b-row.p-2.bg-white.align-items-center(
            //-     v-if="scheduleList.length === 0" 
            //-     style="height: 10rem;"
            //- )
            //-     b-col.col-12
            //-         span.font-weight-bold(style="font-size: 1.2rem;") No data

            b-row
                //---- phone screen ----//
                b-col.col-12.d-block.d-sm-none(style="max-height: 35rem; overflow-y: auto;")
                    b-table(
                        :fields="tableFields" 
                        :items="scheduleList"
                        class="table-header-color border"
                        thead-tr-class="text-white"
                        tbody-tr-class="bg-white"
                        show-empty
                    )
                        template(#empty="scope")
                            b-row(v-if="isFetchingAdminDashboardData")
                                b-col.col-12
                                    b-spinner(variant="info")
                            b-row(v-else)
                                b-col.col-12
                                    span No data
                        template(#cell(scheduleName)="data")
                            span.text-primary(
                                v-b-tooltip.hover :title="`Click to view ${data.item.crb5c_session_id} schedule`"
                                style="cursor: pointer;" 
                                @click="redirectToMasterSchedulePowerApps(data.item.crb5c_fow_session_scheduleid)"
                            ) {{ data.item.crb5c_session_id }}
                        template(#cell(Time)="data")
                            span {{ data.item.crb5c_time_hrs }}:{{ data.item.crb5c_time_mins }}
                        template(#cell(Duration)="data")
                            span {{ data.item.formattedDuration }}
                        template(#cell(createdOn)="data")
                            span {{ data.item.formattedCreateOn }}
                //---- phone screen ----//
                //---- other screen ----//
                b-col.col-12.d-none.d-sm-block(style="max-height: 20rem; overflow-y: auto;")
                    b-table(
                        :fields="tableFields" 
                        :items="scheduleList"
                        class="table-header-color border"
                        thead-tr-class="text-white"
                        tbody-tr-class="bg-white"
                        show-empty
                    )
                        template(#empty="scope")
                            b-row(v-if="isFetchingAdminDashboardData")
                                b-col.col-12
                                    b-spinner(variant="info")
                            b-row(v-else)
                                b-col.col-12
                                    span No data
                        template(#cell(scheduleName)="data")
                            span.text-primary(
                                v-b-tooltip.hover :title="`Click to view ${data.item.crb5c_session_id} schedule`"
                                style="cursor: pointer;" 
                                @click="redirectToMasterSchedulePowerApps(data.item.crb5c_fow_session_scheduleid)"
                            ) {{ data.item.crb5c_session_id }}
                        template(#cell(Time)="data")
                            span {{ data.item.crb5c_time_hrs }}:{{ data.item.crb5c_time_mins }}
                        template(#cell(Duration)="data")
                            span {{ data.item.formattedDuration }}
                        template(#cell(createdOn)="data")
                            span {{ data.item.formattedCreateOn }}
                //---- other screen ----//

            b-row
                b-col.col-12.py-2.text-left
                    span Total master schedule: {{ scheduleList.length }}      


        //- b-col(class="table__body" style="")
        //-     b-row.p-2(v-for="schedule in scheduleList" :key="schedule.id" style="border: 1px solid black; display: flex; align-items: center; background-color: white")
                b-col(v-for="nameDetails in schedule.scheduleName" :key="nameDetails.name" cols="3")
                    a(style="text-decoration: none; border: 0px; background-color: inherit; color: blue; font-size: 0.85rem;" :href="nameDetails.redirectTo" target="_blank") {{ nameDetails.name }}
        //-         b-col(cols="2" style="font-size: 0.85rem") {{ schedule.scheduleDay }}
        //-         b-col(cols="2" style="font-size: 0.85rem") {{ schedule.scheduleTime }}
        //-         b-col(cols="1" style="font-size: 0.85rem") {{ schedule.scheduleDuration }}
        //-         b-col(cols="2" style="font-size: 0.85rem") {{ schedule.scheduleCreated }}
        //-         b-col(v-for="enrolledDetails in schedule.scheduleEnrolled" :key="enrolledDetails.enrolled" col="2")
        //-             a(style="text-decoration: none; border: 0px; background-color: inherit; color: blue; font-size: 0.85rem;" :href="enrolledDetails.redirectTo" target="_blank") {{ enrolledDetails.enrolled }}                 
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { ADMIN_DASHBOARD_NAMESPACE } from '../../store/Modules/AdminDashboard/types-admin-dashboard'

export default {
    data() {
        return {
            tableFields: [
                { 
                    key: "scheduleName", 
                    label: "Schedule Name",
                },
                "Time",
                "Duration",
                { 
                    key: "createdOn", 
                    label: "Created On",
                },
            ],
            inputSearchItemList: '',
            schedules: [],
            scheduleDB: [],
            formattedTime: '',
            selectDay: [
                { 
                    text: 'Monday', 
                    value: 'Monday' 
                },
                { 
                    text: 'Tuesday', 
                    value: 'Tuesday' 
                },
                { 
                    text: 'Wednesday', 
                    value: 'Wednesday' 
                },
                { 
                    text: 'Thursday', 
                    value: 'Thursday' 
                },
                { 
                    text: 'Friday', 
                    value: 'Friday' 
                }
            ],
            daySelectedAll: []
        }
    },
    computed: {
        ...mapState({
          listMasterScehdule: state => (state[ADMIN_DASHBOARD_NAMESPACE].adminDashboard.listMasterScehdule),
          isFetchingAdminDashboardData: state => (state[ADMIN_DASHBOARD_NAMESPACE].adminDashboard.isFetchingAdminDashboardData),
        }),
        scheduleList(){
            // if(this.inputSearchItemList.trim().length > 0){
            //     return this.scheduleDB.filter((schedule) => {
            //         return schedule.scheduleName.some((item) => {
            //             return item.name.toLowerCase().includes(this.inputSearchItemList.trim().toLowerCase());
            //         });
            //     })
            // }else{
            //     return this.scheduleDB
            // }
            let filteredClients = this.listMasterScehdule;
            if(this.inputSearchItemList.trim().length > 0){
                const searchName = this.inputSearchItemList.trim().toLowerCase();
                filteredClients = filteredClients.filter(client => client.crb5c_session_id.toLowerCase().includes(searchName))
            }
            return filteredClients
        }
        
    },
    methods: {
        backAdminHomepage() {
            this.$router.push({ path: '/adminhomepage' })
        },
        moveToHomePage(){
            this.$router.push({ path: "/" })
        },
        async getScheduleDB(){
            try{
                console.log("Master Schedule Mounted")
                const { data : data } = await axios.get('/api/admin_dashboard/get_session_schedule')
                return data
            } catch (error){
                console.log(error)
                return []
            }
        },
        // eslint-disable-next-line no-unused-vars
        redirectToMasterSchedulePowerApps(crb5c_fow_session_schedule){
            const BASE_URL = `https://${this.$store.state.logindata.DATAVERSE_DOMAIN}`;
            const masterScheduleUrl = `${BASE_URL}/main.aspx?appid=d951a007-cf7a-ed11-81ac-000d3a85cc97&pagetype=entityrecord&etn=crb5c_fow_session_schedule&id=${crb5c_fow_session_schedule}`;

            window.open(masterScheduleUrl, '_blank')
            
        },
    },
    async mounted() {},
    
    
}
</script>

<style>
.table__body{
    text-align: center; 
    max-height: 250px; 
    overflow-y: scroll; 
    border-collapse: separate; 
    border-spacing: 0px;
}

.table__body::-webkit-scrollbar{
    width: 0.5rem;
    height: 0.5rem;
}

.table__body::-webkit-scrollbar-thumb{
    border-radius: 0.5rem;
    background-color: #0004;
}

.table-header-color{
  background-color: #50276D;
}

</style>