<template lang="pug">
b-modal#modal-1(title="Edit Submission" v-model="showModal" centered  class="rounded-lg overflow-auto" size="md" scrollable no-close-on-backdrop)
    //- p {{submissionLocal?.eventID}}
    //- p {{event  }}
    //- event "id": "df56acbc-8346-4212-b8a9-9fc1e0d6578d", "name": "Some event ", "selectedGroup": [ { "id": "All", "name": "All" } ], "description": "<p>some thing</p>", "isSingleDay": true, "multipleEvents": [], "singleDay": { "date": "2024-10-17", "time": { "hh": "12", "mm": "00", "A": "PM" }, "itineraries": [], "food": { "lunch": [ { "id": "e9b0caea-3771-47e9-b8ca-9e33fa8875d2", "name": "Chinese" }, { "id": "aeace858-2f94-4f86-936b-b78118b5410a", "name": "Chicken" } ], "beverages": [ { "id": "e2ee0384-4392-46c3-8d43-b9d188639755", "name": "Coke" } ] }, "pricePerPerson": "20" }, "gst": 1.09, "editGST": false, "total": 0, "submissions": [ { "submissionID": "dcc7625b-6e86-ef11-ac20-6045bd5b156c", "client": "13d28ad0-e27e-ed11-81ac-000d3a85cb45", "caregiver": "41c84b5d-e67e-ed11-81ac-000d3a85c68e" }, { "submissionID": "4467c021-7b86-ef11-ac21-002248ee9793", "client": "718ac50e-4c95-ed11-aad1-000d3a856615", "caregiver": "57d21a6a-544e-ef11-a316-002248179b6c" }, { "submissionID": "62c6be9c-ee86-ef11-ac20-00224816aca8", "client": "9dd18ad0-e27e-ed11-81ac-000d3a85cb45", "caregiver": "d1c74b5d-e67e-ed11-81ac-000d3a85c68e" }, { "submissionID": "0f7ffda6-ee86-ef11-ac20-6045bd5b156c", "client": "e1cdf580-d4d2-ed11-a7c7-002248ecdc58", "caregiver": "cc1a5af5-77d3-ed11-a7c7-002248ecdc58" } ], "notAttending": [] }
    //- submission: { "name": "Some event ", "eventID": "00604c69-2186-ef11-ac21-002248ec1beb", "totalAttending": 2, "totalCost": 20, "caregiverName": "James", "clientName": "Alli Rani DO K R Thangavelu", "singleDay": { "whoGoing": [ "Client", "Caregiver" ], "client": { "identifier": { "id": "9dd18ad0-e27e-ed11-81ac-000d3a85cb45", "name": "Alli Rani DO K R Thangavelu" }, "lunch": "Chinese", "beverage": "Coke" }, "caregiver": { "identifier": { "id": "d1c74b5d-e67e-ed11-81ac-000d3a85c68e", "name": "James" }, "lunch": "Chinese", "beverage": "Coke" }, "otherPersons": [] }, "remarks": "", "id": "62c6be9c-ee86-ef11-ac20-00224816aca8" }
    b-overlay(:show="loading" rounded="sm")
        .container
                div(v-if="event.isSingleDay && submission.singleDay" class="d-flex flex-column" style="")
                    small.my-1 Select Who will be attending
                    b-form-checkbox-group(v-model="submissionLocal.singleDay.whoGoing" :options="['Client', 'Caregiver', 'Other Persons']" name="whoGoing")
                    div(v-if="submissionLocal.singleDay.whoGoing.includes('Client')" class="d-flex flex-column shadow-sm rounded-lg p-3 my-2")
                        b-card-text.my-3.h6 Client - {{submissionLocal.clientName}}
                        b-form-group(label="Lunch")
                            b-form-radio-group(v-model="submissionLocal.singleDay.client.lunch" :options="event.singleDay.food.lunch.map(lunch => lunch.name)" )
                        b-form-group(label="Beverage")
                            b-form-radio-group(v-model="submissionLocal.singleDay.client.beverage" :options="event.singleDay.food.beverages.map(beverage => beverage.name)" )
                    div(v-if="submissionLocal.singleDay.whoGoing.includes('Caregiver')" class="d-flex flex-column shadow-sm rounded-lg p-3 my-2")
                        b-card-text.my-3.h6 Caregiver - {{submissionLocal.caregiverName}}
                        b-form-group(label="Lunch")
                            b-form-radio-group(v-model="submissionLocal.singleDay.caregiver.lunch" :options="event.singleDay.food.lunch.map(lunch => lunch.name)" name="lunch")
                        b-form-group(label="Beverage")
                            b-form-radio-group(v-model="submissionLocal.singleDay.caregiver.beverage" :options="event.singleDay.food.beverages.map(beverage => beverage.name)" )
                    div(v-if="submissionLocal.singleDay.whoGoing.includes('Other Persons')" class="d-flex flex-column")
                        b-card-text.my-3.h6 Other Persons
                        div(v-for="person in submissionLocal.singleDay.otherPersons" :key="person.id" class="d-flex flex-column shadow-sm rounded-lg p-3 my-2")
                            div(class="d-flex flex-row align-items-center w-100 justify-content-between")
                                div(class="d-flex flex-row align-items-center" style="gap: 10px")
                                    b-icon(icon="person-fill")
                                    b-card-text {{person.name}}
                                b-button(@click="deleteOtherPersonSingleDay(person.id)" variant="danger" size="sm") Delete
                            div(class="d-flex flex-column")
                                b-form-group(label="Lunch")
                                    b-form-radio-group(v-model="person.lunch" :options="event.singleDay.food.lunch.map(lunch => lunch.name)")
                                b-form-group(label="Beverage")
                                    b-form-radio-group(v-model="person.beverage" :options="event.singleDay.food.beverages.map(beverage => beverage.name)" )
        
        
                        b-form-input(v-model="tempOtherPersonName" type="text" name="name" placeholder="Name")
                        b-button(@click="addOtherPersonSingleDay" variant="primary").mt-2 Add
                //- event { "id": "c7532e41-245c-407e-b5ec-d314839ea477", "name": "Feature Adding - Remarks Mutli", "selectedGroup": [ { "id": "b220a845-067f-ed11-81ad-000d3a856a39", "name": "Friday AM (Mild Dementia Group) 星期五（初期）早上" } ], "description": "<p>Feature Adding - Remarks Mutli</p>", "isSingleDay": false, "multipleEvents": [ { "id": "e321286f-57c2-4373-b7c8-fd5e3a097cf3", "title": "Day 1", "date": "2024-10-17", "time": { "hh": "12", "mm": "00", "A": "AM" }, "pricePerPerson": "50", "itineraries": [], "food": { "lunch": [], "beverages": [] } }, { "id": "ebe0b2f7-6cb6-439b-b440-e31e39ae0096", "title": "Day 2", "date": "2024-10-20", "time": { "hh": "12", "mm": "05", "A": "PM" }, "itineraries": [], "food": { "lunch": [ { "id": "1adac81c-a923-4a5f-8305-01e7d98af34f", "name": "Chinese" }, { "id": "69e971ae-e995-4d59-a034-2bc31a43bc59", "name": "Yapanese" } ], "beverages": [] }, "pricePerPerson": "20" } ], "singleDay": { "date": null, "time": null, "itineraries": [], "food": { "lunch": [], "beverages": [] }, "pricePerPerson": 0 }, "gst": 1.09, "editGST": false, "total": 0, "submissions": [ { "submissionID": "ef0f1b3b-6e86-ef11-ac20-6045bd5b156c", "client": "718ac50e-4c95-ed11-aad1-000d3a856615", "caregiver": "57d21a6a-544e-ef11-a316-002248179b6c" }, { "submissionID": "cff6265b-eb86-ef11-ac20-6045bd5b156c", "client": "36dfb0af-d88d-ee11-be36-002248ebf4f9", "caregiver": "67ce7368-d2c3-ee11-9079-002248ecdc58" }, { "submissionID": "f93ffb37-ef86-ef11-ac20-6045bd5b156c", "client": "9dd18ad0-e27e-ed11-81ac-000d3a85cb45", "caregiver": "d1c74b5d-e67e-ed11-81ac-000d3a85c68e" } ] }
                //- mutiple { "name": "Feature Adding - Remarks Mutli", "eventID": "fc45066c-f685-ef11-ac20-6045bd5b156c", "totalAttending": 2, "totalCost": 70, "caregiverName": "James", "clientName": "Alli Rani DO K R Thangavelu", "multipleDays": { "whoGoing": [ "Client", "Caregiver" ], "client": { "identifier": { "id": "9dd18ad0-e27e-ed11-81ac-000d3a85cb45", "name": "Alli Rani DO K R Thangavelu" }, "attendingDays": [ "Thursday, October 17, 2024", "Sunday, October 20, 2024" ], "lunchByDay": [ { "dayID": "ebe0b2f7-6cb6-439b-b440-e31e39ae0096", "lunchOption": "Chinese" } ], "beverageByDay": [] }, "caregiver": { "identifier": { "id": "d1c74b5d-e67e-ed11-81ac-000d3a85c68e", "name": "James" }, "attendingDays": [ "Thursday, October 17, 2024", "Sunday, October 20, 2024" ], "lunchByDay": [ { "dayID": "ebe0b2f7-6cb6-439b-b440-e31e39ae0096", "lunchOption": "Yapanese" } ], "beverageByDay": [] }, "otherPersons": [] }, "remarks": "", "id": "f93ffb37-ef86-ef11-ac20-6045bd5b156c" }
                div(v-if="!event.isSingleDay && submission.multipleDays" class="d-flex flex-column" style="")
                    small.my-1 Select Who will be attending
                    b-form-checkbox-group(v-model="submissionLocal.multipleDays.whoGoing" :options="['Client', 'Caregiver', 'Other Persons']" name="whoGoing")
                    div(v-if="submissionLocal.multipleDays.whoGoing.includes('Client')" class="d-flex flex-column shadow-sm rounded-lg p-3 my-2")
                        b-card-text.my-3.h6 Client - {{submissionLocal.clientName}}
                        b-form-group(label="Attending Days")
                            b-form-checkbox-group(v-model="submissionLocal.multipleDays.client.attendingDays" :options="event.multipleEvents.map(day => new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" :key="event.multipleEvents.map(day => day.id).join(',')" )
                        div(v-for="(day, index) in event.multipleEvents" :key="day.id" v-if="submissionLocal.multipleDays.client.attendingDays.includes(new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" class="d-flex flex-column")
                                div(v-if="day.food.lunch.length > 0"  class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;")
                                    b-card-text.h6 Select Lunch Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                    b-form-radio-group( :options="day.food.lunch.map(lunch => lunch.name)" :key="day.food.lunch.map(lunch => lunch.id).join(',')" :value="getLunchOptionForDay(day.id, submissionLocal.multipleDays.client.lunchByDay)" @change="updateLunchOptionForDay(day.id, $event, 'client')")
                                div(v-if="day.food.beverages.length > 0"   class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;")
                                    b-card-text.mt-3.h6 Select Beverage Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                    b-form-radio-group( :options="day.food.beverages.map(beverage => beverage.name)" :key="day.food.beverages.map(beverage => beverage.id).join(',')" :value="getBeverageOptionForDay(day.id, submissionLocal.multipleDays.client.beverageByDay)" @change="updateBeverageOptionForDay(day.id, $event, 'client')")
                    div(v-if="submissionLocal.multipleDays.whoGoing.includes('Caregiver')" class="d-flex flex-column shadow-sm rounded-lg p-3 my-2")
                        b-card-text.my-3.h6 Caregiver - {{submissionLocal.caregiverName}}
                        b-form-group(label="Attending Days")
                            b-form-checkbox-group(v-model="submissionLocal.multipleDays.caregiver.attendingDays" :options="event.multipleEvents.map(day => new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" :key="event.multipleEvents.map(day => day.id).join(',')" )
                        div(v-for="(day, index) in event.multipleEvents" :key="day.id" v-if="submissionLocal.multipleDays.caregiver.attendingDays.includes(new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" class="d-flex flex-column")
                                div(v-if="day.food.lunch.length > 0"  class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;")
                                    b-card-text.h6 Select Lunch Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                    b-form-radio-group( :options="day.food.lunch.map(lunch => lunch.name)" :key="day.food.lunch.map(lunch => lunch.id).join(',')" :value="getLunchOptionForDay(day.id, submissionLocal.multipleDays.client.lunchByDay)" @change="updateLunchOptionForDay(day.id, $event, 'client')")
                                div(v-if="day.food.beverages.length > 0"   class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;")
                                    b-card-text.mt-3.h6 Select Beverage Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                    b-form-radio-group( :options="day.food.beverages.map(beverage => beverage.name)" :key="day.food.beverages.map(beverage => beverage.id).join(',')" :value="getBeverageOptionForDay(day.id, submissionLocal.multipleDays.caregiver.beverageByDay)" @change="updateBeverageOptionForDay(day.id, $event, 'caregiver')")
                    div(v-if="submissionLocal.multipleDays.whoGoing.includes('Other Persons')" class="d-flex flex-column")
                        b-card-text.my-3.h6 Other Persons
                        div(v-for="person in submissionLocal.multipleDays.otherPersons" :key="person.id" class="d-flex flex-column shadow-sm rounded-lg p-3 my-2")
                            div(class="d-flex flex-column align-items-center w-100 justify-content-between")
                                div(class="d-flex flex-row align-items-center w-100 justify-content-between" ).mb-2
                                    div(class="d-flex flex-row align-items-center" style="gap: 10px")
                                        b-icon(icon="person-fill")
                                        b-card-text {{person.name}}
                                    b-button(@click="deletePersonMultipleDay(person.id)" variant="danger" size="sm") Delete
                                div(class="d-flex flex-column")
                                b-form-group(label="Attending Days")
                                    b-form-checkbox-group(v-model="person.attendingDays" :options="event.multipleEvents.map(day => new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" :key="event.multipleEvents.map(day => day.id).join(',')" )
                                div(v-for="(day, index) in event.multipleEvents" :key="day.id" v-if="person.attendingDays.includes(new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" class="d-flex flex-column")
                                    div(v-if="day.food.lunch.length > 0"  class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;")
                                        b-card-text.h6 Select Lunch Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                        b-form-radio-group( :options="day.food.lunch.map(lunch => lunch.name)" :key="day.food.lunch.map(lunch => lunch.id).join(',')" :value="getLunchOptionForDay(day.id, person.lunchByDay)" @change="updateLunchOptionForDay(day.id, $event, 'otherPersons', person.id)")
                                    div(v-if="day.food.beverages.length > 0"   class="d-flex flex-column" style="gap: 1rem;border: 1px solid #ccc; padding: 1rem;")
                                        b-card-text.mt-3.h6 Select Beverage Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                        b-form-radio-group( :options="day.food.beverages.map(beverage => beverage.name)" :key="day.food.beverages.map(beverage => beverage.id).join(',')" :value="getBeverageOptionForDay(day.id, person.beverageByDay)" @change="updateBeverageOptionForDay(day.id, $event, 'otherPersons', person.id)")
                        b-form-input(v-model="tempOtherPersonName" type="text" name="name" placeholder="Name")
                        b-button(@click="addOtherPersonMultipleDays" variant="primary").mt-2 Add
    
    template(#modal-footer)
        b-button(@click="showModal = false" variant="danger" :disabled="loading") Cancel
        b-button(@click="submit" variant="primary" :disabled="loading") 
            span(v-if="loading")
                b-spinner(type="border" small)
            span(v-else) Submit
        
                        
                        
                        
                        
                            
                        
                        
                            

                    
                



                    
                    


</template>

<script>
/* eslint-disable */
import { v4 as uuid } from "uuid";
import axios from 'axios';
import _ from 'lodash';
import { validateEvent } from "../../utils/eventValidator";
    export default {
        data() {
            return {
                submissionLocal: _.cloneDeep(this.submission),
                tempOtherPersonName: "",
                loading: false
            }
        },
        props: {
            show: {
                type: Boolean,
                required: true
            },
            submission: {
                type: Object,
                required: true
            },
            event: {
                type: Object,
                required: true
            }

        },
        computed: {
            showModal: {
                get() {
                    return this.show
                },
                set(value) {
                    this.$emit('update:show', value)
                }
            },
            multipleDaysTotalCost() {
            let total = 0;
            
            if (this.submissionLocal.multipleDays.whoGoing.includes('Caregiver')) {
                for (let day of this.submissionLocal.multipleDays.caregiver.attendingDays) {
                    let dayIndex = this.event.multipleEvents.findIndex(eventDay => new Date(eventDay.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) === day);
                    if (dayIndex !== -1) {
                        total += parseFloat(this.event.multipleEvents[dayIndex].pricePerPerson);
                    }
                }
            }
            if (this.submissionLocal.multipleDays.whoGoing.includes('Other Persons')) {
                for (let person of this.submissionLocal.multipleDays.otherPersons) {
                    for (let day of person.attendingDays) {
                        let dayIndex = this.event.multipleEvents.findIndex(eventDay => new Date(eventDay.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) === day);
                        if (dayIndex !== -1) {
                            total += parseFloat(this.event.multipleEvents[dayIndex].pricePerPerson);
                        }
                    }
                }
            }
            return total;
        },
            singleDayTotalCost() {
                let total = 0;
                if (this.submissionLocal.singleDay.whoGoing.includes('Caregiver')) {
                    total += parseInt(this.event.singleDay.pricePerPerson);
                }
                if (this.submissionLocal.singleDay.whoGoing.includes('Other Persons')) {
                    total += this.submissionLocal.singleDay.otherPersons.length * parseInt(this.event.singleDay.pricePerPerson);
                }
                return total;
            },
            
        },
        methods: {
            async submit(){    
                try{
                    this.loading = true;
                    if (this.event.isSingleDay) {
                const validation = validateEvent(this.event, this.submissionLocal);
                if (!validation.isValid) {
                    console.log(validation.message)
                    this.$bvToast.toast(validation.message, {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                    });
                    return;
                }
                const payload = {
                    json: {
                        name: this.event.name,
                        eventID: this.submissionLocal.eventID,
                        totalAttending: this.submissionLocal.singleDay.whoGoing.includes('Client') + this.submissionLocal.singleDay.whoGoing.includes('Caregiver') + this.submissionLocal.singleDay.otherPersons.length,
                        totalCost: this.singleDayTotalCost,
                        caregiverName: this.submissionLocal.singleDay.caregiver.identifier?.name ? this.submissionLocal.singleDay.caregiver.identifier?.name : this.submissionLocal.caregiverName,
                        clientName:     this.submissionLocal.singleDay.client.identifier?.name ? this.submissionLocal.singleDay.client.identifier?.name : this.submissionLocal.clientName,
                        singleDay: {
                            whoGoing: Array.from([...new Set(this.submissionLocal.singleDay.whoGoing)]),
                            client: this.submissionLocal.singleDay.client,
                            caregiver: this.submissionLocal.singleDay.caregiver,
                            otherPersons: this.submissionLocal.singleDay.otherPersons
                        },
                        remarks: this.submissionLocal.remarks ?? ""
                    },
                    client: this.submissionLocal.singleDay.client?.identifier?.id ? this.submissionLocal.singleDay.client?.identifier?.id : this.submissionLocal.clientID,
                    caregiver: this.submissionLocal.singleDay.caregiver?.identifier?.id ? this.submissionLocal.singleDay.caregiver?.identifier?.id : this.submissionLocal.caregiverID,
                    submissionID: this.submissionLocal.id

                }
                
                const res = await axios.patch('/api/events/v2/patchSubmission', payload);
                if (res) {
                
                    this.$bvToast.toast('Updated Successfully', {
                        title: 'Success',
                        variant: 'success',
                        solid: true
                    });
                    setTimeout(() => {
                        this.$emit('refresh');
                        this.showModal = false;
                    }, 1000);
                    
                } else {
                    this.$bvToast.toast('Error submitting event attendance', {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                    });
                    

                }

                
                } else{
                    const validation = validateEvent(this.event, this.submissionLocal);
                    if (!validation.isValid) {
                        this.$bvToast.toast(validation.message, {
                            title: 'Error',
                            variant: 'danger',
                            solid: true
                        });
                        return;
                    }
                    const payload = {
                    json: {
                        name: this.event.name,
                        eventID: this.submissionLocal.eventID,
                        totalAttending: this.submissionLocal.multipleDays.whoGoing.includes('Client') + this.submissionLocal.multipleDays.whoGoing.includes('Caregiver') + this.submissionLocal.multipleDays.otherPersons.length,
                        totalCost: this.multipleDaysTotalCost,
                        caregiverName: this.submissionLocal.multipleDays.caregiver.identifier?.name ? this.submissionLocal.multipleDays.caregiver.identifier?.name : this.submissionLocal.caregiverName,
                        clientName: this.submissionLocal.multipleDays.client.identifier?.name ? this.submissionLocal.multipleDays.client.identifier?.name : this.submissionLocal.clientName,
                        multipleDays: {
                            whoGoing: Array.from([...new Set(this.submissionLocal.multipleDays.whoGoing)]),
                            client: this.submissionLocal.multipleDays.client,
                            caregiver: this.submissionLocal.multipleDays.caregiver,
                            otherPersons: this.submissionLocal.multipleDays.otherPersons
                        },
                        remarks: this.submissionLocal.remarks ?? ""
                    },
                    client: this.submissionLocal.multipleDays.client?.identifier?.id ? this.submissionLocal.multipleDays.client?.identifier?.id : this.submissionLocal.clientID,
                    caregiver:  this.submissionLocal.multipleDays.caregiver?.identifier?.id ? this.submissionLocal.multipleDays.caregiver?.identifier?.id : this.submissionLocal.caregiverID,
                    submissionID: this.submissionLocal.id

                }
                
                const res = await axios.patch('/api/events/v2/patchSubmission', payload);
                if (res) {
                    
                    this.$bvToast.toast('Updated Successfully', {
                        title: 'Success',
                        variant: 'success',
                        solid: true
                    });
                    
                    setTimeout(() => {
                        this.$emit('refresh');
                        this.showModal = false;
                    }, 1000);

                    
                } else {
                    this.$bvToast.toast('Error submitting event attendance', {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                    });
                    

                }
                }

                }catch(err){
                    console.log(err)
                    const jsonError = err.response.data;
                    this.$bvToast.toast(jsonError.message ? jsonError.message :"Something went wrong", {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                    });
                }
                finally{
                    this.loading = false;
                    
                }
                
            },
            deletePersonMultipleDay(id) {
                const choice = confirm("Are you sure you want to delete this person?")
                if (!choice) return
            let index = this.submissionLocal.multipleDays.otherPersons.findIndex(person => person.id === id);
            if (index !== -1) {
                this.submissionLocal.multipleDays.otherPersons.splice(index, 1);
            }

        },
            addOtherPersonMultipleDays() {
            if (this.tempOtherPersonName) {
                this.submissionLocal.multipleDays.otherPersons.push({
                    id: uuid(),
                    name: this.tempOtherPersonName,
                    attendingDays: [],
                    lunchByDay: [],
                    beverageByDay: []
                });
                this.tempOtherPersonName = "";
            }
        },
            updateLunchOptionForDay(dayID, selectedLunch, personType,personID=null) {
            if(personID){
                const personIndex = this.submissionLocal.multipleDays.otherPersons.findIndex(person => person.id === personID);
                if (personIndex !== -1) {
                    const dayIndex = this.submissionLocal.multipleDays.otherPersons[personIndex].lunchByDay.findIndex(day => day.dayID === dayID);
                    if (dayIndex !== -1) {
                        
                        this.submissionLocal.multipleDays.otherPersons[personIndex].lunchByDay[dayIndex].lunchOption = selectedLunch;
                    } else {
                        
                        this.submissionLocal.multipleDays.otherPersons[personIndex].lunchByDay.push({ dayID, lunchOption: selectedLunch });
                    }
                }
            }
            else{
            const dayIndex = this.submissionLocal.multipleDays[personType].lunchByDay.findIndex(day => day.dayID === dayID);
            if (dayIndex !== -1) {
                
                this.submissionLocal.multipleDays[personType].lunchByDay[dayIndex].lunchOption = selectedLunch;
            } else {
                
                this.submissionLocal.multipleDays[personType].lunchByDay.push({ dayID, lunchOption: selectedLunch });
            }
        }
        },
        updateBeverageOptionForDay(dayID, selectedBeverage, personType, personID=null) {
            if(personID){
                const personIndex = this.submissionLocal.multipleDays.otherPersons.findIndex(person => person.id === personID);
                if (personIndex !== -1) {
                    const dayIndex = this.submissionLocal.multipleDays.otherPersons[personIndex].beverageByDay.findIndex(day => day.dayID === dayID);
                    if (dayIndex !== -1) {
                        
                        this.submissionLocal.multipleDays.otherPersons[personIndex].beverageByDay[dayIndex].beverageOption = selectedBeverage;
                    } else {
                        
                        this.submissionLocal.multipleDays.otherPersons[personIndex].beverageByDay.push({ dayID, beverageOption: selectedBeverage });
                    }
                }
                // console.log(this.submissionLocal)
            }
            else{
            const dayIndex = this.submissionLocal.multipleDays[personType].beverageByDay.findIndex(day => day.dayID === dayID);
            if (dayIndex !== -1) {
                
                this.submissionLocal.multipleDays[personType].beverageByDay[dayIndex].beverageOption = selectedBeverage;
            } else {
                
                this.submissionLocal.multipleDays[personType].beverageByDay.push({ dayID, beverageOption: selectedBeverage });
            }
        }
        },
            getLunchOptionForDay(dayID, lunchByDay) {
            const day = lunchByDay.find(day => day.dayID === dayID);
            return day ? day.lunchOption : null;
        },
        getBeverageOptionForDay(dayID, beverageByDay) {
            const day = beverageByDay.find(day => day.dayID === dayID);
            return day ? day.beverageOption : null;
        },
            addOtherPersonSingleDay() {
                if(!this.tempOtherPersonName) return
                this.submissionLocal.singleDay.otherPersons.push({
                    id: uuid(),
                    name: this.tempOtherPersonName,
                    lunch: "",
                    beverage: "",
                })
                this.tempOtherPersonName = ""
            },
            deleteOtherPersonSingleDay(id){
                const choice = confirm("Are you sure you want to delete this person?")
                if(!choice) return
                let index = this.submissionLocal.singleDay.otherPersons.findIndex(person => person.id === id)
                if(index !== -1){
                    this.submissionLocal.singleDay.otherPersons.splice(index, 1)
                }

            }
        },
        watch: {
            submission: {
                handler(newVal, oldVal) {
                    this.submissionLocal = JSON.parse(JSON.stringify(newVal));
                },
                deep: true
            }
        }

    }
</script>

<style lang="scss" scoped>

</style>