<template lang="pug">
div(class="d-flex flex-column justify-content-between h-100 main-container" style="width:15%")
    div(class="d-flex flex-column justify-content-start align-items-center mx-auto h-75" style="width:100%;")
        div(class="d-flex justify-content-center align-items-center image-container w-100 ")
            b-img(:src="DSG_logo", alt="DSG Logo" class="img-logo" width="150px")
        div(class="d-flex flex-column justify-content-start align-items-center px-4 py-2" style="height:fit-content;").w-100
            b-button(v-for="item in navItems" :key="item.name" :to="item.url" variant="primary" class="w-100 mb-2"
                :class="{'active': $route.path === item.url, 'not-active': $route.path !== item.url}" )
                | {{ item.name }}
    div(class="d-flex flex-column justify-content-end align-items-center mx-auto h-25 py-2" style="width:80%;")
        //- b-button.w-100(variant="danger") Logout
        
            
                


</template>

<script>
import DSG_logo from '@/assets/Images/DSG_logo.png';
    export default {
        data(){
            return {
                DSG_logo,
                btnClickedColor: "#50276b",
                navItems:[
                    {name: "Home",url:"/"},
                    {name: "Announcements",url:"/announcements"},
                    // {name: "Messages",url:"/chat"},
                    {name: "Events",url:"/events"},
                    // {name: "Add Events",url:"/addevent"},

                ]
            }
        },
        
    }
</script>

<style lang="scss" scoped>
.main-container{
    background-color: #8967b3;
}
.image-container{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.active {
    background-color: #50276b !important;
    border-color: #50276b !important;
    color: white !important;
    outline: none;
}
.not-active{
    background-color: white;
    color:#50276b;
    outline: none;
    border:none;
}

</style>