<template lang="pug">
b-modal#modal-1(title="Add Days" v-model="showModal"  class="rounded-lg overflow-auto" size="md" scrollable no-close-on-backdrop)
    b-form(@submit.prevent="addDays")
        b-row.no-gutters.mb-4.w-100
            b-col.col-auto(v-for="stepNumber in 4" :key="stepNumber" class="text-center").w-25
                div(
                    @click="step = stepNumber"
                    :class="['px-3', 'py-2', 'rounded', {'bg-primary text-white': step === stepNumber, 'bg-light': step !== stepNumber}]"
                )
                    | Step {{ stepNumber }}
                    
                if (stepNumber < 4)
                    div.mx-2
                        | ------
        b-row(v-if="step === 1")
            b-col
                small.mb-2 General Information
                b-form-group(label="Title" label-for="title" )
                    b-form-input(v-model="title" type="text" id="title" placeholder="Enter title" required :class="{'is-invalid': !title}")
                b-form-group(label="Date" label-for="date")
                    b-form-input(v-model="date" type="date" id="date" required :class="{'is-invalid': !date}")

                div(class="mb-2 p-2 border rounded" :class="{'border-danger': !time}")
                    b-form-group(label="Time" label-for="time")
                        vue-timepicker(v-model="time" format="hh:mm A" :minute-interval="5" id="day-time" placeholder="Select Time" required input-width="100%"  )
                
                b-form-group(label="Price Per Person" label-for="pricePerPerson")
                    b-form-input(v-model="pricePerPerson" type="number" id="pricePerPerson" )
            
        b-row(v-if="step === 2")
            b-col
                small.mb-2 Itineraries (Optional)
                b-form-group(label="Time" label-for="time")
                    vue-timepicker(v-model="tempItinerary.time" format="hh:mm A" :minute-interval="5" id="itinerary-time" placeholder="Select Time" required input-width="100%" )
                b-form-group(label="Description" label-for="description")
                    b-form-input(v-model="tempItinerary.description" type="text" id="description" placeholder="Enter description" required)
                b-button(@click.prevent="addItinerary").w-100 Add Itinerary
                hr
                p(v-if="itineraries.length === 0") No Itineraries Added
                b-card(v-for="itinerary in itineraries" :key="itinerary.id" class="mb-2")
                    b-form-group(label="Time" label-for="time")
                        vue-timepicker(v-model="itinerary.time" format="hh:mm A" :minute-interval="5" id="itinerary-time" placeholder="Select Time" required input-width="100%" )
                    b-form-group(label="Description" label-for="description")
                        b-form-input(v-model="itinerary.description" type="text" id="description" placeholder="Enter description" required)
                    b-button(@click.prevent="removeItinerary(itinerary.id)" variant="danger").w-100 Remove Itinerary
        b-row(v-if="step === 3")
            b-col
                small.mb-2 Food for the day (Optional)
                div(class="list-group")
                    small.mb-2 Lunch    
                    b-list-group-item(v-for="lunch in food.lunch" :key="lunch.id" class="d-flex justify-content-between align-items-center")
                        | {{ lunch.name }}
                        b-button(@click.prevent="removeLunch(lunch.id)" variant="danger") Remove
                    small.mb-2 Beverages
                    b-list-group-item(v-for="beverages in food.beverages" :key="beverages.id" class="d-flex justify-content-between align-items-center")
                        | {{ beverages.name }}
                        b-button(@click.prevent="removeBeverages(beverages?.id)" variant="danger") Remove
                    
                div(class="d-flex justify-content-between  align-items-end" style="gap: 10px")
                    b-form-group(label="Lunch" label-for="lunch").w-75.mb-0 
                        b-form-input(v-model="tempLunch" type="text" id="lunch" placeholder="Enter lunch" required)
                    b-button(@click.prevent="addLunch").w-25 Add
               
                div(class="d-flex justify-content-between  align-items-end" style="gap: 10px")
                    b-form-group(label="Beverages" label-for="beverages").w-75.mb-0 
                        b-form-input(v-model="tempBeverages" type="text" id="beverages" placeholder="Enter beverages" required)
                    b-button(@click.prevent="addBeverages").w-25 Add
        b-row(v-if="step ===4")
            b-col
                small.mb-2 Confirm Details
                b-card
                    b-card-text
                        b-row
                            b-col.col-auto
                                b-card-text Title:
                            b-col.col-auto
                                b-card-text {{ title }}
                        b-row
                            b-col.col-auto
                                b-card-text Date:
                            b-col.col-auto
                                b-card-text {{ date ? new Date(date).toDateString() : "" }}
                        b-row
                            b-col.col-auto
                                b-card-text Time:
                            b-col.col-auto
                                b-card-text {{ time?.hh }}:{{ time?.mm }} {{ time?.A }}
                        b-row
                            b-col.col-auto
                                b-card-text Price Per Person:
                            b-col.col-auto
                                b-card-text ${{ pricePerPerson }}
                        hr
                        b-row
                            b-col.col-auto
                                b-card-text.small.mb-1 Itineraries (Optional)
                                div(class="d-flex align-items-center" v-for="itinerary in itineraries" :key="itinerary.id" style="gap: 10px")
                                    b-icon-clock
                                    b-card-text {{ itinerary?.time?.hh }}:{{ itinerary?.time?.mm }} {{ itinerary?.time?.A }} - {{ itinerary?.description }}
                                    
                                    
                            
                        b-row.mt-4
                            b-col.col-auto
                                b-card-text.small.mb-1 Food - Lunch (Optional)
                                div(class="d-flex align-items-center" v-for="lunch in food.lunch" :key="lunch.id" style="gap: 10px")
                                    b-icon-egg-fried
                                    b-card-text {{ lunch?.name }}
                                b-card-text.small.mb-1.mt-2 Food - Beverages (Optional)
                                div(class="d-flex align-items-center" v-for="beverages in food.beverages" :key="beverages.id" style="gap: 10px")
                                    b-icon-cup
                                    b-card-text {{ beverages?.name }}
                            

                
                
                
                    


                    
        b-row(v-if="step === 4")
                
                
                        
                    
                            
                    
    template(#modal-footer)
        b-button(@click="showModal = false") Cancel
        b-button(@click="incrementStep" variant="primary" v-if="step < 4" class="d-block") Next
        b-button(@click="addDays" variant="success" v-else) Add
    

</template>

<script>
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import { v4 as uuidv4 } from "uuid";
    export default {
        components: {
            VueTimepicker,
        },
        props: {
            show: {
                type: Boolean,
                required: true
            },
        },
        computed: {
            showModal: {
                get() {
                    return this.show
                },
                set(value) {
                    this.step = 1
                    this.$emit('update:show', value)
                }
            }
        },
        data() {
            return {
                step: 1,
                title: "",
                date: null,
                time: null,
                pricePerPerson: 0,
                itineraries: [],
                food: {
                    lunch: [],
                    beverages: [],
                },

                tempItinerary :{
                    time: null,
                    description: "",
                },
                tempLunch: "",
                tempBeverages: "",
            }
        },
        methods: {
            addDays() {
                if(!this.title || !this.date || !this.time) {
                    this.$bvToast.toast('Please fill all the fields', {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                    })
                    this.step = 1
                    return
                    
                }
                this.$emit('addDays', {
                    title: this.title,
                    date: this.date,
                    time: this.time,
                    pricePerPerson: this.pricePerPerson,
                    itineraries: this.itineraries,
                    food: this.food,
                })
                this.showModal = false
                this.step = 1
                this.title = ""
                this.date = null
                this.time = null
                this.pricePerPerson = 0
                this.itineraries = []
                this.food = {
                    lunch: [],
                    beverages: [],
                }
                this.tempItinerary = {
                    time: null,
                    description: "",
                }
                this.tempLunch = ""
                this.tempBeverages = ""
            },
            addItinerary() {
                if(!this.tempItinerary.time || !this.tempItinerary.description) {
                    return
                }
                this.itineraries.push({
                    ...this.tempItinerary,
                    id: uuidv4()
                })
                this.tempItinerary = {
                    time: null,
                    description: "",
                }
                
                
            },
            removeItinerary(id) {
                let choice = confirm("Are you sure you want to remove this itinerary?")
                if(!choice) {
                    return
                }
                let index = this.itineraries.findIndex(itinerary => itinerary.id === id)
                if(index !== -1) {
                    this.itineraries.splice(index, 1)
                }

            },
            incrementStep() {
                if(this.step === 1){
                    if(!this.title || !this.date || !this.time ) {
                        this.$bvToast.toast('Please fill all the fields', {
                            title: 'Error',
                            variant: 'danger',
                            solid: true
                        })
                        return
                    }
                }
                if(this.step !== 4) {
                    this.step++
                }
            },
            addLunch() {
                if(!this.tempLunch) {
                    return
                }
                this.food.lunch.push(
                    {
                        name: this.tempLunch,
                        id: uuidv4()
                    }
                )
                this.tempLunch = ""
                
            },
            addBeverages() {
                if(!this.tempBeverages) {
                    return
                }
                this.food.beverages.push(
                    {
                        name: this.tempBeverages,
                        id: uuidv4()
                    }
                )
                this.tempBeverages = ""
                
            },
            removeLunch(id) {
                let choice = confirm("Are you sure you want to remove this lunch?")
                if(!choice) {
                    return
                }
                let index = this.food.lunch.findIndex(lunch => lunch.id === id)
                if(index !== -1) {
                    this.food.lunch.splice(index, 1)
                }
            },
            removeBeverages(id) {
                let choice = confirm("Are you sure you want to remove this beverages?")
                if(!choice) {
                    return
                }
                let index = this.food.beverages.findIndex(beverages => beverages.id === id)
                if(index !== -1) {
                    this.food.beverages.splice(index, 1)
                }
            }
        }
        
        
    }
</script>

<style lang="scss" scoped>

</style>