
const EVENTS_NAMESPACE = "events";

const GETTERS = Object.freeze({
    INDICATOR_COUNT_CAREGIVER_CASH_OR_CHEQUE_NOT_ACKNOWLEDGE: "indicatorCountCaregiverCashOrChequeNotAcknowledge",
});

const MUTATIONS = Object.freeze({
    SET_LIST_CLIENT_AND_CAREGIVER_DATA: "setListClientAndCaregiverData",
    ADD_LIST_DATA: "addListData",
    REMOVE_EVENT_LIST_DATA: "removeEventListData",
    ADD_CAREGIVER: "addCaregiver",
    ADD_LIST_GROUP_SESSION: "addListGroupSession",
    MODIFY_ITEM_LIST_DATA: "modifyItemListData",
    SET_LIST_LUNCH_DATA_FROM_LIST_DATA: "setListLunchDataFromListData",
});

const ACTIONS = Object.freeze({
    // ADD_CAREGIVER: "addCaregiver",
    DETERMINE_EVENT_STATUS: "determineEventStatus",
    CREATE_EVENT: "createEventAndPostToBackend",
    TRANSFORM_PAYLOAD_EVENT: "transformPayloadEvent",
    VALIDATE_LIST_DATA: "validateListData",
    VALIDATE_PAYLOAD_SUBMIT_PATCH: "validatePayloadSubmitPatch",
    UPDATE_CAREGIVER_PAYMENT_ACKNOWLEDGEMENT: "updateCaregiverPaymentAcknowledgement",
    UPDATE_EVENT_ATTRIBUTES: "updateEventAttributes",
    REMOVE_CAREGIVER_FROM_EVENT: "removeCaregiverFromEvent",

    // DB get
    DB_GET_EVENT: "db_get_event",
    // DB_GET_EVENT_LUNCH: "db_get_event_lunch",
    // DB_GET_EVENT_ITINERARY: "db_get_event_itinerary",
    // DB_GET_EVENT_CAREGIVER: "db_get_event_caregiver",
    // DB_GET_EVENT_CAREGIVER_OTHER_PERSON_JOIN: "db_get_event_caregiver_other_person_join",

    // DB post
    DB_POST_EVENT: "db_post_event",
    // DB_POST_EVENT_LUNCH: "db_post_event_lunch",
    // DB_POST_EVENT_ITINERARY: "db_post_event_itinerary",
    // DB_POST_EVENT_CAREGIVER: "db_post_event_caregiver",
    // DB_POST_EVENT_CAREGIVER_OTHER_PERSON_JOIN: "db_post_event_caregiver_other_person_join",

    // DB patch
    // DB_PATCH_EVENT_LUNCH: "db_patch_event_lunch",
    // DB_PATCH_EVENT_ITINERARY: "db_patch_event_itinerary",
    DB_PATCH_EVENT_CAREGIVER_PAYMENT_ACKNOWLEDGE: "db_patch_event_caregiver_payment_acknowledge",
    // DB_PATCH_EVENT_CAREGIVER_OTHER_PERSON_JOIN: "db_patch_event_caregiver_other_person_join",

    // DB delete
    DB_DELETE_EVENT: "db_delete_event",
    DB_EVENT_CAREGIVER_DELETE: "db_event_caregiver_delete",

});

export { 
    EVENTS_NAMESPACE, 
    GETTERS,
    MUTATIONS, 
    ACTIONS,
}