<template lang="pug">
b-modal#modal-1(title="Add Event" v-model="showModal"  class="rounded-lg overflow-auto" size="lg" scrollable no-close-on-backdrop)
    b-form(@submit.prevent="submitForm" class="form")
        b-form-group(label="Event Name (Required)" label-for="eventName")
            b-form-input(v-model="eventName" id="eventName" type="text" placeholder="Enter event name" required)
        b-form-group(label="Group (Required)" label-for="group")
            v-select(
                id="event-group" 
                :options="groups"
                :reduce="item => ({ id: item.id, name: item.name })" 
                v-model="selectedGroup"
                multiple 
                label="name"
                placeholder="Select Group")
        b-form-group(label="Description (Required)" label-for="description")
            vue-editor(v-model="description" :editorToolbar="customToolbar" placeholder="Enter description" required)
        b-form-group(label="Remarks (if any)" label-for="remarks")
            b-form-textarea(v-model="remarks" id="remarks" placeholder="Enter remarks")
        b-form-group(label="Single Day or Multiple Days Event" label-for="singleDay")
            b-form-radio-group(v-model="isSingleDay")
                b-form-radio(:value="true") Single Day
                b-form-radio(:value="false") Multiple Days
        b-form-group(v-if="isSingleDay" label="Date (Required)" label-for="date")
            b-form-input(v-model="singleDay.date" id="date" type="date" required)
        b-form-group(v-if="isSingleDay" label="Time (Required)" label-for="time")
            b-form-input(v-model="singleDay.time" id="time" type="time" required)
        b-form-group(v-if="isSingleDay" label="Itineraries (if any)" label-for="itineraries")
            div(v-for="(itinerary, index) in singleDay.itineraries" :key="index").my-2.w-100

                div(class="d-flex justify-content-between align-items-center" style="gap: 1rem" :ref="'itinerary' + index")
                    b-form-input(v-model="itinerary.time" type="time" required)
                    b-form-input(v-model="itinerary.description" type="text" placeholder="Enter itinerary" required)
                    b-button(@click.prevent="singleDay.itineraries.splice(index, 1)" variant="danger") 
                        b-icon(icon="trash")

            div(class="d-flex justify-content-between align-items-center" style="gap: 1rem" )
                b-form-input(v-model="tempTime" type="time" required)
                b-form-input(v-model="tempDesc" type="text" placeholder="Enter itinerary" required)
                b-button(@click.prevent="addToItineraries") 
                    b-icon(icon="plus")
        b-form-group(v-if="isSingleDay" label="Lunch Items (if any)" )
            div(v-for="(lunchItem, index) in singleDay.food.lunch" :key="index").my-2.w-100
                div(class="d-flex justify-content-between align-items-center" style="gap: 1rem" :ref="'lunch' + index")
                    b-form-input(v-model="lunchItem.name" type="text" placeholder="Enter lunch item" required)
                    b-button(@click.prevent="singleDay.food.lunch.splice(index, 1)" variant="danger") 
                        b-icon(icon="trash")
                
            div(class="d-flex justify-content-between align-items-center" style="gap: 1rem" )
                b-form-input(v-model="tempLunchFood" type="text" placeholder="Enter lunch item" required)
                b-button(@click.prevent="addLunchItemSingle") 
                    b-icon(icon="plus")
        b-form-group(v-if="isSingleDay" label="Beverages (if any)" )
            div(v-for="(beverage, index) in singleDay.food.beverages" :key="index").my-2.w-100
                div(class="d-flex justify-content-between align-items-center" style="gap: 1rem" :ref="'beverage' + index")
                    b-form-input(v-model="beverage.name" type="text" placeholder="Enter beverage" required)
                    b-button(@click.prevent="singleDay.food.beverages.splice(index, 1)" variant="danger") 
                        b-icon(icon="trash")
                
            div(class="d-flex justify-content-between align-items-center" style="gap: 1rem" )
                b-form-input(v-model="tempBeverages" type="text" placeholder="Enter beverage" required)
                b-button(@click.prevent="addBeverageSingle") 
                    b-icon(icon="plus")    
        b-form-group(v-if="!isSingleDay" label="Multiple Days Event" label-for="multipleDays")
            
            div(v-for="(event, index) in multipleDays.events" :key="index").my-2.w-100.p-2.event-card
                p Item {{ index + 1 }} - Provide a title for the day
                div( class="d-flex justify-content-between align-items-center" style="gap: 1rem" )
                    b-form-input(v-model="event.title" type="text" placeholder="Enter event title eg. Day 1" required)
                    
                
                div(class="event-day-data d-flex flex-column my-2" )
                    label Enter Date
                    b-form-input(v-model="event.date" type="date" required).mb-2
                    label Enter Start Time
                    b-form-input(v-model="event.time" type="time" required).mb-2
                    label.mt-2 Itineraries (if any)
                    div(v-for="(itinerary, i) in event.itineraries" :key="i").my-2.w-100
                        div(class="d-flex justify-content-between align-items-center" style="gap: 1rem" :ref="'itinerary' + i")
                            
                            b-form-input(v-model="itinerary.time" type="time" required placeholder="Enter time")
                            b-form-input(v-model="itinerary.description" type="text" placeholder="Enter itinerary" required)
                            b-button(@click.prevent="event.itineraries.splice(i, 1)" variant="danger") 
                                b-icon(icon="trash")
                    div(class="d-flex justify-content-between align-items-center" style="gap: 1rem" )
                        b-form-input(v-model="tempTime" type="time" required)
                        b-form-input(v-model="tempDesc" type="text" placeholder="Enter itinerary" required)
                        b-button(@click.prevent="addItineraryMultiple(index)")
                            b-icon(icon="plus")
                    label.mt-4 Lunch Items (if any)
                    div(v-for="(lunchItem, i) in event.food.lunch" :key="i").mb-2.w-100
                        div(class="d-flex justify-content-between align-items-center" style="gap: 1rem" :ref="'lunch' + i")
                            b-form-input(v-model="lunchItem.name" type="text" placeholder="Enter lunch item" required)
                            b-button(@click.prevent="event.food.lunch.splice(i, 1)" variant="danger") 
                                b-icon(icon="trash")
                    div(class="d-flex justify-content-between align-items-center" style="gap: 1rem" ).mt-2
                        b-form-input(v-model="tempLunchFood" type="text" placeholder="Enter lunch item" required)
                        b-button(@click.prevent="addLunchToMultiple(index)")
                            b-icon(icon="plus")
                    label.mt-4 Beverages (if any)
                    div(v-for="(beverage, i) in event.food.beverages" :key="i").my-2.w-100
                        div(class="d-flex justify-content-between align-items-center" style="gap: 1rem" :ref="'beverage' + i")
                            b-form-input(v-model="beverage.name" type="text" placeholder="Enter beverage" required)
                            b-button(@click.prevent="event.food.beverages.splice(i, 1)" variant="danger") 
                                b-icon(icon="trash")
                    div(class="d-flex justify-content-between align-items-center" style="gap: 1rem" ).mt-2
                            b-form-input(v-model="tempBeverages" type="text" placeholder="Enter beverage" required)
                            b-button(@click.prevent="addBeverageMultiple(index)")
                                b-icon(icon="plus")
                b-button( @click.prevent="multipleDays.events.splice(index, 1)" variant="danger" style="white-space: nowrap").w-100  Delete Day
            b-button(@click.prevent="addEventToMultipleDays").w-100  Add Day  

                

        b-form-group(label="Price Per Caregiver" label-for="price")
            b-input-group(prepend="$")
                b-form-input(v-model="pricePerCaregiver" id="price" type="number" required)
        b-form-group(label="GST" label-for="gst")
            b-form-checkbox(v-model="editGst" switch).my-2 Edit GST
            b-form-input(v-model="gst" id="gst" type="number" required :disabled="!editGst")
        b-form-group(label="Total" label-for="total")
            b-input-group(prepend="$")
                b-form-input(v-model="calculateTotal" id="total" type="number" required :disabled="true")
    template( #modal-footer)
            b-button(variant="secondary" @click="modalClose") Close
            b-button(v-if="createEventSubmit" class="d-flex align-items-center" variant="success" disabled style="gap: 0.5rem;") 
                b-spinner(small style="color: rgb(124, 53, 159);")
            b-button(variant="success" @click="submit" v-else) Save
            
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
    components: {
        VueEditor
    },
    props: ['show', 'groups','createEventSubmit'],
    emits: ['update:show', 'close','data'],
    data() {
        return {
            customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
                ["link"],
                ["clean"],
            ],
            eventName: '',
            selectedGroup: null,
            description: '',
            isSingleDay: true,
            remarks: '',
            multipleDays: {
                events: []

            },
            singleDay: {    
                date: null,
                time: null,
                itineraries: [],
                food: {
                    lunch: [],
                    beverages: [],
                }
            },
            pricePerCaregiver: 0,
            gst: 1.09,
            editGst: false,
            total: 0,
            tempTime:"",
            tempDesc:"",
            tempLunchFood: "",
            tempBeverages: "",
            




        }
    },
    computed: {
        calculateTotal() {
            let total = this.pricePerCaregiver * this.gst;
            return total.toFixed(2);
        },
        showModal: {
            get() {
                return this.show;
            },
            set(value) {
                this.$emit('update:show', value);
            }
        }
    },
    methods: {
        modalClose() {
            this.eventName = '';
            this.selectedGroup = null;
            this.description = '';
            this.isSingleDay = true;
            this.singleDay = {    
                date: null,
                time: null,
                itineraries: [],
                food: {
                    lunch: [],
                    beverages: [],
                }
            };
            this.multipleDays = {
                events: [{
                    date: null,
                    time: null,
                    itineraries: [],
                    food: {
                        lunch: [],
                        beverages: [],
                    }
                }]
            };
            this.pricePerCaregiver = 0;
            this.gst = 1.09;
            this.editGst = false;
            this.total = 0;
            this.tempTime="";
            this.tempDesc="";
            this.tempLunchFood = "";
            this.tempBeverages = "";

            this.$emit('close');
        },
        addToItineraries() {
            if(!this.tempTime || !this.tempDesc) {
                this.$bvToast.toast(`Please fill in the time and description`, {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 5000,
          appendToast: false
        })
                return
            }
            this.singleDay.itineraries.push({
                time: this.tempTime,
                description: this.tempDesc,
                id: this.singleDay.itineraries.length + 1
            });
            this.tempTime=""
            this.tempDesc=""
            
        },
        addLunchItemSingle() {
        if (!this.tempLunchFood) {
            this.$bvToast.toast(`Please enter a lunch item`, {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false
            });
            return;
        }
        this.singleDay.food.lunch.push({ name: this.tempLunchFood });
        this.tempLunchFood = "";
    },
    addBeverageSingle() {
        if (!this.tempBeverages) {
            this.$bvToast.toast(`Please enter a beverage`, {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false
            });
            return;
        }
        this.singleDay.food.beverages.push({ name: this.tempBeverages });
        this.tempBeverages = "";
    },
    addEventToMultipleDays() {
        this.multipleDays.events.push({
            title: null,
            time: null,
            date: null,
            itineraries: [],
            food: {
                lunch: [],
                beverages: [],
            }
        });
    },
    addItineraryMultiple(index) {
        if(!this.tempTime || !this.tempDesc) {
            this.$bvToast.toast(`Please fill in the time and description`, {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 5000,
          appendToast: false
        })
                return
        }
        this.multipleDays.events[index].itineraries.push({
            time: this.tempTime,
            description: this.tempDesc,
        });
        this.tempTime = "";
        this.tempDesc = "";
    },
    addLunchToMultiple(index) {
        if (!this.tempLunchFood) {
            this.$bvToast.toast(`Please enter a lunch item`, {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false
            });
            return;
        }
        this.multipleDays.events[index].food.lunch.push({ name: this.tempLunchFood });
        this.tempLunchFood = "";
    },
    addBeverageMultiple(index) {
        if (!this.tempBeverages) {
            this.$bvToast.toast(`Please enter a beverage`, {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false
            });
            return;
        }
        this.multipleDays.events[index].food.beverages.push({ name: this.tempBeverages });
        this.tempBeverages = "";
    },
    submit () {
        console.log(this.isSingleDay)
        if(!this.eventName || !this.selectedGroup || !this.description || !this.pricePerCaregiver || !this.gst) {
            this.$bvToast.toast(`Please fill in all the required fields`, {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 5000,
          appendToast: false
        })
            return
        }
        if (this.isSingleDay) {
            if (!this.singleDay.date || !this.singleDay.time) {
                this.$bvToast.toast(`Please fill in the date and time`, {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000,
                    appendToast: false
                });
                return;
            }
            let choice = confirm("Are you sure you want to submit?");
            if (!choice) {
                return;
            }
            this.$emit('data', {
                eventName: this.eventName,
                group: this.selectedGroup,
                description: this.description,
                isSingleDay: this.isSingleDay,
                singleDay: this.singleDay,
                pricePerCaregiver: this.pricePerCaregiver,
                gst: this.gst,
                total: this.calculateTotal,
                remarks: this.remarks
            });
        } else {
            if (this.multipleDays.events.length === 0) {
                this.$bvToast.toast(`Please add at least one day`, {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000,
                    appendToast: false
                });
                return;
            }
            if(this.multipleDays.events.some(event => !event.date || !event.time || !event.title)) {
                console.log(this.multipleDays.events)
                this.$bvToast.toast(`Please fill in the date and time for all days`, {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000,
                    appendToast: false
                });
                return;
            }
            let choice = confirm("Are you sure you want to submit?");
            if (!choice) {
                return;
            }
            this.$emit('data', {
                eventName: this.eventName,
                group: this.selectedGroup,
                description: this.description,
                isSingleDay: this.isSingleDay,
                multipleDays: this.multipleDays,
                pricePerCaregiver: this.pricePerCaregiver,
                gst: this.gst,
                total: this.calculateTotal,
                remarks: this.remarks
            });
        }

        if(!this.createEventSubmit) {
            this.modalClose();
        }
    }
    }
}
</script>

<style lang="sass" scoped>
.event-card
    // shadow
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)
    border: 1px solid #e2e8f0
    border-radius: 0.5rem
</style>
