import { MUTATIONS, ACTIONS } from "./types-admin-dashboard";
import { TABLE } from "../../ModuleName";
import isToday from "dayjs/plugin/isToday";
import isBetween from "dayjs/plugin/isBetween";
import dayjs from "dayjs";
dayjs.extend(isToday);
dayjs.extend(isBetween);

const adminDashboard = {
    namespaced: true,
    state: {
        adminDashboard: {
            listNewClientRegisteredToday: [],
            schedules: [],
            listUpcomingAssessment: [],
            listMasterScehdule: [],
            listWeeklySchedule: [],
            listTodaySchedule: [],
            listClient: [],
            listUpcomingAppointment: [],
            isFetchingAdminDashboardData: false,
        }
    },
    mutations: {
        [MUTATIONS.SET_LIST_NEW_CLIENT_REGISTERED_TODAY]: (state, payload) => {
            state.adminDashboard.listNewClientRegisteredToday = payload;
        },
        [MUTATIONS.SET_SCHEDULES]: (state, payload) => {
            state.adminDashboard.schedules = payload;
        },
        [MUTATIONS.SET_LIST_UPCOMING_ASSESSMENT]: (state, payload) => {
            state.adminDashboard.listUpcomingAssessment = payload;
        },
        [MUTATIONS.SET_LIST_WEEKLY_SCHEDULE]: (state, payload) => {
            state.adminDashboard.listWeeklySchedule = payload;
        },
        [MUTATIONS.SET_LIST_MASTER_SCHEDULE]: (state, payload) => {
            state.adminDashboard.listMasterScehdule = payload;
        },
        [MUTATIONS.SET_LIST_TODAY_SCHEDULE]: (state, payload) => {
            state.adminDashboard.listTodaySchedule = payload;
        },
        [MUTATIONS.SET_LIST_CLIENT]: (state, payload) => {
            state.adminDashboard.listClient = payload;
        },
        [MUTATIONS.SET_LIST_UPCOMING_APPOINTMENT]: (state, payload) => {
            state.adminDashboard.listUpcomingAppointment = payload;
        },
        [MUTATIONS.SET_IS_FETCHING_ADMIN_DASHBOARD_DATA]: (state, payload) => {
            state.adminDashboard.isFetchingAdminDashboardData = payload;
        },
        [MUTATIONS.REMOVE_ITEM_FROM_LIST_APPOINTMENT]: (state, crb5c_fowappointmentid) => {
            state.adminDashboard.listUpcomingAppointment = state.adminDashboard.listUpcomingAppointment
            .filter(item => item.crb5c_fowappointmentid !== crb5c_fowappointmentid);

        },
    },
    actions: {
        [ACTIONS.DB_GET_LIST_NEW_CLIENT_REGISTERED_TODAY]: async ({ rootGetters, commit }) => {
            try {
                const { data: clients } = await rootGetters[`${TABLE}/api`].get("/admin_dashboard/get_client");
                const listNewClientRegisteredToday = clients.filter(item => dayjs(item.createdon).isToday());
                
                commit(MUTATIONS.SET_LIST_NEW_CLIENT_REGISTERED_TODAY, listNewClientRegisteredToday);
                
            } catch (error) {
                console.error(error);
                commit(MUTATIONS.SET_LIST_NEW_CLIENT_REGISTERED_TODAY, []);
            }

        },
        // [ACTIONS.DB_GET_LIST_SCHEDULE]: async ({ rootGetters, commit }) => {
        //     try {

        //     } catch (error) {
        //         console.error(error);
        //     }
        // },
        [ACTIONS.DB_GET_LIST_ASSESSMENT]: async ({ rootGetters, commit }) => {
            try {
                const { data: assessment } = await rootGetters[`${TABLE}/api`].get("/admin_dashboard/get_assessment");
                const mappedAssessments = assessment.map((assessment) =>{
      
                    let clientProgramme = ''
                    if(assessment.crb5c_eq5d5lform === false){
                      clientProgramme = 'FOW'
                    } else{
                      clientProgramme = 'CIP'
                    }
              
                    let dateAssessment = null
                    if(assessment.crb5c_dateofassessment === null){
                      dateAssessment = 'No information'
                    } else{
                      dateAssessment = dayjs(assessment.crb5c_dateofassessment).format('DD-MM-YYYY HH:mm')
                    }
              
                    let currentAssessment = ''
                    if(clientProgramme === 'CIP'){
                      if(assessment.crb5c_cip1stsession === null && assessment.crb5c_cip2ndsession === null){
                      currentAssessment = '14th Session Week'
                      } else{
                        currentAssessment = '6 Months'
                      }
                    }
                    if(clientProgramme === 'FOW'){
                        currentAssessment = '1 Year'        
                      }
                    
                    
                    return{
                      ...assessment,
                      currentAssessment: currentAssessment,
                      clientProgramme: clientProgramme,
                      dateAssessment: dateAssessment
                    }
                  });

                  commit(MUTATIONS.SET_LIST_UPCOMING_ASSESSMENT, mappedAssessments);
                
            } catch (error) {
                console.log(error);
                commit(MUTATIONS.SET_LIST_UPCOMING_ASSESSMENT, []);
            }
        },
        [ACTIONS.DB_GET_LIST_WEEKLY_SCHEDULE]: async ({ rootGetters, commit }) => {
            try {
                const { data: schedules } = await rootGetters[`${TABLE}/api`].get("/admin_dashboard/get_schedule");
                let today = dayjs();

                // console.log("schedules: ", schedules);

                const isInCurrentWeek = (date) => {
                    const now = dayjs();
                    const startOfWeek = now.startOf('week');
                    const endOfWeek = now.endOf('week');
                    return dayjs(date).isBetween(startOfWeek, endOfWeek, null, '[]');
                };
        
                const weekNumber = today.week();

                const modifySchedules = schedules
                .filter((schedule) =>(Boolean(schedule.crb5c_date) && isInCurrentWeek(schedule.crb5c_date)))
                .map((schedule) => {
                    const durationInMin = schedule.crb5c_duration
                    const hour = Math.floor(durationInMin/60)
                    const minute = durationInMin % 60
                    let formattedDuration = ''
                    if(hour > 0){
                        formattedDuration += `${hour}hr`
                    }
                    if(minute > 0){
                        formattedDuration += ` ${minute}min`
                    }

                    return {
                        ...schedule,
                        weekNumber: weekNumber,
                        today: today,
                        currentDateTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                        formattedDuration: formattedDuration,
                        // formattedDate: dayjs(schedule.crb5c_date).format('DD MMM YYYY')
                        crb5c_date: schedule.crb5c_date,
                    }
                });

                const listTodaySchedule = modifySchedules.filter(item => dayjs(item.crb5c_date).isToday());

                const sortModifySchedulesBasedOnDate = modifySchedules.sort((a, b) => {
                    const dateA = dayjs(a.crb5c_date);
                    const dateB = dayjs(b.crb5c_date);
                  
                    // Use the `isBefore` method to compare dates
                    if (dateA.isBefore(dateB)) {
                      return -1;
                    } else if (dateA.isAfter(dateB)) {
                      return 1;
                    } else {
                      return 0;
                    }
                });

                commit(MUTATIONS.SET_LIST_TODAY_SCHEDULE, listTodaySchedule);
                commit(MUTATIONS.SET_LIST_WEEKLY_SCHEDULE, sortModifySchedulesBasedOnDate);

            } catch (error) {
                console.error(error);
                commit(MUTATIONS.SET_LIST_WEEKLY_SCHEDULE, []);
            }
        },
        [ACTIONS.DB_GET_LIST_MASTER_SCHEDULE]: async ({ rootGetters, commit }) => {
            try {
                const { data: schedules } = await rootGetters[`${TABLE}/api`].get("/admin_dashboard/get_session_schedule");

                // console.log("schedules: ", schedules);

                const mappedSchedules = schedules.map((schedule) => {
                    const durationInMin = schedule.crb5c_duration
                    const hour = Math.floor(durationInMin / 60)
                    const minutes = durationInMin % 60
                
                    let formattedDuration = ''  
                    if (hour > 0){
                        formattedDuration += `${hour}hr`
                    }
                    if (minutes > 0){
                        formattedDuration += ` ${minutes}min`
                    }
        
                    // let formattedDay = ''
                    const sessionNameSplit = schedule.crb5c_session_id.split(' ')
                    const day = sessionNameSplit[0].split('').slice(0,3).join("")
                
                    return {
                        ...schedule,
                        formattedDuration: formattedDuration,
                        formattedCreateOn: dayjs(schedule.createdon).format('DD MMM YYYY'),
                        day: day
                    };
                });

                // console.log("mappedSchedules: ", mappedSchedules);

                commit(MUTATIONS.SET_LIST_MASTER_SCHEDULE, mappedSchedules);
                
            } catch (error) {
                console.error(error);
                commit(MUTATIONS.SET_LIST_MASTER_SCHEDULE, []);
            }
        },
        [ACTIONS.DB_GET_LIST_CLIENT]: async ({ rootGetters, commit }) => {
            try {
                const { data: clientData } = await rootGetters[`${TABLE}/api`].get("/admin_dashboard/get_client");
                const { data: assessmentData } = await rootGetters[`${TABLE}/api`].get("/admin_dashboard/get_assessment");
                const { data: caregiverData } = await rootGetters[`${TABLE}/api`].get("/admin_dashboard/get_caregiver");

                const mappedClient = clientData.map((client) => {
                    const clientStatus = client.crb5c_client_status
                    let status = ''
                    if(clientStatus === 411620000){
                        status = 'Active'
                    }
                    else if(clientStatus === 411620001){
                        status = 'Pending'
                    }
                    else if(clientStatus === 411620002){
                        status = 'Discharge'
                    } else{
                        status = 'Inactive'
                    }
    
    
                    const clientProgramme = client.crb5c_cipmember
                    let programme = ''
                    if(clientProgramme === true){
                        programme = 'CIP'
                    } else {
                        programme = 'FOW'
                    }
                    return{
                        ...client,
                        status: status,
                        programme: programme,
                        formattedCreateOn: dayjs(client.createdon).format('DD MMM YYYY')
                    }
                    },
    
                    assessmentData.map((assessment) =>{
                        
                        return{
                            ...assessment
                        }
                    },
                    caregiverData.map((caregiver) => {
                        
                        let caregiverId = caregiver.crb5c_fow_caregiverid
    
                        return{
                            caregiverId: caregiverId,
                            ...caregiver
                        }                    
                    })));


                commit(MUTATIONS.SET_LIST_NEW_CLIENT_REGISTERED_TODAY, mappedClient.filter(item => item.status === "Pending"));
                commit(MUTATIONS.SET_LIST_CLIENT, mappedClient);

            } catch (error) {
                console.log(error);
                commit(MUTATIONS.SET_LIST_CLIENT, []);
            }
        },
        [ACTIONS.SEND_EMAIL_REMINDER_ASSESSMENT]: async ({ rootGetters }, payload) => {
            try {
                const { data } = await rootGetters[`${TABLE}/api`].post("/admin_dashboard/sendEmailAssessment", payload);

                return data;
                
            } catch (error) {
                console.error(error);
            }
        },
        [ACTIONS.DB_GET_APPOINTMENT]: async ({ rootGetters, commit }) => {
            try {
                const { data } = await rootGetters[`${TABLE}/api`].get("/admin_dashboard/addAssessmentAppointment");
                
                commit(MUTATIONS.SET_LIST_UPCOMING_APPOINTMENT, data);

            } catch (error) {
                console.error(error);
            }
        },
        [ACTIONS.DB_ADD_APPOINTMENT]: async ({ rootGetters }, payload) => {
            try {
                const { data } = await rootGetters[`${TABLE}/api`].post("/admin_dashboard/addAssessmentAppointment", payload);
                
                return data;

            } catch (error) {
                console.error(error);
                return null
            }
        },
        // eslint-disable-next-line no-unused-vars
        [ACTIONS.DB_DELETE_APPOINTMENT]: async ({ rootGetters, commit }, crb5c_fowappointmentid) => {
            try {
                // const { data } = await rootGetters[`${TABLE}/api`].delete("/admin_dashboard/addAssessmentAppointment", {
                //     data: {
                //         crb5c_fowappointmentid,
                //     }
                // });
                // console.log(data);
                console.log("crb5c_fowappointmentid: ", crb5c_fowappointmentid);
                
                commit(MUTATIONS.REMOVE_ITEM_FROM_LIST_APPOINTMENT)(crb5c_fowappointmentid);

            } catch (error) {
                console.error(error);
                return null;
            }

        },
        // eslint-disable-next-line no-unused-vars
        [ACTIONS.DB_GET_SESSION_ATTENDANCE]: async ({ rootGetters }, { isDownloadingFullReport, dateFromIsoString, dateToIsoString }) => {
            try {
                const params = new URLSearchParams({
                    isDownloadingFullReport,
                    dateFromIsoString, 
                    dateToIsoString
                });

                const { data } = await rootGetters[`${TABLE}/api`].get(`/admin_dashboard/report/allAttendance?${params.toString()}`);
                
                return data;

            } catch (error) {
                console.error(error);
                return null;
            }
        },
        [ACTIONS.DB_GET_FIRST_AND_LAST_SESSION]: async ({ rootGetters }) => {
            try {
                const { data } = await rootGetters[`${TABLE}/api`].get(`/admin_dashboard/report/getFirstAndLastSession`);
                
                return data;

            } catch (error) {
                console.error(error);
            }
        }
    }
}

export default adminDashboard;