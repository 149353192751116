<template lang="pug">
div(class="event-tab d-flex flex-column")
    div().text-left
        div.d-flex
            b-form-file.my-2.px-3(
            v-model="newImage"
            @change="handleFileChange"
            placeholder="Click browse to pick new image..."
            drop-placeholder="Drop file here..."
            accept=".jpg,.jpeg,.png")
        div.d-flex.justify-content-center.my-3
            b-spinner(v-if="!image")
            b-img(v-if="image" :src="image" width="500px" style="cursor: pointer;" alt="Showscreen")
    b-button(variant="success" v-if="newImage" @click='addAdvertImage()') Save
        
</template>

<script>
import axios from 'axios'
export default {
    data () {
        return{
        newImage: null,
        existingImage: null,
        }
    },
    computed:{
        image(){
            if (this.newImage) {
                return URL.createObjectURL(this.newImage); 
            }else if (this.existingImage) {
                return this.existingImage;
            }
            return null;
        },
    },
    methods:{
        handleFileChange(e){
            this.newImage = e.target.files[0];
        },
        async addAdvertImage(){
            if (!this.newImage) {
                alert('Please choose an image!');
                return;
            }

            const formData = new FormData()
            formData.append('file', this.newImage)
            console.log(this.existingImage.id)
            formData.append('existingImageId', this.existingImage.id);

            console.log(formData)
            const response = await axios.put('/api/sharepoint', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            console.log(response)
        },
    },
    async mounted() {
            try {
              let response = await axios.get('/api/sharepoint');
              if (response.data) {
                  const existingImage = response.data.data.value[0];
                  const downloadUrl = existingImage['@microsoft.graph.downloadUrl'];
                  if (downloadUrl) {
                      let image = await axios.get(downloadUrl, { responseType: 'blob' });
                      const imageUrl = URL.createObjectURL(image.data);
                      this.existingImage = imageUrl;
                  }
              }
          } catch (err) {
              console.error("Error fetching image", err);
          }
    },
}
</script>

<style lang="scss" scoped>

</style>