<template lang="pug">
b-container(style="height: 85%")

  b-modal(id="cip-status" title="Sort CIP or Non-CIP")
    template
      b-col 
        b-form-group(label="Can select more than one : " v-slot="{ ariaDescribedby }")
          b-form-checkbox-group.mt-3(style="display: flex; flex-direction: column; gap: 0.75rem" v-model="listCIPModalSelected" :options="cipStatus" :aria-describedby="ariaDescribedby" name="CipStatus") 
    template(#modal-footer)
      div(style="width: 100%; display: flex; justify-content: space-between")
        b-button.px-5(variant="success" style="font-weight: 500" @click="$bvModal.hide('cip-status')") OK
        //- b-button.px-4(variant="danger" style="font-weight: 500" @click="$bvModal.hide('cip-status')") Close
  
  b-modal(id="type-appointment" title="Sort Type of Assessment")
    template
      b-col 
        b-form-group(label="Can select more than one : " v-slot="{ ariaDescribedby }")
          b-form-checkbox-group.mt-3(style="display: flex; flex-direction: column; gap: 0.75rem" v-model="listAppointmentsSelected" :options="AppointmentStatus" :aria-describedby="ariaDescribedby" name="AppointmentStatus") 
    template(#modal-footer)
      div(style="width: 100%; display: flex; justify-content: space-between")
        b-button.px-5(variant="success" style="font-weight: 500" @click="$bvModal.hide('type-appointment')") OK
        //- b-button.px-4(variant="danger" style="font-weight: 500" @click="$bvModal.hide('type-appointment')") Close

  
  b-modal(
    :id="modalEmailMessage.id" 
    title="Email Message"
    body-class="position-static"
    no-close-on-backdrop
  )
    b-overlay(no-wrap :show="isSendEmail")
      template(#overlay)
    template
      b-row
        b-col.col-12
          b-form-group(
            id="form-group-email-message"
            label="Enter email message"
          )
            b-form-textarea(
              id="email-message-input"
              placeholder="Enter something..."
              v-model="modalEmailMessage.inputEmailMessage"
            )

    template(#modal-footer)
      b-button.px-4(variant="success" style="font-weight: 500;" @click="sendEmailToClient") Send
      b-button.px-4(variant="danger" style="font-weight: 500;" @click="closeEmailMessageModal") Close

  
  //- b-modal(
  //-     :id="sendModal.modalClientId" 
  //-     title="Send Email Confirmation"
  //-     body-class="position-static"
  //-     no-close-on-backdrop
  //-   )
  //-   b-overlay(no-wrap :show="isSendEmail")
  //-     template(#overlay)
  //-   template
  //-     b-col(v-for="client in sendModal.data" :key="client.crb5c_clientid")
  //-       b-row(style="font-weight: 600") Are you sure you want to send this email?  
  //-       b-row {{ client.crb5c_clientname }}
  //-         //- b-col(cols="12")
  //-           li.mt-3(style="font-weight: 400") {{ client.clientName }}
  //-   template(#modal-footer)
  //-     b-button.px-4(variant="success" style="font-weight: 500;" @click="sendEmailToClient") Send
  //-     b-button.px-4(variant="danger" style="font-weight: 500;" @click="closeSendConfirmationModal") Close

  b-col.mt-5
    b-row.mb-3
      b-col.col-8(style="")
        h4.d-flex.justify-content-start.align-items-center Upcoming Assessment
      b-col.col-4(style="d-flex justify-content-center align-items-center")
        b-row(class="" style="height: 1%; margin-right: 0.1rem;")
          b-col.col-12(class="d-flex align-items-center" style="position: relative;")
            BIconSearch(style="position: absolute; left: 30px")
            b-input(class="pr-1 pl-5 border border-dark d-flex justify-content-center align-items-center" style="border-radius: 20px;" placeholder="Search Name" type="text" v-model="inputSearchItemList")

    b-row
      b-col.col-12(style="max-height: 20rem; overflow-y: auto;")
        b-table(
          :fields="tableFields" 
          :items="clientsList"
          class="table-header-color"
          thead-tr-class="text-white"
          tbody-tr-class="bg-white"
          show-empty
        )
          template(#empty="scope")
            b-row(v-if="isFetchingAdminDashboardData")
                b-col.col-12
                    b-spinner(variant="info")
            b-row(v-else)
                b-col.col-12
                    span No data
          template(#cell(name)="data")
            span {{ data.item.crb5c_clientname }}
          template(#cell(Programme)="data")
            span {{ data.item.clientProgramme }}
          template(#cell(typeOfAssessment)="data")
            span {{ data.item.currentAssessment }}
          template(#cell(dateLastAssessment)="data")
            span {{ data.item.crb5c_dateofassessment ? formatDate(data.item.crb5c_dateofassessment) : "No Data" }}
          //- template(#cell(caregiverEmail)="data")
          //-   span {{ data.item.caregiverEmail ?? "-" }}
          //- template(#cell(Reminder)="data")
          //-   //- b-button.font-weight-bold(size="sm" style="font-size: 0.75rem" :class="{ 'enable-button': !isButtonDisabled[data.item.crb5c_clientid], 'disabled-button': isButtonDisabled[data.item.crb5c_clientid] }" variant="info" @click="openConfirmationSendModal(data.item.crb5c_clientid)") {{ isButtonDisabled[data.item.crb5c_clientid] ? 'Sent' : 'Send Email' }}
          //-   b-button.font-weight-bold(size="sm" style="font-size: 0.75rem" variant="info" @click="openEmailMessageModal(data.item.crb5c_clientid, data.item.caregiverEmail)") Send Email
   
    b-row
      b-col.col-12.py-2.text-left
        span Total upcoming assessment: {{ clientsList.length }}

</template>

<script>
import dayjs from 'dayjs';
import { faker } from '@faker-js/faker';
import { mapState, mapActions } from 'vuex';
import { ADMIN_DASHBOARD_NAMESPACE, ACTIONS } from "../../store/Modules/AdminDashboard/types-admin-dashboard.js"

export default {
  data() {
    return {
      isSendEmail: false,
      inputSearchItemList: '',
      currentPage: 0,
      pageSize: 2,
      visibleList: [],
      clientAsessmentDB: [],
      careGiverDB: [],
      tableFields: [
        { 
          key: 'name', 
          label: 'Name',
        }, 
        'Programme', 
        { 
          key: 'typeOfAssessment', 
          label: 'Type Assessment' 
        }, 
        { 
          key: 'dateLastAssessment', 
          label: 'Date Last Assessment'
        }, 
        // {
        //   key: 'caregiverEmail', 
        //   label: 'Caregiver Email'
        // },
        // 'Reminder',
      ],
      clients: [
        { 
          id: 1, 
          clientName: 'Alli Rani DO K R Thangavelu', 
          clientCipStatus: 'FOW', 
          clientTypeAppointment: '1 Year', 
          clientAppointmentDate: '10 July 2023, 11am',
          status: "completed"
        },
        { 
          id: 2, 
          clientName: 'Ang Boy Koh', 
          clientCipStatus: 'CIP', 
          clientTypeAppointment: '6 Months', 
          clientAppointmentDate: '29 September 2023, 2pm',
          status: "pending"
        },
        { 
          id: 3, 
          clientName: 'Chan Maggie', 
          clientCipStatus: 'CIP', 
          clientTypeAppointment: '14th Session Week', 
          clientAppointmentDate: '16 May 2023, 10am',
          status: "inProgress"
        },
        { 
          id: 4, 
          clientName: 'Chan Yin Yin', 
          clientCipStatus: 'CIP', 
          clientTypeAppointment: '14th Session Week', 
          clientAppointmentDate: '29 June 2023, 11am',
          status: "completed"
        },
        { 
          id: 5, 
          clientName: 'Dorothy Soh Pheck Ha', 
          clientCipStatus: 'FOW', 
          clientTypeAppointment: '1 Year', 
          clientAppointmentDate: '29 July 2023, 10am',
          status: "pending"
        },
        { 
          id: 6, 
          clientName: 'Ee Hoe Lee', 
          clientCipStatus: 'FOW', 
          clientTypeAppointment: '1 Year', 
          clientAppointmentDate: '15 August 2023, 2pm',
          status: "inProgress"
        },
        { 
          id: 7, 
          clientName: 'Kang Ah Hong', 
          clientCipStatus: 'CIP', 
          clientTypeAppointment: '14th Session Week', 
          clientAppointmentDate: '29 August 2023, 3pm',
          status: "inProgress"
        },
        { 
          id: 8, 
          clientName: 'Lai Hoew Min Fanny', 
          clientCipStatus: 'FOW', 
          clientTypeAppointment: '1 Year', 
          clientAppointmentDate: '3 September 2023, 11am',
          status: "inProgress"
        },
      ],
      cipStatus: [
        { 
          text: 'CIP',
          value: 'CIP' 
        },
        { text: 'FOW',
          value: 'FOW' 
        },
      ],
      AppointmentStatus: [
        { 
          text: '14th Session Week',
          value: '14th Session Week' 
        },
        { 
          text: '6 Months', 
          value: '6 Months' 
        },
        { 
          text: '1 Year', 
          value: '1 Year' 
        },
      ],
      isActive: true,
      modalEmailMessage: {
        id: null,
        inputEmailMessage: null,
        caregiverEmail: null,
      },
      sendModal: {
        modalClientId: null,
        data: null,
      },
      currentClient: null,
      isButtonDisabled: {},
      listCIPModalSelected: [],
      listAppointmentsSelected: [],
      isSent: {},
    }
  },
  created() {
    // Set selectedAll with all values from cipStatus initially
    this.listCIPModalSelected = this.cipStatus.map(option => option.value);
    this.listAppointmentsSelected = this.AppointmentStatus.map(option => option.value)
  },
  computed: {
    ...mapState({
          listUpcomingAssessment: state => (state[ADMIN_DASHBOARD_NAMESPACE].adminDashboard.listUpcomingAssessment),
          isFetchingAdminDashboardData: state => (state[ADMIN_DASHBOARD_NAMESPACE].adminDashboard.isFetchingAdminDashboardData),
      }),
      // clientsList(){
      //   if(this.listCIPModalSelected.length !== 0){
      //       const result = this.clients.filter(item => this.listCIPModalSelected.includes(item.clientCipStatus));
      //       console.log("Result: ", result);
      //       return result
      //     }
      //   if(this.listAppointmentsSelected.length !== 0){
      //       const result = this.clients.filter(item => this.listAppointmentsSelected.includes(item.clientTypeAppointment))
      //       console.log("Result: ", result)
      //       return result
      //   }
      //   if(this.inputSearchItemList.trim().length > 0){
      //     return this.clients.filter((client) => client.clientName.toLocaleLowerCase().includes(this.inputSearchItemList.trim()))
      //   }else{
      //     return this.clients
      //   }
      // }
    clientsList() {
      let filteredClients = this.listUpcomingAssessment;

      if(!filteredClients){
        return [];
      }

      if (this.listCIPModalSelected !== 0) {
        filteredClients = filteredClients.filter(item => this.listCIPModalSelected.includes(item.clientProgramme));
      }
      // if (this.listAppointmentsSelected !== 0) {
      //   filteredClients = filteredClients.filter(item => this.listAppointmentsSelected.includes(item.clientTypeAppointment));
      // }
      if (this.inputSearchItemList.trim().length > 0) {
        const searchName = this.inputSearchItemList.trim().toLowerCase();
        filteredClients = filteredClients.filter((client) => client.crb5c_clientname.toLowerCase().includes(searchName));
      }

      return filteredClients;
      
    }
  },
  beforeMount: function(){
    this.updateVisibleList()
  },
  methods: {
    ...mapActions(ADMIN_DASHBOARD_NAMESPACE, [
      ACTIONS.SEND_EMAIL_REMINDER_ASSESSMENT,
    ]),
    generateFakeEmail(){
      return faker.internet.email();
    },
    formatDate(rawDate){
      return dayjs(rawDate).format('DD MMM YY, hh:mm A');

    },
    updatePage(pageNumber){
      this.currentPage = pageNumber
      this.updateVisibleList()
    },

    updateVisibleList(){
      this.visibleList = this.clients.slice(this.currentPage * this.pageSize, (this.currentPage * this.pageSize) + this.pageSize);

      // if have 0 list client, go back a page
      if(this.visibleList.length === 0 && this.currentPage > 0){
        this.updatePage(this.currentPage - 1)
      }
    },
    backAdminHomepage() {
      this.$router.push({ path: '/adminhomepage' })
    },
    removeCurlyBracesFromStringClientId(clientId){
      return clientId.replace(/[{}]/g, '');

    },
    openEmailMessageModal(clientId, caregiverEmail){
      if(!caregiverEmail){
        alert("Caregiver email is missing");
        return;
      }
      this.modalEmailMessage.caregiverEmail = caregiverEmail;
      this.modalEmailMessage.id = `modalEmailMessage/${this.removeCurlyBracesFromStringClientId(clientId)}`;
      this.$nextTick(() => {
        this.$bvModal.show(this.modalEmailMessage.id)
      });

    },
    closeEmailMessageModal(){
      this.$bvModal.hide(this.modalEmailMessage.id);
      this.modalEmailMessage.id = null;
      this.modalEmailMessage.inputEmailMessage = null;
      this.modalEmailMessage.caregiverEmail = null;

    },
    async sendEmailToClient(){
      try {
        // const clientId = this.modalEmailMessage.id.split("/")[1];
        const staffEmail = this.$store.state.currentAdmin.username;
        const caregiverEmail = this.modalEmailMessage.caregiverEmail;
        const emailSubject = "Assessment Reminder";
        const emailMessage = this.modalEmailMessage.inputEmailMessage;

        if(!emailMessage || emailMessage === ""){
          alert("Please enter message!");
          return;
        }

        this.isSendEmail = true;

        // console.log(staffEmail);
        // console.log(caregiverEmail);
        // console.log(emailMessage);
  
        this.isSendEmail = true;
  
        const data = await this[ACTIONS.SEND_EMAIL_REMINDER_ASSESSMENT]({
          staffEmail,
          caregiverEmail,
          emailSubject,
          emailMessage,
        });
  
        if(!data){
          throw new Error("No data");
        }

        // // this.isButtonDisabled[clientId] = true;
        
        this.closeEmailMessageModal();
        this.isSendEmail = false;
        
      } catch (error) {
        console.error(error);
        this.closeEmailMessageModal();
        this.isSendEmail = false;
        
      }

    },
    moveToHomePage(){
      this.$router.push({ path: "/" })
    },
  },
  async mounted(){}
};

</script>

<style scoped>
.enabled-button {
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.disabled-button {
  background-color: inherit;
  color: black;
  border: none;
  cursor: not-allowed;
}

.table-header-color{
  background-color: #50276D;
}

.table__body{
    text-align: center; 
    max-height: 250px; 
    overflow-y: scroll; 
    border-collapse: separate; 
    border-spacing: 0px;
}

.table__body::-webkit-scrollbar{
    width: 0.5rem;
    height: 0.5rem;
}

.table__body::-webkit-scrollbar-thumb{
    border-radius: 0.5rem;
    background-color: #0004;
}
</style>

