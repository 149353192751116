<template lang="pug">
div(class="chat-main-container")
    Header(title="FOW Message" :isAdmin="isAdmin")
    div(class="chat-body")
        div(v-bind:class="{'hidden': this.$route.params.id}"
            class="chat-header")
            div(class="chat-header-search")
                ModalContactList(:modalStateUserChat="stateUserChat" @close-modal="hideModal") 
                h2(class="hidden") Chat
                b-input-group(size="sm" class="w-75 h-75 search-input")
                    b-form-input(v-model="inputSearchMessage" placeholder="Search"
                    class="h-100 border-0"
                    )
                    b-input-group-append(class="ml-2 d-flex align-items-center")
                        b-icon(icon="search")
                b-button(:style="`background-color: ${activeBtnColor}`" @click="openModal" class="button-add-chat")
                    b-icon(icon="plus")
            div( class="chat-list"
            )
                div(class="chat-list-filter  d-flex justify-content-between align-items-start" style="gap: 1rem;")
                    p(style="width:fit-content; white-space:nowrap") Filter by Group:
                    v-select(style="width:90%" :options="unselectedGroups" :reduce="s => ({id: s.id, name: s.name})" v-model="filteringGroup" multiple label="name")

                p(class="text-left my-2") Chat List
                b-list-group(class="overflow-auto")
                    div(v-if="loading")
                        b-spinner(type="grow" variant="primary")
                    div(v-if="listMessages.length === 0 && !isLoadingTwilio")
                        p No chat available
                    b-list-group-item(v-if="!isLoadingTwilio && listMessages.length !== 0" v-for="contact in listMessages" :key="contact.id" @click="moveToMessageChat(contact.id)" class=" list-chat m-1" :style="contactStyle(contact)" )
                        div(v-if="contact.countNewMessage !== 0" class="new-message" )
                            p {{ contact.countNewMessage }}
                        div(class="chat-list-item")
                            b-img(class="d-block d-sm-none" v-bind="userImgSettings(contact.photo, true)" rounded="circle" alt="user image")
                            b-img(class="d-none d-sm-block" v-bind="userImgSettings(contact.photo)" rounded="circle" alt="user image")

                            b-col(class="chat-list-item-content w-100  d-flex flex-column ")
                                div(class="d-flex justify-content-between align-items-center mb-2")
                                    p(class="font-weight-bold m-0 small" style="text-align: start;"
                                    ) {{ contact.name }} 
                                    p(class="small m-0" style="text-align: end;") {{ lastMessageTime(contact) }}
                                p(class="small m-0" style="text-align: start;") {{ contact.message[contact.message.length - 1].message }}
            


        div(class="chat-content d-flex flex-column")
            div(class="back-btn" v-if="this.$route.params.id")
                b-button( @click="backToList" class="d-flex align-items-center justify-content-center") 
                    b-icon(icon="arrow-left ")
                    | Back
            div(class="chat-header-name d-flex align-items-center" v-if="this.$route.params.id" :style="` background-color: ${btnColor}`") 

                b-img( v-bind="userImgSettings(messageData.photo, true)" class="" rounded="circle" alt="user_image")


                div(class="text-white")
                    h5(class="d-block text-white d-sm-none small font-weight-bold" ) {{ messageData.name }}
                    h5(class="d-none text-white d-sm-block small font-weight-bold" ) {{ messageData.name }}
            div(class="chat-message d-flex flex-column" v-if="this.$route.params.id")
                div(class="chat-content-body autoScroll h-100 overflow-auto py-2")

                    div(v-if="messageData.message" v-for="message in messageData.message" 
                        class="chat-container")
                        div(v-if="message.stateChat === myParticipantId" class="chat-content-receiver")
                            p  {{ message.message }}
                            p  {{ formatTime(message.time) }}
                        div(v-else class="chat-content-sender")
                            p {{ message.message }}
                            p {{ formatTime(message.time) }}
                    div(v-if="messageData.message.length === 0" class="d-flex justify-content-center align-items-center h-100")
                        p Send a message to start chatting

                div(class="chat-content-footer ")

                    b-form-input(v-model="messageInput" placeholder="Type a message"
                        class=" border-1"
                        @keyup.enter="sendMessage"
                        )

                    b-button(:style="`background-color: ${sendingMessage ? notActiveBtnColor : activeBtnColor}`" :disabled="messageInput === '' || sendingMessage" @click="sendMessage" class="button-add-chat")
                        b-icon(icon="arrow-right" v-if="!sendingMessage")
                        b-spinner(v-else)
            div(class="select-chat   " v-else)
                p Please select a chat to start messaging
            
</template>
<script>
import axios from 'axios';
import DSG_logo from "@/assets/Images/DSG_logo.png";
import { Icon } from "@iconify/vue2";
import { MESSAGE } from "@/store/ModuleName";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import ModalContactList from "@/components/ModalContactList.vue";
import moment from "moment";
import Header from '../components/Header.vue';
import {
    DATE_PATTERN_FORMAT,
    TIME_PATTERN_FORMAT,
    formatTimeFromDate,
} from "@/assets/Utilities/helperFunction";


export default {
    name: "Messagepage",
    data() {
        return {
            groupList: [],
            fetchingGroupList: '',
            filteringGroup: [],
            DSG_logo,
            activeBtnColor: "#903EB8",
            btnClickedColor: "#7C359F",
            notActiveBtnColor: "#D9D9D9",
            choiceStateUserChat: ["admin", "programme"],
            stateUserChat: "admin",
            stateContactListSort: "",
            stateSort: "",
            cloneContactList: [],
            contactHasMessageList: [],
            inpurSearchContactList: "",
            inputSearchMessage: "",
            resultSearchContactList: [],
            resultSearchMessageList: [],
            btnColor: "rgb(118, 80, 137)",
            receiverColor: "#d9d9d9",
            inputChatColor: "#858585",
            user: this.$route.params.typeUser,
            id: this.$route.params.id,
            loading: false,
            messageInput: "",
            sendingMessage: false,
            messageData: [
                {
                    time: "",
                    message: "",
                    stateChat: "",
                },
            ],
        };
    },
    components: {
        Icon,
        ModalContactList,
        Header
    },
    computed: {
        contactStyle() {
            return (contact) => {
                const isMyParticipant = `${this.contactIdSplit(contact.id)[0]}|${this.contactIdSplit(contact.id)[1]}` === this.myParticipantId;
                return {
                    backgroundColor: isMyParticipant ? '#D9D9D9' : 'white',
                    color: 'black'
                };
            };
        },
        myParticipantId() {
            let idSplit = this.$route.params.id?.split('|');
            return idSplit ? `${idSplit[0]}|${idSplit[1]}` : null;
        },
        ...mapState(["currentAdmin"]),
        ...mapState(MESSAGE, ["contactList"]),
        listMessage: {
            get() {
                const admin = this.$store.state[MESSAGE].contactList.admin;
                console.log('admin', admin)
                return admin.find(item => item.id === this.$route.params.id);
            },
            // set: function(value) {

            // }
        },
        unselectedGroups() {
            return this.groupList?.filter(g => !this.filteringGroup.find(tg => tg.id === g.id)) || this.groupList
        },
        ...mapState(["isLoadingTwilio", "isAdmin"]),
        ...mapGetters(MESSAGE, [
            "getAdminMessage",
            "getAdminTotalCountMessage",
            "getProgrammeStaffTotalCountMessage",
        ]),
        listMessages: {
            get() {
                this.setLoading(true)
                let admin = this.$store.state[MESSAGE].contactList.admin;
                // console.log('raw admin',admin)

                admin = this.countNewMessageCaregiver(admin);

                const totalNewMessage = admin.map(item => item.countNewMessage).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                this['setCountTotalCaregiverNewMessage'](totalNewMessage);

                admin = admin.filter(item => item.message.length !== 0);
                // console.log('admin',admin)

                admin.sort((a, b) => {
                    if (a.message.length && !b.message.length) {
                        return -1;
                    }

                    if (!a.message.length && b.message.length) {
                        return 1;
                    }

                    if (a.message.length && b.message.length) {
                        let lastMessageTimeA = a.message?.[a.message.length - 1]?.time;
                        let lastMessageTimeB = b.message?.[b.message.length - 1]?.time;
                        if (lastMessageTimeA >= lastMessageTimeB) {
                            return -1;
                        } else {
                            return 1;
                        }
                    }

                    return 0;
                });
                let filtered = [];
                admin.forEach(a => {
                    let cli_id = a.id.split('|')[1];

                    for (let g of this.groupList) {
                        // console.log('g',g)
                        if (this.filteringGroup.find(f => f.id === g.id)) {
                            for (let cli of g.clients) {
                                if (cli.crb5c_fow_caregiverid === cli_id) {
                                    filtered.push(a);
                                    // console.log('filtered',filtered)
                                }
                            }
                        }
                    }
                });
                const filtering = this.filteringGroup.length !== 0 ? true : false;
                this.setLoading(false)
                return filtering ?
                    filtered.filter(item => item.name.toLowerCase().includes(this.inputSearchMessage.toLowerCase())) :
                    admin.filter(item => item.name.toLowerCase().includes(this.inputSearchMessage.toLowerCase()))
            },

            set: function (value) {
                this.contactHasMessageList = value;
                this.setLoading(false)

            },

        },
    },
    methods: {
        backToList() {
            this.$router.replace({ path: "/chat" });
        },
        contactIdSplit(id) {
            return id.split('|');
        },

        setLoading(value) {
            this.loading = value;
        },
        ...mapMutations(MESSAGE, ["sendProgrammeStaffMessage"]),
        ...mapActions(MESSAGE, ["sendAdminMessage"]),
        getAdminName() {
            return this.currentAdmin.name;

        },
        userImgSettings(photo, isPhoneScreen = false) {
            let obj;

            if (isPhoneScreen) {
                obj = {
                    width: 50,
                    height: 50,
                }
                photo ? obj.src = `data:image/png;base64,${photo}` : (obj.blank = true, obj.blankColor = '#777');

                return obj;
            }

            obj = {
                width: 70,
                height: 70,
                class: "my-2",
            }

            photo ? obj.src = `data:image/png;base64,${photo}` : (obj.blank = true, obj.blankColor = '#777');

            return obj;

        },
        formatTime(date) {
            return formatTimeFromDate(date);
        },
        moveToHomePage() {
            this.$router.push({ path: "/" });
        },
        moveToPreviousPage() {
            this.$router.push({ path: "/messages" });
        },
        sendMessage() {
            if (this.messageInput === "") {
                return;
            }
            this.sendingMessage = true
            const timeMessage = moment()
                .format(
                    `${DATE_PATTERN_FORMAT} ${TIME_PATTERN_FORMAT}`
                )
                .toString();

            const message = {
                time: timeMessage,
                stateChat: "sender",
                message: this.messageInput,
            };

            let idFromRoute = this.$route.params.id;

            if (this.user === this.choiceStateUserChat[0]) {

                const splittedSenderId = idFromRoute.split('|');
                const senderId = `${splittedSenderId[1]}|${splittedSenderId[0]}`;
                this.sendAdminMessage({
                    id: idFromRoute,
                    chatRoomId: idFromRoute,
                    senderId,
                    msg: this.messageInput,
                    admin: this.$store.state?.currentAdmin?.name
                });
            }

            if (this.user === this.choiceStateUserChat[1]) {
                this.sendProgrammeStaffMessage({
                    id: idFromRoute,
                    message,
                    admin: this.$store.state?.currentAdmin?.name
                });
            }

            this.scrollChatToBottom();
            this.sendingMessage = false
            this.messageInput = "";
        },
        scrollChatToBottom() {
            const chatHolder = document.querySelector(".autoScroll");
            if (chatHolder) {
                    chatHolder.scrollTop = chatHolder.scrollHeight;
            }
        },
        // TODO: stored previous messages to localstorage - for caching purpose, if there's changes, update view
        ...mapMutations(MESSAGE, [
            `setCountTotalCaregiverNewMessage`,
        ]),

        lastMessageTime(chat) {
            if (chat?.message?.length) {
                let time = chat.message?.[chat.message.length - 1]?.time;
                return moment(time).format("DD/MM/YYYY h:mm A");
            }
            return ''
        },
        ...mapMutations(MESSAGE, ["setAdminContactList"]),
        async getGroupSessionAndMapTheData() {
            this.loading = true;
            try {
                let { data } = await axios.get('/api/every_group');

                if (data?.sessions) {
                    this.groupList = data.sessions.map(s => {
                        return {
                            name: s.crb5c_session_id,
                            id: s.crb5c_fow_session_scheduleid,
                            clients: s.crb5c_FOW_Customer_session_schedule_crb5c
                        }
                    })
                }
                this.loading = false;
                // console.log('data: ', data);

            } catch (error) {
                console.error(error);
                this.loading = false;
            }

        },
        openModal() {
            this.$bvModal.show("modal-lg");
        },
        hideModal() {
            this.$bvModal.hide("modal-lg");
        },
        initAdminContactHasMessageList() {
            this.contactHasMessageList = this.getAdminTotalCountMessage;
        },
        initProgrammeStaffContactHasMessageList() {
            this.contactHasMessageList = this.getProgrammeStaffTotalCountMessage;
        },
        selectUserChat(typeUser) {
            if (typeUser === this.choiceStateUserChat[0]) {
                this.stateUserChat = this.choiceStateUserChat[0];
                this.initAdminContactHasMessageList();
                return;
            }

            if (typeUser === this.choiceStateUserChat[1]) {
                this.stateUserChat = this.choiceStateUserChat[1];
                this.initProgrammeStaffContactHasMessageList();
                return;
            }
        },
        sortMessage(sortType) {
            if (sortType === "desc") {
                this.contactHasMessageList.sort((a, b) => {
                    if (a.name > b.name) {
                        return -1;

                    }
                    if (a.name < b.name) {
                        return 1;
                    }
                    return 0;
                });

                this.stateSort = "asc";
            }

            if (sortType === "asc") {
                this.contactHasMessageList.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });

                this.stateSort = "desc";
            }
        },
        countNewMessageCaregiver(adminData) {
            const newValue = [];
            console.log('adminData', adminData)
            this.loading = true;
            for (let item of adminData) {
                const uniqueName = item.id.split('|');
                const authorId = `${uniqueName[0]}|${uniqueName[1]}`;
                const listLastChatIsCaregiver = [];

                if (item.message.length !== 0) {
                    const listAuthor = item.message.map(item => item.stateChat);

                    for (let i = listAuthor.length; i > 0; i--) {
                        if (listAuthor[i]) {
                            if (listAuthor[i] === authorId) {
                                listLastChatIsCaregiver.push(listAuthor[i]);
                                continue;
                            }

                            if (listAuthor[i] !== authorId) {
                                break;
                            }
                        }

                    }

                }

                const chat = {
                    ...item,
                    countNewMessage: listLastChatIsCaregiver.length
                }

                newValue.push(chat);

                // const newMessageCount = listLastChatIsCaregiver.length; 
                // this["setCountTotalCaregiverNewMessage"](newMessageCount);

            }

            return newValue;

        },
        async moveToMessageChat(id) {
            this.$router.replace({ path: `/chat/${this.stateUserChat}/${id}` });
            await this.loadMessages();
        },
        async loadMessages() {
            console.log("Triggered loadMessages");
            if (!this.user) {
                this.user = this.$route.params.typeUser;
            }

            if (this.user === this.choiceStateUserChat[0]) {
                const messageIndex = this.contactList.admin.findIndex((item) => {
                    return item.id === this.$route.params.id;
                });



                this.messageData = await this.contactList.admin[messageIndex];
                console.log('messageData', this.messageData)
            }

            if (this.user === this.choiceStateUserChat[1]) {
                const messageIndex = this.contactList.programmeStaff.findIndex((item) => {
                    return item.id === this.$route.params.id;
                });

                this.messageData = this.contactList.programmeStaff[messageIndex];
            }

            this.scrollChatToBottom();

        }
    },
    watch: {

        '$route.params.id': {
            handler() {
                console.log("working");
                this.id = this.$route.params.id;
                this.loadMessages();
            },
            deep: true
        },
        "$route.params.typeUser": {
            handler() {

                this.user = this.$route.params.typeUser;
            },
            deep: true
        },
        "messageData.message": {
            handler() {
                this.$nextTick(() => {
                    this.scrollChatToBottom();
                });
                
            },
            deep: true
        },

        listMessages(value) {
            this.contactHasMessageList = value;

        }
    },
    async mounted() {
        this.initAdminContactHasMessageList();
        this.stateUserChat = this.choiceStateUserChat[0];
        this.fetchingGroupList = true;
        if (this.$route.params.id) {
            this.$router.replace({ path: `/chat` });
        }

        await this.getGroupSessionAndMapTheData();




    },
};
</script>

<style lang="sass" scoped>

.chat-main-container
    width: 100%
    height: 100vh
    display: flex
    flex-direction: column
    padding: 1rem
    background-color: #f5f5f5
    .chat-body
        display: flex
        width: 100%
        height: 90%
        gap: 1rem
        .chat-header
            width: 35%
            display: flex
            flex-direction: column
            gap: 1rem
            .chat-header-search
                display: flex
                justify-content: space-between
                align-items: center
                padding: 1rem
                background-color: white
                border-radius: 5px
                height: 10%
                .search-input
                    background-color: #f5f5f5
                    border-radius: 10px
                    padding: 0 0.5rem
                    width: 100%
                    height: 100%
                    input
                        height: 100%
                        
                        border: none
                        background-color: #f5f5f5
                        border-radius: 10px
            .chat-list
                height: 88%
                padding: 1rem
                background-color: white
                border-radius: 5px
                display: flex
                flex-direction: column
                border: 1px solid #f5f5f5
                .list-chat
                    border-radius: 10px
                    height: fit-content
                    .new-message
                        background-color: #903EB8
                        
                        color: white
                        position: absolute
                        top: 0
                        right: 0
                    .chat-list-item
                        display: flex
                        gap: 1rem
                        padding: 0.5rem
                        border-radius: 10px
                        cursor: pointer
                        .new-message
                            background-color: #FF8A8A
                            color: white
                            padding: 0.5rem
                            border-radius: 10px
                    
            

                
        
        .chat-content
            width: 65%
            height: 100%
            gap: 1rem
            .chat-header-name
                padding: 1rem
                border-radius: 5px
                gap: 1rem
                height: 10%
                .text-white
                    font-size: 1.5rem
                    font-weight: bold
            .back-btn
                display: none
            .select-chat
                display: flex
                justify-content: center
                align-items: center
                height: 100%
                background-color: white
                border-radius: 5px
            .chat-message
                height: 90%
                background-color: white
                border-radius: 5px
                .chat-content-body
                    height: 80%
                    padding: 1rem
                    .chat-container
                        display: flex
                        flex-direction: column
                        gap: 1rem
                        .chat-content-receiver
                            align-self: flex-start
                            background-color: #EEEEEE
                            border-radius: 0.5rem
                            padding: 0.5rem
                            margin: 0.5rem
                            width: fit-content
                            text-align: end
                            max-width: 70%
                            font-size: 1rem
                            font-weight: bold
                            display: flex
                            flex-direction: column
                            gap: 0.5rem
                            p
                                margin: 0
                                padding: 0
                                font-size: 0.8rem
                        .chat-content-sender
                            align-self: flex-end
                            background-color: #903EB8
                            color: white
                            border-radius: 0.5rem
                            padding: 0.5rem
                            margin: 0.5rem
                            width: fit-content
                            text-align: start
                            max-width: 70%
                            font-size: 1rem
                            font-weight: bold
                            display: flex
                            flex-direction: column
                            gap: 0.5rem
                            p
                                margin: 0
                                padding: 0
                                font-size: 0.8rem
                .chat-content-footer
                    height: 14%
                    display: flex
                    justify-content: space-between
                    align-items: center
                    padding: 1rem
                    gap: 1rem
                    .button-add-chat
                        background-color: #903EB8
                        color: white
                        border-radius: 10px
                        padding: 0.5rem
                        width: 10%
                        height: 100%
                        display: flex
                        justify-content: center
                        align-items: center
                        cursor: pointer
                    input
                        border-radius: 10px
                        padding: 0.5rem
                        width: 90%
                        height: 100%
                        border: none
                        background-color: #f5f5f5
                        border-radius: 10px

@media (max-width: 768px)
    .chat-main-container .chat-body
        flex-direction: column
        .chat-header
            width: 100%
            height: 100%
            .chat-header-search
                height: fit-content
            .chat-list 
                height: 100%
                overflow-y: scroll
                .list-chat
                    height: fit-content
                .chat-list-item
                    flex-direction: column
                    align-items: flex-start
                    justify-content: flex-start
                
        .chat-content
            width: 100%
            height: 100%
            .chat-header-name
                height: 10%
                max-height: 15%
                .text-white
                    font-size: 0.7rem
            .back-btn
                display: flex
                justify-content: flex-start
                width: 100%
                height: 5%
                button
                    
                    color: white
                    border-radius: 5px
                    padding: 0.5rem
                    width: fit-content
                    gap: 0.5rem
            .select-chat
                display: none
            .chat-message
                height: 80%
                .chat-content-body
                    height: 90% !important
                    .chat-container
                        .chat-content-receiver
                            max-width: 100%
                        .chat-content-sender
                            max-width: 100%
                
            
@media (max-width:768px)
    .hidden
        display: none !important   
        height: 0 !important  
</style> 
