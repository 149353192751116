<template lang="pug">
b-row(class="d-flex justify-content-start align-items-center" style="height:15%;")
    //-- for phone screen
    b-col(class="d-block d-sm-none")
        b-row(class="justify-content-between align-items-center")
            b-col(class="col-2 d-flex justify-content-start" @click="moveToHomePage")
                b-img(:src="DSG_logo", width="150px" style="cursor: pointer;" alt="DSG Logo")
            
            b-col(
                    v-if="isMoveToFOWMessage"
                    class="col-8 d-flex justify-content-end"
                )
                b-button(:style="`background-color: ${btnClickedColor}`" @click="moveToFOWMessage")
                    | Back to FOW Message

            b-col(
                    v-else-if="isMoveToAnnouncementPage"
                    class="col-8 d-flex justify-content-end"
                )
                b-button(:style="`font-size: 0.8rem; background-color: ${btnClickedColor}`" @click="moveToAnnouncementPage")
                    | Back to Announcement Page

            b-col(
                    v-else
                    class="col-8 d-flex justify-content-end"
                )
                b-button(:style="`background-color: ${btnClickedColor}`" @click="moveToHomePage")
                    | Back to Homepage

        b-row(class="mt-2")
            b-col(class="col-12")
                span(class="font-weight-bold" style="font-size: 1.3rem;")
                    | {{ title }}
    
    //-- for other than phone screen
    b-col(class="d-none d-sm-block")
        b-row(class="justify-content-start align-items-center")
            b-col(class="col-3 d-flex justify-content-start" @click="moveToHomePage")
                b-img(:src="DSG_logo", width="128px" style="cursor: pointer;" alt="DSG Logo")
            b-col(class="col-5")
                span(class="font-weight-bold" style="font-size: 1.3rem;")
                    | {{ title }}
            b-col(
                    v-if="isMoveToFOWMessage"
                    class="col-4 d-flex justify-content-end"
                )
                b-button(:style="`background-color: ${btnClickedColor}`" @click="moveToFOWMessage")
                    | Back to FOW Message
            b-col(
                    v-else-if="isMoveToAnnouncementPage"
                    class="col-4 d-flex justify-content-end"
                )
                b-button(:style="`background-color: ${btnClickedColor}`" @click="moveToAnnouncementPage")
                    | Back to Announcement Page
            b-col(
                    v-else
                    class="col-4 d-flex justify-content-end"
                )
                b-button(v-if="isAdmin" :style="`background-color: ${btnClickedColor}`" @click="moveToHomePage")
                    | Back to Homepage

</template>

<script>
import DSG_logo from '@/assets/Images/DSG_logo.png';
export default {
    name: "Header",
    props: {
        title: {
            type: String,
            required: true,
        },
        isMoveToFOWMessage: {
            type: Boolean,
            default: false,
        },
        isMoveToAnnouncementPage: {
            type: Boolean,
            default: false,
        },
        isAdmin: {
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
            DSG_logo,
            btnClickedColor: "#50276b",
        }
    },
    methods: {
        moveToHomePage() {
            let isAdmin = this.$store.state.isAdmin;
            if (isAdmin) {
                this.$router.push({ path: "/" });
            }
            
        },
        moveToAnnouncementPage(){
            this.$router.push({ path: "/announcements" });
        },
        moveToFOWMessage(){
            this.$router.push({ path: "/messages" });
        }
    }
}
</script>

<style lang="scss">

</style>