import dsgIcon from "../assets/Icons/dsg.png";

const phoneVibrate = () => {
    if(!window){
        return;
      }

      if(!window.navigator){
        return;
      }

      if(!window.navigator.vibrate){
        return;
      }

      const vibratePattern = [200, 100, 200];
      navigator.vibrate(vibratePattern);
}


const sendNotification = (body, title) => {
  if (Notification.permission == "granted") {
    navigator.serviceWorker.getRegistration().then(async function (reg) {
      const options = {
        body,
        icon: dsgIcon,
        vibrate: [100, 50, 100],
        data: {
          dateOfArrival: Date.now(),
          primaryKey: 1,
        },
      };
      await reg?.showNotification(title, options);
      phoneVibrate();
      
      // FIX: when it clicked, cannot go to the apps
      self.addEventListener("notificationclick", (event) => {
        console.log("On notification click: ", event.notification.tag);
        event.notification.close();
        self.focus();
        window.focus();
      });
    });


  }
}

export { sendNotification }