<template lang="pug">
div(class="px-2 flex-column align-items-start d-flex pt-5" style="height:100vh;width:20%;overflow-y:auto;")
    h5.mt-3 Event Preview 
    .hr
    p Event Name: {{ event.name ? event.name : "No Name" }}
    div(v-if="event.selectedGroup" class="w-100 align-items-start d-flex flex-column")
        h5 Selected Group
        p.badge.badge-primary(v-for="group in event.selectedGroup" :key="group.id") {{ group.name }}
    p Description: {{ !event.description ? "No Description" : "" }}
        span(v-html="event.description" class="w-100 align-items-start d-flex flex-column")
    p Event Type : {{ event.isSingleDay ? "Single Day" : "Multiple Days" }}
    .hr
    div(v-if="event.isSingleDay" class="w-100 align-items-start d-flex flex-column")
        h5 Single Day Event
        p Date: {{ event.singleDay.date ? event.singleDay.date : "No Date" }}
        p Time: {{ event.singleDay.time ? event.singleDay.time.hh + ":" + event.singleDay.time.mm + " " + event.singleDay.time.A : "No Time" }}
        p Price Per Person: ${{ event.singleDay.pricePerPerson ? event.singleDay.pricePerPerson : "No Price" }}
        div(v-if="event.singleDay.itineraries.length" class="w-100 align-items-start d-flex flex-column")
            h5 Itineraries
            div(v-for="itinerary in event.singleDay.itineraries" :key="itinerary.id" class="w-100 align-items-start d-flex flex-column" style="border:1px solid #DAE1EB; padding:10px; margin:10px 0")
                p Time: {{ itinerary.time ? itinerary.time : "No Time" }}
                p Description: {{ itinerary.description ? itinerary.description : "No Description" }}
        
        div(v-if="event.singleDay.food.lunch.length" class="w-100 align-items-start d-flex flex-column" style="border:1px solid #DAE1EB; padding:10px; margin:10px 0")
            h5 Lunch
            p(v-for="lunch in event.singleDay.food.lunch" :key="lunch.id") {{ lunch.name }}
        
        div(v-if="event.singleDay.food.beverages.length" class="w-100 align-items-start d-flex flex-column" style="border:1px solid #DAE1EB; padding:10px; margin:10px 0")
            h5 Beverages
            p(v-for="beverage in event.singleDay.food.beverages" :key="beverage.id") {{ beverage.name }}
    div(v-else class="w-100 align-items-start d-flex flex-column")
        h5 Multiple Day Event
        div(v-for="event in event.multipleEvents" :key="event.id" class="w-100 align-items-start d-flex flex-column" style="border:1px solid #DAE1EB; padding:10px; margin:10px 0")
            h5 Event Name: {{ event.title ? event.title : "No Name" }}
            p Date: {{ event.date ? event.date : "No Date" }}
            p Time: {{ event.time ? event.time.hh + ":" + event.time.mm + " " + event.time.A : "No Time" }}
            p Price Per Person: ${{ event.pricePerPerson ? event.pricePerPerson : "No Price" }}
            div(v-if="event.itineraries.length" class="w-100 align-items-start d-flex flex-column")
                h5 Itineraries
                div(v-for="itinerary in event.itineraries" :key="itinerary.id" class="w-100 align-items-start d-flex flex-column" style="border:1px solid #DAE1EB; padding:10px; margin:10px 0")
                    p Time: {{ itinerary.time ? itinerary.time : "No Time" }}
                    p Description: {{ itinerary.description ? itinerary.description : "No Description" }}
            
            div(v-if="event.food.lunch.length" class="w-100 align-items-start d-flex flex-column" style="border:1px solid #DAE1EB; padding:10px; margin:10px 0")
                h5 Lunch
                p(v-for="lunch in event.food.lunch" :key="lunch.id") {{ lunch.name }}
            
            div(v-if="event.food.beverages.length" class="w-100 align-items-start d-flex flex-column" style="border:1px solid #DAE1EB; padding:10px; margin:10px 0")
                h5 Beverages
                p(v-for="beverage in event.food.beverages" :key="beverage.id") {{ beverage.name }}


</template>

<script>
    export default {
        props: {
            event: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
.hr {
    height: 1px;
    background-color: #DAE1EB;
    width: 100%;
    margin: 10px 0;
}
</style>