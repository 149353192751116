<template lang="pug">
b-container(style="height: 85%; max-height: 85%;")

    //-- modal filter client
    //--b-modal(id="filter-client" title="Filter Clients")
        template
            b-container
                b-row
                    b-col.col-6
                        b-form-group(label="Status:" v-model="selectedStatus")
                            b-form-checkbox-group.mt-3(style="display: flex; flex-direction: column; gap: 0.75rem" v-model="listActiveStatusSelected" :options="activeStatus" name="activeStatus")
                    b-col.col-6
                        b-form-group(label="Programme:" v-modal="selectedProgramme")
                            b-form-checkbox-group.mt-3(style="display: flex; flex-direction: column; gap: 0.75rem" v-model="listProgrammeSelected" :options="programmeStatus" name="programmeStatus")
        template(#modal-footer)
            b-button(variant="secondary" @click="$bvModal.hide('filter-client')") Close
    //-- modal filter client

    b-modal(id="ConfirmationModal" title="Send Survey to All Caregivers" centered)
        h5.font-weight-bold Are you sure that you want to send survey to all caregivers?
        template(#modal-footer)
            b-button.mr-auto(variant="danger" @click="$bvModal.hide('ConfirmationModal')") No 
            b-button.custom-button( @click="sendSurveyNotificationToAllCaregivers") Yes

    b-modal(id="ZaritQuestionModal" title="Zarit Burden Survey Question" centered)
        div
            span.font-weight-bold.mr-3 1.
            span {{ chineseOptions ? "你 是 否 觉 得 因 为 花 了 时 间 在 你 亲 人 身 上 而 造 成 自 己 没 有 足 够 的 时 间 ？*" : "Do you feel that because of the time you spend with your relative that you don't have enough time for yourself?*" }}
        hr
        div
            span.font-weight-bold.mr-3 2.
            span {{ chineseOptions ? "你 是 否 因 为 要 照 料 你 亲 人 ，同 时 又 要 应 付 家 庭 或 工 作 上 的 其 他 责 任 而 觉 得 有 压力 ？*" : "Do you feel stressed between caring for your relative and trying to meet other responsibilities for your family or work?*" }}
        hr
        div
            span.font-weight-bold.mr-3 3.
            span {{ chineseOptions ? "当 你 和 你 亲 人 在 一 起 时 ，你 是 否 感 到 不 自 在 ？*" : "Do you feel strained when you are around your relative?*" }}
        hr
        div
            span.font-weight-bold.mr-3 4.
            span {{ chineseOptions ? "你 是 否 觉 得 不 能 确 定 要 为 你 亲 人 做 什 么 ？*" : "Do you feel uncertain about what to do about your relative?*" }}
        hr
        div
            span.font-weight-bold.mr-3 5.
            span {{ chineseOptions ? "总 的 来 说，你 在 照 顾 亲 戚 方 面 感 到 负 担 有 多 大 ？*" : "Overall, how burdened do you feel in caring for your relative?*" }}
        hr
        div
            span.font-weight-bold.mr-3 6.
            span {{ chineseOptions ? "总 的 来 说，我 对 FOW 很 满 意，因 为 它 符 合 我 的 期 望 ？*" : "Overall, I am satisfied with FOW, as it met my expectations?*" }}
        template(#modal-footer)
            b-form-checkbox.mr-auto(switch v-model="chineseOptions") {{ chineseOptions ? "中文" : "English"}}
            b-button.mx-3(variant="danger" @click="$bvModal.hide('ZaritQuestionModal')") Close 

    b-col.mt-5
        b-row.mb-4.mb-sm-2
            b-col.d-none.d-sm-block(style="")
                div.d-flex.justify-content-between
                    h4.d-none.d-md-block Check Caregiver Survey
                    b-input.px-3.border.border-dark.rounded.w-50(placeholder="Search Name" type="text" v-model="inputSearchItemList")
                    div(v-if="!loading")
                        b-dropdown(id="dropdown-1" text="More" right variant="primary")
                            b-dropdown-item
                                b-button.custom-button(block @click="$bvModal.show('ConfirmationModal')") Send Survey to All Caregivers
                            b-dropdown-item
                                b-button.custom-button(block @click="$bvModal.show('ZaritQuestionModal')") View Zarit Survey Question
                

        b-row
            b-col.col-12(
                style="max-height: 35rem; overflow-y: auto;"
            )
                b-spinner.my-5(v-if="loading" label="Spinning")
                b-table(
                    v-if="!loading"
                    :fields="visibleFields" 
                    :items="caregiverSurveyList"
                    class="table-header-color"
                    thead-tr-class="text-white"
                    tbody-tr-class="bg-white"
                    show-empty
                    id="survey-table"
                )
                    template(#empty="scope")
                        b-spinner(v-if="loading" label="Spinning")
                    template(#cell(crb5c_name)="data")
                        p {{ data.item.crb5c_name }}
                        b-badge.d-md-none.d-inline(v-if="data.item.crb5c_latestnotificationsentdate" :variant="data.item.crb5c_surveystatus ?  'danger' : 'success' ")
                            span(v-if="data.item.crb5c_latestnotificationsentdate") {{ data.item.crb5c_surveystatus ?  'Incomplete' : 'Complete' }}
                    template(#cell(recent_survey_date)="data")
                        span {{ getLatestDateByCreatedOn(data.item.crb5c_crb5c_zaritscreenmeasureoffamilycaregiver) ? getLatestDateByCreatedOn(data.item.crb5c_crb5c_zaritscreenmeasureoffamilycaregiver) : "No Survey Submitted" }}
                        <!-- br
                        span {{ getLatestDateByCreatedOn(data.item.crb5c_crb5c_zaritscreenmeasureoffamilycaregiver) ? getLatestDateByCreatedOn(data.item.crb5c_crb5c_zaritscreenmeasureoffamilycaregiver) : "" }} -->
                    template(#cell(crb5c_latestnotificationsentdate)="data") 
                        span {{ data.item.crb5c_latestnotificationsentdate ? formatDateOnly(data.item.crb5c_latestnotificationsentdate): "No Date Recorded" }}
                        <!-- b-badge {{ data.item.crb5c_latestnotificationsentdate ? add6Month(data.item.crb5c_latestnotificationsentdate): "" }} -->
                    template(#cell(crb5c_surveystatus)="data")
                        span(v-if="data.item.crb5c_surveystatus == null") Not Survey Sent 
                        b-badge(v-else :variant="data.item.crb5c_surveystatus ?  'danger' : 'success' ")
                            span(v-if="data.item.crb5c_latestnotificationsentdate") {{ data.item.crb5c_surveystatus ?  'Incomplete' : 'Complete' }}
                    template(#cell(action)="data")
                        b-button.font-weight-bold(block variant="info" size="sm" @click="sendSurveyNotification(data.item.crb5c_fow_caregiverid)") Send Survey To Caregiver
                        b-button.font-weight-bold(block variant="info" size="sm" @click="openModal(data.item)") View Survey ({{ data.item.crb5c_crb5c_zaritscreenmeasureoffamilycaregiver.length }})
                div.d-flex.justify-content-between
                    //- b-pagination(v-model="currentPage" :per-page="perPage" aria-controls="survey-table" :total-rows="rows")
                    span.d-none.d-sm-block Total Caregivers: {{ caregiverSurveyList.length }}
            b-modal(id="success-modal" header-bg-variant="success" header-text-variant="white" hide-footer title="Success" centered)
                h4 Survey has been succesfully sent
            b-modal(id="error-modal" header-bg-variant="danger" header-text-variant="white" title="Error" centered)
                h4 There seems to be an error. Please try again later, or refresh the page

            b-modal(:id="modalId" v-model="modalVisible" hide-header size="lg" centered scrollable)
                b-tabs(v-if="selectedItem && selectedItem.crb5c_crb5c_zaritscreenmeasureoffamilycaregiver.length > 0" pills card justified)
                    b-tab(v-for="tab in sortedTabs" :key="tab.crb5c_zaritscreenmeasureoffamilycaregiverburdenid" :title="formatDateOnly(tab.createdon)")
                        .row 
                            .col-8.border.p-2(style="border-radius: 16px 0 0 0;")
                                div
                                    label {{ chineseOptions ? "你 是 否 觉 得 因 为 花 了 时 间 在 你 亲 人 身 上 而 造 成 自 己 没 有 足 够 的 时 间 ？*" : "Do you feel that because of the time you spend with your relative that you don't have enough time for yourself?*" }}
                                    br
                                    b-badge(:variant="getZaritAnswer(tab.crb5c_zaritquestion1).variant")
                                        span {{ getZaritAnswer(tab.crb5c_zaritquestion1).answer }}
                                hr
                                div
                                    label {{ chineseOptions ? "你 是 否 因 为 要 照 料 你 亲 人 ，同 时 又 要 应 付 家 庭 或 工 作 上 的 其 他 责 任 而 觉 得 有 压力 ？*" : "Do you feel stressed between caring for your relative and trying to meet other responsibilities for your family or work?*" }}
                                    br
                                    b-badge(:variant="getZaritAnswer(tab.crb5c_zaritquestion2).variant")
                                        span {{ getZaritAnswer(tab.crb5c_zaritquestion2).answer }}
                                hr
                                div
                                    label {{ chineseOptions ? "当 你 和 你 亲 人 在 一 起 时 ，你 是 否 感 到 不 自 在 ？*" : "Do you feel strained when you are around your relative?*" }}
                                    br
                                    b-badge(:variant="getZaritAnswer(tab.crb5c_zaritquestion3).variant")
                                        span {{ getZaritAnswer(tab.crb5c_zaritquestion3).answer }}
                                hr
                                div
                                    label {{ chineseOptions ? "你 是 否 觉 得 不 能 确 定 要 为 你 亲 人 做 什 么 ？*" : "Do you feel uncertain about what to do about your relative?*" }}
                                    br
                                    b-badge(:variant="getZaritAnswer(tab.crb5c_zaritquestion4).variant")
                                        span {{ getZaritAnswer(tab.crb5c_zaritquestion4).answer }}
                                hr
                                div
                                    label {{ chineseOptions ? "总 的 来 说，你 在 照 顾 亲 戚 方 面 感 到 负 担 有 多 大 ？*" : "Overall, how burdened do you feel in caring for your relative?" }}
                                    br
                                    b-badge(:variant="getZaritAnswer(tab.crb5c_zaritquestion5).variant")
                                        span {{ getZaritAnswer(tab.crb5c_zaritquestion5).answer }}
                            .col-4.border.p-2(style="border-radius: 0 16px 0 0")
                                div.text-center
                                    h6 {{ chineseOptions ? "平均分" : "Average Score" }}
                                    h3.font-weight-bold.ml-2 {{calculateAverage(tab)}}
                                div.mt-2.d-none.d-lg-inline
                                    b-button.mx-auto(size="sm" variant="primary" @click="collapseState = !collapseState" block)  {{ chineseOptions ? "分数指南" : "Information Guide" }}
                                b-collapse(v-model="collapseState")
                                    b-card
                                        b-badge(variant="success")
                                            span Never 从不 = 0
                                        br
                                        b-badge(variant="success")
                                            span Rarely 很少 = 1
                                        br
                                        b-badge(variant="warning")
                                            span Sometimes 有时 = 2
                                        br
                                        b-badge(variant="danger")
                                            span Quite Frequently 经常 = 3
                                        br
                                        b-badge(variant="danger")
                                            span Nearly Always 总是 = 4
                                        // hr
                                        // b-badge(variant="danger")
                                        //     span Strongly Disagree 非常不同意 = 1
                                        // br
                                        // b-badge(variant="danger")
                                        //     span Disagree 不同意 = 2
                                        // br
                                        // b-badge(variant="warning")
                                        //     span Somewhat Disagree 有些不同意 = 3
                                        // br
                                        // b-badge(variant="success")
                                        //     span Somewhat Agree 有些同意 = 4
                                        // br
                                        // b-badge(variant="success")
                                        //     span Agree 同意 = 5
                                        // br
                                        // b-badge(variant="success")
                                        //     span Strongly Agree 非常同意 = 6
                        .row 
                            .col-8.border.p-2(style="border-radius: 0 0 0 16px;")
                                div
                                    label {{ chineseOptions ? "总 的 来 说，我 对 FOW 很 满 意，因 为 它 符 合 我 的 期 望 ？*" : "Overall, I am satisfied with FOW, as it met my expectations?" }}
                                    br
                                    b-badge(:variant="getZaritFinalAnswer(tab.crb5c_zaritquestion6).variant")
                                        span {{ getZaritFinalAnswer(tab.crb5c_zaritquestion6).answer }}
                            .col-4.border.p-2(style="border-radius: 0 0 16px 0;")
                                div.text-center(v-if="tab.crb5c_zaritquestion6")
                                    h6 
                                        // b-icon.mr-1(icon="info-circle-fill" id="tooltip-target-1")
                                        span {{  chineseOptions ?  "FOW总体满意度" : "Overall Satisfaction on FOW" }}
                                    h3.font-weight-bold.ml-2 {{ tab.crb5c_zaritquestion6 }}
                                    // b-tooltip(target="tooltip-target-1" triggers="hover")
                                    //     p {{ getZaritFinalAnswer(tab.crb5c_zaritquestion6).answer }} = {{ tab.crb5c_zaritquestion6 }}
                div(v-else)
                    h4 {{  chineseOptions ?  "未找到有关看护者的调查"  :  "No Survey Found for Caregiver"}}
                template(#modal-footer)
                    b-form-checkbox.mr-auto(switch v-model="chineseOptions") {{ chineseOptions ? "中文" : "English"}}
                    b-button(@click="closeModal('modalId')" size="sm") Close 

        // b-row(v-if="!loading")
        //   b-col.col-12.py-2.text-left
        //        span Total Caregivers: {{ caregiverSurveyList.length }}
        


</template>
    
<script>
// import { mapState } from 'vuex';
// import { ADMIN_DASHBOARD_NAMESPACE } from '../../store/Modules/AdminDashboard/types-admin-dashboard';
import axios from 'axios'
import dayjs from 'dayjs';
// import { RouterLink } from 'vue-router';

export default {
    data() {
        return {
            currentPage:1,
            perPage: 5,
            loading:true,
            collapseState:false,
            chineseOptions:false,
            modalId: '',
            modalVisible: false,
            selectedItem: null,
            tableFields: [
                { 
                    key: "crb5c_name", 
                    label: "Caregiver Name",
                    sortable: true,
                }, 
                { 
                    key: "recent_survey_date", 
                    label: "Latest Survey Date",
                    sortable: true,
                }, 
                { 
                    key: "crb5c_latestnotificationsentdate", 
                    label: "Recent Notification Date",
                    sortable: true,
                }, 
                { 
                    key: "crb5c_surveystatus", 
                    label: "Status",
                    sortable: true,
                },
                { key: 'action', name:'Action'}, 
            ],
            visibleFields:[],
            sortTable: {
                by: "crb5c_name",
                desc: false,
            },
            caregiver_survey_items:[],
            selectedStatus: null,
            selectedProgramme: null,
            inputSearchItemList: '',
            clientsDB: [],
            caregiverDB: [],
            assessmentDB: [],
            listActiveStatusSelected: [],
            listProgrammeSelected: [],
            isFOWCheck: false,
            isCIPCheck: false,
            activeStatus: [
                {
                    text: 'Active',
                    value: 'Active'
                },
                {
                    text: 'Inactive',
                    value: 'Inactive'
                },
                {
                    text: 'Discharge',
                    value: 'Discharge'
                },
                {
                    text: 'Pending',
                    value: 'Pending'
                }
            ],
            programmeStatus: [
                {
                    text: 'CIP',
                    value: 'CIP'
                },
                {
                    text: 'FOW',
                    value: 'FOW'
                }
            ],
            
            modal: {
                modalClientId: 'unique-modal-id',
                data: null,
            },
            currentClient: null,
            clientCaregiver: null
        }
    },
    computed: {
        caregiverSurveyList(){
            let filteredCaregiverSurvey = this.caregiver_survey_items;
            
            if (this.inputSearchItemList.trim().length > 0) {
                    const searchName = this.inputSearchItemList.trim().toLowerCase();
                    filteredCaregiverSurvey = filteredCaregiverSurvey.filter((caregiver) => {
                    return caregiver.crb5c_name && caregiver.crb5c_name.toLowerCase().includes(searchName);
                });
            }
            return filteredCaregiverSurvey;
        },
        rows() {
            return this.caregiverSurveyList.length
        },
        sortedTabs() {
            // Sort the tabs by createdon date in descending order
            return this.selectedItem.crb5c_crb5c_zaritscreenmeasureoffamilycaregiver.slice().sort((a, b) => {
                return new Date(b.createdon) - new Date(a.createdon);
            })
        },
    },
    async mounted(){
        try {
            this.loading = true;
            const isLoadData = await this.loadData();
            
            if(!isLoadData){
                this.loading = false;
                return;
            }

            window.addEventListener('resize', this.handleResize);
            this.handleResize();
            this.loading = false;
            
            setInterval(async () => {
                await this.loadData();
            }, 10000);
            
        } catch (error) {
            console.error(error);
            this.loading = false;
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            const columnsToHideSmall =  ['recent_survey_date', "recent_notification_date", "survey_status"];
            if (window.innerWidth < 576) {
                // Hide specified columns on smaller screens
                this.visibleFields = this.tableFields.filter(field => !columnsToHideSmall.includes(field.key));
            } else if (window.innerWidth > 576 && window.innerWidth < 768) {
                // Hide specified columns on smaller screens
                this.visibleFields = this.tableFields.filter(field => !columnsToHideSmall.includes(field.key));
            } else {
                // Show all columns
                this.visibleFields = this.tableFields;
            }
        },
        async loadData() {
            try {
                const {data} = await axios.get("/api/survey");
                this.caregiver_survey_items = data.caregiverSurveyData;                
                return true;
            } catch (error) {
                console.error("Error fetching data:", error);
                this.$bvModal.show('error-modal');
                
                return false;
            }
        },
        async sendSurveyNotification(id){
            try{
                this.loading = true;
                let caregiverid = id
                let payload = {
                    crb5c_surveystatus: true,
                    crb5c_latestnotificationsentdate: dayjs().format('YYYY-MM-DD')
                }
                console.log(payload);
                const response = await axios.patch("/api/survey", {payload, caregiverid});
                console.log(response);
                await axios.get("/api/survey").then(({data})=>{
                    this.caregiver_survey_items = data.caregiverSurveyData
                    // console.log("TEST",this.claimTableItems);
                    return data;
                }),
                this.loading = false;
                this.$bvModal.show('success-modal')
            }catch{
                this.loading = false;
                this.$bvModal.show('error-modal')
            }
        },
        async sendSurveyNotificationToAllCaregivers(){
            try{
                this.loading = true;
                let caregiveridArray = this.caregiver_survey_items.map((item)=>item.crb5c_fow_caregiverid);
                let payload = {
                    crb5c_surveystatus: true,
                    crb5c_latestnotificationsentdate: dayjs().format('YYYY-MM-DD')
                }
                console.log(payload);
                const updatePromises = caregiveridArray.map(async (id) => {
                    const response = await axios.patch("/api/survey", { payload, caregiverid: id });
                    console.log(`Updated caregiver ${id}:`, response);
                });

                // Wait for all PATCH requests to complete
                await Promise.all(updatePromises);
                await axios.get("/api/survey").then(({data})=>{
                    this.caregiver_survey_items = data.caregiverSurveyData
                    // console.log("TEST",this.claimTableItems);
                    return data;
                }),
                this.loading = false;
                this.$bvModal.show('success-modal')
            }catch{
                this.loading = false;
                this.$bvModal.show('error-modal')
            }
        },
        calculateAverage(data) {
            let filteredData = Object.keys(data).reduce((acc, key) => {
                if (key !== 'crb5c_zaritquestion6' && key !== '_crb5c_caregiver_value' && key !== 'createdon' && key !== '@odata.etag' && key !== 'crb5c_zaritscreenmeasureoffamilycaregiverburdenid' && data[key] !== null) {
                    acc[key] = data[key];
                }
                return acc;
            }, {});

            let values = Object.values(filteredData);

            if (values.length === 0) {
                return 0; // Handle the case where all values are null or filtered out
            }
            return values.reduce((sum, value) => sum + value, 0) / values.length;
        },
        getZaritAnswer(value) {
            let answer = "";
            let variant = "";
            if (value === 0) {
                answer = "Never 从不";
                variant = "success";
            } else if (value === 1) {
                answer = "Rarely 很少";
                variant = "success";
            } else if (value === 2) {
                answer = "Sometimes 有时";
                variant = "warning";
            } else if (value === 3) {
                answer = "Quite Frequently 经常";
                variant = "danger";
            } else if (value === 4) {
                answer = "Nearly Always 总是";
                variant = "danger";
            } else {
                answer = "Not Recorded";
                variant = "info";
            }
            return { answer, variant };
        },
        getZaritFinalAnswer(value) {
            let answer = "";
            let variant = "";
            if (value === 1) {
                answer = "Strongly Disagree 非常不同意";
                variant = "danger";
            } else if (value === 2) {
                answer = "Disagree 不同意";
                variant = "danger";
            } else if (value === 3) {
                answer = "Somewhat Disagree 有些不同意";
                variant = "warning";
            } else if (value === 4) {
                answer = "Somewhat Agree 有些同意";
                variant = "success";
            } else if (value === 5) {
                answer = "Agree 同意";
                variant = "success";
            } else if (value === 6) {
                answer = "Strongly Agree 非常同意";
                variant = "success";
            } else {
                answer = "Not Recorded";
                variant = "info";
            }
            return { answer, variant };
        },
        getLatestDateByCreatedOn(arrayObj) {
            if (!Array.isArray(arrayObj) || arrayObj.length === 0) {
                return null;
            }
            let latestDate = dayjs(arrayObj[0].createdon);
            for (let i = 1; i < arrayObj.length; i++) {
                const currentDate = dayjs(arrayObj[i].createdon);
                if (currentDate.isAfter(latestDate)) {
                latestDate = currentDate;
                }
            }
            return this.formatDateOnly(latestDate);
        },
        formatDateOnly(date){
            return dayjs(date).format('DD/MM/YYYY')
        },
        add6Month(date){
            return dayjs(date).format('DD/MM/YYYY').add(6,'month')
        },
        backAdminHomepage() {
            this.$router.push({ path: '/adminhomepage' })
        },
        openModal(item) {
            this.selectedItem = item;
            this.modalId = `modal_${item.crb5c_fow_caregiverid}`;
            this.modalVisible = true;
        },
        closeModal(item) {
            this.selectedItem = null;
            this.modalId = `modal_${item.crb5c_fow_caregiverid}`;
            this.modalVisible = false;
        },
    }
}
</script>

<style>
.table__body{
    text-align: center; 
    max-height: 250px; 
    /* overflow-y: scroll;  */
    border-collapse: separate; 
    border-spacing: 0px;
}
.custom-button{
    background-color: #50276b;
    color:white;
}

.table__body::-webkit-scrollbar{
    width: 0.5rem;
    height: 0.5rem;
}

.table__body::-webkit-scrollbar-thumb{
    border-radius: 0.5rem;
    background-color: #0004;
}
</style>