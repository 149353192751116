import dayjs from "dayjs";

export const Table = {
  namespaced: true,
  state: {
    itemTable: {
      announcements: [],
      reminders: [],
    },
  },
  actions:{
    async addAnnouncements({ getters, commit, dispatch }, payload){
      
      try {
        const finalPl = await dispatch("transformPayloadAnnouncement", payload);
        
        // TODO: check whether finalPl is empty or not
        finalPl.admin = getters.currentAdminName;

        const { data } = await getters.api.post("/announcement", finalPl);
        
        const { 
          sid: id,
          body: message,
          attributes,
          dateUpdated,
        } = data;

        const { 
          eventDate,
          eventTime,
          isEvent,
          title,
          groupName,
        } = JSON.parse(attributes);

        const dataToCommit = {
          id,
          convId: data.convId,
          message,
          title,
          dateUpdated,
          options: "",
          eventDate,
          eventTime,
          isEvent,
          groupName,
          recipients: groupName ? groupName: "Everyone", // Default
        }

        commit("addAnnouncements", dataToCommit);
        
        
      } catch (error) {
        console.error(error);
      }

    },
    async removeAnnouncement({ getters }, payload) {
      await getters.api.delete("/announcement", {
        data: payload,
      })

    },
    async editAnnouncement({ getters }, payload) {
      payload.admin = getters.currentAdminName;
      await getters.api.patch("/announcement", payload)

    },
    async addReminders({ commit }, payload){
      commit("addReminders", payload);

    },
    async transformPayloadAnnouncement(_, payload){
      /**
          {
              "id": "c9278997",
              "title": "BODFJK",
              "eventDate": "20/03/2023",
              "eventTime": "03:31",
              "message": "LOL",
              "options": "1",
              "date": "31/03/2023", //"No Date"
              "time": "00:31",
              "recipients": "Everyone"
            }
           */
            let {
              message: body, 
              title, 
              eventDate,
              eventTime,
              isEvent,
              date: scheduleDate,
              group, 
              groupName, 
              convId
            } = payload
          
            let output ={
              body,
              title,
              eventDate,
              eventTime,
              isEvent,
              scheduleDate, 
              group,
              groupName, 
              convId
            };
      
            if(scheduleDate == "No Date"){
              delete output.scheduleDate;
            }
      
            return output;
      }

  },
  mutations: {
    clearAnnouncements(state){
      state.itemTable.announcements = [];

    },
    // mountedAnnouncements(state, payload){
    //   state.itemTable.announcements.push(payload);
    // },
    addAnnouncements(state, payload) {
      // console.log(payload);
      state.itemTable.announcements.push(payload);
      
    },
    addReminders(state, payload) {
      state.itemTable.reminders.push(payload);
    },
    updateAnnouncementsItem(state, payload) {
      const { id } = payload;
      const itemToUpdate = state.itemTable.announcements.findIndex((item) => {
        return item.id === id;
      });

      state.itemTable.announcements[itemToUpdate] = payload;
    },
    updateRemindersItem(state, payload) {
      const { id } = payload;
      const itemToUpdate = state.itemTable.reminders.findIndex((item) => {
        return item.id === id;
      });

      state.itemTable.reminders[itemToUpdate] = payload;
    },
    deleteAnnouncementsItem(state, payload) {
      const { id } = payload;
      const tableItem = state.itemTable.announcements.findIndex((item) => {
        return item.id === id;
      });

      state.itemTable.announcements.splice(tableItem, 1);
    },
    deleteRemindersItem(state, payload) {
      const { id } = payload;
      const tableItem = state.itemTable.reminders.findIndex((item) => {
        return item.id === id;
      });

      state.itemTable.reminders.splice(tableItem, 1);
    },
  },
  getters: {
    currentAdminName(state, getters, rootState) {
      return rootState.currentAdmin?.name
    },
    api(state, getters, rootState){
      return rootState.api;
    },
    getAnnouncementData(state) {
      return state.itemTable.announcements.sort((a, b) => dayjs(b.dateUpdated).toDate() - dayjs(a.dateUpdated).toDate());
    },
    getRemindersData(state) {
      return state.itemTable.reminders;
    },
    retrieveAnnouncementsItem: (state) => (id) => {
      return state.itemTable.announcements.find((item) => {
        return item.id === id;
      });
    },
    retrieveRemindersItem: (state) => (id) => {
      return state.itemTable.reminders.find((item) => {
        return item.id === id;
      });
    },
  },
};
