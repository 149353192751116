
const ADMIN_DASHBOARD_NAMESPACE = "adminDashboard";

const MUTATIONS = Object.freeze({
    SET_LIST_NEW_CLIENT_REGISTERED_TODAY: "setListNewClientRegisteredToday",
    SET_SCHEDULES: "setSchedules",
    SET_LIST_UPCOMING_ASSESSMENT: "setListUpcomingAssessment",
    SET_LIST_MASTER_SCHEDULE: "setListMasterSchedule",
    SET_LIST_WEEKLY_SCHEDULE: "setListWeeklySchedule",
    SET_LIST_TODAY_SCHEDULE : "setListTodaySchedule",
    SET_LIST_CLIENT: "setListClient",
    SET_LIST_UPCOMING_APPOINTMENT: "setListUpcomingAppointment",
    SET_IS_FETCHING_ADMIN_DASHBOARD_DATA: "setIsFetchingAdminDashboardData",
    REMOVE_ITEM_FROM_LIST_APPOINTMENT: "removeItemFromListAppointment",
});

const ACTIONS = Object.freeze({
    DB_GET_LIST_NEW_CLIENT_REGISTERED_TODAY: "dbGetListNewClientRegisteredToday",
    DB_GET_LIST_SCHEDULE: "dbGetListSchedule",
    DB_GET_LIST_ASSESSMENT: "dbGetListAssessment",
    DB_GET_LIST_MASTER_SCHEDULE: "dbGetListMasterSchedule",
    DB_GET_LIST_WEEKLY_SCHEDULE: "dbGetListWeeklySchedule",
    DB_GET_LIST_CLIENT: "dbGetListClient",
    SEND_EMAIL_REMINDER_ASSESSMENT: "sendEmailReminderAssessment",
    DB_GET_APPOINTMENT: "dbGetAppointment",
    DB_ADD_APPOINTMENT: "dbAddAppointment",
    DB_DELETE_APPOINTMENT: "dbDeleteAppointment",
    DB_GET_SESSION_ATTENDANCE: "dbGetSessionAttendance",
    DB_GET_FIRST_AND_LAST_SESSION: "dbGetFirstAndLastSession"
});

export { 
    ADMIN_DASHBOARD_NAMESPACE, 
    MUTATIONS, 
    ACTIONS,
}