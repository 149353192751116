<template lang="pug">
b-modal(v-model="showModal" title="Create A New Announcement" size="md" ) 
    div(class="w-100  h-100")
        b-form-group(label="Title")
            b-form-input(v-model="title" placeholder="Enter Title" required :class="{ 'is-invalid': title.length < 5 && title.length > 0 }" )
            p(v-if="error.title" class="text-danger") {{ error.title }}
        
        b-form-group(label="Body")
            div(:class="{ 'invalidVueEditor': body.length < 5 && body.length > 0 }")
                VueEditor(v-model="body" :editorToolbar="customToolbar" placeholder="Enter Body" required)
            p(v-if="error.body" class="text-danger") {{ error.body }}
        b-form-group(label)
            v-select( id="event-group"  :options="groups" :reduce="item => ({ id: item.id, name: item.name })" multiple label="name" placeholder="Select Group" :class="{'invalid': selectedGroup.length === 0}" v-model="selectedGroup")
        div(class="d-flex justify-content-between")
            p Pinned 
            b-form-checkbox(v-model="isPinned" switch) 
        hr(v-if="isPinned")
        div(class="d-flex flex-column" v-if="isPinned")
            p Expiry Date for Pinned Announcement
            b-form-datepicker(v-model="expiryDate" :min="new Date()" placeholder="Select a Date" :class="{'invalid': expiryDate === ''}")
        div(class="d-flex flex-column my-3" v-if="isPinned")
            p Expiry Time for Pinned Announcement
            b-form-timepicker(v-model="expiryTime" placeholder="Select a Time" :class="{'invalid': expiryTime === ''}" locale="en" )
    template(v-slot:modal-footer)
        b-button(@click="showModal = false" :disabled="loading") Cancel
        b-button(@click="submit" variant="primary" :disabled="!validateTitle || !validateBody || loading") 
            span(v-if="loading") 
                b-spinner(type="grow" small)
            span(v-else) Create
        
</template>

<script>
import { VueEditor } from "vue2-editor";
import { mapActions } from "vuex";
import axios from "axios";
export default {
    components: {
        VueEditor,
    },
    props: ["showCreateDialog", "groups"],

    data() {
        return {

            customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" },
                ],
                ["link"],
                ["clean"],
                ['image']
            ],
            title: "",
            body: "",
            selectedGroup: [],
            isPinned: false,
            expiryDate: "",
            expiryTime: "",
            error: {
                title: "",
                body: "",
            },
            loading: false,
            
        };
    },
    async mounted() {
        
    },
    methods: {
        
        consoleLog() {
            console.log(this.title)
            console.log(this.$root)
        },
        ...mapActions(["getEveryGroup"]),
        async submit () {
            if(this.selectedGroup.length === 0){
                this.$root.$bvToast.toast("Please select a group", {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                });
                return;
            }
            if (this.validateTitle && this.validateBody) {
                if(this.isPinned && this.expiryDate === "" && this.expiryTime === ""){
                    this.$root.$bvToast.toast("Please select an expiry date", {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                    return;
                }
            this.loading = true;
                try{
                const data = {
                    title: this.title,
                    body: JSON.stringify(this.body),
                    isPinned: this.isPinned,
                    groupIds: JSON.stringify(this.selectedGroup.map((item) => item.id),),
                    expiryDate: this.isPinned ? new Date(this.expiryDate + " " + this.expiryTime).toISOString() : null,
                };
                const res = await axios.post("/api/announcement/v2", data);
                if (res.status === 200) {
                    this.showModal = false;
                    this.$emit("created");
                }
                this.$root.$bvToast.toast("Announcement created successfully", {
                    title: "Success",
                    variant: "success",
                    solid: true,
                });
            }   
            catch (error) {
                console.log(error);
                this.$root.$bvToast.toast("An error occurred", {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                });
            }
            finally {
                this.loading = false;
            }
            }
        },
    },
    computed: {
        showModal: {
            get() {
                return this.showCreateDialog;
            },
            set(value) {
                this.$emit("update:showCreateDialog", value);
            },
        },
        validateTitle() {
            return this.title.length >= 5;
        },
        validateBody() {
            return this.body.length >= 5;
        },
    },
    watch: {
        title(newTitle) {
            this.error.title = newTitle.length < 5 ? "Title must be at least 5 characters" : "";
        },
        body(newBody) {
            this.error.body = newBody.length < 5 ? "Body must be at least 5 characters" : "";
        },
        showModal(newVal) {
            if (!newVal) {
                this.title = "";
                this.body = "";
                this.isPinned = false;
                this.error = {
                    title: "",
                    body: "",
                };
                this.selectedGroup = [];
                this.expiryDate = "";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.invalidVueEditor {
    border: 1px solid red;
}
</style>
