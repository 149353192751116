import * as msal from "@azure/msal-browser";
import store from './index';
import axios from 'axios';

const authModule = {
  async init() {
    // console.log('init')
    const response = await axios.get("/api/init");
    // console.log('response',response)
    const logindata = response.data;
    store.commit("logindata", logindata);

    const msalConfig = {
      auth: {
        clientId: logindata.MS_APP_ID,
        authority: `https://login.microsoftonline.com/${logindata.TENANT_ID}`,
      },
    };
    
    const msalApp = new msal.PublicClientApplication(msalConfig);
    
    const accounts = msalApp.getAllAccounts();
    
    if (accounts.length > 0) {
        msalApp.setActiveAccount(accounts[0]);

        localStorage.setItem('loggedUsername',accounts[0].username)
        // console.log('added email',accounts[0].username)
        store.commit("msalApp", msalApp);
        store.commit("isAdmin", true);
        await this.prepareAxios(msalApp);
    } else {
        await this.handleRedirect(msalApp);
    }
},
async handleRedirect(msalApp) {
  // console.log('handleRedirect')
  try {
      await msalApp.handleRedirectPromise();
      const account = msalApp.getActiveAccount();
      if (account) {
          store.commit("msalApp", msalApp);
          store.commit("isAdmin", true);
          await this.prepareAxios(msalApp);
      } else {
          this.login(msalApp);
      }
  } catch (error) {
      console.error(error);
      this.login(msalApp);
  }
},

async login(msalApp) {
  console.log('login')
  const logindata = store.state.logindata;
  await msalApp.loginRedirect({
      scopes: [`https://${logindata.DATAVERSE_DOMAIN}/.default`],
      redirectUri: location.origin + "/",
  });
},

async prepareAxios() {
  // console.log('prepare axios')
  const accessToken = await this.getAccessToken();
  const instance = axios.create({
      baseURL: `${location.origin}/api`,
      headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
      },
  });
  // console.log()
  store.commit('api', instance);
},

async getAccessToken() {
  // console.log('getAccessToken')
  const msalApp = store.state.msalApp;
  const logindata = store.state.logindata;
  const accounts = msalApp.getAllAccounts();
  // console.log('accounts',accounts)
  if (accounts.length > 0) {
      msalApp.setActiveAccount(accounts[0]);
      const result = await msalApp.acquireTokenSilent({
          scopes: [`https://${logindata.DATAVERSE_DOMAIN}/.default`],
      });
      return result.accessToken;
  } else {
      throw new Error('No accounts found.');
  }
},
};

export default authModule;