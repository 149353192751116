<template lang="pug">
b-container(style="height: 85%")

    //-- modal filter client
    b-modal(id="filter-client" title="Filter Clients")
        template
            b-container
                b-row
                    b-col.col-6
                        b-form-group(label="Status:" v-model="selectedStatus")
                            b-form-checkbox-group.mt-3(style="display: flex; flex-direction: column; gap: 0.75rem" v-model="listActiveStatusSelected" :options="activeStatus" name="activeStatus")
                    b-col.col-6
                        b-form-group(label="Programme:" v-modal="selectedProgramme")
                            b-form-checkbox-group.mt-3(style="display: flex; flex-direction: column; gap: 0.75rem" v-model="listProgrammeSelected" :options="programmeStatus" name="programmeStatus")
        template(#modal-footer)
            b-button(variant="secondary" @click="$bvModal.hide('filter-client')") Close
    //-- modal filter client


    //- b-modal(:id="modal.modalClientId" v-for="client in modal.data" :key="client.crb5c_fow_customerid" title="Client Details Information ")
    //-     template#modal-title
    //-         b-col.my-1()
    //-             b-row {{ client.crb5c_no }}
    //-             b-row Care Giver: {{ clientCaregiver.crb5c_name }}
    //-             b-row.mt-4(style="color: blue; cursor: pointer" @click="redirectToClientPage(client.crb5c_fow_customerid)") To Client Page - Click Here
                
    //-             //- b-col.mx-3(style="font-weight: 600; font-size: 1.25rem") List of Assessment 
    //-             //-     b-row.mt-2(v-for="assessmentDetail in client.assessmentDetails" :key="assessmentDetail.assessmentCode")
    //-             //-         b-button.mmx-3(variant="outline-primary" size="sm" :href="assessmentDetail.redirectTo" target="_blank") {{ assessmentDetail.assessmentCode }} - {{ assessmentDetail.assessmentDate }}
    //-             //-     div.mx-0.mt-2(style="font-weight: 400" v-if="client.clientAssessment.length === 0") No Assessment found for {{ client.clientName }}
    //-         //- b-row.my-1(v-for="client in modal.data" :key="client.crb5c_no")
    //-             b-col.mx-3.mt-3(style="font-weight: 600; font-size: 1.25rem") List of Care Giver 
    //-                 b-col
    //-                     b-row(v-for="caregiver in client.clientCaregiver" :key="caregiver")
    //-                         li(style="font-weight: 400") {{ caregiver }}
    //-                 div.mx-0.mt-2(style="font-weight: 400" v-if="client.clientCaregiver.length === 0") No Care Giver found for {{ client.clientName }}
            
    //-         //- b-row.mt-4(v-for="client in modal.data" :key="client.id")
    //-             b-col.mx-3
    //-                 b-row.mt-4 
    //-                     b-button.mx-1(style="background-color: inherit; color: blue; border: none; font-size: small" :href="client.clientLink" target="_blank") To Client Page - Click Here
    //-     //- template(#modal-footer)
    //-         div(style="width: 100%; display: flex; justify-content: end")
    //-             b-button.px-4(variant="danger" style="font-weight: 500" @click="hideModal") Close


    b-col.mt-5
        b-row.mb-3
            b-col.col-7.col-sm-6
                h4.d-flex.justify-content-start.align-items-center List of Clients
            b-col.col-5.col-sm-2.align-self-center
                b-row
                    b-col.col-12.text-right
                        b-button.font-weight-bold(size="sm" style="background-color: #7C359F;" @click="$bvModal.show('filter-client')") Filter Clients
            b-col.col-12.col-sm-4.mt-3.mt-sm-0(style="d-flex justify-content-center align-items-center")
                b-row(style="height: 1%; margin-right: 0.1rem;")
                  b-col.col-12(class="d-flex align-items-center" style="position: relative;")
                    BIconSearch(style="position: absolute; left: 30px")
                    b-input(class="pr-1 pl-5 border border-dark d-flex justify-content-center align-items-center" style="border-radius: 20px;" placeholder="Search Name" type="text" v-model="inputSearchItemList")
            
        //- b-col(style="text-align: center;")
        //-     b-row.py-3(style="display: flex; align-items: center; justify-content: center; background-color: #50276D; color: white; font-weight: 400; font-size: medium; width: 102.2%")
        //-         b-col(cols="4" style="font-size: 0.85rem") Name
        //-         b-col(cols="2" class="d-flex justify-content-center align-items-center")
        //-             b-button(style="background-color: inherit; border: none; font-size: 0.85rem" @click="$bvModal.show('client-status')") Status
        //-                 b-icon.mx-1(font-scale="0.9" icon="filter")
        //-         b-col(cols="2" class="d-flex justify-content-center align-items-center")
        //-             b-button(style="background-color: inherit; border: none; font-size: 0.85rem" @click="$bvModal.show('client-programme')") Programme
        //-                 b-icon.mx-1(font-scale="0.9" icon="filter")
        //-         b-col(cols="2" style="font-size: 0.85rem") Last Active
        //-         b-col(cols="2" style="font-size: 0.85rem") Action

        //- b-col(class="table__body" style="")
            b-row.py-2(v-for="(client, index) in clientsList" :key="index" style="border: 1px solid black; display: flex; align-items: center; background-color: white")
                b-col(cols="4")
                    b-button(style="border: 0px; background-color: inherit; color: blue; font-size: medium; font-size: 0.85rem" @click="openModal(client.id)") {{ client.clientName }}
                b-col(cols="2" style="font-size: 0.85rem") {{ client.clientActiveStatus }}
                b-col(cols="2" style="font-size: 0.85rem") {{ client.clientLastActive }}
                b-col(cols="2" style="font-size: 0.85rem") {{ client.clientProgramme }}
                b-col(cols="2")
                    b-button.p-1(variant="info" style="border: none; font-size: 0.85rem" size="sm" :href="clients[0].assessmentLink" target="_blank") Assessment
        


        //- b-col(class="table__body" style="")
        //-     b-row.py-2(
        //-             v-if="clientsList.length !== 0"
        //-             v-for="client in clientsList"
        //-             :key="client.crb5c_fow_customerid" 
        //-             style="border: 1px solid black; display: flex; align-items: center; background-color: white"
        //-         )
        //-         b-col(cols="4")
        //-             b-button(style="border: 0px; background-color: inherit; color: blue; font-size: medium; font-size: 0.85rem" @click="openModal(client.crb5c_fow_customerid)") {{ client.crb5c_no }}
        //-         b-col(cols="2" style="font-size: 0.85rem") {{ client.status }}
        //-         b-col(cols="2" style="font-size: 0.85rem") {{ client.programme }}
        //-         b-col(cols="2" style="font-size: 0.85rem") {{ client.formattedCreateOn }}
        //-         b-col(cols="2")
        //-             b-button.p-1(variant="info" style="border: none; font-size: 0.85rem" size="sm" target="_blank" @click="redirectToClientAssessment(client.crb5c_fow_customerid)") Assessment
        //-     b-row.p-2.bg-white.align-items-center(
        //-             v-if="clientsList.length === 0"
        //-             style="height: 10rem;"
        //-         )
        //-         b-col.col-12
        //-             span.font-weight-bold(style="font-size: 1.2rem;") No data


        b-row
            //---- phone screen ----//
            b-col.col-12.d-block.d-sm-none(style="max-height: 35rem; overflow-y: auto;")
                b-table(
                    :fields="tableFields" 
                    :items="clientsList"
                    class="table-header-color"
                    thead-tr-class="text-white"
                    tbody-tr-class="bg-white"
                    :sort-by.sync="sortTable.by"
                    :sort-desc.sync="sortTable.desc"
                    show-empty
                )
                    template(#empty="scope")
                        b-row(v-if="isFetchingAdminDashboardData")
                            b-col.col-12
                                b-spinner(variant="info")
                        b-row(v-else)
                            b-col.col-12
                                span No data
                    template(#cell(crb5c_no)="data")
                        span.text-primary(
                            v-b-tooltip.hover :title="`Click to view ${data.item.crb5c_no} profile`"
                            style="cursor: pointer;" 
                            @click="$root.redirectToClientPage(data.item.crb5c_fow_customerid)"
                        ) {{ data.item.crb5c_no }}
                    template(#cell(Status)="data")
                        span {{ data.item.status }}
                    template(#cell(Programme)="data")
                        span {{ data.item.programme }}
                    template(#cell(lastActive)="data")
                        span {{ data.item.formattedCreateOn }}
                    template(#cell(Action)="data")
                        b-button.font-weight-bold(
                            variant="info" 
                            style="border: none; font-size: 0.85rem" 
                            size="sm" 
                            target="_blank" 
                            @click="$root.redirectToClientAssessment(data.item.crb5c_fow_customerid)"
                        ) Assessment
            //---- phone screen ----//
            //---- other screen ----//
            b-col.col-12.d-none.d-sm-block(style="max-height: 20rem; overflow-y: auto;")
                b-table(
                    :fields="tableFields" 
                    :items="clientsList"
                    class="table-header-color"
                    thead-tr-class="text-white"
                    tbody-tr-class="bg-white"
                    :sort-by.sync="sortTable.by"
                    :sort-desc.sync="sortTable.desc"
                    show-empty
                )
                    template(#empty="scope")
                        b-row(v-if="isFetchingAdminDashboardData")
                            b-col.col-12
                                b-spinner(variant="info")
                        b-row(v-else)
                            b-col.col-12
                                span No data
                    template(#cell(crb5c_no)="data")
                        span.text-primary(
                            v-b-tooltip.hover :title="`Click to view ${data.item.crb5c_no} profile`"
                            style="cursor: pointer;" 
                            @click="$root.redirectToClientPage(data.item.crb5c_fow_customerid)"
                        ) {{ data.item.crb5c_no }}
                    template(#cell(Status)="data")
                        span {{ data.item.status }}
                    template(#cell(Programme)="data")
                        span {{ data.item.programme }}
                    template(#cell(lastActive)="data")
                        span {{ data.item.formattedCreateOn }}
                    template(#cell(Action)="data")
                        b-button.font-weight-bold(
                            variant="info" 
                            style="border: none; font-size: 0.85rem" 
                            size="sm" 
                            target="_blank" 
                            @click="$root.redirectToClientAssessment(data.item.crb5c_fow_customerid)"
                        ) Assessment
            //---- other screen ----//
            
        b-row
            b-col.col-12.py-2.text-left
                span Total clients: {{ clientsList.length }}
        
        


</template>
    
<script>
import { mapState } from 'vuex';
import { ADMIN_DASHBOARD_NAMESPACE } from '../../store/Modules/AdminDashboard/types-admin-dashboard';
// import { RouterLink } from 'vue-router';

export default {
    data() {
        return {
            tableFields: [
                { 
                    key: "crb5c_no", 
                    label: "Name",
                    sortable: true,
                }, 
                "Status",
                "Programme",
                { 
                    key: "lastActive", 
                    label: "Last Active",
                },
                "Action", 
            ],
            sortTable: {
                by: "crb5c_no",
                desc: false,
            },
            selectedStatus: null,
            selectedProgramme: null,
            inputSearchItemList: '',
            clientsDB: [],
            caregiverDB: [],
            assessmentDB: [],
            listActiveStatusSelected: [],
            listProgrammeSelected: [],
            isFOWCheck: false,
            isCIPCheck: false,
            activeStatus: [
                {
                    text: 'Active',
                    value: 'Active'
                },
                {
                    text: 'Inactive',
                    value: 'Inactive'
                },
                {
                    text: 'Discharge',
                    value: 'Discharge'
                },
                {
                    text: 'Pending',
                    value: 'Pending'
                }
            ],
            programmeStatus: [
                {
                    text: 'CIP',
                    value: 'CIP'
                },
                {
                    text: 'FOW',
                    value: 'FOW'
                }
            ],
            
            modal: {
                modalClientId: 'unique-modal-id',
                data: null,
            },
            currentClient: null,
            clientCaregiver: null
        }
    },
    created() {
        this.listActiveStatusSelected = this.activeStatus.map(option => option.value);
        this.listProgrammeSelected = this.programmeStatus.map(option => option.value);
    },
    computed: {
        ...mapState({
            listClient: state => (state[ADMIN_DASHBOARD_NAMESPACE].adminDashboard.listClient),
            isFetchingAdminDashboardData: state => (state[ADMIN_DASHBOARD_NAMESPACE].adminDashboard.isFetchingAdminDashboardData),
        }),
      clientsList(){
        let filteredClients = this.listClient;
        
        if (this.inputSearchItemList.trim().length > 0) {
            const searchName = this.inputSearchItemList.trim().toLowerCase();
            filteredClients = filteredClients.filter((client) => client.crb5c_no.toLowerCase().includes(searchName));
        }
        if(this.listActiveStatusSelected !== 0){
            filteredClients = filteredClients.filter((item) => this.listActiveStatusSelected.includes(item.status))
        }
        if(this.listProgrammeSelected !== 0){
            filteredClients = filteredClients.filter((item) => this.listProgrammeSelected.includes(item.programme))
        }
        
        return filteredClients;

      }
    },
    methods: {
        backAdminHomepage() {
            this.$router.push({ path: '/adminhomepage' })
        },
        async openModal(clientId){
            // this.modal.modalClientId = `modal-${clientId}`;
            // this.modal.data = this.listModalData(this.modal.modalClientId)
            // this.$nextTick(() =>{
            //     this.$bvModal.show(this.modal.modalClientId);
            // })

            // console.log("data: ", this.modal.data);

            // this.currentClient = this.clientsList.find(client => client.crb5c_no === clientId)
            // this.$bvModal.show(this.modal.modalClientId);
            // console.log("openModal Opened", clientId, this.modal.modalClientId)

            // Generate a unique modal ID based on clientId
            this.currentClient = this.clientsList.find((client) => client.crb5c_fow_customerid === clientId);

            // Find the current client
            this.modal.data = [this.currentClient];

            // Set the modalClientId and show the modal
            this.$bvModal.show(this.modal.modalClientId);
            console.log("openModal Opened", clientId);

            this.clientCaregiver = this.caregiverDB.find((caregiver) => caregiver._crb5c_client_value === clientId)
            console.log("test:", this.clientCaregiver)

        },
        // listModalData(modalClientId){
        //     const clientId = modalClientId.split("-")[1]

        //     return this.clients
        //     .filter(client => client.id === +clientId)
        // },
        hideModal(){
            this.$bvModal.hide(this.modal.modalClientId)

            this.modal.modalClientId = null
            this.modal.data = null
        },
        moveToHomePage(){
            this.$router.push({ path: "/" })
        },
    },
    async mounted(){},
}
</script>

<style>
.table__body{
    text-align: center; 
    max-height: 250px; 
    overflow-y: scroll; 
    border-collapse: separate; 
    border-spacing: 0px;
}

.table__body::-webkit-scrollbar{
    width: 0.5rem;
    height: 0.5rem;
}

.table__body::-webkit-scrollbar-thumb{
    border-radius: 0.5rem;
    background-color: #0004;
}
</style>