<template lang="pug">
div(class="w-100 mx-auto  d-flex" style="gap: 0.5rem;height: 100vh")
    NavbarV2
    
    b-container()            
        //- Header(:title="typePage" :isAdmin="isAdmin")
        div(class="d-flex justify-content-end align-items-end mt-4").pt-5
            h3.mr-auto Announcements
            b-button.my-3(@click="showCreateDialog = true" variant="primary") Create Announcements
        b-overlay(:show="loading" spinner-variant="primary" spinner-type="grow" spinner-small)
            b-table(:items="announcements" :fields="fields" class="mt-2" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" striped hover responsive="sm")
            
                template(v-slot:cell(isPinned)="data")
                    span.badge(:class="{'badge-success': data.value && 'Pinned', 'badge-secondary': !data.value && 'Not Pinned'}") {{ data.value ? 'Pinned' : 'Not Pinned' }}
                    
            
                template(v-slot:cell(groups)="data")
                    div(v-for="group in data.value" :key="group.id") {{group.name}}
            
                template(v-slot:cell(expiryDate)="data")
                    p {{ data.value ? new Date(data.value).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' }) : 'No Expiry Date' }}
                template(v-slot:cell(body)="data")
                    div(:class="{'truncate-text': !expanded[data.index], 'expanded-text': expanded[data.index]}") 
                        div(v-html="data.value" class="w-100" style="white-space: pre-line")  
                    b-button(size="sm" variant="link" @click="toggleExpand(data.index)")  {{ expanded[data.index] ? 'Show Less' : 'Show More' }} 
            
                template(v-slot:cell(actions)="data")
                    b-dropdown(:text="'Actions'") 
                        b-dropdown-item(@click="setEditAnnouncement(data.item)") Edit
                        b-dropdown-item(@click="deleteAnnouncement(data.item)") Delete
            p(v-if="announcements.length === 0" class="my-5 text-lg") No Announcements, Create one!
            div(class="d-flex justify-content-center mt-4" v-if="announcements.length >= 4 && !loading")
                b-pagination(:total-rows="announcements.length" :per-page="perPage" v-model="currentPage")
    AddAnnouncementDialog(:showCreateDialog.sync="showCreateDialog" :groups="groups" @created="reloadData")
    EditAnnouncementDialog(:showEditDialog.sync="showEditDialog" :groups="groups" :announcement="editAnnouncement" @updated="reloadData")
</template>

<script>
import NavbarV2 from "../components/NavbarV2.vue";
import Header from '../components/Header.vue';
import AddAnnouncementDialog from '../components/announcements/AddAnnouncementDialog.vue';
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import EditAnnouncementDialog from '../components/announcements/EditAnnouncementDialog.vue';
export default {
    components: {
        NavbarV2,
        Header,
        AddAnnouncementDialog,
        EditAnnouncementDialog,
    },
    async mounted() {
        this.loading = true;
        try {
            const { sessions } = await this.getEveryGroup();

            if (!sessions || sessions.length === 0) {
                return;
            }

            const sessionMap = sessions.map(item => ({
                id: item.crb5c_fow_session_scheduleid,
                name: item.crb5c_session_id,
            }));
            let sortListGroupSession = this.$root.sortSessionGroupNameByDay(sessionMap);
            sortListGroupSession.unshift({ id: 'All', name: 'All' });


            this.groups = sortListGroupSession;

            const { data } = await axios.get('/api/announcement/v2');
            let { announcements } = data;
            let formatedAnnouncements = [];

            if (announcements) {
                announcements.forEach(announcement => {
                    let groupIds = JSON.parse(announcement.crb5c_recipient);
                    let groups = [];
                    groupIds.forEach(groupId => {
                        let group = this.groups.find(group => group.id === groupId);
                        if (group) {
                            groups.push(group);
                        }
                    });
                    formatedAnnouncements.push({
                        id: announcement.crb5c_fowannouncementid,
                        title: announcement.crb5c_title,
                        body: JSON.parse(announcement.crb5c_body),
                        isPinned: announcement.crb5c_ispinned,
                        groups: groups,
                        createdAt: new Date(announcement.createdon).toLocaleDateString(),
                        expiryDate: announcement.crb5c_expiry ? new Date(announcement.crb5c_expiry) : "",
                    });
                });
            }

            this.announcements = formatedAnnouncements;
        }
        catch (e) {
            console.log(e)
        }
        finally {
            this.loading = false;
        }
    },
    data() {
        return {
            typePage: "Announcements",
            showCreateDialog: false,
            announcements: [],
            groups: [],
            fields: [
                { key: "title", label: "Title" },
                { key: "body", label: "Body" },
                { key: "groups", label: "Groups" },
                { key: "isPinned", label: "Pinned" , sortable: true},
                { key: "createdAt", label: "Created", sortable: true },
                { key: "expiryDate", label: "Expiry Date", sortable: true },
                { key: "actions", label: "Actions" },
            ],
            perPage: 5,
            currentPage: 1,
            sortBy: "title",
            sortDesc: false,
            expanded: {},
            loading: false,
            showEditDialog: false,
            editAnnouncement: {},
        };
    },
    methods: {
        async deleteAnnouncement(item) {
            let choice = confirm('Are you sure you want to delete this announcement?');
            if (!choice) return;
            try {
                let index = this.announcements.findIndex(announcement => announcement.id === item.id);
                let res = await axios.put(`/api/announcement/v2/delete`, { id: item.id });
                if (res.status === 200) {
                    this.announcements.splice(index, 1);
                    this.$root.$bvToast.toast("Announcement deleted", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                    });
                }
            }
            catch (e) {
                console.log(e)
                this.$root.$bvToast.toast("Error deleting announcement", {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                });
            }

        },
        setEditAnnouncement(announcement) {
            this.editAnnouncement = announcement;
            this.showEditDialog = true;
        },

        async reloadData() {
            const { data } = await axios.get('/api/announcement/v2');
            let { announcements } = data;
            let formatedAnnouncements = [];

            if (announcements) {
                announcements.forEach(announcement => {
                    let groupIds = JSON.parse(announcement.crb5c_recipient);
                    let groups = [];
                    groupIds.forEach(groupId => {
                        let group = this.groups.find(group => group.id === groupId);
                        if (group) {
                            groups.push(group);
                        }
                    });
                    formatedAnnouncements.push({
                        id: announcement.crb5c_fowannouncementid,
                        title: announcement.crb5c_title,
                        body: JSON.parse(announcement.crb5c_body),
                        isPinned: announcement.crb5c_ispinned,
                        groups: groups,
                        createdAt: new Date(announcement.createdon).toLocaleDateString(),
                        expiryDate: announcement.crb5c_expiry ? new Date(announcement.crb5c_expiry) : "",
                    });
                });
            }

            this.announcements = formatedAnnouncements;
        },
        ...mapActions(['getEveryGroup']),
        toggleExpand(index) {
            this.$set(this.expanded, index, !this.expanded[index]);
        }
    },
    computed: {
        ...mapState(['isLoadingTwilio', 'isAdmin']),
    }
};
</script>
<style lang="scss" scoped>
.truncate-text {
    width: 100%;
    max-height: 3.6em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.expanded-text {
    max-height: none;
    white-space: normal;
}

@media (min-width: 768px) {
    .truncate-text {
        max-height: 6em;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}
</style>
