<template lang="pug">
b-modal(v-model="showModal" title="Edit Announcement" size="md" scrollable) 
    div(class="w-100  h-100")
        b-form-group(label="Title")
            b-form-input(v-model="title" placeholder="Enter Title" required :class="{ 'is-invalid': title.length < 1 && title.length > 0 }" )
        b-form-group(label="Body")
            div(:class="{ 'invalidVueEditor': body.length < 1 && body.length > 0 }")
                VueEditor(v-model="body" :editorToolbar="customToolbar" placeholder="Enter Body" required)
    
        b-form-group(label)
            v-select( id="event-group"  :options="groups" :reduce="item => ({ id: item.id, name: item.name })" multiple label="name" placeholder="Select Group" :class="{'invalid': selectedGroup.length === 0}" v-model="selectedGroup")
        div(class="d-flex justify-content-between")
            p Pinned 
            b-form-checkbox(v-model="isPinned" switch) 
        hr(v-if="isPinned")
        div(class="d-flex flex-column" v-if="isPinned")
            p Expiry Date for Pinned Announcement
            b-form-datepicker(v-model="expiryDate" :min="new Date()" placeholder="Select a Date" :class="{'invalid': expiryDate === ''}")
        div(class="d-flex flex-column my-3" v-if="isPinned")
            p Expiry Time for Pinned Announcement
            b-form-timepicker(v-model="expiryTime" placeholder="Select a Time" :class="{'invalid': expiryTime === ''}" locale="en" )

    template(v-slot:modal-footer)
        b-button(@click="showModal = false" :disabled="loading") Cancel
        b-button(@click="submit" variant="primary" :disabled="!validateTitle || !validateBody || loading") 
            span(v-if="loading") 
                b-spinner(type="grow" small)
            span(v-else) Update
        
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
export default {
    components: {
        VueEditor,
    },
    props: ["showEditDialog", "groups", "announcement"],

    data() {
        return {

            customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" },
                ],
                ["link"],
                ["clean"],
            ],
            title: "",
            body: "",
            selectedGroup: [],
            isPinned: false,
            error: {
                title: "",
                body: "",
            },
            loading: false,
            expiryDate: "",
            expiryTime: "",
            
        };
    },
    async mounted() {
        if (this.announcement) {
            
            this.title = this.announcement.title;
            this.body = this.announcement.body;
            this.isPinned = this.announcement.isPinned === 1 ? true : false;
            this.selectedGroup = this.announcement.groups;
            if (this.announcement.expiryDate && this.announcement.expiryDate !== "") {
                const expiryDateTime = new Date(this.announcement.expiryDate);
                expiryDateTime.setDate(expiryDateTime.getDate() + 1);
                this.expiryDate = expiryDateTime.toISOString().split("T")[0];
                this.expiryTime = expiryDateTime.toTimeString().split(" ")[0];
            }
            
        }    

    },
    methods: {
        
        consoleLog() {
            console.log(this.title)
            console.log(this.$root)
        },
        
        async submit () {
            if (this.validateTitle && this.validateBody) {
                this.loading = true;
                try{
                const data = {
                    id: this.announcement.id,
                    title: this.title,
                    body: JSON.stringify(this.body),
                    isPinned: this.isPinned,
                    groupIds: JSON.stringify(this.selectedGroup.map((item) => item.id),),
                    expiryDate: this.isPinned ? new Date(this.expiryDate + " " + this.expiryTime).toISOString() : null,
                };
                const res = await axios.put("/api/announcement/v2", data);
                if (res.status === 200) {
                    this.showModal = false;
                    this.$emit("updated");
                }
                this.$root.$bvToast.toast("Announcement updated", {
                    title: "Success",
                    variant: "success",
                    solid: true,
                });
            }
            catch(e){
                console.log(e)
                this.$root.$bvToast.toast("Error updating announcement", {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                });
            }
            finally{
                this.loading = false;
            }
            }
        },
    },
    computed: {


        showModal: {
            get() {
                return this.showEditDialog;
            },
            set(value) {
                this.$emit("update:showEditDialog", value);
            },
        },
        validateTitle() {
            if(!this.title) return
            return this.title.length >= 1;
        },
        validateBody() {
            if(!this.body) return
            return this.body.length >= 1;
        },
    },
    watch: {

        showModal(newVal) {
            if (!newVal) {
                this.title = "";
                this.body = "";
                this.isPinned = false;
                this.error = {
                    title: "",
                    body: "",
                };
                this.selectedGroup = [];
                this.expiryDate = "";
                this.expiryTime = "";
            }
            else {
                this.title = this.announcement.title;
                this.body = this.announcement.body;
                this.isPinned = this.announcement.isPinned === 1 ? true : false;
                this.selectedGroup = this.announcement.groups;
                if (this.announcement.expiryDate && this.announcement.expiryDate !== "") {
                    const expiryDateTime = new Date(this.announcement.expiryDate);
                    expiryDateTime.setDate(expiryDateTime.getDate() + 1);
                    this.expiryDate = expiryDateTime.toISOString().split("T")[0];
                    this.expiryTime = expiryDateTime.toTimeString().split(" ")[0];
                }
            }
        },
        announcement(newVal) {
            if (newVal) {
                this.title = newVal.title;
                this.body = newVal.body;
                this.isPinned = newVal.isPinned === 1 ? true : false;
                this.selectedGroup = newVal.groups;
                if (newVal.expiryDate && newVal.expiryDate !== "") {
                    const expiryDateTime = new Date(newVal.expiryDate);
                    expiryDateTime.setDate(expiryDateTime.getDate() + 1);
                    this.expiryDate = expiryDateTime.toISOString().split("T")[0];
                    this.expiryTime = expiryDateTime.toTimeString().split(" ")[0];
                }
            }
        },
    },
    
};
</script>