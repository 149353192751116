<template lang="pug">
div
    //-- phone screen --//
    b-alert.w-100(
        class="d-block d-sm-none"
        variant="danger"
        fade 
        dismissible
        :show="alert.dismissCountDown"
        @dismissed="alert.dismissCountDown=0" 
        @dismiss-count-down="callAlert"
      )
        | {{ alert.alertMessage }}
    //-- phone screen --//
    //-- other screen --//
    b-alert.w-100(
        class="d-none d-sm-block"
        variant="danger"
        style="position: fixed;"
        fade
        dismissible
        :show="alert.dismissCountDown" 
        @dismissed="alert.dismissCountDown=0" 
        @dismiss-count-down="callAlert"
      )
        | {{ alert.alertMessage }}
    //-- other screen --//
    b-container(style="height: 100vh;")
        Header(:title="typePage" :isAdmin="isAdmin")
        b-row(class="justify-content-center align-items-center" style="height: 3%;")
            b-col(class="col-12 d-flex align-items-center" style="position: relative;")
                BIconSearch(style="position: absolute; left: 30px")
                b-input(class="pr-1 pl-5 border border-dark" style="border-radius: 20px;" placeholder="Search announcement" v-model="inputSearchItemList")
        b-row(class="align-items-center mt-2" style="height: 10%;")
            b-col(class="col-9 col-sm-4 d-flex justify-content-start align-items-center clickable mt-3" style="gap: 1rem;" @click="moveToForm")
                Icon(icon="material-symbols:add-box-outline" style="font-size: 2rem;")
                span(class="text-left")
                    | Add new {{ typePage.toLowerCase() }}
        b-row(class="px-3 px-sm-0" style="height: 60%;")
            // TODO: fix style spinner, make it inside b-col 
            //- b-spinner(v-if="isShowSpinner" variant="success" style="z-index: 5; position: absolute; top: 50%; left: 50%;")
            b-col(class="col-12" style="max-height: 100%; overflow-y: scroll; border: 1px solid black; border-top: 0; margin-left: 0px;padding: 0px;")
                // TODO: change b-table-simple to b-table
                b-table(
                  :fields="tableFields"
                  :items="cloneItemList"
                  class="table-header-color"
                  thead-tr-class="text-white"
                  tbody-tr-class="bg-white"
                  show-empty
                )
                  template(#empty="scope")
                    b-row(v-if="!isLoadingTwilio && cloneItemList.length === 0")
                      b-col.col-12
                        span No Data
                    b-row(v-else)
                      b-col.col-12
                        b-spinner(variant="secondary")
                  template(#cell(title)="data")
                    span {{ data.item.title }}
                  template(#cell(message)="data")
                    //- span {{ data.item.message }}
                    span(v-html="data.item.message" v-if="data.item.message.includes('<')")
                    span(v-else) {{ data.item.message }}
                    
                  template(#cell(dateCreated)="data")
                    span {{ formatDateCreated(data.item.dateUpdated) }}
                  template(#cell(recipients)="data")
                    span {{ data.item.recipients }}
                  template(#cell(edit)="data")
                    div(v-b-tooltip.hover title="Edit announcement" @click="editItem(data.item.id)")
                      Icon(icon="lucide:pencil" style="width:1.5rem; height: 1.5rem; cursor: pointer;")
                  template(#cell(delete)="data")
                    div(@click="deleteItem(data.item.id, data.item.convId)")
                      Icon(v-b-tooltip.hover title="Delete announcement" icon="tabler:trash-x" style="width:1.5rem; height: 1.5rem; color: #c0392b; cursor: pointer;")
                      ModalDeleteConfirmation(:itemId="data.item.id" :tableType="typePage" :convId="data.item.convId" @alert-delete="callAlert" @notify-id="constructAlertMessage")

</template>
<script>
import DSG_logo from "@/assets/Images/DSG_logo.png";
import { BIconSearch, BIconXLg } from "bootstrap-vue";
import { Icon } from "@iconify/vue2";
import Header from '../components/Header.vue';
import ModalDeleteConfirmation from "@/components/ModalDeleteConfirmation.vue";
import { dynamicDeleteModalName } from "@/assets/Utilities/helperModal";
import { TABLE } from "../store/ModuleName";
import { mapState, mapActions} from "vuex";
import dayjs from "dayjs";
export default {
  name: "TablePage",
  components: {
    BIconSearch,
    BIconXLg,
    Icon,
    Header,
    ModalDeleteConfirmation,
  },
  props: {
    typePage: {
      type: String,
      required: true,
    },
    itemList: {
      type: Array,
      default() {
        return [
          {
            id: "1",
            title: "",
            message: "No data here",
            date: "",
            recipients: "",
          },
        ];
      },
    },
  },
  data() {
    return {
      adminStatus: false,
      DSG_logo,
      buttonColor: "rgb(118, 80, 137)",
      tableFields: [
          { 
            key: "title", 
            label: "Title",
          },
          { 
            key: "message",
            label: "Message",
          },
          { 
            key: "dateCreated",
            label: "Date Created",
          },
          { 
            key: "recipients", 
            label: "Recipients",
          },
          { 
            key: "edit", 
            label: "Edit",
          },
          { 
            key: "delete", 
            label: "Delete",
          },
      ],
      alert: {
        dismissSecs: 5,
        dismissCountDown: 0,
        showDismissibleAlert: true,
        alertMessage: "",
      },
      inputSearchItemList: "",
      stateSort: "",
      searchResult: [],
      cloneItemList: this.itemList,
      convId: null
    };
  },
  computed: {
    ...mapState(["isLoadingTwilio", "isAdmin"]),
  },
  methods: {
    ...mapActions(TABLE, ["removeAnnouncement"]),
    showDate(eventDate, isEvent = true) {
      if (!eventDate || !isEvent) {
        return 'Not an event'
      }
      if (eventDate?.includes('-')) {
        return this.$root.dayjs(eventDate, 'YYYY-MM-YYYY').format('DD/MM/YYYY')
      }
      if (eventDate === "Invalid date" || eventDate === "No Date") return "Not an event"

      return eventDate;
    },
    moveToHomePage() {
      this.$router.push({ path: "/" });
    },
    moveToForm() {
      if (this.typePage === "Announcements") {
        this.$router.push({ path: "/form/Announcements" });
      }

      if (this.typePage === "Reminders") {
        this.$router.push({ path: "/form/Reminders" });
      }
    },
    callAlert() {
      const SECOND = 3;
      this.alert.dismissCountDown = SECOND;
    },
    constructAlertMessage(id) {
      this.removeAnnouncement({"action": "remove", msgid:id, convId: this.convId});
      this.alert.alertMessage = `${this.typePage} ID: ${id} deleted.`;
    },
    formatDateCreated(rawDate){
      return dayjs(rawDate).format("D/M/YYYY");
    },
    editItem(id) {
      this.$router.push({ path: `/formEdit/${this.typePage}/${id}` });
    },
    deleteItem(id, convId) {
      this.convId = convId;
      this.$bvModal.show(dynamicDeleteModalName(id));
    },
    searchItems() {
      const search = this.inputSearchItemList.toLowerCase();
      const regex = new RegExp(search, "");

      this.cloneItemList = this.cloneItemList.filter((item) => {
        return regex.test(item.title.toLowerCase()) || regex.test(item.message.toLowerCase());
      });

      if (this.cloneItemList.length === 0) {
        

        return;
      }
    },
    clearSearchResults() {
      this.inputSearchItemList = "";

      // making axios request back
      this.cloneItemList = this.itemList;
    },
    testClickIcon() {
      console.log("hello");
    },
  },
  watch: {
    inputSearchItemList(value) {
      if (value === "") {
        this.clearSearchResults();
        return;
      }

      if (value !== "") {
        this.searchItems();
        return;
      }
    },
    itemList(value){
      this.cloneItemList = value;
    }
  },
};
</script>
<style lang="scss">
.clickable {
  cursor: pointer;
  
}</style>
