<template lang="pug">
b-container(style="height: 85%;")
    b-row.h-100
        b-col.col-12
            b-row(style="height: 20%")
                b-col.col-12.text-left
                    span.font-weight-bold(style="font-size: 1.5rem;") Report Page
            b-row(style="height: 60%")
                b-col.col-12
                    b-row.justify-content-center
                        b-col.col-6
                            b-form-group(label="From" label-for="report-date-from" :description="`*First record is on ${attendanceSession.firstSessionDateIsoString ? formatDate(attendanceSession.firstSessionDateIsoString) : 'Loading...'}`")
                                b-form-input(id="report-date-from" type="month" v-model="inputDate.dateFrom")
                    b-row.justify-content-center
                        b-col.col-6
                            b-form-group(label="To" label-for="report-date-to" :description="`*Last record is on ${attendanceSession.lastSessionDateIsoString ? formatDate(attendanceSession.lastSessionDateIsoString) : 'Loading...'}`")
                                b-form-input(id="report-date-to" type="month" v-model="inputDate.dateTo")
                b-col.col-12
                    b-button.font-weight-bold(variant="secondary" @click="clearDateInput") Clear
            b-row(style="height: 20%;")
                b-col.col-12
                    b-button.font-weight-bold.d-flex.flex-row.mx-auto(
                        variant="success" 
                        :disabled="isDownloadingExcel.partialReport"
                        style="gap: 0.5rem;" 
                        @click="downloadReportWithRangeDate"
                    ) 
                        span Download Attendance Excel Report
                        b-icon(icon="download")
                        b-spinner(
                            v-if="isDownloadingExcel.partialReport"
                            variant="secondary" small
                        )
                b-col.col-12
                    b-button.font-weight-bold.d-flex.flex-row.mx-auto(
                        variant="info"
                        :disabled="isDownloadingExcel.fullReport"
                        style="gap: 0.5rem;"
                        @click="downloadFullReport"
                    ) 
                        //- @click="downloadFullReport"
                        span Download Attendance Full Report
                        b-icon(icon="download")
                        b-spinner(
                            v-if="isDownloadingExcel.fullReport"
                            variant="secondary" small
                        )

</template>
    
<script>
import { ADMIN_DASHBOARD_NAMESPACE, ACTIONS } from "../../store/Modules/AdminDashboard/types-admin-dashboard";
import { mapActions } from 'vuex'; 
import XLSX from "xlsx-js-style";
import dayjs from "dayjs";

export default {
    data() {
        return {
            attendanceSession: {
                firstSessionDateIsoString: null,
                lastSessionDateIsoString: null,
            },
            inputDate: {
                dateFrom: null,
                dateTo: null,
            },
            listSessionType: [
                "Group HQ (Centre) Based", 
                "One-to-one HQ (Centre) Based", 
                "Home Based", 
                "Virtual/Online/Zoom"
            ],
            listData: {
                listGroupHqCentreBased: [],
                listOneToOneHqCentreBased: [],
                listHomeBased: [],
                listVirtualOnlineZoom: [],
            },
            isDownloadingExcel: {
                fullReport: false,
                partialReport: false,
            },
        }
    },
    computed: {},
    methods: {
        ...mapActions(ADMIN_DASHBOARD_NAMESPACE, [
            ACTIONS.DB_GET_SESSION_ATTENDANCE,
            ACTIONS.DB_GET_FIRST_AND_LAST_SESSION,
        ]),
        formatDate(rawDateIsoString){
            return dayjs(rawDateIsoString).format("D MMM YYYY");
        },
        validateDate(dateFrom, dateTo){
            const dateFromConverted = dayjs(dateFrom).add(1, "month");
            const dateToConverted = dayjs(dateTo);
            const isDateCorrect = dateFromConverted.subtract(1, "month").isBefore(dateToConverted);

            if(!dateFrom || !dateTo){
                const message = "Please enter the date"; 
                alert(message);
                throw new Error(message);
            }

            if(!isDateCorrect){
                const message = "Please correct the date";
                alert(message);
                throw new Error(message);
            }

            const isDateNotBeforeFirstRecord = dayjs(dateFromConverted).isAfter(this.attendanceSession.firstSessionDateIsoString);
            const isDateToNotExceedTodaysMonthAndYear = dayjs(dateToConverted).isBefore(this.attendanceSession.lastSessionDateIsoString);
            
            if(!isDateNotBeforeFirstRecord){
                const message = "Incorrect From Date";
                alert(message);
                throw new Error(message);
            }
            
            if(!isDateToNotExceedTodaysMonthAndYear){
                const message = "Incorrect To Date";
                alert(message);
                throw new Error(message);
            }

            return [
                dateFromConverted.subtract(1, "month").toISOString(), 
                dateToConverted.add(1, "month").toISOString()
            ];


        },
        clearDateInput(){
            this.inputDate.dateFrom = null;
            this.inputDate.dateTo = null;
        },
        async getSessionAttendanceReport(isDownloadingFullReport, dateFromIsoString, dateToIsoString){
            const data = await this[ACTIONS.DB_GET_SESSION_ATTENDANCE]({
                isDownloadingFullReport,
                dateFromIsoString, 
                dateToIsoString
            });

            if(!data){
                return null;
            }

            console.log("data: ", data);

            this.listData.listGroupHqCentreBased = data.filter(item => item.sessionType === this.listSessionType[0]);
            this.listData.listOneToOneHqCentreBased = data.filter(item => item.sessionType === this.listSessionType[1]);
            this.listData.listHomeBased = data.filter(item => item.sessionType === this.listSessionType[2]);
            this.listData.listVirtualOnlineZoom = data.filter(item => item.sessionType === this.listSessionType[3]);

            return true;

        },
        processTheExcelExporting(isFullReport){
            const listMonth = ["January", "February", "March", "April", "May", "June", "July", "August", "September","October","November","December"];
            const { 
                listGroupHqCentreBased, 
                listHomeBased,
                listOneToOneHqCentreBased, 
                listVirtualOnlineZoom 
            } = this.listData;
            const listKeyName = ["Client Name", "Session Name", "Session Type", "Checkin Time"];
            const getObjMapped = (item) => {
                return {
                    [listKeyName[0]]: item.crb5c_client.crb5c_no,
                    [listKeyName[1]]: item.crb5c_session.crb5c_sessionname,
                    [listKeyName[2]]: item.sessionType,
                    [listKeyName[3]]: item.crb5c_checkin_time,
                }
            }

            //---- filter to exclude CIP from report
            // FIX: "Wednesday AM CIP 星期二 （CIP）早上 中心" still not filtered
            const mappedListGroupHqCentreBased = listGroupHqCentreBased
            .map(item => (getObjMapped(item)))
            .filter(item => {
                const regex = /CIP/i;
                const hasCIP = regex.test(item[listKeyName[1]]);

                if(hasCIP){
                    return;
                }

                return item;
            });
            // console.log("mappedListGroupHqCentreBased: ", mappedListGroupHqCentreBased);
            const mappedHomeBased = listHomeBased.map(item => (getObjMapped(item)));
            const mappedListOneToOneHqCentreBased = listOneToOneHqCentreBased.map(item => (getObjMapped(item)));
            const mappedListVirtualOnlineZoom = listVirtualOnlineZoom.map(item => (getObjMapped(item)));

            const worksheets = {
                sheetCentreBased: mappedListGroupHqCentreBased.concat(mappedListOneToOneHqCentreBased),
                sheetHomeBased: mappedHomeBased.concat(mappedListVirtualOnlineZoom),
            }

            const groupingByYearMonth = (sheetType, typeGrouped) => {
                for (const item of sheetType) {
                    const checkinTime = dayjs(item["Checkin Time"]);
                    const yearMonth = checkinTime.format('YYYY-MM');

                    if (!typeGrouped[yearMonth]) {
                        typeGrouped[yearMonth] = [];
                    }

                    const modifyItem = {
                        ...item,
                        [listKeyName[3]]: dayjs(item[listKeyName[3]]).format("DD/MM/YYYY"), 
                    }
                    typeGrouped[yearMonth].push(modifyItem);
                }
            }
            const centreBasedGroupedDataByYearMonth = {};
            const homeBasedGroupedDataByYearMonth = {};
            groupingByYearMonth(worksheets.sheetCentreBased, centreBasedGroupedDataByYearMonth);
            groupingByYearMonth(worksheets.sheetHomeBased, homeBasedGroupedDataByYearMonth);

            const newBook = XLSX.utils.book_new();

            // home based
            for(const [key, value] of Object.entries(homeBasedGroupedDataByYearMonth)){
                const [year, month] = key.split("-");


                // // example data
                // [
                //     [
                //         [
                //             "Tuesday PM (One-to-one - Home) Week 1", 
                //             "Time", 
                //             "Session 1", 
                //             "Session 2", 
                //             "Session 3", 
                //             "Session 4", 
                //             "Session 5", 
                //             "Session 6", 
                //             "Session 7", 
                //             "Session 8", 
                //             "Session 9", 
                //             "Session 10", 
                //             "Rate", 
                //             "Count"
                //             "Staff"
                //         ],
                //         [
                //             "Tuesday PM (One-to-one - Home) Week 1", 
                //             "", 
                //             "09/12/2023", 
                //             "", 
                //             "", 
                //             "", 
                //             "", 
                //             "", 
                //             "", 
                //             "", 
                //             "", 
                //             "", 
                //             "", 
                //             "0"
                //             ""
                //         ],
                //     ],
                // ]
                // // example data


                const mappedValue = value.map(item => ({
                    ...item,
                    "Session Name": item["Session Name"].replace(/Week \d+/i, '').trim()
                }));
                const groupBySessionName = Object.groupBy(mappedValue, item => item[listKeyName[1]]);
                // console.log(groupBySessionName);
                
                let transformedData = {};
                // make this a set, make every client unique, dont have a duplicate,
                Object.keys(groupBySessionName).forEach(sessionName => {
                    transformedData[sessionName] = groupBySessionName[sessionName].map(session => ({
                        "Client Name": session["Client Name"],
                        "Session Name": session["Session Name"],
                        "Session Type": session["Session Type"],
                        "listSession": groupBySessionName[sessionName].map(item => item["Checkin Time"]),
                    }));
                });

                // Create a set to track unique client names and sessions within each group
                const uniqueData = {};
                // Filter out duplicates and make "listSession" unique within each group
                for (const key in transformedData) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (transformedData.hasOwnProperty(key)) {
                        const sessions = transformedData[key];
                        const uniqueSessions = [];

                        const uniqueClientNames = new Set();

                        sessions.forEach(session => {
                        const clientName = session["Client Name"];
                        const listSession = [...new Set(session["listSession"])]; // Use Set to make "listSession" unique

                        if (!uniqueClientNames.has(clientName)) {
                            uniqueClientNames.add(clientName);
                            uniqueSessions.push({ ...session, listSession });
                        }
                        });

                        uniqueData[key] = uniqueSessions;
                    }
                }


                const result = [];
                for (const key in uniqueData) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (uniqueData.hasOwnProperty(key)) {
                        const sessions = uniqueData[key];

                        const groupData = [
                            [
                                "",
                                key,
                                "Session 1",
                                "Session 2",
                                "Session 3",
                                "Session 4",
                                "Session 5",
                                "Session 6",
                                "Session 7",
                                "Session 8",
                                "Session 9",
                                "Session 10",
                                "Rate",
                                "Count"
                            ]
                        ];

                        sessions.forEach(session => {
                            const clientName = session["Client Name"];
                            const listSession = session["listSession"];

                            const clientRow = ["", clientName];

                            // Push "listSession" values to the corresponding "Session" slots
                            for (let i = 0; i < 10; i++) {
                                clientRow.push(listSession[i] || "");
                            }

                            // Push empty values for remaining "Session" slots
                            for (let i = clientRow.length; i < groupData[0].length - 2; i++) {
                                clientRow.push("");
                            }

                            // Add "Rate" and "Count" placeholders
                            clientRow.push("");
                            clientRow.push(`${listSession.length}`);

                            // Add the client row to the group data
                            groupData.push(clientRow);
                        });

                        result.push(groupData);
                        // make empty row
                        result.push(["", "", "", "", "", "", "", "", "", ""]);
                        result.push(["", "", "", "", "", "", "", "", "", ""]);
                    }
                }
                

                const sheetHomeBased = XLSX.utils.aoa_to_sheet(result[0], { origin: 4 });
                // sheetHomeBased["!cols"] = Object.keys(value[0]).map(item => ({
                //     wch: item.length + 10
                // }));
                XLSX.utils.sheet_add_aoa(sheetHomeBased, [['ATTENDANCE RECORD (Home-Based)']], { origin: "B2" });
                XLSX.utils.sheet_add_aoa(sheetHomeBased, [[`${listMonth[month-1]} ${year}`]], { origin: "B3" });
                
                const longestColumnWordOne = 'ATTENDANCE RECORD (Centre-Based)';
                sheetHomeBased["!cols"] = [
                    {
                        wch: 5,
                    },
                    { 
                        wch: longestColumnWordOne.length + 18,
                    }
                ];

                const outputArrayWithoutFirstElement = result.slice(1);
                outputArrayWithoutFirstElement.forEach((item) => {
                    XLSX.utils.sheet_add_aoa(sheetHomeBased, item, { origin: -1 });
                });

                XLSX.utils.book_append_sheet(newBook, sheetHomeBased, `${year} ${month}_Home`);
            }

            // centre based
            for(const [key, value] of Object.entries(centreBasedGroupedDataByYearMonth)){
                const [year, month] = key.split("-");
                const categoryList = [
                    ["", "Category", "Mild (Grp)", "Mild/Moderate (G)", "Moderate (existing)", "Foreigner (G)", "2-HR one-to-one (C)", "One to one", "1-1 (1.5hr)", "1-1 (1hr)", "CIP"],
                    ["", "Rate wef 01/01/2023", "80.00", "80.00", "80.00", "80.00", "160.00", "120.00", "90.00", "80.00", ""],
                ];

                // // example data
                // [
                //     [
                //         ["Thursday Am", "Session 1", "Session 2", "Session 3", "Session 4", "Session 5", "Session 6", "Rate", "Count"],
                //         ["Sng Lian Eng", "03/01/2023", "", "", "", "", "", "10"],
                //     ],
                //     [
                //         ["Thursday Am", "Session 1", "Session 2", "Session 3", "Session 4", "Session 5", "Session 6", "Rate", "Count"],
                //         ["Sng Lian Eng", "03/01/2023", "", "", "", "", "", "10"],
                //     ],
                // ]
                // // example data

                const mappedValue = value.map(item => ({
                    ...item,
                    "Session Name": item["Session Name"].replace(/Week \d+/i, '').trim()
                }));
                const groupBySessionName = Object.groupBy(mappedValue, item => item[listKeyName[1]]);
                // console.log(groupBySessionName);
                
                let transformedData = {};
                // make this a set, make every client unique, dont have a duplicate,
                Object.keys(groupBySessionName).forEach(sessionName => {
                    transformedData[sessionName] = groupBySessionName[sessionName].map(session => ({
                        "Client Name": session["Client Name"],
                        "Session Name": session["Session Name"],
                        "Session Type": session["Session Type"],
                        "listSession": groupBySessionName[sessionName].map(item => item["Checkin Time"]),
                    }));
                });
                // console.log(transformedData);
                

                // Create a set to track unique client names and sessions within each group
                const uniqueData = {};

                // Filter out duplicates and make "listSession" unique within each group
                for (const key in transformedData) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (transformedData.hasOwnProperty(key)) {
                        const sessions = transformedData[key];
                        const uniqueSessions = [];

                        const uniqueClientNames = new Set();

                        sessions.forEach(session => {
                            const clientName = session["Client Name"];
                            const listSession = [...new Set(session["listSession"])]; // Use Set to make "listSession" unique

                            if (!uniqueClientNames.has(clientName)) {
                                uniqueClientNames.add(clientName);
                                uniqueSessions.push({ ...session, listSession });
                            }
                        });

                        uniqueData[key] = uniqueSessions;
                    }
                }


                const result = [];
                for (const key in uniqueData) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (uniqueData.hasOwnProperty(key)) {
                        const sessions = uniqueData[key];

                        const groupData = [
                            [
                                "",
                                key,
                                "Session 1",
                                "Session 2",
                                "Session 3",
                                "Session 4",
                                "Session 5",
                                "Session 6",
                                "Rate",
                                "Count"
                            ]
                        ];

                        sessions.forEach(session => {
                            const clientName = session["Client Name"];
                            const listSession = session["listSession"];

                            const clientRow = ["", clientName];

                            // Push "listSession" values to the corresponding "Session" slots
                            for (let i = 0; i < 6; i++) {
                                clientRow.push(listSession[i] || "");
                            }

                            // Push empty values for remaining "Session" slots
                            for (let i = clientRow.length; i < groupData[0].length - 2; i++) {
                                clientRow.push("");
                            }

                            // Add "Rate" and "Count" placeholders
                            clientRow.push("");
                            clientRow.push(`${listSession.length}`);

                            // Add the client row to the group data
                            groupData.push(clientRow);
                        });

                        result.push(groupData);
                        // make empty row
                        result.push(["", "", "", "", "", "", "", "", "", ""]);
                        result.push(["", "", "", "", "", "", "", "", "", ""]);
                    }
                }


                const sheetCentreBased = XLSX.utils.aoa_to_sheet(result[0], { origin: 7 });
                // sheetCentreBased["!cols"] = Object.keys(value[0]).map(item => ({
                //     wch: item.length + 10
                // }));
                XLSX.utils.sheet_add_aoa(sheetCentreBased, [['ATTENDANCE RECORD (Centre-Based)']], { origin: "B2" });
                XLSX.utils.sheet_add_aoa(sheetCentreBased, [[`${listMonth[month-1]} ${year}`]], { origin: "B3" });
                XLSX.utils.sheet_add_aoa(sheetCentreBased, categoryList, { origin: 4 });
                
                const longestColumnWordOne = "ATTENDANCE RECORD (Centre-Based)";
                const longestColumnWordTwo = "2-HR one-to-one (C)";
                
                const columns = ["B", "C", "D", "E", "F", "G", "H", "I", "J", "K"];
                const indexCol = ["5", "6"];
                const backgroundColors = ["D9E1F2", "4472C4", "FCE4D6", "C65911", "F4B084", "92D050", "FFC000", "E2EFDA", "D9D9D9"];
                const style = {
                    // fill: { 
                    //     fgColor: { rgb: 'FFFF00' } 
                    // },
                    border: { 
                        top: { 
                            style: 'thin', 
                            color: { 
                                rgb: '000000' 
                            } 
                        }, 
                        right: { 
                            style: 'thin', 
                            color: { 
                                rgb: '000000' 
                            } 
                        }, 
                        bottom: { 
                            style: 'thin', 
                            color: { 
                                rgb: '000000' 
                            } 
                        }, 
                        left: { 
                            style: 'thin', 
                            color: { 
                                rgb: '000000' 
                            } 
                        } 
                    },
                }


                for(const [index, value] of columns.entries()){
                    if(value === "B"){
                        sheetCentreBased[value + indexCol[0]].s = style;
                        sheetCentreBased[value + indexCol[1]].s = style;
                    }
                    else{
                        const modStyle = {
                            ...style,
                            fill: { 
                                fgColor: { rgb: backgroundColors[index - 1] } 
                            },
                        }
                        sheetCentreBased[value + indexCol[0]].s = modStyle;
                        sheetCentreBased[value + indexCol[1]].s = modStyle;
                    }
                }

                sheetCentreBased["!cols"] = [
                    {
                        wch: 5,
                    },
                    { 
                        wch: longestColumnWordOne.length + 25,
                    },
                    {
                        wch: longestColumnWordTwo.length,
                    },
                    {
                        wch: longestColumnWordTwo.length,
                    },
                    {
                        wch: longestColumnWordTwo.length,
                    },
                    {
                        wch: longestColumnWordTwo.length,
                    },
                    {
                        wch: longestColumnWordTwo.length,
                    },
                    {
                        wch: longestColumnWordTwo.length,
                    },
                    {
                        wch: longestColumnWordTwo.length,
                    },
                    {
                        wch: longestColumnWordTwo.length,
                    },
                ];

                const outputArrayWithoutFirstElement = result.slice(1);
                outputArrayWithoutFirstElement.forEach((item) => {
                    XLSX.utils.sheet_add_aoa(sheetCentreBased, item, { origin: -1 });
                });

                XLSX.utils.book_append_sheet(newBook, sheetCentreBased, `${year} ${month}_Centre`);
            }

            XLSX.writeFile(newBook,"Client_Attendance_Report.xlsx");

            if(isFullReport){
                this.isDownloadingExcel.fullReport = false;
            }else{
                this.isDownloadingExcel.partialReport = false;
            }

        },
        async downloadFullReport(){
            try {    
                this.isDownloadingExcel.fullReport = true;
    
                const isSuccess = await this.getSessionAttendanceReport(true, null, null);
                
                if(!isSuccess){
                    alert("Error downloading excel report");
                    this.isDownloadingExcel.fullReport = false;
                    return;
                }

                this.processTheExcelExporting(true);
    
            } catch (error) {
                console.error(error);
            }
        },
        async downloadReportWithRangeDate(){
            try {
                const { dateFrom, dateTo } = this.inputDate;
                const [dateFromIsoString, dateToIsoString] = this.validateDate(dateFrom, dateTo);
    
                this.isDownloadingExcel.partialReport = true;
    
                const isSuccess = await this.getSessionAttendanceReport(false, dateFromIsoString, dateToIsoString);
                
                if(!isSuccess){
                    alert("Error downloading excel report");
                    this.isDownloadingExcel.partialReport = false;
                    return;
                }
    
                this.processTheExcelExporting(false);

            } catch (error) {
                console.error(error);
            }

        },
        downloadSampleTwo(){
            //----- style
            const style = {
                font: {
                    sz: 13,
                    bold: true,
                    color: { rgb: "000000" },
                },
                fill: { 
                    fgColor: { rgb: 'FFFF00' } 
                }, 
                alignment: { 
                    vertical: 'center', 
                    horizontal: 'center' ,
                    wrapText: true
                },
                border: { 
                    top: { 
                        style: 'medium', 
                        color: { 
                            rgb: '000000' 
                        } 
                    }, 
                    right: { 
                        style: 'medium', 
                        color: { 
                            rgb: '000000' 
                        } 
                    }, 
                    bottom: { 
                        style: 'medium', 
                        color: { 
                            rgb: '000000' 
                        } 
                    }, 
                    left: { 
                        style: 'medium', 
                        color: { 
                            rgb: '000000' 
                        } 
                    } 
                },
            };
            //----- style


            const data = [
                { 
                    name: "",
                    test: "test2",
                    key: "test3",
                },
            ];
            const listTitle = [
                "ATTENDANCE RECORD (Centre-Based)",
            ];
            const columnWidths = listTitle.map(item => ({
                wch: item.length + 10
            }));

            const columns = ["A"];
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(data);
            XLSX.utils.sheet_add_aoa(worksheet, [listTitle], { origin: "A1" });
            // insert empty row
            // XLSX.utils.sheet_add_aoa(worksheet, [], { origin: "A2" });
            // insert empty row
            worksheet["!cols"] = columnWidths;
            worksheet["!rows"] = [
                { 
                    hpt: 50
                }
            ];
            //apply the style to the header
            for (const col of columns) {
                worksheet[col + "1"].s = style;
            }

            XLSX.utils.book_append_sheet(workbook, worksheet);
            /* generate an XLSX file */
            XLSX.writeFile(workbook, `Client_Import_Template.xlsx`);
        },
        downloadSample() {
            let data = [
                { 
                    name: "", 
                    age:"", 
                    gender:"",
                    enqID: "", 
                    outcomePlanID: "", 
                    outcomePlanStatus:"", 
                    caseOpenDate:"", 
                    caseCloseDate:""  
                },
            ]
            const BORDER_ALL = { 
                border: { 
                    top: { 
                        style: 'medium', 
                        color: { 
                            rgb: '000000' 
                        } 
                    }, 
                    right: { 
                        style: 'medium', 
                        color: { 
                            rgb: '000000' 
                        } 
                    }, 
                    bottom: { 
                        style: 'medium', 
                        color: { 
                            rgb: '000000' 
                        } 
                    }, 
                    left: { 
                        style: 'medium', 
                        color: { 
                            rgb: '000000' 
                        } 
                    } 
                } 
            };
            const TITLE = [
                "Client Name (Only For FSCs)",
                "Age",
                "Gender",
                "Enquiry ID",
                "Outcome Plan ID",
                "Outcome Plan Status",
                "Case Open Date (DD/MM/YYYY)",
                "Case Closure Date (DD/MM/YYYY)"
            ];
            const columnWidths = [];
            for (let i = 0; i < TITLE.length; i++) {
                columnWidths.push({ wch: TITLE[i].length + 10 });
            }
            const style = {
                font: {
                    sz: 13,
                    bold: true,
                    color: { rgb: "000000" },
                },
                fill: { 
                    fgColor: { rgb: 'FFFF00' } 
                }, 
                alignment: { 
                    vertical: 'center', 
                    horizontal: 'center' ,
                    wrapText: true
                },
                ...BORDER_ALL,
            };
            const columns = ["A", "B", "C", "D","E","F","G", "H"];
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(data);
            XLSX.utils.sheet_add_aoa(ws, [TITLE], { origin: "A1" });
            ws["!cols"] = columnWidths;
            ws["!rows"] = [
                { 
                    hpt: 50
                }
            ];
            //apply the style to the header
            for (const col of columns) {
                ws[col + "1"].s = style;
            }
            XLSX.utils.book_append_sheet(wb, ws);
            /* generate an XLSX file */
            XLSX.writeFile(wb, `Client_Import_Template.xlsx`);
        },
    },
    async mounted(){
        try {
            const { firstSession, lastSession } =  await this[ACTIONS.DB_GET_FIRST_AND_LAST_SESSION]();
            this.attendanceSession.firstSessionDateIsoString = dayjs(firstSession.crb5c_checkin_time).toISOString();
            this.attendanceSession.lastSessionDateIsoString = dayjs(lastSession.crb5c_checkin_time).toISOString();
            
        } catch (error) {
            console.error(error);
        }

    }
}
</script>

<style>
</style>