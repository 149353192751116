<template lang="pug">
b-modal#modalAdd(title="Add Event" v-model="showModal"  class="rounded-lg overflow-auto" size="lg" scrollable no-close-on-backdrop)
    b-overlay(:show="loading")
        div(class="d-flex flex-column")
        b-form-group(label="Event Name (Required)" label-for="eventName")
            b-form-input(v-model="event.name" id="eventName" placeholder="Enter Event Name" required :class="{'is-invalid': event.name.length === 0}")
        b-form-group(label="Select Group (Required)" label-for="selectGroup")
            v-select( id="event-group"  :options="groups" :reduce="item => ({ id: item.id, name: item.name })"  v-model="event.selectedGroup" multiple label="name" placeholder="Select Group" :class="{'invalid': event.selectedGroup.length === 0}")
        b-form-group(label="Description (Required)" label-for="description")
            VueEditor(v-model="event.description" :editorToolbar="customToolbar"  placeholder="Enter Description"  required :class="{'invalid': event.description.length === 0}")
        b-form-group(label="Event Type (Required)" label-for="isSingleDay")
            b-form-radio-group(v-model="event.isSingleDay")
                b-form-radio(:value="true") Single Day
                b-form-radio(:value="false") Multiple Days
        b-form-group(v-if="event.isSingleDay" label="Single Day Event" class="my-2")
            b-form-group(label="Date (Required)" label-for="date")
                b-form-input(v-model="event.singleDay.date" id="date" type="date" required :class="{'is-invalid': !event.singleDay.date}" :min="new Date().toISOString().split('T')[0]")
            b-form-group(label="Time (Required)" label-for="time" class="w-100" :class="{'is-invalid': !event.singleDay.time}"  )
                //- b-form-input(v-model="event.singleDay.time" id="time" type="time" required :class="{'is-invalid': !event.singleDay.time}" ) 
                vue-timepicker(v-model="event.singleDay.time" format="hh:mm A" :minute-interval="5" id="time" required input-width="100%")
            b-form-group(label="Itineraries For Single Day (Optional)" label-for="itineraries").w-100
                div(class="d-flex" style="gap: 10px")
                    b-form-input(v-model="tempData.itineraries.time" id="itineraries" placeholder="Enter Time" required type="time")
                    b-form-input(v-model="tempData.itineraries.description" placeholder="Enter Description" required)
                    b-button(@click="addItinerary") Add
                h5.mt-4(v-if="event?.singleDay?.itineraries.length !==0") Itineraries
                div(v-for="itinerary in event.singleDay?.itineraries" :key="itinerary.id" class="d-flex justify-content-between align-items-center my-2" style="gap: 10px")
                    //- b-form-input(v-model="itinerary.time" type="time" required)
                    vue-timepicker(v-model="itinerary.time" format="hh:mm A" :minute-interval="5" required input-width="100%")
                    b-form-input(v-model="itinerary.description" required)
                    b-button(@click="removeItinerarySingle(itinerary.id)" variant="danger") Remove
            b-form-group(label="Lunch For Single Day (Optional)" label-for="lunch").w-100
                div(class="d-flex" style="gap: 10px")
                    b-form-input(v-model="tempData.food.lunch" id="lunch" placeholder="Enter Lunch" required)
                    b-button(@click="addLunch") Add
                h5.mt-4(v-if="event?.singleDay?.food?.lunch.length !==0") Lunch
                div(v-for="lunch in event.singleDay?.food?.lunch" :key="lunch.id" class="d-flex justify-content-between align-items-center my-2" style="gap: 10px")
                    b-form-input(v-model="lunch.name" required)
                    b-button(@click="removeLunch(lunch.id)" variant="danger") Remove
            b-form-group(label="Beverages For Single Day (Optional)" label-for="beverages").w-100
                div(class="d-flex" style="gap: 10px")
                    b-form-input(v-model="tempData.food.beverage" id="beverages" placeholder="Enter Beverages" required)
                    b-button(@click="addBeverage") Add
                h5.mt-4(v-if="event?.singleDay?.food?.beverages.length !==0") Beverages
                div(v-for="beverage in event.singleDay?.food?.beverages" :key="beverage.id" class="d-flex justify-content-between align-items-center my-2" style="gap: 10px")
                    b-form-input(v-model="beverage.name" required)
                    b-button(@click="removeBeverage(beverage.id)" variant="danger") Remove
            b-form-group(label="Price Per Person (Required)" label-for="pricePerPerson")
                    b-input-group
                        b-input-group-prepend
                            span(class="input-group-text") $
                        b-form-input(v-model="event.singleDay.pricePerPerson" id="pricePerPerson" type="number")
        b-form-group(v-else label="Multiple Day Events" class="my-2")
            b-form-group(v-for="day in event.multipleEvents" :key="day.id" class="my-2")
                p Day {{event.multipleEvents.indexOf(day) + 1}}
                b-form-group(label="Event Title (Required)" label-for="eventTitle")
                    b-form-input(v-model="day.title" id="eventTitle" placeholder="Enter Event Title" required :class="{'is-invalid': day.title.length === 0}")
                b-form-group(label="Date (Required)" label-for="date")
                    b-form-input(v-model="day.date" id="date" type="date" required :class="{'is-invalid': !day.date}" :min="new Date().toISOString().split('T')[0]")
                b-form-group(label="Time (Required)" label-for="time")
                    //- b-form-input(v-model="day.time" id="time" type="time" required :class="{'is-invalid': !day.time}")
                    vue-timepicker(v-model="day.time" format="hh:mm A"  :minute-interval="5" id="time" required input-width="100%")
                b-form-group(:label="`Itineraries For Day ${event.multipleEvents.indexOf(day) + 1} (Optional)`" label-for="itineraries").w-100
                    div(class="d-flex" style="gap: 10px")
                        //- b-form-input(v-model="tempData.itineraries.time" id="itineraries" placeholder="Enter Time" required type="time")
                        vue-timepicker(v-model="tempData.itineraries.time" format="hh:mm A" :minute-interval="5" id="itineraries" required input-width="100%")
                        b-form-input(v-model="tempData.itineraries.description" placeholder="Enter Description" required)
                        b-button(@click="addItineraryMultipleDay(day.id)") Add
                    h5.mt-4(v-if="day?.itineraries.length !==0") Itineraries
                    div(v-for="itinerary in day?.itineraries" :key="itinerary.id" class="d-flex justify-content-between align-items-center my-2" style="gap: 10px")
                        //- b-form-input(v-model="itinerary.time" type="time" required)
                        vue-timepicker(v-model="itinerary.time" format="hh:mm A" :minute-interval="5" required input-width="100%")
                        b-form-input(v-model="itinerary.description" required)
                        b-button(@click="removeItineraryMulti(itinerary.id,day.id)" variant="danger") Remove
                b-form-group(:label="`Lunch For Day ${event.multipleEvents.indexOf(day) + 1} (Optional)`" label-for="lunch").w-100
                    div(class="d-flex" style="gap: 10px")
                        b-form-input(v-model="tempData.food.lunch" id="lunch" placeholder="Enter Lunch" required)
                        b-button(@click="addLunchMulti(day.id)") Add
                    h5.mt-4(v-if="day?.food?.lunch.length !==0") Lunch
                    div(v-for="lunch in day?.food?.lunch" :key="lunch.id" class="d-flex justify-content-between align-items-center my-2" style="gap: 10px")
                        b-form-input(v-model="lunch.name" required)
                        b-button(@click="removeLunchMulti(lunch.id,day.id)" variant="danger") Remove
                b-form-group(:label="`Beverages For Day ${event.multipleEvents.indexOf(day) + 1} (Optional)`" label-for="beverages").w-100
                    div(class="d-flex" style="gap: 10px")
                        b-form-input(v-model="tempData.food.beverage" id="beverages" placeholder="Enter Beverages" required)
                        b-button(@click="addBeverageMulti(day.id)") Add
                    h5.mt-4(v-if="day?.food?.beverages.length !==0") Beverages
                    div(v-for="beverage in day?.food?.beverages" :key="beverage.id" class="d-flex justify-content-between align-items-center my-2" style="gap: 10px")
                        b-form-input(v-model="beverage.name" required)
                        b-button(@click="removeBeverageMulti(beverage.id,day.id)" variant="danger") Remove
                b-form-group(:label="`Price for Day ${event.multipleEvents.indexOf(day) + 1}`" label-for="pricePerDay" :class="{'is-invalid': day.pricePerPerson === 0}")
                    b-input-group
                        b-input-group-prepend
                            span(class="input-group-text") $
                        b-form-input(v-model="day.pricePerPerson" id="pricePerDay" type="number" required)
                b-button(@click="event.multipleEvents.splice(event.multipleEvents.indexOf(day), 1)" variant="danger" class="mt-2 w-100") Remove Day
            b-button(@click="addDayToMulti" variant="primary").w-100.my-2 Add New Day

        //- b-form-group(label="GST (Optional)" label-for="gst")
        //-     b-form-input(v-model="event.gst" id="gst" type="number" :class="{'is-invalid': event.gst === 0}" :disabled="!event.editGST")
        //- b-form-group(label="Edit GST" label-for="editGST")
        //-     b-form-checkbox(v-model="event.editGST" id="editGST" switch)
        b-form-group(label="Total Price" label-for="total")
            b-input-group
                b-input-group-prepend
                    span(class="input-group-text") $
                b-form-input(v-model="calculateTotal" id="total" type="number" :class="{'is-invalid': calculateTotal === 0}" readonly)
    template( #modal-footer)
            b-button(variant="secondary" @click="showModal = false" :disabled="loading") Close
            b-button(variant="success" @click="submit" :disabled="loading") 
                b-spinner(v-if="loading" small)
                span(v-else) Submit
  
                    
               
            
              
                    

                
            
</template>

<script>
import {v4 as uuidv4} from 'uuid';
import { VueEditor } from "vue2-editor";
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import axios from 'axios';
    export default {
        props:['show','groups'],
        emits:['close'],
        components:{
            VueEditor,
            VueTimepicker,
        },
        data(){
            return{
                customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
                ["link"],
                ["clean"],
            ],
                event: {
                    name:'',
                    selectedGroup: '',
                    description: '',
                    isSingleDay: true,
                    multipleEvents:[
                    ],
                    singleDay:{
                        date:null,
                        time:null,
                        itineraries:[],
                        food:{
                            lunch:[],
                            beverages:[],
                        },
                        pricePerPerson: 0,
                    },
                    gst:1.09,
                    editGST: false,
                    total: 0,

                },
                tempData: {
                    date: null,
                    time: null,
                    itineraries: {
                        time: '',
                        description: '',
                    },
                    food: {
                        lunch: '',
                        beverage: '',
                    }
                },
                loading: false,
            }
        },
        computed:{
            showModal:{
                get() {
                return this.show;
            },
            set(value) {
                this.$emit('update:show', value);
            }
            },
            calculateTotal(){
                let total = 0;
                if(this.event.singleDay.pricePerPerson){
                    total = this.event.singleDay.pricePerPerson;
                }else{
                    total = this.event.multipleEvents.reduce((item, day) => item + (parseInt(day.pricePerPerson) || 0), 0);
                }
                if(this.event.gst){
                    total = total * this.event.gst;
                }
                return total.toFixed(2);
            }
        },
        methods:{
          async  submit(){
                if(this.event.isSingleDay){
                    if(!this.event.name || !this.event.selectedGroup || !this.event.description || !this.event.singleDay.date || !this.event.singleDay.time ){
                        this.$bvToast.toast('Please fill all the fields', {
                            title: 'Error',
                            variant: 'danger',
                            solid: true,
                        });
                        return;
                    }
                    this.loading = true;
                    const eventData = {
                       name: this.event.name,
                       recipient: this.event.selectedGroup,
                       date: this.event.singleDay.date,
                       json: {
                        id: uuidv4(),
                        ...this.event
                       }

                    }

                    const res = await axios.post('/api/events/v2', eventData);
                    if(res.status === 200){
                        this.$bvToast.toast('Event Added Successfully', {
                            title: 'Success',
                            variant: 'success',
                            solid: true,
                        });
                        this.showModal = false;
                        this.event = {
                            name:'',
                            selectedGroup: '',
                            description: '',
                            isSingleDay: true,
                            multipleEvents:[
                            ],
                            singleDay:{
                                date:null,
                                time:null,
                                itineraries:[],
                                food:{
                                    lunch:[],
                                    beverages:[],
                                },
                                pricePerPerson: 0,
                            },
                            gst:1.09,
                            editGST: false,
                            total: 0,
                        }
                        this.tempData = {
                            date: null,
                            time: null,
                            itineraries: {
                                time: '',
                                description: '',
                            },
                            food: {
                                lunch: '',
                                beverage: '',
                            }
                        }
                        this.showModal  =false
                        
                        this.$emit('close',{fetch:true});


                    }
                    else{
                        this.$bvToast.toast('Error Adding Event', {
                            title: 'Error',
                            variant: 'danger',
                            solid: true,
                        });
                    }
                    this.loading = false;
                    


                }else{
                    if(!this.event.name || !this.event.selectedGroup || !this.event.description || this.event.multipleEvents.length === 0){
                        this.$bvToast.toast('Please fill all the fields', {
                            title: 'Error',
                            variant: 'danger',
                            solid: true,
                        });
                        return;
                    }
                    if(this.event.multipleEvents.some((day) => !day.title || !day.date || !day.time)){
                        this.$bvToast.toast('Please fill all the fields', {
                            title: 'Error',
                            variant: 'danger',
                            solid: true,
                        });
                        return;
                    }
                    this.loading = true;
                    const eventData = {
                       name: this.event.name,
                       recipient: this.event.selectedGroup,
                        json : {
                            id: uuidv4(),
                            ...this.event
                        },
                       date: this.event.multipleEvents[0].date,

                    }

                    const res = await axios.post('/api/events/v2', eventData);
                    if(res.status === 200){
                        this.$bvToast.toast('Event Added Successfully', {
                            title: 'Success',
                            variant: 'success',
                            solid: true,
                        });
                        this.showModal = false;
                        this.event = {
                            name:'',
                            selectedGroup: '',
                            description: '',
                            isSingleDay: true,
                            multipleEvents:[
                            ],
                            singleDay:{
                                date:null,
                                time:null,
                                itineraries:[],
                                food:{
                                    lunch:[],
                                    beverages:[],
                                },
                                pricePerPerson: 0,
                            },
                            
                            gst:1.09,
                            editGST: false,
                            total: 0,
                        }
                        this.tempData = {
                            date: null,
                            time: null,
                            itineraries: {
                                time: '',
                                description: '',
                            },
                            food: {
                                lunch: '',
                                beverage: '',
                            }
                        }
                        this.showModal  =false
                        this.$emit('close',{fetch:true});
                    }
                    else{
                        this.$bvToast.toast('Error Adding Event', {
                            title: 'Error',
                            variant: 'danger',
                            solid: true,
                        });
                    }
                    this.loading = false;

                }

            },
            removeBeverageMulti(id,dayId){
                let index = this.event.multipleEvents.findIndex((day) => day.id === dayId);
                if(index !== -1){
                    let beverageIndex = this.event.multipleEvents[index].food.beverages.findIndex((beverage) => beverage.id === id);
                    if(beverageIndex !== -1){
                        this.event.multipleEvents[index].food.beverages.splice(beverageIndex, 1);
                    }
                }
            },
            addBeverageMulti(id){
                let index = this.event.multipleEvents.findIndex((day) => day.id === id);
                if(index !== -1){
                    if(!this.tempData.food.beverage){
                        this.$bvToast.toast('Please fill all the fields', {
                            title: 'Error',
                            variant: 'danger',
                            solid: true,
                        });
                        return;
                    }
                    this.event.multipleEvents[index].food.beverages.push({
                        id: uuidv4(),
                        name: this.tempData.food.beverage,
                    });
                    this.tempData.food.beverage = '';
                }
            },
            removeLunchMulti(id,dayId){
                let index = this.event.multipleEvents.findIndex((day) => day.id === dayId);
                if(index !== -1){
                    let lunchIndex = this.event.multipleEvents[index].food.lunch.findIndex((lunch) => lunch.id === id);
                    if(lunchIndex !== -1){
                        this.event.multipleEvents[index].food.lunch.splice(lunchIndex, 1);
                    }
                }
            },
            addLunchMulti(id){
                let index = this.event.multipleEvents.findIndex((day) => day.id === id);
                if(index !== -1){
                    if(!this.tempData.food.lunch){
                        this.$bvToast.toast('Please fill all the fields', {
                            title: 'Error',
                            variant: 'danger',
                            solid: true,
                        });
                        return;
                    }
                    this.event.multipleEvents[index].food.lunch.push({
                        id: uuidv4(),
                        name: this.tempData.food.lunch,
                    });
                    this.tempData.food.lunch = '';
                }
            },
            removeItineraryMulti(id,dayId){
                let index = this.event.multipleEvents.findIndex((day) => day.id === dayId);
                if(index !== -1){
                    let itineraryIndex = this.event.multipleEvents[index].itineraries.findIndex((itinerary) => itinerary.id === id);
                    if(itineraryIndex !== -1){
                        this.event.multipleEvents[index].itineraries.splice(itineraryIndex, 1);
                    }
                }
            },
            addItineraryMultipleDay(id){
                let index = this.event.multipleEvents.findIndex((day) => day.id === id);
                if(index !== -1){
                    if(!this.tempData.itineraries.time || !this.tempData.itineraries.description){
                        this.$bvToast.toast('Please fill all the fields', {
                            title: 'Error',
                            variant: 'danger',
                            solid: true,
                        });
                        return;
                    }
                    this.event.multipleEvents[index].itineraries.push({
                        id: uuidv4(),
                        time: this.tempData.itineraries.time,
                        description: this.tempData.itineraries.description,
                    });
                    this.tempData.itineraries.time = '';
                    this.tempData.itineraries.description = '';
                }
            },
            addDayToMulti(){
                this.event.multipleEvents.push({
                    id: uuidv4(),
                    title: '',
                    date: null,
                    time: null,
                    pricePerPerson: 0,
                    itineraries:[],
                    food:{
                        lunch:[],
                        beverages:[],
                    },
                    
                });
            },
            removeBeverage(id){
                let index = this.event.singleDay.food.beverages.findIndex((beverage) => beverage.id === id);
                if(index !== -1){
                    this.event.singleDay.food.beverages.splice(index, 1);
                }
            },
            addBeverage(){
                if(!this.tempData.food.beverage){
                    this.$bvToast.toast('Please fill all the fields', {
                        title: 'Error',
                        variant: 'danger',
                        solid: true,
                    });
                    return;
                }
                this.event.singleDay.food.beverages.push({
                    id: uuidv4(),
                    name: this.tempData.food.beverage,
                });
                this.tempData.food.beverage = '';
            },
            removeLunch(id){
                let index = this.event.singleDay.food.lunch.findIndex((lunch) => lunch.id === id);
                if(index !== -1){
                    this.event.singleDay.food.lunch.splice(index, 1);
                }
            },
            addLunch(){
                if(!this.tempData.food.lunch){
                    this.$bvToast.toast('Please fill all the fields', {
                        title: 'Error',
                        variant: 'danger',
                        solid: true,
                    });
                    return;
                }
                this.event.singleDay.food.lunch.push({
                    id: uuidv4(),
                    name: this.tempData.food.lunch,
                });
                this.tempData.food.lunch = '';
            },
            removeItinerarySingle(id){
                let index = this.event.singleDay.itineraries.findIndex((itinerary) => itinerary.id === id);
                if(index !== -1){
                    this.event.singleDay.itineraries.splice(index, 1);
                }
            },
            addItinerary(){
                if(!this.tempData.itineraries.time || !this.tempData.itineraries.description){
                    this.$bvToast.toast('Please fill all the fields', {
                        title: 'Error',
                        variant: 'danger',
                        solid: true,
                    });
                    return;
                }
                this.event.singleDay.itineraries.push({
                    id: uuidv4(),
                    time: this.tempData.itineraries.time,
                    description: this.tempData.itineraries.description,
                });
                this.tempData.itineraries.time = '';
                this.tempData.itineraries.description = '';
            }
        }
        
    }
</script>

<style lang="scss" scoped>
.invalid{
    border: 1px solid red;
    border-radius: 5px;
}
</style>