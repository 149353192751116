<template lang="pug">
div(class="event-tab d-flex flex-column")
    b-overlay(:show="loading" spinner-variant="primary" spinner-type="grow" spinner-small)
        b-table(:items="tableData" striped hover :fields="fields" headerTitle="Events" )
            template(v-slot:cell(name)="data")
                div(@click="editEvent(data.item)" style="color: #227B94;cursor:pointer;") {{data.item.name}}
            template(v-slot:cell(description)="data")
                div(v-html="data.item.description")
        
    p(v-if="!tableData.length") No events found
    //- EditEvent(:eventData="selectedEvent" :showEdit.sync="showEditModal" :update:showEdit.sync="showEditModal" :groups="groups" @updatedEvent="eventUpdated")
    show-event-modal(:eventData="selectedEvent" :showDetails.sync="showEditModal" :update:showDetails.sync="showEditModal" :groups="groups" @updatedEvent="eventUpdated")
    
</template>

<script>
import EditEvent from './eventModals/EditEvent.vue';
import ShowEventModal from './eventModals/ShowEventModal.vue';
    export default {
        components: {
            EditEvent,
            ShowEventModal
        },
        props:['events','loading','groups'],
        data () {
            return{
            fields: [
                { key: 'name', label: 'Event Name', sortable: false ,},
                
                { key: 'date', label: 'Date', sortable: true },
                // { key: 'pricePerPerson', label: 'Price Per Person', sortable: false },
                // let add the attendance number here
                { key: 'attendance', label: 'Attendance', sortable: false },
            ],
            showEditModal: false,
            selectedEvent: null
            }
        },
        computed:{
            tableData (){
                if(!this.events){
                    return [];
                }
                // let eventJSON = this.events.map(event => JSON.parse(event.crb5c_eventjson))
                /*
                [ { "name": "Test Multiple Day Event", "selectedGroup": [ { "id": "633410dd-c961-ee11-8df0-002248ecdc58", "name": "FRI PM(Mild dementia group)" }, { "id": "b220a845-067f-ed11-81ad-000d3a856a39", "name": "Friday AM (Mild Dementia Group) 星期五（初期）早上" } ], "description": "<p>Demo</p>", "isSingleDay": false, "multipleEvents": [ { "id": "3de61020-9127-4429-9a79-de68f17b6208", "title": "Day 1", "date": "2024-09-10", "time": "00:50", "itineraries": [ { "id": "578bbcb9-941a-4ecf-bc35-9525387cdadd", "time": "23:40", "description": "Wake up" } ], "food": { "lunch": [ { "id": "9f890339-6e4c-4f1d-b489-5802e1020b6b", "name": "lunch" } ], "beverages": [ { "id": "67b3b320-52ec-4488-b2b1-cf17a9895f1b", "name": "water" } ] } } ], "singleDay": { "date": null, "time": null, "itineraries": [], "food": { "lunch": [], "beverages": [] } }, "pricePerPerson": "20", "gst": 1.09, "editGST": false, "total": 0 } ]
                */
                //console.log('eventJSON',eventJSON)
                let jsonData = this.events.map(event => {
                    // console.log('evet each',event)
                    let att = event.submissions ? event.submissions.reduce((item,submission) => item + (submission.totalAttending || 0), 0 ) : 0;
                    return {
                        id: JSON.parse(event.crb5c_eventjson).id,
                        name: JSON.parse(event.crb5c_eventjson).name,
                        description: JSON.parse(event.crb5c_eventjson).description,
                        date: JSON.parse(event.crb5c_eventjson).isSingleDay ? JSON.parse(event.crb5c_eventjson).singleDay.date : JSON.parse(event.crb5c_eventjson).multipleEvents[0].date,
                        pricePerPerson: JSON.parse(event.crb5c_eventjson).pricePerPerson,
                        attendance: att,
                    }
                })
                return jsonData;
            }
        },
        methods:{
            eventUpdated(){
                this.$emit('fetchEvents');
                this.showEditModal = false;
            },
            editEvent(event){
                console.log('event',event);
                let findEvent = this.events.find(e => JSON.parse(e.crb5c_eventjson).id === event.id)
                this.selectedEvent = findEvent; 
                this.showEditModal = true;
                
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>