import { MUTATIONS, ACTIONS, GETTERS } from "./types-events"
import { TABLE } from "../../ModuleName";
import dayjs from "dayjs";
import { z } from "zod";
import store from '@/store';

const events = {
    namespaced: true,
    state: {
        listGroupSession: [],
        listData: [],
        listLunchDataFromListData: [],
        listAllClientAndCaregiverData: [],
    },
    getters: {
        [GETTERS.INDICATOR_COUNT_CAREGIVER_CASH_OR_CHEQUE_NOT_ACKNOWLEDGE]: (state) => {
            if(state.listData.length === 0){
                return;
            }

            const filterCaregiversCashOrChequePayment = state.listData
            // .filter(item => item.caregivers.filter(itemFilter => itemFilter.isAttending === true).length !== 0)
            .filter(item => item.caregivers.filter(itemFilter => (itemFilter.paymentInfo.typeOfPayment === "cash" || itemFilter.paymentInfo.typeOfPayment === "cheque")).length !== 0)
            .filter(item => item.caregivers.filter(itemFilter => itemFilter.paymentInfo.proofOfPayment.cashMemo === null && itemFilter.paymentInfo.proofOfPayment.chequeNo === null).length !== 0)

            return filterCaregiversCashOrChequePayment;
        }
    },
    mutations: {
        [MUTATIONS.ADD_LIST_GROUP_SESSION]: (state, payload) => {
            state.listGroupSession.push(payload);
        },
        [MUTATIONS.ADD_LIST_DATA]: (state, payload) => {
            state.listData.push(payload);
        },
        [MUTATIONS.REMOVE_EVENT_LIST_DATA]: (state, msgId) => {
            state.listData = state.listData.filter(item => item.msgId !== msgId);
        },
        [MUTATIONS.ADD_CAREGIVER]: (state, { itemId, caregiverPayload }) => {
            state.listData.find(item => item.id === itemId).caregivers.push(caregiverPayload)
        },
        [MUTATIONS.MODIFY_ITEM_LIST_DATA]: (state, payload) => {
            state.listData = state.listData.map(item => {
                if(item.msgId === payload.msgId){
                    return {
                        ...payload,
                    }
                }
                return item;
            });
            
        },
        [MUTATIONS.SET_LIST_LUNCH_DATA_FROM_LIST_DATA]: (state, listData) => {
            if(!listData || listData.length === 0){
                return;
            }
            
            state.listLunchDataFromListData = listData.map(item => {
                if(item.caregivers.length === 0){
                    return {
                        msgId: item.msgId,
                        main: item.lunch.listMain.map(adminSpecifyMainLunch => (
                            {
                                name: adminSpecifyMainLunch,
                                listPersonChoose: [],
                            }
                        )),
                        beverage: item.lunch.listBeverage.map(adminSpecifyLunchBeverage => (
                            {
                                name: adminSpecifyLunchBeverage,
                                listPersonChoose: [],
                            }
                        )),
                    }
                }

                return {
                    msgId: item.msgId,
                    main: item.lunch.listMain.map(adminSpecifyMainLunch => (
                        {
                            name: adminSpecifyMainLunch,
                            listPersonChoose: [
                                ...item.caregivers.filter(itemCaregivers => itemCaregivers).filter(main => main.listLunchSelected.caregiver.main.includes(adminSpecifyMainLunch)).map(itemMap => itemMap.name),
                                ...item.caregivers.filter(itemCaregivers => itemCaregivers).filter(main => main.listLunchSelected.client.main.includes(adminSpecifyMainLunch)).map(itemMap => itemMap.client.name),
                                ...item.caregivers.filter(itemCaregivers => itemCaregivers).map(itemMap => itemMap.otherPersonJoining)[0].filter(itemFilter => itemFilter.lunch.main === adminSpecifyMainLunch).map(itemMap2 => itemMap2.name),
                            ],
                            
                        }
                    )),
                    beverage: item.lunch.listBeverage.map(adminSpecifyLunchBeverage => (
                        {
                            name: adminSpecifyLunchBeverage,
                            listPersonChoose: [
                                ...item.caregivers.filter(itemCaregivers => itemCaregivers).filter(beverage => beverage.listLunchSelected.caregiver.beverage.includes(adminSpecifyLunchBeverage)).map(itemMap => itemMap.name),
                                ...item.caregivers.filter(itemCaregivers => itemCaregivers).filter(beverage => beverage.listLunchSelected.client.beverage.includes(adminSpecifyLunchBeverage)).map(itemMap => itemMap.client.name),
                                ...item.caregivers.filter(itemCaregivers => itemCaregivers).map(itemMap => itemMap.otherPersonJoining)[0].filter(itemFilter => itemFilter.lunch.beverage === adminSpecifyLunchBeverage).map(itemMap2 => itemMap2.name),
                            ],
                            
                        }
                    )),
                }
            });
        },
        [MUTATIONS.SET_LIST_CLIENT_AND_CAREGIVER_DATA]: (state, payload) => {
            state.listAllClientAndCaregiverData = payload;
        }

    },
    actions: {
        [ACTIONS.DETERMINE_EVENT_STATUS]: async (_, rawDate) => {
            const dateNow = dayjs();
            const diffInMilis = dayjs(rawDate).diff(dateNow);

            if(diffInMilis > 0){
                return 'Upcoming'
            }

            return 'Past';
            
        },
        // eslint-disable-next-line no-unused-vars
        [ACTIONS.CREATE_EVENT]: async ({ rootGetters, commit, dispatch }, payload) => {
            console.log('running action')
            const finalPayload = await dispatch(ACTIONS.TRANSFORM_PAYLOAD_EVENT, payload);
            finalPayload.admin = store.state.currentAdmin.username;
            console.log('action getter',finalPayload )
            console.log('getter',store.state.currentAdmin.username)

            await rootGetters[`${TABLE}/api`].post("/events", finalPayload);

        },
        [ACTIONS.TRANSFORM_PAYLOAD_EVENT]: (_, payload) => {
            // eslint-disable-next-line no-unused-vars
            const { caregivers, description, ...rest } = payload;

            const payloadTransform = {
                ...rest,
                body: description,
                caregivers: [],
                isEventConversation: true,
            } 

            return payloadTransform;

        },
        // eslint-disable-next-line no-unused-vars
        [ACTIONS.UPDATE_CAREGIVER_PAYMENT_ACKNOWLEDGEMENT]: async ({ rootGetters, dispatch }, payload) => {
            await dispatch(ACTIONS.VALIDATE_PAYLOAD_SUBMIT_PATCH, payload);
            await rootGetters[`${TABLE}/api`].patch("/events", payload);
            
        },
        [ACTIONS.UPDATE_EVENT_ATTRIBUTES]: async ({ rootGetters, dispatch }, payload) => {
            await dispatch(ACTIONS.VALIDATE_PAYLOAD_SUBMIT_PATCH, payload);
            await rootGetters[`${TABLE}/api`].patch("/events", payload);
            
        },
        [ACTIONS.REMOVE_CAREGIVER_FROM_EVENT]: async ({ rootGetters, dispatch }, payload) => {
            await dispatch(ACTIONS.VALIDATE_PAYLOAD_SUBMIT_PATCH, payload);
            await rootGetters[`${TABLE}/api`].patch("/events", payload);

        },
        // eslint-disable-next-line no-unused-vars
        [ACTIONS.VALIDATE_LIST_DATA]: async (_, payload) => {
            // console.log('validate action',payload)
            try {
                const SchemaEventListData = z.object({
                    msgId: z.string(),
                    convId: z.string(),
                    title: z.string(),
                    listGroup: z.array(z.object({
                        id: z.string(),
                        name: z.string(),
                    })),
                    lunch: z.object({
                        listMain: z.array(
                            z.string()
                        ),
                        listBeverage: z.array(
                            z.string()
                        ),
                    }),
                    description: z.string(),
                    itinerary: z.array(
                        z.object({
                            time: z.string(),
                            description: z.string(),
                        })
                    ).min(0),
                    pricePerPerson: z.number(),
                    gst: z.number().step(0.01),
                    date: z.string().datetime(),
                    status: z.enum(["Upcoming", "Past"]),
                    admin: z.string(),
                    media: z.string().nullish(),
                    isEventConversation: z.boolean(),
                    caregivers: z.array(z.object({
                        id: z.string(),
                        name: z.string(),
                        isAttending: z.boolean().nullable(),
                        isCaregiverCome: z.boolean(),
                        listLunchSelected: z.object({
                            caregiver: z.object({
                                main: z.array(
                                    z.string().nullable(),
                                ),
                                beverage: z.array(
                                    z.string().nullable(),
                                ),
                            }),
                            client: z.object({
                                main: z.array(
                                    z.string().nullable(),
                                ),
                                beverage: z.array(
                                    z.string().nullable(),
                                ),
                            }),
                        }),
                        client: z.object({
                            id: z.string(),
                            name: z.string(),
                        }),
                        otherPersonJoining: z.array(
                            z.object({
                                name: z.string(),
                                lunch: z.object({
                                    main: z.string(),
                                    beverage: z.string(),
                                }),
                            }).strict(),
                        ).min(0),
                        paymentInfo: z.object({
                            totalPrice: z.number(),
                            typeOfPayment: z.enum(["online", "cash", "cheque", "not applicable"]).nullable(),
                            proofOfPayment: z.object({
                                cashMemo: z.string().nullable(),
                                onlineReceiptId: z.string().nullable(),
                                chequeNo: z.string().nullable(),
                            }).strict(),
                            paymentStatus: z.boolean(),
                            paymentDate: z.string().datetime().nullable(),
                        }).strict(),
                    })).min(0),
                }).strict();
    
                SchemaEventListData.parse(payload);
                
            } catch (error) {
                console.error(error);
            }

        },
        [ACTIONS.VALIDATE_PAYLOAD_SUBMIT_PATCH]: async (_, payload) => {
            const SchemaPayloadSubmit = z.object({
                msgId: z.string(),
                convId: z.string(),
                media: z.string().nullish(),
                attributePayload: z.object({
                    title: z.string(),
                    listGroup: z.array(z.object({
                        id: z.string(),
                        name: z.string(),
                    })),
                    lunch: z.object({
                        listMain: z.array(
                            z.string()
                        ),
                        listBeverage: z.array(
                            z.string()
                        ),
                    }),
                    itinerary: z.array(
                        z.object({
                            time: z.string(),
                            description: z.string(),
                        })
                    ).min(0),
                    pricePerPerson: z.number(),
                    gst: z.number().step(0.01),
                    date: z.string().datetime(),
                    admin: z.string(),
                    isEventConversation: z.boolean(),
                    caregivers: z.array(z.object({
                        id: z.string(),
                        name: z.string(),
                        isAttending: z.boolean().nullable(),
                        isCaregiverCome: z.boolean(),
                        listLunchSelected: z.object({
                            caregiver: z.object({
                                main: z.array(
                                    z.string().nullable(),
                                ),
                                beverage: z.array(
                                    z.string().nullable(),
                                ),
                            }),
                            client: z.object({
                                main: z.array(
                                    z.string().nullable(),
                                ),
                                beverage: z.array(
                                    z.string().nullable(),
                                ),
                            }),
                        }),
                        client: z.object({
                            id: z.string(),
                            name: z.string(),
                        }),
                        otherPersonJoining: z.array(
                            z.object({
                                name: z.string(),
                                lunch: z.object({
                                    main: z.string(),
                                    beverage: z.string(),
                                }),
                            }).strict(),
                        ).min(0),
                        paymentInfo: z.object({
                            totalPrice: z.number(),
                            typeOfPayment: z.enum(["online", "cash", "cheque", "not applicable"]).nullable(),
                            proofOfPayment: z.object({
                                cashMemo: z.string().nullable(),
                                onlineReceiptId: z.string().nullable(),
                                chequeNo: z.string().nullable(),
                            }).strict(),
                            paymentStatus: z.boolean(),
                            paymentDate: z.string().datetime().nullable(),
                        }).strict(),
                    })).min(0),
                }).required()
            }).strict();

            SchemaPayloadSubmit.parse(payload);
        },
        // eslint-disable-next-line no-unused-vars
        [ACTIONS.DB_GET_EVENT]: async ({ rootGetters }, _) => {
            try {
                // eslint-disable-next-line no-unused-vars
                const { data } = await rootGetters[`${TABLE}/api`].get("/events_db/event_table");
                console.log('get',data);
                
            } catch (error) {
                console.error(error);
            }

        },
        [ACTIONS.DB_POST_EVENT]: async ({ rootGetters }, payload) => {
            try {
                const { data } = await rootGetters[`${TABLE}/api`].post("/events_db/event_table", payload);
                console.log(data);

                return data;

            } catch (error) {
                console.error(error);
                return null;

            }

        },
        [ACTIONS.DB_DELETE_EVENT]: async ({ rootGetters }, payload) => {
            try {
                const { data } = await rootGetters[`${TABLE}/api`].delete("/events_db/event_table", {
                    data: payload
                });
                console.log(data);
                
                return data;

            } catch (error) {
                console.error(error);
                return null;

            }
        },
        [ACTIONS.DB_EVENT_CAREGIVER_DELETE]: async ({ rootGetters }, payload) => {
            try {
                const { data } = await rootGetters[`${TABLE}/api`].delete("/events_db/event_caregiver", {
                    data: payload
                });
                console.log(data);
                
                return data;

            } catch (error) {
                console.error(error);
                return null;
            }
        },
        [ACTIONS.DB_PATCH_EVENT_CAREGIVER_PAYMENT_ACKNOWLEDGE]: async ({ rootGetters }, payload) => {
            try {
                const { data } = await rootGetters[`${TABLE}/api`].patch("/events_db/event_caregiver", payload);
                console.log(data);
                
                return data;

            } catch (error) {
                console.error(error);
            }
        }
    }
}

export default events;