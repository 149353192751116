<template lang="pug">
b-container(style="height: 85%")


    //- b-modal(id="open-calendar" hide-footer modal-class="wider-modal" title="Filter Client Date")
    //-     template
    //-     b-collapse(id="filter")
    //-         b-card.mb-3(style="border-radius: 20px")
    //-             div.d-flex.align-items-start.mb-3(style="font-weight: 600") Sort by Selected Date : 
    //-             div.d-flex.align-items-center
    //-                 b-form-datepicker(type="date" v-model="datefilter1")
    //-                 span.mx-3 to
    //-                 b-form-datepicker(type="date" v-model="datefilter2")
    //-                 b-button.ml-2(@click="filterDate") Filter
    //-                 b-button.ml-2.btn-danger(@click="clearDate") Clear
    //-     template
    //-         div.d-flex.justify-content-end.align-items-center
    //-             b-button(@click="$bvModal.hide('open-calendar')") OK


    b-col.mt-5(style="")
        b-row.mb-3
            b-col.col-12.col-sm-4(class="d-flex justify-content-start align-items-center")
                h4 Weekly Schedule
            //- b-col.col-8(class="d-flex justify-content-end align-items-center")
            //-     b-button(v-b-toggle:filter variant="info" style="font-size: 0.85rem;" @click="$bvModal.show('open-calendar')") Open Calendar

        //- b-col(style="text-align: center; height: max-content")
        //-     b-row.py-3(style="display: flex; align-items: center; justify-content: center; background-color: #50276D; color: white; font-weight: 400; font-size: medium; width: 102.2%")
        //-         b-col(cols="5" class="d-flex justify-content-center align-items-center")
        //-             b-button.m-0(style="background-color: inherit; border: none; font-size: 0.85rem") Schedule Name 
        //-         b-col(cols="2" style="font-size: 0.85rem") Date
        //-         b-col(cols="2" style="font-size: 0.85rem") Duration
        //-         b-col(cols="3" style="font-size: 0.85rem") Checked In / Enrolled
        //- //- b-col(class="table__body" style="")
        //- //-     b-row.p-2(v-for="schedule in scheduleList" :key="schedule.id" v-bind:visibleList="visibleList" v-bind:currentPage="currentPage" style="border: 1px solid black; display: flex; align-items: center; background-color: white; ")
        //- //-         b-col(v-for="nameDetails in schedule.scheduleName" :key="nameDetails.name" cols="5")
        //- //-             b-button(style="text-decoration: none; background-color: inherit; border: 0px; color: blue; font-size: medium; font-size: 0.85rem;" :href="nameDetails.redirectTo" target="_blank") {{ nameDetails.name }}
        //- //-         b-col(cols="2" style="font-size: 0.85rem") {{ schedule.scheduleDate }}
        //- //-         b-col(cols="2" style="font-size: 0.85rem") {{ schedule.scheduleDuration }}
        //- //-         b-col(cols="3" style="font-size: 0.85rem") {{ schedule.scheduleEnrolled }}

        //- b-col(class="table__body" style="")
        //-     b-row.p-2(
        //-             v-if="scheduleDB.length !== 0"
        //-             v-for="schedule in scheduleDB" 
        //-             :key="schedule.crb5c_fow_sessionid" 
        //-             v-bind:visibleList="visibleList" 
        //-             v-bind:currentPage="currentPage" 
        //-             style="border: 1px solid black; display: flex; align-items: center; background-color: white; "
        //-         )
        //-         //- b-col(v-for="nameDetails in schedule.scheduleName" :key="nameDetails.name" cols="5")
        //-             b-button(style="text-decoration: none; background-color: inherit; border: 0px; color: blue; font-size: medium; font-size: 0.85rem;" :href="nameDetails.redirectTo" target="_blank") {{ nameDetails.name }}
        //-         b-col(cols="5" style="font-size: 0.85rem") {{ schedule.crb5c_sessionname }}

        //-         b-col(cols="2" style="font-size: 0.85rem") {{ schedule.formattedDate }}
        //-         b-col(cols="2" style="font-size: 0.85rem") {{ schedule.formattedDuration }}
        //-         //- b-col(cols="3" style="font-size: 0.85rem") {{ schedule.scheduleEnrolled }}
        //-     b-row.p-2.bg-white.align-items-center(
        //-             v-if="scheduleDB.length === 0"
        //-             style="height: 10rem;"
        //-         )
        //-         b-col.col-12
        //-             span.font-weight-bold(style="font-size: 1.2rem;") No data
        //-     b-row.mt-4.d-flex.justify-content-end.align-items-end()
        //-         b-col.col-2.d-flex.justify-content-end.align-items-center(style="font-size: 13px") Week In Year : {{ weekInYear - 1 }}
        //-         //- b-col.col-2(style="margin-right: 1rem; font-size: 12px; padding: 15px 0;") Checked In/ Enrolled :



        b-row
            //---- phone screen ----//
            b-col.col-12.d-block.d-sm-none(style="max-height: 35rem; overflow-y: auto;")
                b-table(
                    :fields="tableFields" 
                    :items="listWeeklySchedule"
                    class="table-header-color"
                    thead-tr-class="text-white"
                    tbody-tr-class="bg-white"
                    show-empty
                )
                    template(#empty="scope")
                        b-row(v-if="isFetchingAdminDashboardData")
                            b-col.col-12
                                b-spinner(variant="info")
                        b-row(v-else)
                            b-col.col-12
                                span No data
                    template(#cell(scheduleName)="data")
                        span.text-primary(
                                v-b-tooltip.hover :title="`Click to view ${data.item.crb5c_sessionname} session`"
                                style="cursor: pointer;" 
                                @click="redirectToWeeklySchedulePowerApps(data.item.crb5c_fow_sessionid)"
                            ) {{ data.item.crb5c_sessionname }}
                    template(#cell(Date)="data")
                        span {{ formatDate(data.item.crb5c_date) }}
                    template(#cell(Duration)="data")
                        span {{ data.item.formattedDuration }}
                    //- template(#cell(checkInEnrolled)="data")
                    //-     span {{ data.item.scheduleEnrolled }}
            //---- phone screen ----//
            //---- other screen ----//
            b-col.col-12.d-none.d-sm-block(style="max-height: 20rem; overflow-y: auto;")
                b-table(
                    :fields="tableFields" 
                    :items="listWeeklySchedule"
                    class="table-header-color"
                    thead-tr-class="text-white"
                    tbody-tr-class="bg-white"
                    show-empty
                )
                    template(#empty="scope")
                        b-row(v-if="isFetchingAdminDashboardData")
                            b-col.col-12
                                b-spinner(variant="info")
                        b-row(v-else)
                            b-col.col-12
                                span No data
                    template(#cell(scheduleName)="data")
                        span.text-primary(
                                v-b-tooltip.hover :title="`Click to view ${data.item.crb5c_sessionname} session`"
                                style="cursor: pointer;" 
                                @click="redirectToWeeklySchedulePowerApps(data.item.crb5c_fow_sessionid)"
                            ) {{ data.item.crb5c_sessionname }}
                    template(#cell(Date)="data")
                        span {{ formatDate(data.item.crb5c_date) }}
                    template(#cell(Duration)="data")
                        span {{ data.item.formattedDuration }}
                    //- template(#cell(checkInEnrolled)="data")
                    //-     span {{ data.item.scheduleEnrolled }}
            //---- other screen ----//

        
        b-row
            b-col.col-12.py-2.text-left
                span Total weekly schedule: {{ listWeeklySchedule.length }}

</template>


<script>
// import Pagination from './admin-component/Pagination.vue';
import dayjs from 'dayjs';
import { mapState } from 'vuex';
import { ADMIN_DASHBOARD_NAMESPACE } from '../../store/Modules/AdminDashboard/types-admin-dashboard'


export default{
    components: {
        // Pagination
    },
    data(){
        return{
            tableFields: [
                { 
                    key: "scheduleName",
                    label: "Schedule Name",
                },
                "Date",
                "Duration",
                // { 
                //     key: "checkInEnrolled",
                //     label: "Checked In / Enrolled",
                // },
            ],
            schedules: [],
            scheduleDB: [],
            inputSearchItemList: '',
            currentPage: 0,
            pageSize: 4,
            visibleList: [],
            
        }
    },
    computed: {
        ...mapState({
            listWeeklySchedule: state => (state[ADMIN_DASHBOARD_NAMESPACE].adminDashboard.listWeeklySchedule),
            isFetchingAdminDashboardData: state => (state[ADMIN_DASHBOARD_NAMESPACE].adminDashboard.isFetchingAdminDashboardData),
        }),
        scheduleList(){
            if(this.inputSearchItemList.trim().length > 0){
                return this.schedules.filter(schedule => {
                    return schedule.scheduleName.some(item => {
                        return item.name.toLowerCase().includes(this.inputSearchItemList.trim().toLowerCase());
                    });
                })
            }else{
                return this.schedules
            }
        },
        weekInYear(){
            const date = dayjs();
            return date.week();
        }
    },
    beforeMount: function(){
        this.updateVisibleList()
    },
    methods: {
        formatDate(rawIsoStringDate){
            return dayjs(rawIsoStringDate).format('DD MMM YYYY');
        },
        updatePage(pageNumber){
            this.currentPage = pageNumber
            this.updateVisibleList()
        },
        updateVisibleList(){
            this.visibleList = this.schedules.slice(this.currentPage * this.pageSize, (this.currentPage * this.pageSize) + this.pageSize);

            // if have 0 list client, go back a page
            if(this.visibleList.length === 0 && this.currentPage > 0){
                this.updatePage(this.currentPage - 1)
            }
        },
        backAdminHomepage() {
            this.$router.push({ path: '/adminhomepage' })
        },  
        moveToHomePage(){
            this.$router.push({ path: "/" })
        },
        redirectToWeeklySchedulePowerApps(crb5c_fow_sessionid){
            const BASE_URL = `https://${this.$store.state.logindata.DATAVERSE_DOMAIN}`;
            const weeklyScheduleUrl = `${BASE_URL}/main.aspx?appid=d951a007-cf7a-ed11-81ac-000d3a85cc97&pagetype=entityrecord&etn=crb5c_fow_session&id=${crb5c_fow_sessionid}`;
            
            window.open(weeklyScheduleUrl, '_blank');
            
        },
    },
}
</script>

<style>
.checkin-result:hover{
    background-color: rgba(100, 94, 94, 0.1);
    border-radius: 20px;  
}

.table__body{
    text-align: center; 
    max-height: 250px; 
    overflow-y: scroll; 
    border-collapse: separate; 
    border-spacing: 0px;
}

.table__body::-webkit-scrollbar{
    width: 0.5rem;
    height: 0.5rem;
}

.table__body::-webkit-scrollbar-thumb{
    border-radius: 0.5rem;
    background-color: #0004;
}

.table-header-color{
  background-color: #50276D;
}

</style>