<template lang="pug">
div(class="w-100 mx-auto  d-flex" style="gap: 1rem;height: 100vh")
    //- Header(title="Mobile App Configuration" ).mb-5
    NavbarV2
    div(class="px-5 pt-5" style="height: 94vh;overflow-y: auto;width:80%" )
        div(class="d-flex justify-content-between align-items-center")
        h3 Mobile App Configuration
        b-modal#welcome(title="Welcome Page" class="rounded-lg overflow-auto" size="lg")
            Advert
        div(class="px-4 pt-4")
        b-list-group(v-for="(config,index) in configurationList")
                b-list-group-item.d-flex.align-items-center(@click='triggerModal(config)')
                    b-icon(:icon="config.icon").mr-3
                    | {{ config.name}}
                    span.ml-auto.text-muted() >  
</template>

<script>
import Header from '../components/Header.vue';
import Advert from '../components/admin-component/advert.vue';
import NavbarV2 from "../components/NavbarV2.vue";

export default {
    components: {
        Header,
        Advert,
        NavbarV2
    },
    data() {
        return {
            showAdvert: true,
            configurationList: [
                { name: 'Change Welcome picture', type: 'welcome'},
                { name: 'Last active (Caregiver)' , type:'', link:'https://dsg-fow.crm5.dynamics.com/main.aspx?appid=d951a007-cf7a-ed11-81ac-000d3a85cc97&pagetype=entitylist&etn=crb5c_fowmobilerecords&viewid=14168e08-b085-ef11-ac21-000d3a80ca55&viewType=1039' },
                { name: 'Client consent' , type:'', link:'https://dsg-fow.crm5.dynamics.com/main.aspx?appid=d951a007-cf7a-ed11-81ac-000d3a85cc97&pagetype=entitylist&etn=crb5c_fowconsentrecord&viewid=9b4bec8a-b085-ef11-ac21-000d3a80ca55&viewType=1039' }
            ]
        }
    },
    methods: {
        triggerModal(list){
            if (list.type) {
                this.$bvModal.show(list.type);
            }else if(list.link){
                window.open(list.link, '_blank');
            }
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="scss" scoped></style>