<template lang="pug">
b-modal#showDetails(title="Edit Event" v-model="showModalDetails" class="rounded-lg overflow-auto user-select-none" size="lg" scrollable )
    div(class="d-flex flex-column header-details").m-0.p-0
        div(class="d-flex justify-content-between align-items-center").mt-2
            h5 {{ event.name }}
            div(class="d-flex align-items-center" style="gap: 10px;")
                b-button(variant="outline-primary" @click="showEditModal = true")
                    b-icon(icon="pencil")
                //- b-button(variant="outline-danger" @click="showModalDetails = false")
                //-     b-icon(icon="trash")
        div(class="d-flex")
            span.badge(v-if="event.isSingleDay" :class="new Date(event.singleDay.date).toLocaleDateString() > new Date().toLocaleDateString() ? 'badge-primary' : 'badge-secondary'" )  {{new Date(event.singleDay.date).toLocaleDateString() > new Date().toLocaleDateString() ? 'Upcoming' : 'Past'}}
            span.badge(v-else :class="new Date(event.multipleEvents[0].date).toLocaleDateString() > new Date().toLocaleDateString() ? 'badge-primary' : 'badge-secondary'" )  {{new Date(event.multipleEvents[0].date).toLocaleDateString() > new Date().toLocaleDateString() ? 'Upcoming' : 'Past'}}
        span.mt-2 Description:
        span(v-html="event.description")
        div(style=" border-top: 1px solid #000; margin-top: 1rem; margin-bottom: 1rem;")
    div(class="d-flex flex-column event-details").m-0.p-0
        h5 Event Details
        div(class="d-flex flex-column")
            h5 Itinerary
            p.badge-local.mt-2.mb-0(class="d-flex align-items-center" style="gap: 0.5rem;width:fit-content;" v-if="event.isSingleDay && event.singleDay")
                b-icon-cash-coin
                span $ {{ (event.singleDay.pricePerPerson * event.gst).toFixed(2) }}
            b-card-text.badge-local.my-2(style="width:fit-content;") Event Type: {{ event.isSingleDay ? "Single Day" :"Multiple Days" }}
            div(class="d-flex align-items-center" style="gap: 1rem;")
                b-card-text.m-0.badge-local(class="d-flex align-items-center" style="gap: 0.5rem")
                    b-icon-calendar2-date-fill
                    span {{ event.isSingleDay ? event.singleDay.date : event.multipleEvents[0].date }}
                b-card-text.badge-local(class="d-flex align-items-center" style="gap: 0.5rem")
                    b-icon-clock-fill
                    span {{ event.isSingleDay ? event.singleDay.time : event.multipleEvents[0].time }}
            div.text-details.mt-2
                span.badge-local(v-for="group in event.selectedGroup" :key="group.id") {{ group.name }}
            div(style=" border-top: 1px solid #000; margin-top: 1rem; margin-bottom: 1rem;")
        div(v-if="event.isSingleDay && event.singleDay" class="d-flex flex-column" style="gap: 1rem;")
                div(class="itineraries" v-if="event.singleDay.itineraries.length > 0" style="gap: 1rem;")
                    b-card-text.my-2.h6 Itineraries
                    div(v-for="itinerary in event.singleDay.itineraries" :key="itinerary.id" class="d-flex align-items-center" style="gap: 0.5rem;")
                        b-icon-arrow-right-circle-fill
                        b-card-text.m-0 {{ itinerary.time }} - {{ itinerary.description }}
                div(v-if="event.singleDay.food.lunch.length > 0" style="gap: 1rem;")
                    b-card-text.my-2.h6 Lunch Options
                    div(v-for="lunch in event.singleDay.food.lunch" :key="lunch.id" class="d-flex align-items-center" style="gap: 0.5rem;")
                        b-icon-egg-fried
                        b-card-text.m-0 {{ lunch.name }}
                div(v-if="event.singleDay.food.beverages.length > 0" style="gap: 1rem;")
                    b-card-text.my-2.h6 Beverages
                    div(v-for="beverage in event.singleDay.food.beverages" :key="beverage.id" class="d-flex align-items-center" style="gap: 0.5rem;")
                        b-icon-cup-straw
                        b-card-text.m-0 {{ beverage.name }}
                div(v-if="submissions.length > 0" class="d-flex flex-column" style="gap: 1rem;")
                    b-card-text.my-2.h6 Submissions 
                    b-card-text.my-2.h6 Total Submission: {{ submissions.length }}
                    b-card-text.my-2.h6 Total Attending: {{ submissions.filter(submission => submission.status !== 'Not Attending').length }}
                    //- [ { "name": "Test Event Single Day", "eventID": "9aa31e0a-396b-ef11-a671-002248ed82d3", "totalAttending": 1, "totalCost": 0, "caregiverName": "Sharon", "clientName": "Neo Leng Neo", "singleDay": { "whoGoing": [ "Client" ], "client": { "lunch": "", "beverage": "" }, "caregiver": { "lunch": "", "beverage": "" }, "otherPersons": [] } } ]
                    div(v-for="(submission, index) in submissions" v-if="submission.singleDay && (submission.status === undefined || submission.status !== 'Not Attending')" :key="submission.id" class="d-flex flex-column" style="gap: 0.5rem; border: 1px solid #ccc; padding: 1rem;").my-1
                        p Submission : {{ index + 1 }}
                        p Total Amount: $ {{ (submission.totalCost * event.gst).toFixed(2) }}

                        div(class="d-flex align-items-center" style="gap: 0.5rem;")
                            b-icon-person-badge
                            b-card-text.m-0(v-if="submission?.totalAttending")
                                span Total Attending: {{ submission?.totalAttending }}
                        div(class="d-flex align-items-center" style="gap: 0.5rem;" v-if="submission?.clientName && submission?.singleDay?.whoGoing.includes('Client')").my-2
                            b-icon-person-badge
                            b-card-text.m-0(v-if="submission?.clientName")
                                span Client Name: {{ submission?.clientName }}
                        div(v-if="submission?.singleDay?.whoGoing.includes('Client') && submission?.singleDay?.client?.lunch")
                            b-card-text.mt-3.h6 Client Lunch Option
                            div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                b-icon-egg-fried
                                b-card-text.m-0 {{ submission?.singleDay?.client?.lunch }}
                        div(v-if="submission?.singleDay?.whoGoing.includes('Client') && submission?.singleDay?.client?.beverage")
                            div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                b-icon-cup-straw
                                b-card-text.m-0 {{ submission?.singleDay?.client?.beverage }}
                        div(style=" border-top: 1px solid #000; margin-top: 1rem; margin-bottom: 1rem;" v-if="submission?.singleDay?.whoGoing.includes('Caregiver') ")
                        div(class="d-flex align-items-center" style="gap: 0.5rem;" v-if="submission?.caregiverName && submission?.singleDay?.whoGoing.includes('Caregiver')").my-2
                            b-icon-person-badge
                            b-card-text.m-0(v-if="submission?.caregiverName")
                                span Caregiver Name: {{ submission?.caregiverName }}
                        div(v-if="submission?.singleDay?.whoGoing.includes('Caregiver') && submission?.singleDay?.caregiver?.lunch")
                            b-card-text.mt-3.h6 Caregiver Lunch Option
                            div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                b-icon-egg-fried
                                b-card-text.m-0 {{ submission?.singleDay?.caregiver?.lunch }}
                        div(v-if="submission?.singleDay?.whoGoing.includes('Caregiver') && submission?.singleDay?.caregiver?.beverage")
                            div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                b-icon-cup-straw
                                b-card-text.m-0 {{ submission?.singleDay?.caregiver?.beverage }}
                        div(style=" border-top: 1px solid #000; margin-top: 1rem; margin-bottom: 1rem;" v-if="submission?.singleDay?.whoGoing.includes('Other Persons')")
                        div(v-if="submission?.singleDay?.whoGoing.includes('Other Persons') && submission?.singleDay?.otherPersons.length > 0")
                            span Other Persons
                            div(v-for="person in submission?.singleDay?.otherPersons" :key="person.id" class="d-flex flex-column" style="gap: 0.5rem; border: 1px solid #ccc; padding: 1rem;")
                                b-card-text.m-0 {{ person.name }}
                                div(v-if="person.lunch")
                                    b-card-text.mt-3.h6 Lunch Option
                                    div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                        b-icon-egg-fried
                                        b-card-text.m-0 {{ person.lunch }}
                                div(v-if="person.beverage")
                                    b-card-text.mt-3.h6 Beverage Option
                                    div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                        b-icon-cup-straw
                                        b-card-text.m-0 {{ person.beverage }}
                





        div(v-if="!event.isSingleDay && event.multipleEvents.length > 0" class="d-flex flex-column" style="gap: 1rem;")
                div(class="multipleDay-itineraries" v-if="event.multipleEvents.length > 0" style="gap: 1rem;")
                    b-card-text.my-2.h6 Details about each day
                    div(v-for="day in event.multipleEvents" :key="day.id" style="border: 1px solid #ccc; padding: 1rem;").my-1
                        div(class="d-flex flex-column" style="gap: 0.5rem;")
                            b-card-text.my-2.h6 {{ day.title }}
                            div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                b-icon-calendar2-date-fill
                                b-card-text.m-0 {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                        div(v-if="day.pricePerPerson" class="d-flex align-items-center" style="gap: 0.5rem;")
                            b-card-text.m-0(class="d-flex align-items-center" style="gap: 0.5rem")
                                b-icon-cash-coin
                                span $ {{ (day.pricePerPerson * event.gst).toFixed(2) }}
                        b-card-text.mt-3.h6 Itineraries
                        div(class="d-flex align-items-center" style="gap: 0.5rem;" v-for="itinerary in day.itineraries").my-1
                            b-icon-arrow-right-circle-fill
                            b-card-text.m-0 {{ itinerary.time }} - {{ itinerary.description }}
                        div(v-if="day.itineraries.length === 0").my-1
                            b-card-text No Itineraries for this day
                        div(v-if="day.food.lunch.length > 0")
                            b-card-text.mt-3.h6 Lunch Options
                            div(v-for="lunch in day.food.lunch" :key="lunch.id" class="d-flex align-items-center" style="gap: 0.5rem;" )
                                b-icon-egg-fried
                                b-card-text.m-0 {{ lunch.name }}
                        div(v-if="day.food.beverages.length > 0" style="gap: 1rem;")
                            b-card-text.my-2.h6 Beverages
                            div(v-for="beverage in day.food.beverages" :key="beverage.id" class="d-flex align-items-center" style="gap: 0.5rem;")
                                b-icon-cup-straw
                                b-card-text.m-0 {{ beverage.name }}
                //- [ { "name": "Test Multiple Day Event", "eventID": "0f74b26f-3b6b-ef11-a671-002248ec1beb", "totalAttending": 3, "totalCost": 40, "caregiverName": "Sharon", "clientName": "Neo Leng Neo", "multipleDays": { "whoGoing": [ "Client", "Client", "Caregiver", "Other Persons" ], "client": { "attendingDays": [ "Thursday, September 12, 2024", "Saturday, September 7, 2024" ], "lunchByDay": [ { "dayID": "24722281-28e9-4370-9813-aac4d91cef77", "lunchOption": "Chicken" }, { "dayID": "ccbba842-536b-4e0c-afe0-bb80d3d46bf2", "lunchOption": "Chinese Food" } ], "beverageByDay": [ { "dayID": "24722281-28e9-4370-9813-aac4d91cef77", "beverageOption": "Water" } ] }, "caregiver": { "attendingDays": [ "Thursday, September 12, 2024" ], "lunchByDay": [ { "dayID": "24722281-28e9-4370-9813-aac4d91cef77", "lunchOption": "Chicken" } ], "beverageByDay": [ { "dayID": "24722281-28e9-4370-9813-aac4d91cef77", "beverageOption": "Water" } ] }, "otherPersons": [ { "id": "d4335ddd-145c-45bc-b502-92d016d1443c", "name": "Abdullah", "attendingDays": [ "Saturday, September 7, 2024" ], "lunchByDay": [ { "dayID": "ccbba842-536b-4e0c-afe0-bb80d3d46bf2", "lunchOption": "Chinese Food" } ], "beverageByDay": [] } ] } } ]
                div(v-if="submissions.length > 0" class="d-flex flex-column" style="gap: 1rem;")
                    b-card-text.my-2.h6 Submissions 
                    b-card-text.my-2.h6 Total Submissions: {{ submissions.length }}
                    b-card-text.my-2.h6 Total Attending: {{ submissions.filter(submission => submission.status !== 'Not Attending').length }}
                    div(v-for="(submission, index) in submissions" v-if="submission.multipleDays && (submission.status === undefined || submission.status !== 'Not Attending')" :key="submission.id" class="d-flex flex-column" style="gap: 0.5rem; border: 1px solid #ccc; padding: 1rem;").my-1
                        p Submission : {{ index + 1 }}
                        p Total Amount: $ {{ (submission.totalCost * event.gst).toFixed(2) }}
                        div(class="d-flex align-items-center" style="gap: 0.5rem;")
                            b-icon-person-badge
                            b-card-text.m-0(v-if="submission?.totalAttending")
                                span Total Attending: {{ submission?.totalAttending }}
                        div(class="d-flex align-items-center" style="gap: 0.5rem;" v-if="submission?.clientName && submission?.multipleDays?.whoGoing.includes('Client')").my-2
                            b-icon-person-badge
                            b-card-text.m-0(v-if="submission?.clientName")
                                span Client Name: {{ submission?.clientName }}


                        div(class="d-flex align-items-center" style="gap: 0.5rem;" v-if="submission?.multipleDays?.whoGoing.includes('Client') && submission?.multipleDays?.client?.attendingDays.length > 0")
                            span Client Attending Days
                            b-card-text.m-0
                                span {{ submission?.multipleDays?.client?.attendingDays.join(', ') }}
                        div(class="d-flex flex-column" style="gap: 0.5rem;" v-if="submission?.multipleDays?.whoGoing.includes('Client') && submission?.multipleDays?.client?.lunchByDay.length > 0")
                            span Client Lunch Items
                            div(v-for="lunch in submission?.multipleDays?.client?.lunchByDay" :key="lunch.dayID" class="d-flex flex-column" style="gap: 0.5rem;border: 1px solid #ccc; padding: 1rem;")
                                b-card-text.day-details {{ event?.multipleEvents.find(day => day.id === lunch.dayID).title }}
                                div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                    b-icon-egg-fried
                                    b-card-text.m-0 {{ lunch.lunchOption }}
                        div(class="d-flex flex-column" style="gap: 0.5rem;" v-if="submission?.multipleDays?.whoGoing.includes('Client') && submission?.multipleDays?.client?.beverageByDay.length > 0")
                            span Client Beverage Items
                            div(v-for="beverage in submission?.multipleDays?.client?.beverageByDay" :key="beverage.dayID" class="d-flex flex-column" style="gap: 0.5rem;border: 1px solid #ccc; padding: 1rem;")
                                b-card-text.day-details {{ event?.multipleEvents.find(day => day.id === beverage.dayID).title }}
                                div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                    b-icon-cup-straw
                                    b-card-text.m-0 {{ beverage.beverageOption }}
                        div(style=" border-top: 1px solid #000; margin-top: 1rem; margin-bottom: 1rem;" v-if="submission?.multipleDays?.whoGoing.includes('Caregiver') ")
                        div(class="d-flex align-items-center" style="gap: 0.5rem;" v-if="submission?.caregiverName && submission?.multipleDays?.whoGoing.includes('Caregiver')").my-2
                            b-icon-person-badge
                            b-card-text.m-0(v-if="submission?.caregiverName")
                                span Caregiver Name: {{ submission?.caregiverName }}
                        div(class="d-flex align-items-center" style="gap: 0.5rem;" v-if="submission?.multipleDays?.whoGoing.includes('Caregiver') ")
                            span Caregiver Attending Days
                            b-card-text.m-0
                                span {{ submission?.multipleDays?.caregiver?.attendingDays.join(', ') }}
                        div(class="d-flex flex-column" style="gap: 0.5rem;" v-if="submission?.multipleDays?.whoGoing.includes('Caregiver') && submission?.multipleDays?.caregiver?.lunchByDay.length > 0")
                            span Caregiver Lunch Items
                            div(v-for="lunch in submission?.multipleDays?.caregiver?.lunchByDay" :key="lunch.dayID" class="d-flex flex-column" style="gap: 0.5rem;border: 1px solid #ccc; padding: 1rem;")
                                b-card-text.day-details {{ event?.multipleEvents.find(day => day.id === lunch.dayID).title }}
                                div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                    b-icon-egg-fried
                                    b-card-text.m-0 {{ lunch.lunchOption }}
                        div(class="d-flex flex-column" style="gap: 0.5rem;" v-if="submission?.multipleDays?.whoGoing.includes('Caregiver') && submission?.multipleDays?.caregiver?.beverageByDay.length > 0")
                            span Caregiver Beverage Items
                            div(v-for="beverage in submission?.multipleDays?.caregiver?.beverageByDay" :key="beverage.dayID" class="d-flex flex-column" style="gap: 0.5rem;border: 1px solid #ccc; padding: 1rem;")
                                b-card-text.day-details {{ event?.multipleEvents.find(day => day.id === beverage.dayID).title }}
                                div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                    b-icon-cup-straw
                                    b-card-text.m-0 {{ beverage.beverageOption }}
                        div(style=" border-top: 1px solid #000; margin-top: 1rem; margin-bottom: 1rem;" v-if="submission?.multipleDays?.whoGoing.includes('Other Persons') ")
                        div(v-if="submission?.multipleDays?.whoGoing.includes('Other Persons') && submission?.multipleDays?.otherPersons.length > 0")
                            span Other Persons
                            div(v-for="person in submission?.multipleDays?.otherPersons" :key="person.id" class="d-flex flex-column" style="gap: 0.5rem; border: 1px solid #ccc; padding: 1rem;")
                                b-card-text.m-0 {{ person.name }}
                                div(v-if="person.attendingDays.length > 0" class="d-flex align-items-center" style="gap: 0.5rem;")
                                    span Attending Days:
                                    b-card-text.m-0
                                        span {{ person.attendingDays.join(', ') }}
                                div(v-if="person.lunchByDay.length > 0")
                                    span Lunch Items
                                    div(v-for="lunch in person.lunchByDay" :key="lunch.dayID" class="d-flex flex-column" style="gap: 0.5rem;border: 1px solid #ccc; padding: 1rem;")
                                        b-card-text.day-details {{ event?.multipleEvents.find(day => day.id === lunch.dayID).title }}
                                        div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                            b-icon-egg-fried
                                            b-card-text.m-0 {{ lunch.lunchOption }}
                                div(v-if="person.beverageByDay.length > 0")
                                    span Beverage Items
                                    div(v-for="beverage in person.beverageByDay" :key="beverage.dayID" class="d-flex flex-column" style="gap: 0.5rem;border: 1px solid #ccc; padding: 1rem;")
                                        b-card-text.day-details {{ event?.multipleEvents.find(day => day.id === beverage.dayID).title }}
                                        div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                            b-icon-cup-straw
                                            b-card-text.m-0 {{ beverage.beverageOption }}
        div(v-if="submissions.length > 0 && submissions.filter(submission => submission.status === 'Not Attending').length" class="d-flex flex-column" style="gap: 1rem;")
                    b-card-text.my-2.h6 Total Not Attending: {{submissions.filter(submission => submission.status === 'Not Attending').length}}
                    div(v-for="(submission, index) in submissions" v-if="submission.status && submission.status === 'Not Attending'" :key="submission.id" class="d-flex flex-column" style="gap: 0.5rem; border: 1px solid red; padding: 1rem;").my-1
                        p Not Attending : {{ index + 1 }}
                        div(class="d-flex align-items-center" style="gap: 0.5rem;" v-if="submission?.clientName").my-2
                            b-icon-person-badge
                            b-card-text.m-0(v-if="submission?.clientName")
                                span Client Name: {{ submission?.clientName }}
                        div(class="d-flex align-items-center" style="gap: 0.5rem;" v-if="submission?.caregiverName").my-2
                            b-icon-person-badge
                            b-card-text.m-0(v-if="submission?.caregiverName")
                                span Caregiver Name: {{ submission?.caregiverName }}
                        

    EditEvent(:eventData="eventData" :showEdit.sync="showEditModal" :update:showEdit.sync="showEditModal" :groups="groups" @updatedEvent="eventUpdated")
    template(#modal-footer)

        b-button(variant="primary" @click="showModalDetails = false") Close
</template>
<script>
/* eslint-disable */
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import EditEvent from "./EditEvent.vue";
export default {
  props: ["eventData", "showDetails", "groups"],
  components: {
    EditEvent,
  },
  data() {
    return {
      event: {},
      loading: false,
      showEditModal: false,
      submissions: [],
      notAttending: [],
    };
  },
  computed: {
    showModalDetails: {
      get() {
        return this.showDetails;
      },
      set(val) {
        this.$emit("update:showDetails", val);
      },
    },
  },
  methods: {
    async eventUpdated() {
      this.$emit("updatedEvent");
    },
  },

  watch: {
    showDetails(newVal) {
      if (newVal && this.eventData) {
        this.event = JSON.parse(this.eventData.crb5c_eventjson);
        console.log("event", this.event);
        if (
          this.eventData.submissions &&
          this.eventData.submissions.length > 0
        ) {
          this.submissions = this.eventData.submissions;
          console.log("submissions", this.submissions);
        }
       
      }
      console.log(this.groups);
    },
  },
};
</script>

<style lang="scss" scoped>
.badge-local {
  background-color: #d1e9f6;
  color: #000;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  font-size: 0.875rem;
}
.event-details {
  h5 {
    font-size: 1rem;
  }
}
</style>
