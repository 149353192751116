<template lang="pug">
b-modal(v-model="showModal", size="md", centered   no-close-on-backdrop title="Add People to Event" scrollable)
    template(#modal-header)
        b-card-text.m-0.h5 Add People to Event {{event.name}}
    .container 
        div(v-if="event.isSingleDay && event.singleDay" class="d-flex flex-column food-selector")
                b-card-text.my-3.h6 Select Who will be attending
                b-form-checkbox-group.my-3(v-model="foodSelected.singleDay.whoGoing" :options="peopleGoingOptions")
                div(v-if="foodSelected.singleDay.whoGoing.includes('Client')")
                    small.m-0.text-muted Select the Client & Caregiver Selector will appear
                    b-spinner.my-2(v-if="!clients.length")
                    v-select(v-if="clients.length" id="manually-add-client-name"  :options="clients.map(item => ({id: item.crb5c_fow_customerid, name: item.crb5c_no}))" v-model="foodSelected.singleDay.client.identifier" label="name" placeholder="Select Client").mb-2
                div(v-if="foodSelected.singleDay.whoGoing.includes('Client') && event.singleDay.food.lunch.length>0 && foodSelected.singleDay.client.identifier?.id" class="d-flex flex-column rounded-sm shadow-sm p-3" style="gap:1rem;")
                    b-card-text.h6 Select Lunch Option for Client
                    b-form-radio-group(v-model="foodSelected.singleDay.client.lunch" :options="event.singleDay.food.lunch.map(lunch => lunch.name)" :key="event.singleDay.food.lunch.map(lunch => lunch.id).join(',')")
                    div(v-if="event.singleDay.food.beverages.length > 0" class="d-flex flex-column" style="gap:0.5rem;")
                        b-card-text.mt-3.h6 Select Beverage Option for Client
                        b-form-radio-group(v-model="foodSelected.singleDay.client.beverage" :options="event.singleDay.food.beverages.map(beverage => beverage.name)" :key="event.singleDay.food.beverages.map(beverage => beverage.id).join(',')")
                div(v-if="foodSelected.singleDay.whoGoing.includes('Caregiver') && getCaregiverFromClient && foodSelected.singleDay.client.identifier?.id" class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;").mt-3
                    small.mb-0.mt-4.text-muted() Select the Caregiver
                    b-spinner.my-2(v-if="!getCaregiverFromClient")
                    v-select(v-if="foodSelected.singleDay.whoGoing.includes('Caregiver') && getCaregiverFromClient" id="manually-add-caregiver-name" :options="getCaregiverFromClient.map(item => ({id: item.crb5c_Caregiver.crb5c_fow_caregiverid, name: item.crb5c_Caregiver.crb5c_name}))" v-model="foodSelected.singleDay.caregiver.identifier" label="name" placeholder="Select Caregiver").mb-2
                div(v-if="foodSelected.singleDay.whoGoing.includes('Caregiver') && event.singleDay.food.lunch.length>0 && foodSelected.singleDay.caregiver.identifier?.id" class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;")
                    b-card-text.h6 Select Lunch Option for Caregiver
                    b-form-radio-group(v-model="foodSelected.singleDay.caregiver.lunch" :options="event.singleDay.food.lunch.map(lunch => lunch.name)" :key="event.singleDay.food.lunch.map(lunch => lunch.id).join(',')")
                    div(v-if="event.singleDay.food.beverages.length > 0" class="d-flex flex-column" style="gap: 1rem;")
                        b-card-text.mt-3.h6 Select Beverage Option for Caregiver
                        b-form-radio-group(v-model="foodSelected.singleDay.caregiver.beverage" :options="event.singleDay.food.beverages.map(beverage => beverage.name)" :key="event.singleDay.food.beverages.map(beverage => beverage.id).join(',')")
                div(v-if="foodSelected.singleDay.whoGoing.includes('Other Persons')" class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;").mt-3
                    b-card-text.h6 Select Lunch Option for Other Persons
                    div(v-if="foodSelected.singleDay.otherPersons.length > 0" class="d-flex flex-column" style="gap: 1rem;")
                        b-card-text.h6 Other Persons
                        div(v-for="person in foodSelected.singleDay.otherPersons" :key="person.name" class="d-flex flex-column" style="gap: 0.5rem; border: 1px solid #ccc; padding: 1rem;")
                            div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                b-icon(icon="person-badge")
                                b-card-text.m-0 {{ person.name }}
                            div(class="d-flex flex-column" v-if="event.singleDay.food.lunch.length>0")
                                b-card-text.mt-3.h6 Select Lunch Option for {{ person.name }}
                                b-form-radio-group(v-model="person.lunch" :options="event.singleDay.food.lunch.map(lunch => lunch.name)" :key="event.singleDay.food.lunch.map(lunch => lunch.id).join(',')")
                                div(v-if="event.singleDay.food.beverages.length > 0" class="d-flex flex-column" style="gap: 1rem;")
                                    b-card-text.mt-3.h6 Select Beverage Option for {{ person.name }}
                                    b-form-radio-group(v-model="person.beverage" :options="event.singleDay.food.beverages.map(beverage => beverage.name)" :key="event.singleDay.food.beverages.map(beverage => beverage.id).join(',')")
                                b-button(@click="deleteOtherPersonSingleDay(person.id)" variant="danger").w-100.my-2 Remove Person
                    b-form-group(label="Enter Name of Other Person")
                        b-form-input(v-model="tempOtherPersonName" type="text").mb-2
                        b-button(@click="addOtherPersonSingleDay" variant="primary").w-100 Add Person
        div(v-if="!event.isSingleDay && event.multipleEvents.length > 0")
                    
                    b-card-text.mt-3.h6 Select who will be accompanying Client
                    b-form-checkbox-group(v-model="foodSelected.multipleDays.whoGoing" :options="peopleGoingOptions").mb-4
                    b-spinner.my-2(v-if="!clients.length")
                    div(v-if="foodSelected.multipleDays.whoGoing.includes('Client')")
                        small.m-0.text-muted Select the Client & Caregiver Selector will appear
                        v-select(v-if="clients.length" id="manually-add-client-name"  :options="clients.map(item => ({id: item.crb5c_fow_customerid, name: item.crb5c_no}))" v-model="foodSelected.multipleDays.client.identifier" label="name" placeholder="Select Client").mb-2
                    div(v-if="foodSelected.multipleDays.whoGoing.includes('Client') && foodSelected.multipleDays.client.identifier?.id" class="d-flex flex-column whichDay-selection" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;").mb-4
                        
                        b-form-checkbox-group(v-model="foodSelected.multipleDays.client.attendingDays" :options="event.multipleEvents.map(day => new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" :key="event.multipleEvents.map(day => day.id).join(',')" ) 
                        div(v-for="(day, index) in event.multipleEvents" :key="day.id" v-if="foodSelected.multipleDays.client.attendingDays.includes(new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" class="d-flex flex-column")
                            div(v-if="day.food.lunch.length > 0"  class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;")
                                b-card-text.h6 Select Lunch Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                b-form-radio-group( :options="day.food.lunch.map(lunch => lunch.name)" :key="day.food.lunch.map(lunch => lunch.id).join(',')" :value="getLunchOptionForDay(day.id, foodSelected.multipleDays.client.lunchByDay)" @change="updateLunchOptionForDay(day.id, $event, 'client')")
                            div(v-if="day.food.beverages.length > 0"   class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;")
                                b-card-text.mt-3.h6 Select Beverage Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                b-form-radio-group( :options="day.food.beverages.map(beverage => beverage.name)" :key="day.food.beverages.map(beverage => beverage.id).join(',')" :value="getBeverageOptionForDay(day.id, foodSelected.multipleDays.client.beverageByDay)" @change="updateBeverageOptionForDay(day.id, $event, 'client')")


                    div(class="d-flex flex-column" v-if="getCaregiverFromClient && foodSelected.multipleDays.whoGoing.includes('Caregiver')").mb-1
                        small.m-0.text-muted Select the Caregiver 
                        b-spinner.my-2(v-if="!getCaregiverFromClient")
                        v-select(id="manually-add-caregiver-name" v-if="getCaregiverFromClient" :options="getCaregiverFromClient.map(item => ({id: item.crb5c_Caregiver.crb5c_fow_caregiverid, name: item.crb5c_Caregiver.crb5c_name}))" v-model="foodSelected.multipleDays.caregiver.identifier" label="name" placeholder="Select Caregiver").mb-2
                    div(v-if="foodSelected.multipleDays.whoGoing.includes('Caregiver') && foodSelected.multipleDays.caregiver.identifier?.id" class="d-flex flex-column whichDay-selection" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;").mb-4
                        b-card-text.h6 Select Days for Caregiver
                        b-form-checkbox-group(v-model="foodSelected.multipleDays.caregiver.attendingDays" :options="event.multipleEvents.map(day => new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" :key="event.multipleEvents.map(day => day.id).join(',')") 
                        div(v-for="(day, index) in event.multipleEvents" :key="day.id" v-if="foodSelected.multipleDays.caregiver.attendingDays.includes(new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" class="d-flex flex-column" )
                            div(v-if="day.food.lunch.length > 0" class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;")
                                b-card-text.h6 Select Lunch Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                b-form-radio-group(:options="day.food.lunch.map(lunch => lunch.name)" :key="day.food.lunch.map(lunch => lunch.id).join(',')" :value="getLunchOptionForDay(day.id, foodSelected.multipleDays.caregiver.lunchByDay)" @change="updateLunchOptionForDay(day.id, $event, 'caregiver')")
                            div(v-if="day.food.beverages.length > 0" class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;")
                                b-card-text.mt-3.h6 Select Beverage Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                b-form-radio-group(:options="day.food.beverages.map(beverage => beverage.name)" :key="day.food.beverages.map(beverage => beverage.id).join(',')" :value="getBeverageOptionForDay(day.id, foodSelected.multipleDays.caregiver.beverageByDay)" @change="updateBeverageOptionForDay(day.id, $event, 'caregiver')")
                    div(v-if="foodSelected.multipleDays.whoGoing.includes('Other Persons')" class="d-flex flex-column whichDay-selection" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;").mb-4

                        b-card-text Add Other Persons
                        b-form-group(label="Enter Name of Other Person")
                            b-form-input(v-model="tempOtherPersonName" type="text").mb-2
                            b-button(@click="addOtherPersonMultipleDays" variant="primary").w-100 Add Person
                        div(v-if="foodSelected.multipleDays.otherPersons.length > 0" class="d-flex flex-column" style="gap: 1rem;")
                            b-card-text.h6 Other Persons
                            div(v-for="person in foodSelected.multipleDays.otherPersons" :key="person.name" class="d-flex flex-column").my-1
                                div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                    b-icon-person-badge
                                    b-card-text.m-0 {{ person.name }}
                                div(class="d-flex flex-column")
                                    b-card-text.mt-3.h6 Select Days for {{ person.name }}
                                    b-form-checkbox-group(v-model="person.attendingDays" :options="event.multipleEvents.map(day => new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" :key="event.multipleEvents.map(day => day.id).join(',')") 
                                    div(v-for="(day, index) in event.multipleEvents" :key="day.id" v-if="person.attendingDays.includes(new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" class="d-flex flex-column" ).my-1
                                        div(v-if="day.food.lunch.length > 0" class="d-flex flex-column" style="gap: 1rem;")
                                            b-card-text.h6 Select Lunch Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                            b-form-radio-group(:options="day.food.lunch.map(lunch => lunch.name)" :key="day.food.lunch.map(lunch => lunch.id).join(',')" :value="getLunchOptionForDay(day.id, foodSelected.multipleDays.client.lunchByDay)" @change="updateLunchOptionForDay(day.id, $event, 'otherPersons', person.id)")
                                        div(v-if="day.food.beverages.length > 0" class="d-flex flex-column" style="gap: 1rem;")
                                            b-card-text.mt-3.h6 Select Beverage Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                            b-form-radio-group(:options="day.food.beverages.map(beverage => beverage.name)" :key="day.food.beverages.map(beverage => beverage.id).join(',')" :value="getBeverageOptionForDay(day.id, foodSelected.multipleDays.client.beverageByDay)" @change="updateBeverageOptionForDay(day.id, $event, 'otherPersons', person.id)")
                                    
                                    b-button(@click="deletePersonMultipleDay(person.id)" variant="danger").w-100.my-2 Remove Person 
        div(class="total-price")
            b-card-text.m-0.h6 Total Price: {{ event.isSingleDay ? singleDayTotalCost : multipleDaysTotalCost }}
    template(#modal-footer)
        b-button(@click="closeModalCleanup" variant="danger" :disabled="loading") Cancel
        b-button(@click="submit" variant="primary" :disabled="loading") 
            span(v-if="loading")
                b-spinner(small grow)
            span(v-else) Submit

</template>

<script>
/* eslint-disable */
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { EVENTS_NAMESPACE, GETTERS, MUTATIONS, ACTIONS } from "../../store/Modules/Events/types-events";
import { v4 as uuid } from "uuid";
import { validateEvent } from '../../utils/eventValidator';
import axios from 'axios';
    export default {
        props: {
            event: {
                type: Object,
                required: true
            },
            show: {
                type: Boolean,
                required: true
            },
            eventID: {
                type: String,
                required: true
            }
        },
        data(){
            return{
                clients: [],
                caregiverClientLinks: [],
                loading :false, 
                tempOtherPersonName: "",
                foodSelected: {
                singleDay: {
                    whoGoing: [],
                    client: {
                        identifier:{},
                        lunch: "",
                        beverage: "",
                    },
                    caregiver: {
                        identifier:{},
                        lunch: "",
                        beverage: "",
                    },
                    otherPersons: []
                },
                multipleDays: {
                    whoGoing: ["Client"],
                    client: {
                        identifier:{},
                        attendingDays: [],
                        lunchByDay: [
                            
                        ],
                        beverageByDay: [
                        
                        ]

                    },
                    caregiver: {
                        identifier:{},
                        attendingDays: [],
                        lunchByDay: [],
                        beverageByDay: []
                    },
                    otherPersons: []



                },
                remarks:""
            },
            peopleGoingOptions: [
        { text: "Client", value: "Client", disabled: false },
        { text: "Caregiver", value: "Caregiver" },
        { text: "Other Persons", value: "Other Persons" }
      ],
            }
        },
        async mounted(){
            const {data} = await axios.get(`/api/every_client`);
            this.clients = data.cli_list;
            this.caregiverClientLinks = data.cgLinks.filter(item => item.crb5c_Client?.crb5c_fow_customerid);
        },
        computed:{
            multipleDaysTotalCost() {
            let total = 0;
            
            if (this.foodSelected.multipleDays.whoGoing.includes('Caregiver')) {
                for (let day of this.foodSelected.multipleDays.caregiver.attendingDays) {
                    let dayIndex = this.event.multipleEvents.findIndex(eventDay => new Date(eventDay.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) === day);
                    if (dayIndex !== -1) {
                        total += parseFloat(this.event.multipleEvents[dayIndex].pricePerPerson);
                    }
                }
            }
            if (this.foodSelected.multipleDays.whoGoing.includes('Other Persons')) {
                for (let person of this.foodSelected.multipleDays.otherPersons) {
                    for (let day of person.attendingDays) {
                        let dayIndex = this.event.multipleEvents.findIndex(eventDay => new Date(eventDay.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) === day);
                        if (dayIndex !== -1) {
                            total += parseFloat(this.event.multipleEvents[dayIndex].pricePerPerson);
                        }
                    }
                }
            }
            return total;
        },
            singleDayTotalCost() {
            let total = 0;
            
            if (this.foodSelected.singleDay.whoGoing.includes('Caregiver')) {
                total += parseFloat(this.event.singleDay.pricePerPerson);
            }
            if (this.foodSelected.singleDay.whoGoing.includes('Other Persons')) {
                total += this.foodSelected.singleDay.otherPersons.length * parseFloat(this.event.singleDay.pricePerPerson);
            }
            return total;
        },
            showModal: {
                get() {
                    return this.show;
                },
                set(value) {
                    this.$emit('update:show', value);
                }
            },
            ...mapState({
                listAllClientAndCaregiverData: state => (state[EVENTS_NAMESPACE].listAllClientAndCaregiverData),
            }),
            getCaregiverFromClient() {
                if(this.event?.isSingleDay){
                    console.log(this.foodSelected.singleDay.client?.identifier)
                    const clientId = this.foodSelected.singleDay?.client?.identifier?.id;
                    console.log(clientId)
                    if(!clientId) return
                    const clientAndCaregiverItem = this.caregiverClientLinks.filter(item => item.crb5c_Caregiver?.crb5c_fow_caregiverid && item.crb5c_Client?.crb5c_fow_customerid === clientId);
                    // console.log('clientAndCaregiverItem',clientAndCaregiverItem)
                    if (!clientAndCaregiverItem) {
                        return null;
                    }
                    return clientAndCaregiverItem;
                }
                else {
                    const clientId = this.foodSelected.multipleDays?.client?.identifier?.id;
                    if (!clientId) return null;
                    const clientAndCaregiverItem = this.caregiverClientLinks.filter(item => item.crb5c_Caregiver?.crb5c_fow_caregiverid && item.crb5c_Client?.crb5c_fow_customerid === clientId);
                    // console.log('clientAndCaregiverItem',clientAndCaregiverItem)
                    if (!clientAndCaregiverItem) {
                        return null;
                    }
                    return clientAndCaregiverItem;
                }   

        },
        },
        methods:{
            async submit(){
                try{
                    this.loading = true;
                    if (this.event.isSingleDay) {
                const validation = validateEvent(this.event, this.foodSelected);
                if (!validation.isValid) {
                    console.log(validation.message)
                    this.$bvToast.toast(validation.message, {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                    });
                    return;
                }
                const payload = {
                    json: {
                        name: this.event.name,
                        eventID: this.eventID,
                        totalAttending: this.foodSelected.singleDay.whoGoing.includes('Client') + this.foodSelected.singleDay.whoGoing.includes('Caregiver') + this.foodSelected.singleDay.otherPersons.length,
                        totalCost: this.singleDayTotalCost,
                        caregiverName: this.foodSelected.singleDay.caregiver.identifier?.name,
                        clientName:     this.foodSelected.singleDay.client.identifier?.name,
                        singleDay: {
                            whoGoing: Array.from([...new Set(this.foodSelected.singleDay.whoGoing)]),
                            client: this.foodSelected.singleDay.client,
                            caregiver: this.foodSelected.singleDay.caregiver,
                            otherPersons: this.foodSelected.singleDay.otherPersons
                        },
                        remarks: this.foodSelected.remarks ?? ""
                    },
                    client: this.foodSelected.singleDay.client?.identifier?.id,
                    caregiver: this.foodSelected.singleDay.caregiver?.identifier?.id,

                }
                const res = await axios.post('/api/events/v2/addSubmission', payload);
                if (res) {
                    console.log("res", res);
                    this.$bvToast.toast('Submitted event attendance', {
                        title: 'Success',
                        variant: 'success',
                        solid: true
                    });
                    this.closeModalCleanup();
                    setTimeout(() => {
                        this.$emit('refresh');
                    }, 2000);
                    
                } else {
                    this.$bvToast.toast('Error submitting event attendance', {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                    });
                    

                }

                
                } else{
                    const validation = validateEvent(this.event, this.foodSelected);
                    if (!validation.isValid) {
                        this.$bvToast.toast(validation.message, {
                            title: 'Error',
                            variant: 'danger',
                            solid: true
                        });
                        return;
                    }
                    const payload = {
                    json: {
                        name: this.event.name,
                        eventID: this.eventID,
                        totalAttending: this.foodSelected.multipleDays.whoGoing.includes('Client') + this.foodSelected.multipleDays.whoGoing.includes('Caregiver') + this.foodSelected.multipleDays.otherPersons.length,
                        totalCost: this.multipleDaysTotalCost,
                        caregiverName: this.foodSelected.multipleDays.caregiver.identifier?.name,
                        clientName: this.foodSelected.multipleDays.client.identifier?.name,
                        multipleDays: {
                            whoGoing: Array.from([...new Set(this.foodSelected.multipleDays.whoGoing)]),
                            client: this.foodSelected.multipleDays.client,
                            caregiver: this.foodSelected.multipleDays.caregiver,
                            otherPersons: this.foodSelected.multipleDays.otherPersons
                        },
                        remarks: this.foodSelected.remarks ?? ""
                    },
                    client: this.foodSelected.multipleDays.client?.identifier?.id,
                    caregiver:  this.foodSelected.multipleDays.caregiver?.identifier?.id,

                }
                const res = await axios.post('/api/events/v2/addSubmission', payload);
                if (res) {
                    
                    this.$bvToast.toast('Submitted event attendance', {
                        title: 'Success',
                        variant: 'success',
                        solid: true
                    });
                    
                    this.closeModalCleanup();
                    setTimeout(() => {
                        this.$emit('refresh');
                    }, 2000);
                    
                } else {
                    this.$bvToast.toast('Error submitting event attendance', {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                    });
                    

                }
                }

                }catch(err){
                    console.log(err)
                    const jsonError = err.response.data;
                    this.$bvToast.toast(jsonError.message ? jsonError.message :"Something went wrong", {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                    });
                }
                finally{
                    this.loading = false;
                    
                }
            },


            addOtherPersonSingleDay() {
            if (this.tempOtherPersonName) {
                this.foodSelected.singleDay.otherPersons.push({
                    id: uuid(),
                    name: this.tempOtherPersonName,
                    lunch: "",
                    beverage: "",
                });
                this.tempOtherPersonName = "";
            }
        },
        deleteOtherPersonSingleDay(id) {
            let index = this.foodSelected.singleDay.otherPersons.findIndex(person => person.id === id);
            if(index !== -1){
                this.foodSelected.singleDay.otherPersons.splice(index, 1);
            }
        },
        getLunchOptionForDay(dayID, lunchByDay) {
            const day = lunchByDay.find(day => day.dayID === dayID);
            return day ? day.lunchOption : null;
        },
        getBeverageOptionForDay(dayID, beverageByDay) {
            const day = beverageByDay.find(day => day.dayID === dayID);
            return day ? day.beverageOption : null;
        },
        updateLunchOptionForDay(dayID, selectedLunch, personType,personID=null) {
            if(personID){
                const personIndex = this.foodSelected.multipleDays.otherPersons.findIndex(person => person.id === personID);
                if (personIndex !== -1) {
                    const dayIndex = this.foodSelected.multipleDays.otherPersons[personIndex].lunchByDay.findIndex(day => day.dayID === dayID);
                    if (dayIndex !== -1) {
                        
                        this.foodSelected.multipleDays.otherPersons[personIndex].lunchByDay[dayIndex].lunchOption = selectedLunch;
                    } else {
                        
                        this.foodSelected.multipleDays.otherPersons[personIndex].lunchByDay.push({ dayID, lunchOption: selectedLunch });
                    }
                }
            }
            else{
            const dayIndex = this.foodSelected.multipleDays[personType].lunchByDay.findIndex(day => day.dayID === dayID);
            if (dayIndex !== -1) {
                
                this.foodSelected.multipleDays[personType].lunchByDay[dayIndex].lunchOption = selectedLunch;
            } else {
                
                this.foodSelected.multipleDays[personType].lunchByDay.push({ dayID, lunchOption: selectedLunch });
            }
        }
        },
        updateBeverageOptionForDay(dayID, selectedBeverage, personType, personID=null) {
            if(personID){
                const personIndex = this.foodSelected.multipleDays.otherPersons.findIndex(person => person.id === personID);
                if (personIndex !== -1) {
                    const dayIndex = this.foodSelected.multipleDays.otherPersons[personIndex].beverageByDay.findIndex(day => day.dayID === dayID);
                    if (dayIndex !== -1) {
                        
                        this.foodSelected.multipleDays.otherPersons[personIndex].beverageByDay[dayIndex].beverageOption = selectedBeverage;
                    } else {
                        
                        this.foodSelected.multipleDays.otherPersons[personIndex].beverageByDay.push({ dayID, beverageOption: selectedBeverage });
                    }
                }
                // console.log(this.foodSelected)
            }
            else{
            const dayIndex = this.foodSelected.multipleDays[personType].beverageByDay.findIndex(day => day.dayID === dayID);
            if (dayIndex !== -1) {
                
                this.foodSelected.multipleDays[personType].beverageByDay[dayIndex].beverageOption = selectedBeverage;
            } else {
                
                this.foodSelected.multipleDays[personType].beverageByDay.push({ dayID, beverageOption: selectedBeverage });
            }
        }
        },
        deletePersonMultipleDay(id) {
            let index = this.foodSelected.multipleDays.otherPersons.findIndex(person => person.id === id);
            if (index !== -1) {
                this.foodSelected.multipleDays.otherPersons.splice(index, 1);
            }

        },
        addOtherPersonMultipleDays() {
            if (this.tempOtherPersonName) {
                this.foodSelected.multipleDays.otherPersons.push({
                    id: uuid(),
                    name: this.tempOtherPersonName,
                    attendingDays: [],
                    lunchByDay: [],
                    beverageByDay: []
                });
                this.tempOtherPersonName = "";
            }
        },
        deleteOtherPersonSingleDay(id) {
            this.foodSelected.singleDay.otherPersons = this.foodSelected.singleDay.otherPersons.filter(person => person.id !== id);
        },
        closeModalCleanup() {
            this.foodSelected = {
                singleDay: {
                    whoGoing: [],
                    client: {
                        identifier: {},
                        lunch: "",
                        beverage: "",
                    },
                    caregiver: {
                        identifier: {},
                        lunch: "",
                        beverage: "",
                    },
                    otherPersons: []
                },
                multipleDays: {
                    whoGoing: ["Client"],
                    client: {
                        identifier: {},
                        attendingDays: [],
                        lunchByDay: [],
                        beverageByDay: []

                    },
                    caregiver: {
                        identifier: {},
                        attendingDays: [],
                        lunchByDay: [],
                        beverageByDay: []
                    },
                    otherPersons: []
                },
                remarks: ""
            };
            this.showModal = false;
        }
        },
        watch: {
            "foodSelected.singleDay.whoGoing": {
                handler: function (val) {
                    if (!val.includes("Client")) {
                        console.log("client unselected")
                        this.foodSelected.singleDay.client = {
                            identifier: {},
                            lunch: "",
                            beverage: "",
                        };
                    }
                    if (!val.includes("Caregiver")) {
                        this.foodSelected.singleDay.caregiver = {
                            identifier: {},
                            lunch: "",
                            beverage: "",
                        };
                    }
                    if (!val.includes("Other Persons")) {
                        this.foodSelected.singleDay.otherPersons = [];
                    }
                },
                deep: true,
            },
            "foodSelected.singleDay.client.identifier":{
                handler: function(val){
                    if(val){
                        this.foodSelected.singleDay.client.lunch = "";
                        this.foodSelected.singleDay.client.beverage = "";
                        this.foodSelected.singleDay.caregiver = {
                            identifier: {},
                            lunch: "",
                            beverage: "",
                        };
                    }
                },
                deep: true,
            },
            "foodSelected.multipleDays.whoGoing": {
                handler: function (val) {
                    if (!val.includes("Client")) {
                        this.foodSelected.multipleDays.client = {
                            identifier: {},
                            attendingDays: [],
                            lunchByDay: [],
                            beverageByDay: []
                        };
                    }
                    if (!val.includes("Caregiver")) {
                        this.foodSelected.multipleDays.caregiver = {
                            identifier: {},
                            attendingDays: [],
                            lunchByDay: [],
                            beverageByDay: []
                        };
                    }
                    if (!val.includes("Other Persons")) {
                        this.foodSelected.multipleDays.otherPersons = [];
                    }
                },
                deep: true,
            },
            "foodSelected.multipleDays.client.identifier": {
                handler: function (val) {
                    if (val) {
                        this.foodSelected.multipleDays.client.attendingDays = [];
                        this.foodSelected.multipleDays.client.lunchByDay = [];
                        this.foodSelected.multipleDays.client.beverageByDay = [];
                        this.foodSelected.multipleDays.caregiver = {
                            identifier: {},
                            attendingDays: [],
                            lunchByDay: [],
                            beverageByDay: []
                        };
                    }
                },
                deep: true,
            },
        }
        
    }
</script>

<style lang="scss" scoped>

</style>